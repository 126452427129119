import { Box, Button, Card, CardActionArea, Grid, Popover, styled, Typography, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import TuneTwoToneIcon from '@mui/icons-material/TuneTwoTone';
import PeopleTwoToneIcon from '@mui/icons-material/PeopleTwoTone';
import AdminPanelSettingsTwoToneIcon from '@mui/icons-material/AdminPanelSettingsTwoTone';
import HandymanTwoToneIcon from '@mui/icons-material/HandymanTwoTone';
import DisplaySettingsTwoToneIcon from '@mui/icons-material/DisplaySettingsTwoTone';
import { NavLink } from 'react-router-dom';
import { nav } from 'src/statics/navigations';
import { startWalkthrough, stopWalkthrough } from '../../../redux/thunks/guidethrough.thunk';
import { useDispatch } from '../../../redux/store';
import { canUserAccessRoute } from 'src/utils/RouteHelper';

const StyledButton = styled(Button)(
    ({ theme }) => `
        padding: ${theme.spacing(1, 2)};
        margin-right: ${theme.spacing(1)};
        height: ${theme.spacing(6)};
        color: ${theme.colors.secondary.dark};
        font-size: 16px;
        .MuiSvgIcon-root: {
            color: ${theme.colors.secondary.dark};
            transition: ${theme.transitions.create(['color'])};
        },
        &:hover: {
            background-color: ${theme.colors.secondary.main};
            color: ${theme.palette.getContrastText(theme.colors.secondary.main)};

            .MuiSvgIcon-root: {
                color: ${theme.palette.getContrastText(theme.colors.secondary.main)};
            }
        }
        ${theme.breakpoints.down('lg')} {
            font-size: 14px;
            .MuiSvgIcon-root {
                height: 1.3rem;
                width: 1.3rem;
            }
        }
  `
);
const StyledCard = styled(Card)(
    ({ theme }) => `
        .MuiCardActionArea-root.Mui-focusVisible {
            background-color: ${theme.colors.secondary.light};
        }
  `
);

function HeaderTools() {
    const { t } = useTranslation();
    const theme = useTheme();
    const ref = useRef<any>(null);
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!isOpen) {
            dispatch(stopWalkthrough());
        }
    }, [isOpen]);

    const isAllowedToAccessAdminPage = canUserAccessRoute(nav.TOOLS.sub.ADMIN.path);
    const isDevelopment = process.env.REACT_APP_STAGE == null || process.env.REACT_APP_STAGE === 'development';

    return (
        <>
            <StyledButton
                id={'toolsLink'}
                ref={ref}
                onClick={() => {
                    setOpen(true);
                    // dispatch(startWalkthrough('toolsAndSettings'));
                }}
                startIcon={<HandymanTwoToneIcon sx={{ height: '1.6rem', width: '1.6rem' }} />}
            >
                {t('toolsAndSettings')}
            </StyledButton>

            <Popover
                disableScrollLock
                anchorEl={ref.current}
                onClose={() => setOpen(false)}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                sx={{
                    '.MuiPopover-paper': {
                        background: theme.colors.gradients.blue3
                    }
                }}
                PaperProps={{
                    sx: { display: 'flex' }
                }}
            >
                <Box sx={{ width: 450 }} p={3} id={'toolsAndSettingsBox'}>
                    <Typography variant="h4" gutterBottom textAlign="center" sx={{ color: theme.colors.alpha.trueWhite[100], fontSize: theme.typography.pxToRem(18) }}>
                        {t('toolsAndSettings')}
                    </Typography>
                    <Typography variant="subtitle2" textAlign="center" sx={{ color: theme.colors.alpha.trueWhite[70] }}>
                        {t('toolsAndSettingsExplanation')}
                    </Typography>
                    <Grid container mt={1} spacing={2}>
                        <Grid item xs={12} sm={isAllowedToAccessAdminPage ? 6 : 12}>
                            <StyledCard>
                                <CardActionArea sx={{ p: 2 }} component={NavLink} to={nav.TOOLS.sub.DEVELOPER_SETTINGS.path} onClick={() => setOpen(false)}>
                                    <SettingsTwoToneIcon sx={{ mb: 1 }} />
                                    <Typography variant="h4" noWrap>
                                        {t(nav.TOOLS.sub.DEVELOPER_SETTINGS.caption)}
                                    </Typography>
                                </CardActionArea>
                            </StyledCard>
                        </Grid>
                        {isAllowedToAccessAdminPage && (
                            <Grid item xs={12} sm={6}>
                                <StyledCard>
                                    <CardActionArea sx={{ p: 2 }} component={NavLink} to={nav.TOOLS.sub.ADMIN.path} onClick={() => setOpen(false)}>
                                        <AdminPanelSettingsTwoToneIcon sx={{ mb: 1 }} />
                                        <Typography variant="h4" noWrap>
                                            {t(nav.TOOLS.sub.ADMIN.caption)}
                                        </Typography>
                                    </CardActionArea>
                                </StyledCard>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={12}>
                            <StyledCard>
                                <CardActionArea sx={{ p: 2 }} component={NavLink} to={nav.TOOLS.sub.CUSTOMERS.path} onClick={() => setOpen(false)}>
                                    <PeopleTwoToneIcon sx={{ mb: 1 }} />
                                    <Typography variant="h4" noWrap>
                                        {t(nav.TOOLS.sub.CUSTOMERS.caption)}
                                    </Typography>
                                </CardActionArea>
                            </StyledCard>
                        </Grid>
                        {isDevelopment && (
                            <Grid item xs={12} sm={12}>
                                <StyledCard>
                                    <CardActionArea sx={{ p: 2 }} component={NavLink} to={nav.TOOLS.sub.CALC_EDITOR.path} onClick={() => setOpen(false)}>
                                        <TuneTwoToneIcon sx={{ mb: 1 }} />
                                        <Typography variant="h4" noWrap>
                                            {t(nav.TOOLS.sub.CALC_EDITOR.caption)}
                                        </Typography>
                                    </CardActionArea>
                                </StyledCard>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Popover>
        </>
    );
}

export default HeaderTools;
