import { Box, Button, Card, CircularProgress, Collapse, Divider, IconButton, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { wT } from 'src/utils/wizardTranslations';
import store, { RootState } from 'src/redux/store';
import ItemShowButton from './ItemShowButton';
import { ProCalcUpdateTypes, updateProCalc } from 'src/redux/shared/proCalc.common';
import { ProCalcUpdateLoading } from 'src/redux/slices/proCalc.reducer';
import { ItemResource, SettingsResource, ValueResource } from 'src/backend/coreCalc';
import { ViewerDetails, ViewerTypes } from '../../ProCalc.types';
import QuantityInput from 'src/components/input/QuantityInput/QuantityInput';
import ChangeableText from 'src/components/input/ChangeableText/ChangeableText';
import ItemMessages from './ItemMessages';
import InputSections from 'src/components/input/InputSections/InputSections';
import { getCalcRelevantVariableInternalIdentifiers, getInputVariablesGroupedBySection, renderImage } from 'src/utils/CalcHelpers';
import ComputationPrice from 'src/components/display/ComputationPrice/ComputationPrice';
import ItemStats from './ItemStats';
import ItemSurcharges from './ItemSurcharges';
import Item3dViewerDialog from './FileViewer/Item3dViewerDialog';
import ItemPdfViewerDialog from './FileViewer/ItemPdfViewerDialog';
import ItemDxfViewerDialog from './FileViewer/ItemDxfViewerDialog';

const Header = styled(Box)(
    ({ theme }) => `
        display: grid;
        grid-template-columns: auto 1fr auto;
    `
);
const Center = styled(Stack)(
    ({ theme }) => `
        flex-direction: row;
        min-height: 20rem;
        max-height: 60rem;
    `
);
const Footer = styled(Stack)(
    ({ theme }) => `
        flex-direction: row;
        align-items: center;
        padding: ${theme.spacing(1)} ${theme.spacing(2)};
        overflow: auto;
    `
);

const StyledImageWrapper = styled(Stack)(
    ({ theme }) => `
        position: relative;
        max-width: 20rem;
        width: 100%;
        justify-content: center;
        padding: ${theme.spacing(1)};

        ${theme.breakpoints.down('xl')} {
            max-width: 14rem;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    `
);

interface Props {
    item: ItemResource;
    settings: SettingsResource;
    isUpdateLoading?: ProCalcUpdateLoading;
    showCompact?: boolean;
    viewerDetails?: ViewerDetails;
    setCurrentViewer?: (currentViewer: { item: ItemResource; dialogType: ViewerTypes }) => void;
}

const Item: React.FC<Props> = ({ item, settings, isUpdateLoading, showCompact, viewerDetails, setCurrentViewer }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const { t } = useTranslation();

    const calcRelevantVariableInternalIdentifiers = useMemo(() => getCalcRelevantVariableInternalIdentifiers(item.supportedComputations, item.variables), [item.supportedComputations, item.variables]);
    const inputVariablesGroupedBySection = useMemo(() => getInputVariablesGroupedBySection(item.variables), [item.variables]);
    const usedComputation = useMemo(
        () => (item.supportedComputations || []).find((computation) => computation.id === item.selectedComputationId),
        [item.supportedComputations, item.selectedComputationId]
    );

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const removeItem = () => {
        dispatch(updateProCalc(ProCalcUpdateTypes.DELETE_ITEM, item.id));
    };

    const duplicateItem = () => {
        dispatch(updateProCalc(ProCalcUpdateTypes.DUPLICATE_ITEM, item.id));
    };

    const saveVariableValue = (variableId: number, value: ValueResource) => {
        dispatch(updateProCalc(ProCalcUpdateTypes.ITEM_VARIABLE_VALUE, { itemId: item.id, variableId, value }));
    };

    const handleSaveName = (newName: string) => {
        dispatch(updateProCalc(ProCalcUpdateTypes.ITEM, { id: item.id, name: newName }));
    };

    const fileExtensionOfFile = item?.dataSourceOriginalFileName ? item.dataSourceOriginalFileName.split('.').pop() : null;

    const getAvailableDialogType = (item: ItemResource, desiredDialogType?: ViewerTypes) => {
        if (desiredDialogType === 'PDF' && item.pdfFileId) return 'PDF';
        if (desiredDialogType === '3D' && item.threeDFileId) return '3D';
        if (desiredDialogType === 'DXF' && item.dxfFileId) return 'DXF';
        return item.pdfFileId ? 'PDF' : item.threeDFileId ? '3D' : 'DXF';
    };

    const selectNext = (desiredDialogType?: ViewerTypes) => {
        setCurrentViewer({ item: viewerDetails.next, dialogType: getAvailableDialogType(viewerDetails.next, desiredDialogType) });
    };
    const selectPrevious = (desiredDialogType?: ViewerTypes) => {
        setCurrentViewer({ item: viewerDetails.previous, dialogType: getAvailableDialogType(viewerDetails.previous, desiredDialogType) });
    };

    return (
        <Card sx={{ overflow: 'hidden' }}>
            <Header className="item-header" alignItems="center" px={2} py={1} overflow="auto !important" maxWidth="100%">
                <QuantityInput variables={item.variables} isSmall={isSmallScreen} saveVariableValue={saveVariableValue} />

                <ChangeableText sx={{ ml: 2 }} text={item.name || wT(item.translationKey, s)} saveText={handleSaveName} isSmall={isSmallScreen} />

                <Stack direction="row" alignItems="center" gap={2}>
                    <ComputationPrice computation={usedComputation} variables={item.variables} settings={settings} />
                    {showCompact && <ItemShowButton item={item} settings={settings} viewerDetails={viewerDetails} setCurrentViewer={setCurrentViewer} />}
                </Stack>
            </Header>

            <Collapse in={!showCompact} timeout="auto" unmountOnExit>
                <Stack>
                    <Divider />

                    <Center className="item-center" direction="row" id={'allInfosOfProduct'}>
                        <StyledImageWrapper>
                            {renderImage(item.thumbnailUrl)}
                            {item.threeDFileId && <Button onClick={() => setCurrentViewer({ item, dialogType: '3D' })}>{t('openIn3dViewer')}</Button>}
                            {item.pdfFileId && <Button onClick={() => setCurrentViewer({ item, dialogType: 'PDF' })}>{t('openInPdfViewer')}</Button>}
                            {item.dxfFileId && <Button onClick={() => setCurrentViewer({ item, dialogType: 'DXF' })}>{t('openInDxfViewer')}</Button>}
                            {fileExtensionOfFile && (
                                <Typography
                                    variant="caption"
                                    sx={{
                                        position: 'absolute',
                                        right: theme.spacing(1),
                                        top: theme.spacing(1),
                                        bgcolor: theme.colors.info.dark,
                                        color: theme.colors.alpha.white[100],
                                        py: 0.1,
                                        px: 0.4,
                                        borderRadius: 1
                                    }}
                                >
                                    .{fileExtensionOfFile}
                                </Typography>
                            )}
                        </StyledImageWrapper>

                        <Divider flexItem orientation="vertical" />

                        <InputSections
                            variables={item.variables}
                            inputVariablesGroupedBySection={inputVariablesGroupedBySection}
                            calcRelevantVariableInternalIdentifiers={calcRelevantVariableInternalIdentifiers}
                            saveVariableValue={saveVariableValue}
                        />

                        <Divider flexItem orientation="vertical" />

                        <ItemStats variables={item.variables} />
                    </Center>

                    <Divider />

                    {/* {part.geometryPackageFragmentType === MAIN_FRAGMENT && (
                        <>
                            <ItemFragments partId={part.id} fragments={part.fragments} metadata={part.calculationParameters.objectMetadataResources} />
                            <Divider />
                        </>
                    )} */}

                    <Footer className="item-footer">
                        <Stack direction="row" id={'attachmentsAndMoreBox'} gap={isSmallScreen ? 0.5 : 2}>
                            {/* <ItemAttachments partId={part.id} attachments={part.attachments} isSmall={isSmallScreen} /> */}
                            <ItemSurcharges itemId={item.id} variables={item.variables} isSmall={isSmallScreen} />
                            <ItemMessages messages={item.messages} isSmall={isSmallScreen} />
                            {/* {item.notes && <ItemNotes notes={item.notes} isSmall={isSmallScreen} />} todo can be a message without criteria? */}
                        </Stack>

                        <Stack id={'deleteDuplicateButtons'} direction="row" ml="auto">
                            <Button sx={{ px: 1.2, py: 0.5 }} startIcon={<DeleteTwoToneIcon />} variant="text" color="secondary" onClick={removeItem} size={isSmallScreen ? 'small' : 'medium'}>
                                {t('delete')}
                            </Button>
                            <Button
                                sx={{ px: 1.2, py: 0.5 }}
                                disabled={isUpdateLoading[ProCalcUpdateTypes.DUPLICATE_ITEM]}
                                startIcon={isUpdateLoading[ProCalcUpdateTypes.DUPLICATE_ITEM] ? <CircularProgress color="inherit" size={20} sx={{ p: 0.25 }} /> : <ContentCopyTwoToneIcon />}
                                variant="text"
                                onClick={duplicateItem}
                                size={isSmallScreen ? 'small' : 'medium'}
                            >
                                {t('duplicate')}
                            </Button>
                        </Stack>
                    </Footer>
                </Stack>
            </Collapse>

            <Item3dViewerDialog
                is3dViewer={viewerDetails?.dialogType === '3D' && viewerDetails?.current?.id === item.id}
                threeDFileId={viewerDetails?.current?.threeDFileId}
                close3dViewer={() => setCurrentViewer(null)}
                hasPrevious={!!viewerDetails?.previous}
                hasNext={!!viewerDetails?.next}
                selectPrevious={() => selectPrevious('3D')}
                selectNext={() => selectNext('3D')}
            />

            <ItemPdfViewerDialog
                isPdfViewerOpen={viewerDetails?.dialogType === 'PDF' && viewerDetails?.current?.id === item.id}
                closePdfViewer={() => setCurrentViewer(null)}
                item={item}
                inputVariablesGroupedBySection={inputVariablesGroupedBySection}
                calcRelevantVariableInternalIdentifiers={calcRelevantVariableInternalIdentifiers}
                hasPrevious={!!viewerDetails?.previous}
                hasNext={!!viewerDetails?.next}
                selectPrevious={() => selectPrevious('3D')}
                selectNext={() => selectNext('3D')}
                saveVariableValue={saveVariableValue}
            />

            <ItemDxfViewerDialog
                isDxfViewerOpen={viewerDetails?.dialogType === 'DXF' && viewerDetails?.current?.id === item.id}
                closeDxfViewer={() => setCurrentViewer(null)}
                item={item}
                inputVariablesGroupedBySection={inputVariablesGroupedBySection}
                calcRelevantVariableInternalIdentifiers={calcRelevantVariableInternalIdentifiers}
                hasPrevious={!!viewerDetails?.previous}
                hasNext={!!viewerDetails?.next}
                selectPrevious={() => selectPrevious('3D')}
                selectNext={() => selectNext('3D')}
                saveVariableValue={saveVariableValue}
            />
        </Card>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        isUpdateLoading: state.proCalc.isUpdateLoading
    };
};
export default connect(mapStateToProps)(Item);
