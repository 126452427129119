import { Card, Collapse, Divider, Stack, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import MasterDeliveryAndPickup from './MasterDeliveryAndPickup';
import MasterButtons from './MasterButtons';
import { formatPrice } from 'src/utils/FormatHelpers';
import Customer from './Customer/Customer';
import HeadlineButton from 'src/components/display/HeadlineButton/HeadlineButton';
import { RootState } from 'src/redux/store';
import { ProCalcUpdateTypes, updateProCalc } from 'src/redux/shared/proCalc.common';
import { CalculationResource, ValueResource } from 'src/backend/coreCalc';

interface Props {
    calculation?: CalculationResource;
}

const Master: React.FC<Props> = ({ calculation }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState<boolean>(true);

    const masterPrice = useMemo(() => {
        return 100;
    }, [calculation?.masterComputation]);

    const saveVariableValue = (variableId: number, value: ValueResource) => {
        dispatch(updateProCalc(ProCalcUpdateTypes.MASTER_VARIABLE_VALUE, { variableId, value }));
    };

    return (
        calculation && (
            <Stack>
                <HeadlineButton isOpen={isOpen} setOpen={setOpen} variant="page">
                    {t('order')}
                    {masterPrice != null && (
                        <Stack ml="auto">
                            <Typography color="primary" fontSize={20}>
                                {formatPrice(masterPrice, true)}
                            </Typography>
                        </Stack>
                    )}
                </HeadlineButton>

                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <Card sx={{ mb: 3 }}>
                        <Stack p={2} px={3} gap={4} direction="row" alignItems="center">
                            <Customer customer={calculation?.customer} />
                            <Divider flexItem orientation="vertical" sx={{ my: -2, mr: 'auto' }} />
                            <MasterDeliveryAndPickup variables={calculation?.masterVariables} saveVariableValue={saveVariableValue} />
                            <Divider flexItem orientation="vertical" sx={{ my: -2, mr: 'auto' }} />
                            <MasterButtons calculation={calculation} />
                        </Stack>
                    </Card>
                </Collapse>
            </Stack>
        )
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        calculation: state.proCalc.calculation
    };
};
export default connect(mapStateToProps)(Master);
