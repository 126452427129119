/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BooleanValueDTO } from './BooleanValueDTO';
import type { MetadataDTO } from './MetadataDTO';
import type { VariableDTO } from './VariableDTO';

export type BooleanInputVariableDTO = (VariableDTO & {
    name?: string;
    description?: string;
    metadata?: Array<MetadataDTO>;
    sequence?: number;
    section?: BooleanInputVariableDTO.section;
    hasUiContract?: boolean;
    alwaysActive?: boolean;
    visibility?: BooleanInputVariableDTO.visibility;
    value?: BooleanValueDTO;
    defaultValue?: BooleanValueDTO;
});

export namespace BooleanInputVariableDTO {

    export enum section {
        GEOMETRY_SECTION = 'GEOMETRY_SECTION',
        COMPONENT_SECTION = 'COMPONENT_SECTION',
        SURFACE_SECTION = 'SURFACE_SECTION',
        CALCULATION_SECTION = 'CALCULATION_SECTION',
        ADDITIONAL_WORK_SECTION = 'ADDITIONAL_WORK_SECTION',
        QUALITY_SECTION = 'QUALITY_SECTION',
    }

    export enum visibility {
        HIDDEN = 'HIDDEN',
        DISABLED = 'DISABLED',
        VISIBLE = 'VISIBLE',
    }


}
