import { InputAdornment, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { InputVariableResource, StringValueResource, ValueResource, VariableResource } from 'src/backend/coreCalc';
import store from 'src/redux/store';
import { getStringValue, measureInputValueWidth } from 'src/utils/CalcHelpers';
import ExtractedIndicator from '../ExtractedIndicator';
import DefaultIndicator from '../DefaultIndicator';
import InfoLabel from '../InfoLabel';
import { StyledTextInputField } from '../InputSections.styles';

interface Props {
    variable: InputVariableResource;
    variables?: Array<VariableResource>;
    setVariable: (variable: InputVariableResource) => void;
}

const TextInputField: React.FC<Props> = ({ variable, variables, setVariable }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const { t } = useTranslation();
    const ref = useRef<any>(null);
    const [left, setLeft] = useState<number>(0);

    const value = getStringValue(variable.value) || getStringValue(variable.defaultValue) || '';

    const setVariableValue = (value: StringValueResource) => {
        setVariable({ ...variable, value });
    };

    const handleChange = (event) => {
        setVariableValue({ type: ValueResource.type.STRING_VALUE, stringValue: event.target.value });
    };

    useEffect(() => {
        if (ref.current) setLeft(measureInputValueWidth(ref.current));
    }, [ref.current, value]);

    return (
        <StyledTextInputField
            label={<InfoLabel variable={variable} />}
            value={value}
            InputProps={{
                startAdornment: <ExtractedIndicator variable={variable} variables={variables} setValue={setVariableValue} />,
                endAdornment: (
                    <InputAdornment position="end">
                        <DefaultIndicator variable={variable} left={left} />
                    </InputAdornment>
                )
            }}
            inputRef={ref}
            onChange={handleChange}
            disabled={variable.visibility === InputVariableResource.visibility.DISABLED}
            size="small"
        />
    );
};

export default TextInputField;
