import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import CalcEditorHeader from './CalcEditorHeader';
import MasterCalc from 'src/components/calc-editor/MasterCalc/MasterCalc';
import CategoryList from 'src/components/calc-editor/Categories/CategoryList';
import { ReactNode, useEffect } from 'react';
import { CalcEditorError, resetBlueprint, resetError } from 'src/redux/slices/calcEditor.reducer';
import { cancelBlueprintUpdates } from 'src/redux/shared/calcEditor.common';
import { RootState, snackContext } from 'src/redux/store';
import { Box, Typography } from '@mui/material';
import SurfLoader from 'src/components/display/Loader/SurfLoader';
import { loadBlueprint } from 'src/redux/thunks/calcEditors.thunk';
import { t } from 'i18next';
import { UserManagementRestControllerService } from 'src/backend/market';
import { nav } from 'src/statics/navigations';
import { useTranslation } from 'react-i18next';
import useDialog from 'src/hooks/useDialog';

interface Props {
    isLoading: boolean;
    error: CalcEditorError;
}

const CalcEditor: React.FC<Props> = ({ isLoading, error }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const dialog = useDialog();
    const { t } = useTranslation();

    useEffect(() => {
        if (id == null) return;
        dispatch(loadBlueprint(id));
        return () => {
            dispatch(resetBlueprint());
            dispatch(cancelBlueprintUpdates());
        };
    }, []);

    useEffect(() => {
        if (!error || isLoading) return;
        const link = nav.TOOLS.sub.CALC_EDITOR.path;
        const okText = t('toBlueprintOverview');

        if (error.type === 'locked') {
            const title = t('lockedByOtherUserTitle');

            UserManagementRestControllerService.getUserById(parseInt(error.data))
                .then((user) => {
                    const component = <Typography>{t('lockedByOtherUserExplanation', { user: user.fullName })}</Typography>;
                    openDialog(link, component, title, okText, true);
                })
                .catch(() => {
                    const component = <Typography>{t('lockedByOtherUserExplanation', { user: error.data })}</Typography>;
                    openDialog(link, component, title, okText, true);
                });
        } else if (error.type === 'notAllowed') {
            const component = <Typography>{t('notAllowedBlueprintErrorExplanation', { id })}</Typography>;
            openDialog(link, component, t('notAllowedBlueprintErrorTitle'), okText);
        } else if (error.type === 'badRequest' || error.type === 'unknown') {
            const component = <Typography>{t('unexpectedBlueprintErrorExplanation', { id })}</Typography>;
            openDialog(link, component, t('unexpectedBlueprintErrorTitle'), okText);
        } else if (error.type === 'unknown') {
            snackContext.enqueueSnackbar(`${t('Ein Fehler ist aufgetreten')} - ${error.data} ERROR`, { variant: 'error' });
        }
    }, [error]);

    const openDialog = (link: string, component: ReactNode, title: string, okText: string, disableClickAway?: boolean) => {
        dialog.openDialog({
            severity: 'error',
            okText,
            showCancel: false,
            disableClickAway,
            component,
            title,
            okCallback: () => {
                dialog.closeDialog();
                navigate(link);
                dispatch(resetError());
            }
        });
    };

    return isLoading ? (
        <Box width="100%" flexGrow={1} display="flex" justifyContent="center" alignItems="center">
            <SurfLoader />
        </Box>
    ) : (
        <>
            <CalcEditorHeader />
            <MasterCalc />
            <CategoryList />
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        error: state.calcEditor.error,
        isLoading: state.calcEditor.isLoading
    };
};
export default connect(mapStateToProps)(CalcEditor);
