/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlueprintNameDTO } from '../models/BlueprintNameDTO';
import type { BlueprintResource } from '../models/BlueprintResource';
import type { BooleanInputVariableDTO } from '../models/BooleanInputVariableDTO';
import type { BooleanInputVariableResource } from '../models/BooleanInputVariableResource';
import type { BooleanValueDTO } from '../models/BooleanValueDTO';
import type { BooleanValueResource } from '../models/BooleanValueResource';
import type { CategoryDTO } from '../models/CategoryDTO';
import type { CategoryResource } from '../models/CategoryResource';
import type { ComputationDTO } from '../models/ComputationDTO';
import type { ComputationResource } from '../models/ComputationResource';
import type { DeclaredVariableDTO } from '../models/DeclaredVariableDTO';
import type { DeclaredVariableResource } from '../models/DeclaredVariableResource';
import type { ListValueDTO } from '../models/ListValueDTO';
import type { ListValueResource } from '../models/ListValueResource';
import type { MultiSelectInputVariableDTO } from '../models/MultiSelectInputVariableDTO';
import type { MultiSelectInputVariableResource } from '../models/MultiSelectInputVariableResource';
import type { NewCategoryDTO } from '../models/NewCategoryDTO';
import type { NumberInputVariableDTO } from '../models/NumberInputVariableDTO';
import type { NumberInputVariableResource } from '../models/NumberInputVariableResource';
import type { NumberValueDTO } from '../models/NumberValueDTO';
import type { NumberValueResource } from '../models/NumberValueResource';
import type { OnTheFlyValueVariableDTO } from '../models/OnTheFlyValueVariableDTO';
import type { ProvidedVariableDTO } from '../models/ProvidedVariableDTO';
import type { ProvidedVariableResource } from '../models/ProvidedVariableResource';
import type { RoundedVariableDTO } from '../models/RoundedVariableDTO';
import type { RoundedVariableResource } from '../models/RoundedVariableResource';
import type { RuleBasedVariableDTO } from '../models/RuleBasedVariableDTO';
import type { RuleBasedVariableResource } from '../models/RuleBasedVariableResource';
import type { SettingsDTO } from '../models/SettingsDTO';
import type { SettingsResource } from '../models/SettingsResource';
import type { ShortBlueprintResource } from '../models/ShortBlueprintResource';
import type { SingleSelectInputVariableDTO } from '../models/SingleSelectInputVariableDTO';
import type { SingleSelectInputVariableResource } from '../models/SingleSelectInputVariableResource';
import type { StringValueDTO } from '../models/StringValueDTO';
import type { StringValueResource } from '../models/StringValueResource';
import type { SubCategoryDTO } from '../models/SubCategoryDTO';
import type { SurchargeVariableDTO } from '../models/SurchargeVariableDTO';
import type { SurchargeVariableResource } from '../models/SurchargeVariableResource';
import type { TermVariableDTO } from '../models/TermVariableDTO';
import type { TermVariableResource } from '../models/TermVariableResource';
import type { TextInputVariableDTO } from '../models/TextInputVariableDTO';
import type { TextInputVariableResource } from '../models/TextInputVariableResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class BlueprintRestControllerService {

    /**
     * @param blueprintId
     * @param variableId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateMasterVariable(
        blueprintId: string,
        variableId: number,
        requestBody: (BooleanInputVariableDTO | DeclaredVariableDTO | MultiSelectInputVariableDTO | NumberInputVariableDTO | OnTheFlyValueVariableDTO | ProvidedVariableDTO | RoundedVariableDTO | RuleBasedVariableDTO | SingleSelectInputVariableDTO | SurchargeVariableDTO | TermVariableDTO | TextInputVariableDTO),
    ): CancelablePromise<(BooleanInputVariableResource | DeclaredVariableResource | MultiSelectInputVariableResource | NumberInputVariableResource | ProvidedVariableResource | RoundedVariableResource | RuleBasedVariableResource | SingleSelectInputVariableResource | SurchargeVariableResource | TermVariableResource | TextInputVariableResource)> {
        return __request({
            method: 'PUT',
            path: `/coreCalc/blueprints/${blueprintId}/variables/${variableId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @param variableId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteMasterVariable(
        blueprintId: string,
        variableId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/coreCalc/blueprints/${blueprintId}/variables/${variableId}`,
        });
    }

    /**
     * @param blueprintId
     * @param variableId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateMasterVariableValue1(
        blueprintId: string,
        variableId: number,
        requestBody: (BooleanValueDTO | ListValueDTO | NumberValueDTO | StringValueDTO),
    ): CancelablePromise<(BooleanValueResource | ListValueResource | NumberValueResource | StringValueResource)> {
        return __request({
            method: 'PUT',
            path: `/coreCalc/blueprints/${blueprintId}/variables/${variableId}/value`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @param requestBody
     * @returns SettingsResource OK
     * @throws ApiError
     */
    public static updateSettings(
        blueprintId: string,
        requestBody: SettingsDTO,
    ): CancelablePromise<SettingsResource> {
        return __request({
            method: 'PUT',
            path: `/coreCalc/blueprints/${blueprintId}/settings`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @param requestBody
     * @returns BlueprintResource OK
     * @throws ApiError
     */
    public static updateName(
        blueprintId: string,
        requestBody: BlueprintNameDTO,
    ): CancelablePromise<BlueprintResource> {
        return __request({
            method: 'PUT',
            path: `/coreCalc/blueprints/${blueprintId}/name`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @param requestBody
     * @returns ComputationResource OK
     * @throws ApiError
     */
    public static updateMasterComputation(
        blueprintId: string,
        requestBody: ComputationDTO,
    ): CancelablePromise<ComputationResource> {
        return __request({
            method: 'PUT',
            path: `/coreCalc/blueprints/${blueprintId}/masterComputation`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @param categoryId
     * @param requestBody
     * @returns CategoryResource OK
     * @throws ApiError
     */
    public static updateCategory(
        blueprintId: string,
        categoryId: number,
        requestBody: CategoryDTO,
    ): CancelablePromise<CategoryResource> {
        return __request({
            method: 'PUT',
            path: `/coreCalc/blueprints/${blueprintId}/categories/${categoryId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @param categoryId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteCategory(
        blueprintId: string,
        categoryId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/coreCalc/blueprints/${blueprintId}/categories/${categoryId}`,
        });
    }

    /**
     * @param blueprintId
     * @param categoryId
     * @param variableId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateCategoryVariable(
        blueprintId: string,
        categoryId: number,
        variableId: number,
        requestBody: (BooleanInputVariableDTO | DeclaredVariableDTO | MultiSelectInputVariableDTO | NumberInputVariableDTO | OnTheFlyValueVariableDTO | ProvidedVariableDTO | RoundedVariableDTO | RuleBasedVariableDTO | SingleSelectInputVariableDTO | SurchargeVariableDTO | TermVariableDTO | TextInputVariableDTO),
    ): CancelablePromise<(BooleanInputVariableResource | DeclaredVariableResource | MultiSelectInputVariableResource | NumberInputVariableResource | ProvidedVariableResource | RoundedVariableResource | RuleBasedVariableResource | SingleSelectInputVariableResource | SurchargeVariableResource | TermVariableResource | TextInputVariableResource)> {
        return __request({
            method: 'PUT',
            path: `/coreCalc/blueprints/${blueprintId}/categories/${categoryId}/variables/${variableId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @param categoryId
     * @param variableId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteCategoryVariable(
        blueprintId: string,
        categoryId: number,
        variableId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/coreCalc/blueprints/${blueprintId}/categories/${categoryId}/variables/${variableId}`,
        });
    }

    /**
     * @param blueprintId
     * @param categoryId
     * @param variableId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateCategoryVariableValue(
        blueprintId: string,
        categoryId: number,
        variableId: number,
        requestBody: (BooleanValueDTO | ListValueDTO | NumberValueDTO | StringValueDTO),
    ): CancelablePromise<(BooleanValueResource | ListValueResource | NumberValueResource | StringValueResource)> {
        return __request({
            method: 'PUT',
            path: `/coreCalc/blueprints/${blueprintId}/categories/${categoryId}/variables/${variableId}/value`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @param categoryId
     * @param computationId
     * @param requestBody
     * @returns ComputationResource OK
     * @throws ApiError
     */
    public static updateCategoryComputation(
        blueprintId: string,
        categoryId: number,
        computationId: number,
        requestBody: ComputationDTO,
    ): CancelablePromise<ComputationResource> {
        return __request({
            method: 'PUT',
            path: `/coreCalc/blueprints/${blueprintId}/categories/${categoryId}/supportedComputations/${computationId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns BlueprintResource OK
     * @throws ApiError
     */
    public static createBlueprint(
        requestBody: BlueprintNameDTO,
    ): CancelablePromise<BlueprintResource> {
        return __request({
            method: 'POST',
            path: `/coreCalc/blueprints`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createMasterVariable(
        blueprintId: string,
        requestBody: (BooleanInputVariableDTO | DeclaredVariableDTO | MultiSelectInputVariableDTO | NumberInputVariableDTO | OnTheFlyValueVariableDTO | ProvidedVariableDTO | RoundedVariableDTO | RuleBasedVariableDTO | SingleSelectInputVariableDTO | SurchargeVariableDTO | TermVariableDTO | TextInputVariableDTO),
    ): CancelablePromise<(BooleanInputVariableResource | DeclaredVariableResource | MultiSelectInputVariableResource | NumberInputVariableResource | ProvidedVariableResource | RoundedVariableResource | RuleBasedVariableResource | SingleSelectInputVariableResource | SurchargeVariableResource | TermVariableResource | TextInputVariableResource)> {
        return __request({
            method: 'POST',
            path: `/coreCalc/blueprints/${blueprintId}/variables`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @param requestBody
     * @returns CategoryResource OK
     * @throws ApiError
     */
    public static createCategory(
        blueprintId: string,
        requestBody: NewCategoryDTO,
    ): CancelablePromise<CategoryResource> {
        return __request({
            method: 'POST',
            path: `/coreCalc/blueprints/${blueprintId}/categories`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @param categoryId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createCategoryVariable(
        blueprintId: string,
        categoryId: number,
        requestBody: (BooleanInputVariableDTO | DeclaredVariableDTO | MultiSelectInputVariableDTO | NumberInputVariableDTO | OnTheFlyValueVariableDTO | ProvidedVariableDTO | RoundedVariableDTO | RuleBasedVariableDTO | SingleSelectInputVariableDTO | SurchargeVariableDTO | TermVariableDTO | TextInputVariableDTO),
    ): CancelablePromise<(BooleanInputVariableResource | DeclaredVariableResource | MultiSelectInputVariableResource | NumberInputVariableResource | ProvidedVariableResource | RoundedVariableResource | RuleBasedVariableResource | SingleSelectInputVariableResource | SurchargeVariableResource | TermVariableResource | TextInputVariableResource)> {
        return __request({
            method: 'POST',
            path: `/coreCalc/blueprints/${blueprintId}/categories/${categoryId}/variables`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @param categoryId
     * @param requestBody
     * @returns ComputationResource OK
     * @throws ApiError
     */
    public static createCategoryComputation(
        blueprintId: string,
        categoryId: number,
        requestBody: ComputationDTO,
    ): CancelablePromise<ComputationResource> {
        return __request({
            method: 'POST',
            path: `/coreCalc/blueprints/${blueprintId}/categories/${categoryId}/supportedComputations`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @param categoryId
     * @param requestBody
     * @returns CategoryResource OK
     * @throws ApiError
     */
    public static createSubCategory(
        blueprintId: string,
        categoryId: number,
        requestBody: SubCategoryDTO,
    ): CancelablePromise<CategoryResource> {
        return __request({
            method: 'POST',
            path: `/coreCalc/blueprints/${blueprintId}/categories/${categoryId}/subCategories`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @param categoryId
     * @returns CategoryResource OK
     * @throws ApiError
     */
    public static duplicateCategory(
        blueprintId: string,
        categoryId: number,
    ): CancelablePromise<CategoryResource> {
        return __request({
            method: 'POST',
            path: `/coreCalc/blueprints/${blueprintId}/categories/${categoryId}/duplicates`,
        });
    }

    /**
     * @param blueprintId
     * @returns BlueprintResource OK
     * @throws ApiError
     */
    public static getBlueprint(
        blueprintId: string,
    ): CancelablePromise<BlueprintResource> {
        return __request({
            method: 'GET',
            path: `/coreCalc/blueprints/${blueprintId}`,
        });
    }

    /**
     * @param blueprintId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteBlueprint(
        blueprintId: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/coreCalc/blueprints/${blueprintId}`,
        });
    }

    /**
     * @returns ShortBlueprintResource OK
     * @throws ApiError
     */
    public static getAllBlueprints(): CancelablePromise<Array<ShortBlueprintResource>> {
        return __request({
            method: 'GET',
            path: `/coreCalc/blueprints/companyBlueprints`,
        });
    }

}