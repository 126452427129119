import { Box, Button, Card, Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import CalculateTwoToneIcon from '@mui/icons-material/CalculateTwoTone';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { FileResource } from 'src/backend/market';
import { useNavigate } from 'react-router';
import { createProCalc } from 'src/redux/thunks/proCalcs.thunk';
import { nav } from 'src/statics/navigations';
import { RootState, snackContext } from 'src/redux/store';
import { BlueprintResource, CalculationResource } from 'src/backend/coreCalc';
import useDialog from 'src/hooks/useDialog';
import { loadBlueprints } from 'src/redux/thunks/calcEditors.thunk';
import TemplateSelectionItem from '../display/TemplateSelectionItem/TemplateSelectionItem';
import { getRelativeTime } from 'src/utils/FormatHelpers';

const GridBox = styled(Box)(
    ({ theme }) => `
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: ${theme.spacing(3)};
        padding: ${theme.spacing(3)};
        padding-top: 0;
        width: 30rem;
    `
);
interface Props {
    blueprints: Array<BlueprintResource>;
    navigateAfterCreation?: boolean;
    displayInitiator: (createCalc: Function) => JSX.Element;
    onCalcCreate?: (calculation: CalculationResource) => void;
}

const NewProCalc: React.FC<Props> = ({ blueprints, navigateAfterCreation = true, displayInitiator, onCalcCreate }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dialog = useDialog();
    const { t } = useTranslation();
    const [isBlueprintSelectorOpen, setBlueprintSelectorOpen] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        dispatch(loadBlueprints());
    }, []);

    const createCalc = () => {
        if (blueprints == null || blueprints.length === 0) noBlueprintDialog();
        else if (blueprints.length === 1) createCalcRaw(blueprints[0].id);
        else setBlueprintSelectorOpen(true);
    };

    const createCalcRaw = async (blueprintId: string) => {
        setLoading(true);
        const calculation = await dispatch(createProCalc(blueprintId));
        if (!calculation) return showCreateBlueprintError();
        if (navigateAfterCreation) navigate(nav.CALCULATIONS.sub.EDIT.path.replace(':id', calculation.id));
        if (onCalcCreate) onCalcCreate(calculation);
        setLoading(false);
    };

    const noBlueprintDialog = () => {
        dialog.openDialog({
            severity: 'warning',
            title: t('noBlueprintsFound'),
            component: <Typography>{t('noBlueprintsFoundExplanation')}</Typography>,
            okText: t('goToBlueprints'),
            cancelText: t('cancel'),
            okCallback: async () => {
                navigate(nav.TOOLS.sub.CALC_EDITOR.path);
                dialog.closeDialog();
            }
        });
    };

    const showCreateBlueprintError = () => {
        snackContext.enqueueSnackbar(t('errorWhileCreatingBlueprint'), { variant: 'error' });
    };

    return (
        <>
            {displayInitiator(createCalc)}
            <Dialog open={isBlueprintSelectorOpen} maxWidth="sm" onClose={() => setBlueprintSelectorOpen(false)}>
                <DialogTitle>
                    <Typography sx={{ fontSize: '1.875rem', fontWeight: 700, mt: 0.5, mb: 1, pr: 7 }} gutterBottom>
                        Neue Kalkulation
                    </Typography>
                    <IconButton aria-label="close" onClick={() => setBlueprintSelectorOpen(false)} sx={{ position: 'absolute', right: 16, top: 16, color: (theme) => theme.palette.grey[500] }}>
                        <CloseTwoToneIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ p: 0 }}>
                    <GridBox>
                        {blueprints.map((blueprint) => (
                            <TemplateSelectionItem
                                key={blueprint.id}
                                template={{ name: blueprint.name, description: `${t('created')} ${getRelativeTime(blueprint.createdAt)}`, icon: 'CalculateTwoTone' }}
                                onClick={() => createCalcRaw(blueprint.id)}
                                isDisable={isLoading}
                            />
                        ))}
                    </GridBox>
                </DialogContent>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        blueprints: state.calcEditors.blueprints
    };
};

export default connect(mapStateToProps)(NewProCalc);
