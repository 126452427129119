import { Box, IconButton, Stack, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import store from 'src/redux/store';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import DragAndDropFileInput from 'src/components/input/DragAndDropFileInput';
import { CategoryResource } from 'src/backend/coreCalc';

interface Props {
    subCategory: CategoryResource;
    setSubCategory?: (subCategory: CategoryResource) => void;
}

const SubCategoryForm: React.FC<Props> = ({ subCategory, setSubCategory }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const { t } = useTranslation();
    const [logoSrc, setLogoSrc] = useState<string>();

    useEffect(() => {
        if (subCategory) return;
        setSubCategory({ name: '' });
    }, []);

    const handleNameChange = (event) => {
        setSubCategory({
            ...subCategory,
            name: event.target.value
        });
    };

    const handleFileSelection = (fileList: FileList) => {
        const files = Array.from(fileList);
        if (!files) return;
        const file = files[0];
        const fr = new FileReader();
        fr.onload = (event) => {
            const result = event.target.result;
            const base64String = typeof result === 'string' ? result : String.fromCharCode.apply(null, new Uint16Array(result));
            setLogoSrc(base64String);
        };
        fr.readAsDataURL(file);
    };

    return (
        subCategory && (
            <>
                <TextField label="Name" value={subCategory.name} onChange={handleNameChange} />
                <Stack>
                    <Typography variant="caption">Bild</Typography>
                    {logoSrc && (
                        <Box sx={{ display: 'flex', mb: 1, maxHeight: '7rem' }}>
                            <Box sx={{ position: 'relative', display: 'flex', maxHeight: '100%' }}>
                                <Box sx={{ position: 'relative', maxWidth: '100%', maxHeight: '100%' }}>
                                    <img src={logoSrc} style={{ maxHeight: '100%', objectFit: 'contain', objectPosition: 'left' }} />
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            color: theme.colors.alpha.white[100],
                                            bgcolor: theme.colors.alpha.black[70],
                                            borderTopRightRadius: 0,
                                            borderBottomRightRadius: 0,
                                            borderTopLeftRadius: 0,
                                            ':hover': {
                                                bgcolor: theme.colors.alpha.black[100]
                                            }
                                        }}
                                        size="large"
                                        onClick={() => setLogoSrc(null)}
                                    >
                                        <CloseTwoToneIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                    )}
                    <DragAndDropFileInput fileSelected={handleFileSelection} accept=".png, .jpg, .jpeg" primaryText={t('selectCompanyLogoHere')} />
                </Stack>
            </>
        )
    );
};

export default SubCategoryForm;
