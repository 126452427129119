import { Dialog, DialogTitle, Stack, IconButton, DialogContent, useTheme, Divider } from '@mui/material';
import SurfLoader from 'src/components/display/Loader/SurfLoader';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useEffect, useState } from 'react';
import { FileResource } from 'src/backend/market';
import { loadFile } from 'src/redux/thunks/fileManager.thunk';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';
import DxfViewer from 'src/components/display/DxfViewer/DxfViewer';
import store from 'src/redux/store';
import ItemStats from '../ItemStats';
import { ItemResource, ValueResource } from 'src/backend/coreCalc';
import { InputVariablesGroupedBySection } from 'src/components/calc-editor/CalcEditor.types';
import { getText } from 'src/utils/CalcHelpers';
import QuantityInput from 'src/components/input/QuantityInput/QuantityInput';
import InputSections from 'src/components/input/InputSections/InputSections';

interface Props {
    item: ItemResource;
    inputVariablesGroupedBySection?: InputVariablesGroupedBySection;
    calcRelevantVariableInternalIdentifiers?: Array<string>;
    isDxfViewerOpen: boolean;
    hasPrevious?: boolean;
    hasNext?: boolean;
    selectPrevious?: () => void;
    selectNext?: () => void;
    closeDxfViewer?: () => void;
    saveVariableValue: (variableId: number, value: ValueResource) => void;
}

const ItemDxfViewerDialog: React.FC<Props> = ({
    item,
    inputVariablesGroupedBySection,
    calcRelevantVariableInternalIdentifiers,
    isDxfViewerOpen,
    hasPrevious,
    hasNext,
    selectPrevious,
    selectNext,
    closeDxfViewer,
    saveVariableValue
}) => {
    const dispatch = useDispatch();
    const s = store.getState();
    const theme = useTheme();
    const { t } = useTranslation();
    const [dxfPath, setDxfPath] = useState<string>();
    const [isLoading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!item.dxfFileId || !isDxfViewerOpen) return;
        loadFileResource();
    }, [item.dxfFileId, isDxfViewerOpen]);

    const loadFileResource = async () => {
        setLoading(true);
        const file: FileResource = await dispatch(loadFile(item.dxfFileId));
        setDxfPath(file.url);
        setLoading(false);
    };

    return (
        <Dialog onClose={closeDxfViewer} open={isDxfViewerOpen} maxWidth="xl" PaperProps={{ sx: { width: '100%', height: '100%' } }}>
            <DialogTitle sx={{ pr: 7 }}>
                <Stack direction="row" gap={2} my={-0.6} alignItems="center">
                    <QuantityInput variables={item.variables} saveVariableValue={saveVariableValue} />
                    {getText(item)}
                </Stack>
                <IconButton
                    color="primary"
                    aria-label="close"
                    onClick={closeDxfViewer}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        background: theme.colors.primary.main,
                        color: theme.colors.alpha.white[100],
                        ':hover': {
                            background: theme.colors.primary.dark
                        }
                    }}
                >
                    <CloseTwoToneIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ position: 'relative', overflow: 'hidden', minHeight: '20rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Stack direction="row" height="100%" flexGrow={1} sx={{ mr: -3 }}>
                    <Stack flexGrow={1}>
                        {dxfPath && !isLoading ? (
                            <DxfViewer url={dxfPath} />
                        ) : (
                            <Stack alignItems="center" justifyContent="center" height="100%" flexGrow={1} bgcolor={theme.colors.alpha.black[7]}>
                                <SurfLoader />
                            </Stack>
                        )}
                    </Stack>
                    <Stack sx={{ width: '35%', flexShrink: 0, '.item-calculated-values': { width: '100%', maxWidth: '100%' } }}>
                        <Divider />
                        <InputSections
                            variables={item.variables}
                            inputVariablesGroupedBySection={inputVariablesGroupedBySection}
                            calcRelevantVariableInternalIdentifiers={calcRelevantVariableInternalIdentifiers}
                            saveVariableValue={saveVariableValue}
                        />
                        <Divider sx={{ mt: 'auto' }} />
                        <ItemStats variables={item.variables} />
                    </Stack>
                </Stack>
                {hasPrevious && (
                    <div style={{ position: 'absolute', left: 0, alignSelf: 'center', borderRadius: 8, background: theme.colors.alpha.white[100] }}>
                        <IconButton color="primary" onClick={selectPrevious}>
                            <ArrowBackIosNewTwoToneIcon sx={{ fontSize: 32 }} />
                        </IconButton>
                    </div>
                )}
                {hasNext && (
                    <IconButton color="primary" onClick={selectNext} sx={{ position: 'absolute', right: 0, alignSelf: 'center' }}>
                        <ArrowForwardIosTwoToneIcon sx={{ fontSize: 32 }} />
                    </IconButton>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ItemDxfViewerDialog;
