import {
    InternalCalculationResource,
    InternalCalculationRestControllerService,
    SurchargeOverrideStorageResource,
    CalculationParameterDto,
    InternalCalculationAdditionalStorageRestControllerService,
    AdditionalStorageDto,
    ExtractedValuesDto,
    CreateCalculationPartDto,
    InternalCalcPriceManipulationParameterValueResource,
    CalculationPartResource
} from '../../backend/internalCalc';
import { AppDispatch, RootState } from '../store';
import { ALLOWED_3D_FILE_TYPES, HOLES, HOLES_DIMENSIONS, QUANTITY, THREADS, THREADS_DIMENSIONS, TMP_LOADER_ITEM } from 'src/statics/statics';
import { API_CONFIG } from 'src/config';
import { AiMailConversationResource, AiMailConversationRestControllerService, FileResource, HoleRecognitionAfterburnerRestControllerService, MailConfigurationDto } from 'src/backend/market';
import { addFileWithThumbnail } from './fileManager.thunk';
import { cloneDeep, isString } from 'lodash';
import { handleError, setMailConversation, setProCalcValue, ProCalcHoleRecognitionResult, deleteItem, addItem } from '../slices/proCalc.reducer';
import { ModelStats } from '@surface-solutions/ssa-3d-viewer/dist/context/ViewerContext';
import {
    CalculationResource,
    CalculationRestControllerService,
    ItemDTO,
    ItemResource,
    NewItemDTO,
    ValueDTO,
    ValueResource,
    CustomerResource,
    SurchargeVariableResource,
    AttachmentResource
} from 'src/backend/coreCalc';
import { NewItemWithFile } from 'src/components/pro-calc/ProCalc.types';
import { getFileExtension, getSpecificNodeId, getVariable } from 'src/utils/CalcHelpers';

/*
###############
##  GENERAL  ##
###############
*/

export const loadMailConversation = (uniqueId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const loadedMailConversations: { [uniqueId: string]: AiMailConversationResource } = getState().proCalcs.mailConversations || {};
    let mailConversation = loadedMailConversations[uniqueId];
    if (!mailConversation) {
        mailConversation = await AiMailConversationRestControllerService.getConversationStreamOfCalculation(uniqueId);
    }
    if (mailConversation?.conversation?.[0]) await AiMailConversationRestControllerService.setMailRead(uniqueId, mailConversation.conversation[0].id);
    dispatch(setMailConversation(mailConversation));
};
export const loadGeneratedAnswerMail = (uniqueId: string) => async (dispatch: AppDispatch) => {
    const mail = await AiMailConversationRestControllerService.getGeneratedAnswerToMail(uniqueId);
    return mail;
};
export const sendAnswerMail = (uniqueId: string) => async (dispatch: AppDispatch) => {
    await AiMailConversationRestControllerService.sendAnswer(uniqueId);
    await dispatch(loadMailConversation(uniqueId));
};
export const updateAnswerMail = (uniqueId: string, updatedAnswerMail: MailConfigurationDto) => async (dispatch: AppDispatch) => {
    await AiMailConversationRestControllerService.updateAnswer(uniqueId, updatedAnswerMail);
};

/*
###############
##  HELPERS  ##
###############
*/

export const getInitParameters = (calc: InternalCalculationResource, newPart: CalculationPartResource, initParameters?: Array<CalculationParameterDto>) => {
    let parameters: Array<CalculationParameterDto> = [];

    const filteredParts = (calc.parts || []).filter((part) => part.itemName !== TMP_LOADER_ITEM);
    const mappedParameters = newPart.calculationParameters.allParameters.reduce((accumulator, parameter) => {
        if (parameter.name !== QUANTITY) accumulator[parameter.name] = parameter;
        return accumulator;
    }, {});

    if (filteredParts.length === 0 && !initParameters) return null;

    if (filteredParts.length > 0) {
        const previousPart = filteredParts[filteredParts.length - 1];
        const previousGeometryParameters = previousPart.calculationParameters.allParameters.filter((parameter) => !!parameter.geometry).map((parameter) => parameter.name);

        const mappedPreviousParameters = previousPart.calculationParameters.allParameters.reduce((accumulator, parameter) => {
            if (parameter.name !== QUANTITY && !previousGeometryParameters.includes(parameter.name)) accumulator[parameter.name] = parameter;
            return accumulator;
        }, {});

        const mappedPreviousSetParameters = previousPart.setParameters.reduce((accumulator, parameter) => {
            if (parameter.name !== QUANTITY && !previousGeometryParameters.includes(parameter.name)) accumulator[parameter.name] = parameter.value;
            return accumulator;
        }, {});

        parameters = newPart.setParameters.map((setParameter) => {
            const foundInitParam = (initParameters || []).find((param) => param.name === setParameter.name);
            if (foundInitParam) return foundInitParam;
            const parameter = mappedParameters[setParameter.name];
            const previousParameter = mappedPreviousParameters[setParameter.name];
            const previousValue = mappedPreviousSetParameters[setParameter.name];

            if (previousValue == null || previousParameter?.type !== parameter?.type) return setParameter;

            if (parameter?.type === 'enumeration' && !parameter.items.some((item) => item.id === previousValue)) return setParameter;

            return {
                name: setParameter.name,
                value: previousValue
            };
        });
    } else if (initParameters) parameters.push(...initParameters);

    const partCostResult = (newPart.costResult.subCalculationResults || []).find((partCostResult) => partCostResult.additionalInformation === newPart.subCalculationIdentificationKey);
    if (partCostResult?.parameterValueChangeCommands?.length > 0) {
        const parametersToChange = partCostResult.parameterValueChangeCommands.reduce((accumulator, parameterValueChangeCommand) => {
            accumulator[parameterValueChangeCommand.parameterName] = parameterValueChangeCommand.newValue;
            return accumulator;
        }, {});

        parameters = parameters.map((parameter) => {
            if (parametersToChange[parameter.name]) return { ...parameter, value: parametersToChange[parameter.name] };
            return parameter;
        });
    }

    return parameters;
};

export const addStorageEntriesToPart = (calc: InternalCalculationResource, partId: number, additionalStorage: Array<AdditionalStorageDto>) => async () => {
    for (const storageEntry of additionalStorage) {
        await InternalCalculationAdditionalStorageRestControllerService.addAdditionalStorageToPart(calc.id, partId, storageEntry);
    }
};

export const updateCostResult = (calc: InternalCalculationResource) => async (dispatch: AppDispatch) => {
    const costResult = await InternalCalculationRestControllerService.getInternalCalculationCostResult(calc.id);
    dispatch(setProCalcValue({ calcId: calc.id, path: 'costResult', value: costResult }));
    return costResult;
};

/*
####################
##  CALC UPDATES  ##
####################
*/
export const updateNote = (calc: InternalCalculationResource, note: string | null) => async () => {
    await InternalCalculationRestControllerService.setNote(calc.id, { note });
};

export const updatePriceManipulationParameter =
    (calc: InternalCalculationResource, value: { index: number; paramValue: InternalCalcPriceManipulationParameterValueResource }) => async (dispatch: AppDispatch) => {
        const paramValue = value.paramValue;
        await InternalCalculationRestControllerService.setPriceManipulationParameterValue(calc.id, paramValue.valueId, { newValue: paramValue.value });
        await dispatch(updateCostResult(calc));
    };

export const updateSurchargeOverrides = (calc: InternalCalculationResource, surchargeStorage: Array<SurchargeOverrideStorageResource>) => async (dispatch: AppDispatch) => {
    await InternalCalculationRestControllerService.updateSurchargeStorage(calc.id, { surchargeStorage });
    await dispatch(updateCostResult(calc));
};

/*
####################
##  PART UPDATES  ##
####################
*/
export const createPart =
    (
        calc: InternalCalculationResource,
        value: {
            itemId: number;
            initParameters?: Array<CalculationParameterDto>;
            fileResource?: FileResource;
            extractedValuesDto?: ExtractedValuesDto;
            additionalStorage?: Array<AdditionalStorageDto>;
            userDefinedPartName?: string;
            thumbnail?: {
                base64String: string;
                name: string;
            };
        }
    ) =>
    async (dispatch: AppDispatch) => {
        const partDto: CreateCalculationPartDto = { itemId: value.itemId };
        const fileName = value.fileResource?.name || '';
        if (fileName.endsWith('.pdf')) partDto.pdfFileId = value.fileResource.nodeId;
        else if (fileName.endsWith('.dwg')) partDto.dwgFileId = value.fileResource.nodeId;
        else if (fileName.endsWith('.dxf')) partDto.dxfFileId = value.fileResource.nodeId;
        else if (fileName) partDto.threeDFileId = value.fileResource.nodeId;

        if (fileName) partDto.dataSourceOriginalFileName = value.fileResource.name;

        let newPart = await InternalCalculationRestControllerService.addPart(calc.id, partDto);
        const initParameters = getInitParameters(calc, newPart, value?.initParameters);
        if (initParameters) {
            newPart = await InternalCalculationRestControllerService.updatePart(calc.id, newPart.id, {
                itemId: newPart.itemId,
                parameters: initParameters,
                extractedValuesDto: value?.extractedValuesDto
            });
        }
        if (value?.additionalStorage) {
            newPart.additionalStorage = [...value?.additionalStorage];
            await dispatch(addStorageEntriesToPart(calc, newPart.id, value.additionalStorage));
        }
        if (value?.userDefinedPartName) {
            newPart = await dispatch(updateUserDefinedPartName(calc, { partId: newPart.id, name: value.userDefinedPartName }));
        }
        if (value?.thumbnail) {
            // const assignedImageName = await dispatch(uploadThumbnail(value.thumbnail.base64String, value.thumbnail.name));
            // newPart = await InternalCalculationRestControllerService.addThumbnailToPart(calc.id, newPart.id, { thumbnailName: assignedImageName });
        }

        await dispatch(updateCostResult(calc));
        const partIndex = calc.parts.findIndex((part) => part.itemName === TMP_LOADER_ITEM);
        // dispatch(setPart({ index: partIndex, part: newPart, calcId: calc.id }));
        return newPart.id;
    };

export const updatePartParameter = (calc: InternalCalculationResource, value: { partId: number; itemId: number; parameter: CalculationParameterDto }) => async (dispatch: AppDispatch) => {
    const updatedPart = await InternalCalculationRestControllerService.updatePart(calc.id, value.partId, { itemId: value.itemId, parameters: [value.parameter] });
    dispatch(setProCalcValue({ calcId: calc.id, path: 'status', value: updatedPart.calculationStatus }));
    dispatch(setProCalcValue({ calcId: calc.id, path: 'invalidStatus', value: updatedPart.calculationInvalidStatus }));
    await dispatch(updateCostResult(calc));
};
export const updatePartParameters = (calc: InternalCalculationResource, value: { partId: number; itemId: number; parameters: Array<CalculationParameterDto> }) => async (dispatch: AppDispatch) => {
    const updatedPart = await InternalCalculationRestControllerService.updatePart(calc.id, value.partId, { itemId: value.itemId, parameters: value.parameters });
    dispatch(setProCalcValue({ calcId: calc.id, path: 'status', value: updatedPart.calculationStatus }));
    dispatch(setProCalcValue({ calcId: calc.id, path: 'invalidStatus', value: updatedPart.calculationInvalidStatus }));
    await dispatch(updateCostResult(calc));
};

export const updateUserDefinedPartName = (calc: InternalCalculationResource, value: { partId: number; name: string }) => async () => {
    const part = await InternalCalculationRestControllerService.updateUserDefinedPartName(calc.id, value.partId, { userDefinedPartName: value.name });
    return part;
};

export const updatePartForcePrice = (calc: InternalCalculationResource, value: { partId: number; force: boolean; price: number }) => async (dispatch: AppDispatch) => {
    // const updatedCalculation = await InternalCalculationRestControllerService.setPartForcePrice(calc.id, value.partId, { force: value.force, price: value.price });
    // dispatch(setProCalcSelectively({ updatedCalculation, paths: ['costResult'], calcId: calc.id }));
};

const isFileResource = (file: File | FileResource): file is FileResource => {
    return (file as FileResource).nodeId !== undefined;
};

export const create3dPart =
    (
        calc: InternalCalculationResource,
        value: {
            itemId: number;
            file: File | FileResource;
            stats: ModelStats;
            disableHoleRecognition?: boolean;
        }
    ) =>
    async (dispatch: AppDispatch) => {
        const { file, stats, itemId } = value;

        const dimensions: Array<CalculationParameterDto> = [
            { name: 'groessteLaenge', value: Math.round(stats.boundingBox.length.baseValue) + '' },
            { name: 'groessteBreite', value: Math.round(stats.boundingBox.width.baseValue) + '' },
            { name: 'groessteHoehe', value: Math.round(stats.boundingBox.height.baseValue) + '' }
        ];

        const additionalStorage = dimensions.map((dimension) => ({
            key: 'extracted_' + dimension.name,
            value: dimension.value
        }));

        let fileResource;
        if (isFileResource(file)) {
            fileResource = file;
        } else {
            fileResource = await dispatch(addFileWithThumbnail(file, file.name, stats.screenshot));
        }

        const partInfos = {
            itemId,
            initParameters: dimensions,
            fileResource,
            extractedValuesDto: {
                objectSurfaceArea: stats.surfaceArea.value,
                boundingBoxSurfaceArea: stats.boundingBox.surfaceArea.value,
                objectConvexHullSurfaceArea: stats.convexHull.surfaceArea.value,
                objectVolume: stats.volume.value
            },
            additionalStorage,
            userDefinedPartName: file.name,
            thumbnail: {
                base64String: stats.screenshot,
                name: file.name + '_screenshot.png'
            }
        };

        const partId = await dispatch(createPart(calc, partInfos));

        const filename = file.name.toLocaleLowerCase();
        if (value.disableHoleRecognition !== true && (filename.endsWith('.step') || filename.endsWith('.stp'))) {
            // dispatch(setHoleRecognitionLoader({ partId, isLoading: true }));
            const result = await dispatch(getHolesAndThreads(fileResource.url));

            if (result?.holes != null && result?.threads != null) {
                const parameters = [
                    { name: HOLES, value: result.holes + '' },
                    { name: THREADS, value: result.threads + '' }
                ];
                const additionalData = parameters.map((param) => ({ key: 'extracted_' + param.name, value: param.value }));
                if (Array.isArray(result.holeDimensions) && result.holeDimensions.length > 0) {
                    additionalData.push({ key: 'extracted_' + HOLES_DIMENSIONS, value: JSON.stringify(result.holeDimensions) });
                }
                if (Array.isArray(result.threadDimensions) && result.threadDimensions.length > 0) {
                    additionalData.push({ key: 'extracted_' + THREADS_DIMENSIONS, value: JSON.stringify(result.threadDimensions) });
                }
                await dispatch(addStorageEntriesToPart(calc, partId, additionalData));
                await dispatch(updatePartParameters(calc, { partId, itemId, parameters }));

                // dispatch(addAdditionalStorageToPart({ partId, additionalStorage: additionalData, calcId: calc.id }));
                // dispatch(setPartParameter({ partId, itemId, parameter: parameters[0], calcId: calc.id }));
                // dispatch(setPartParameter({ partId, itemId, parameter: parameters[1], calcId: calc.id }));
            }

            // setTimeout(() => dispatch(setHoleRecognitionLoader({ partId, isLoading: false })), 500);
        }
    };

/*
#####################
##       NEW       ##
#####################
*/

export const getProCalc = (calculationId: string) => async () => {
    const calc = await CalculationRestControllerService.getCalculation(calculationId);
    return calc;
};

export const updateName = (calc: CalculationResource, value: string) => async (dispatch: AppDispatch) => {
    // await CalculationRestControllerService.updateName(calc.id, value); todo
};
export const updateCustomer = (calc: CalculationResource, customer: CustomerResource) => async (dispatch: AppDispatch) => {
    // todo: don't forget  longitude and latitude auto set on the server!
    // const dto: CalculationMetadataDto = {
    //     companyName: metadata.companyName,
    //     contactPerson: metadata?.contactPerson,
    //     note: metadata?.note,
    //     contactedVia: metadata.contactedVia || null
    // };
    // if (metadata.placeId) {
    //     dto.manualAddressEntry = null;
    //     dto.placeId = metadata.placeId;
    // } else {
    //     dto.manualAddressEntry = {
    //         street: metadata.address?.street || '',
    //         houseNumber: metadata.address?.houseNumber || '',
    //         zipcode: metadata.address?.zipcode || '',
    //         city: metadata.address?.city || '',
    //         country: metadata.address?.country || null
    //     };
    //     dto.placeId = null;
    // }
    // const result = await InternalCalculationRestControllerService.setMetadata(calc.id, dto);
    // const valuesToChange = metadata.placeId
    //     ? ['calculationMetadata.address', 'calculationMetadata.companyName', 'calculationMetadata.placeId', 'calculationMetadata.contactedVia']
    //     : ['calculationMetadata.address.latitude', 'calculationMetadata.address.longitude'];
    // dispatch(setProCalcSelectively({ calcId: calc.id, updatedCalculation: result, paths: valuesToChange }));
};

export const updateMasterVariableValue = (calc: CalculationResource, value: { variableId: number; value: ValueResource }) => async (dispatch: AppDispatch) => {
    await CalculationRestControllerService.updateMasterVariableValue(calc.id, value.variableId, value.value as ValueDTO);
};

export const updateMasterSurchargeVariableMode = (calc: CalculationResource, value: { variableId: number; mode: SurchargeVariableResource.mode }) => async (dispatch: AppDispatch) => {
    // await CalculationRestControllerService.updateMasterVariableValue(calc.id, value.variableId, value.value as ValueDTO); todo
};

export const createMasterAttachment = (calc: CalculationResource, value: AttachmentResource) => async (dispatch: AppDispatch) => {
    // await CalculationRestControllerService.updateMasterVariableValue(calc.id, value.variableId, value.value as ValueDTO); todo
};
export const removeMasterAttachment = (calc: CalculationResource, value: number) => async (dispatch: AppDispatch) => {
    // await CalculationRestControllerService.updateMasterVariableValue(calc.id, value.variableId, value.value as ValueDTO); todo
};

export const createItem = (calc: CalculationResource, value: NewItemDTO) => async (dispatch: AppDispatch) => {
    const item = await CalculationRestControllerService.createItem(calc.id, value);
    dispatch(deleteItem(-1)); // delete the temp item
    dispatch(addItem(item));
};

export const createItemWithFile = (calc: CalculationResource, value: NewItemWithFile) => async (dispatch: AppDispatch) => {
    let item = await CalculationRestControllerService.createItem(calc.id, { categoryId: value.categoryId });
    item = await dispatch(updateItemWithFile(calc, { item, fileResource: value.fileResource, modelStats: value.modelStats }));

    dispatch(deleteItem(-1)); // delete the temp item
    dispatch(addItem(item));
};

export const duplicateItem = (calc: CalculationResource, value: number) => async (dispatch: AppDispatch) => {
    const item = await CalculationRestControllerService.duplicateItem(calc.id, value);
    dispatch(deleteItem(-1)); // delete the temp item
    dispatch(addItem(item));
};

export const removeItem = (calc: CalculationResource, value: number) => async () => {
    await CalculationRestControllerService.deleteItem(calc.id, value);
};

export const updateItem = (calc: CalculationResource, value: ItemResource) => async (dispatch: AppDispatch) => {
    const item = await CalculationRestControllerService.updateItem(calc.id, value.id, value as ItemDTO);
    return item;
};

export const updateItemVariableValue = (calc: CalculationResource, value: { itemId: number; variableId: number; value: ValueResource }) => async (dispatch: AppDispatch) => {
    const itemValue = await CalculationRestControllerService.updateItemVariableValue(calc.id, value.itemId, value.variableId, value.value as ValueDTO);
    return itemValue;
};

export const updateItemSurchargeVariableMode = (calc: CalculationResource, value: { itemId: number; variableId: number; mode: SurchargeVariableResource.mode }) => async (dispatch: AppDispatch) => {
    // await CalculationRestControllerService.updateMasterVariableValue(calc.id, value.variableId, value.value as ValueDTO); todo
};

export const createItemAttachment = (calc: CalculationResource, value: { itemId: number; attachment: AttachmentResource }) => async (dispatch: AppDispatch) => {
    // await CalculationRestControllerService.updateMasterVariableValue(calc.id, value.variableId, value.value as ValueDTO); todo
};
export const removeItemAttachment = (calc: CalculationResource, value: { itemId: number; attachmentId: number }) => async (dispatch: AppDispatch) => {
    // await CalculationRestControllerService.updateMasterVariableValue(calc.id, value.variableId, value.value as ValueDTO); todo
};

/*
###############
##  HELPERS  ##
###############
*/
const setExtractedValue =
    (calc: CalculationResource, item: ItemResource, baseValue: number | string, extractedVariableIdentifier: string, inputVariableIdentifier?: string) => async (dispatch: AppDispatch) => {
        const clonedItem = cloneDeep(item);
        const value = isString(baseValue) ? { type: ValueResource.type.STRING_VALUE, stringValue: baseValue } : { type: ValueResource.type.NUMBER_VALUE, numberValue: Math.round(baseValue) };
        const extractedVariable = getVariable(extractedVariableIdentifier, item.variables);
        if (extractedVariable) {
            const updatedValue = await dispatch(updateItemVariableValue(calc, { variableId: extractedVariable.id, itemId: item.id, value }));
            const variable = clonedItem.variables.find((variable) => variable.id === extractedVariable.id);
            variable.value = updatedValue;
        }
        if (inputVariableIdentifier) {
            const inputVariable = getVariable(inputVariableIdentifier, item.variables);
            if (inputVariable) {
                const updatedValue = await dispatch(updateItemVariableValue(calc, { variableId: inputVariable.id, itemId: item.id, value }));
                const variable = clonedItem.variables.find((variable) => variable.id === inputVariable.id);
                variable.value = updatedValue;
            }
        }
        return clonedItem;
    };

export const getHolesAndThreads = (fileUrl: string) => async (): Promise<ProCalcHoleRecognitionResult> => {
    const key = await HoleRecognitionAfterburnerRestControllerService.getHoleRecognitionAfterburnerApiKey();
    const response = await fetch(API_CONFIG.HOLE_RECOGNITION.HOLE_RECOGNITION_PATH, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': key.apiKey },
        body: JSON.stringify({ url: fileUrl })
    });
    const result = await response.json();
    return result;
};

export const updateItemWithFile = (calc: CalculationResource, value: { item: ItemResource; fileResource: FileResource; modelStats: ModelStats }) => async (dispatch: AppDispatch) => {
    const { modelStats, fileResource } = value;
    let item = cloneDeep(value.item);

    const fileExtension = getFileExtension(fileResource.name);
    const nodeIds = getSpecificNodeId(fileResource);

    item = await dispatch(
        updateItem(calc, {
            ...item,
            ...nodeIds,
            name: fileResource.name,
            dataSourceOriginalFileName: fileResource.name,
            thumbnailUrl: fileResource.thumbnail
        })
    );
    if (ALLOWED_3D_FILE_TYPES.includes(fileExtension)) {
        item = await dispatch(setExtractedValue(calc, item, modelStats.surfaceArea.baseValue, 'extractedObjectSurfaceArea')); // todo constants
        item = await dispatch(setExtractedValue(calc, item, modelStats.volume.baseValue, 'extractedObjectVolume'));
        item = await dispatch(setExtractedValue(calc, item, modelStats.convexHull.surfaceArea.baseValue, 'extractedConvexHullSurfaceArea'));
        item = await dispatch(setExtractedValue(calc, item, modelStats.boundingBox.surfaceArea.baseValue, 'extractedBoundingBoxSurfaceArea'));
        item = await dispatch(setExtractedValue(calc, item, modelStats.boundingBox.length.baseValue, 'extractedLargestLength', 'largestLength'));
        item = await dispatch(setExtractedValue(calc, item, modelStats.boundingBox.width.baseValue, 'extractedLargestWidth', 'largestWidth'));
        item = await dispatch(setExtractedValue(calc, item, modelStats.boundingBox.height.baseValue, 'extractedLargestHeight', 'largestHeight'));

        const result = await dispatch(getHolesAndThreads(fileResource.url));
        if (result?.holes != null && result?.threads != null) {
            item = await dispatch(setExtractedValue(calc, item, result.holes, 'extractedHoles', 'holes'));
            item = await dispatch(setExtractedValue(calc, item, result.threads, 'extractedThreads', 'threads'));

            if (Array.isArray(result.holeDimensions) && result.holeDimensions.length > 0) {
                item = await dispatch(setExtractedValue(calc, item, JSON.stringify(result.holeDimensions), 'extractedHoleDimensions'));
            }
            if (Array.isArray(result.threadDimensions) && result.threadDimensions.length > 0) {
                item = await dispatch(setExtractedValue(calc, item, JSON.stringify(result.threadDimensions), 'extractedThreadDimensions'));
            }
        }
    }

    return item;
};
