import { AppDispatch, RootState } from '../store';
import { handleError, setBlueprint, setLoading } from '../slices/calcEditor.reducer';
import { BlueprintResource, BlueprintRestControllerService } from 'src/backend/coreCalc';
import { loadTemplates } from './templates.thunk';
import { addBlueprint, removeBlueprint, setBlueprints } from '../slices/calcEditors.reducer';

export const createBlueprint = (name: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const blueprint = await BlueprintRestControllerService.createBlueprint({ name });
        dispatch(addBlueprint(blueprint));
        return blueprint;
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const loadBlueprint = (blueprintId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
        await dispatch(loadTemplates());
        const blueprint = await BlueprintRestControllerService.getBlueprint(blueprintId);
        dispatch(setBlueprint(blueprint));
        dispatch(setLoading(false));
        return blueprint;
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(handleError(error));
    }
};

export const deleteBlueprint = (blueprintId: string) => async (dispatch: AppDispatch) => {
    await BlueprintRestControllerService.deleteBlueprint(blueprintId);
    dispatch(removeBlueprint(blueprintId));
};

export const loadBlueprints = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const loadedBlueprints: Array<BlueprintResource> = getState().calcEditor.blueprints;
    if (loadedBlueprints && loadedBlueprints.length > 0) return loadedBlueprints;

    try {
        const blueprints = await BlueprintRestControllerService.getAllBlueprints();
        dispatch(setBlueprints(blueprints));
        return blueprints;
    } catch (error) {
        dispatch(handleError(error));
    }
};
