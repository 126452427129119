import { Box, Card, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, useTheme } from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { visuallyHidden } from '@mui/utils';
import CalcOverviewTableRow from './CalcOverviewTableRow';
import { AiMailConversationResource } from 'src/backend/market';
import { updatePaging } from 'src/redux/thunks/proCalcs.thunk';
import { ShortCalculationResource } from 'src/backend/coreCalc';

type Order = 'asc' | 'desc';

interface Props {
    calculations: Array<ShortCalculationResource>;
    mailConversations: { [uniqueId: string]: AiMailConversationResource };
    isLoading: boolean;
    sort: GridSortModel;
    paging: { page: number; pageSize: number; total: number; rowCount: number };
    handleEditClicked: Function;
    handleViewClicked: Function;
    handleDuplicateClicked: Function;
    handleDeleteClicked: Function;
    handleSortModelChange: (sortModel: GridSortModel) => void;
}

const CalcOverviewTable: React.FC<Props> = ({
    calculations,
    mailConversations,
    isLoading,
    paging,
    sort,
    handleEditClicked,
    handleViewClicked,
    handleDuplicateClicked,
    handleDeleteClicked,
    handleSortModelChange
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<string>('id');
    const [openRow, setOpenRow] = React.useState<ShortCalculationResource>(null);

    const onPageChangeCallback = (event: any, page: number) => {
        dispatch(updatePaging({ page: page }));
    };

    const onPageSizeChangeCallback = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updatePaging({ pageSize: parseInt(event.target.value) }));
    };

    const getOppositeSort = (sort) => {
        return sort === 'asc' ? 'desc' : 'asc';
    };

    const HeadCell = ({ cellId, children = null, isSortable = true }) => {
        return (
            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                {isSortable ? (
                    <TableSortLabel
                        active={sort[0].field === cellId}
                        direction={sort[0].field === cellId ? sort[0].sort : 'asc'}
                        onClick={() =>
                            handleSortModelChange([
                                {
                                    field: cellId,
                                    sort: sort[0] ? getOppositeSort(sort[0].sort) : 'asc'
                                }
                            ])
                        }
                    >
                        {children}
                        {orderBy === cellId ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                ) : (
                    children
                )}
            </TableCell>
        );
    };

    const rows = Object.values(calculations);

    return (
        <Card sx={{ flexGrow: 1 }}>
            <TableContainer sx={{ height: '100%', maxHeight: 'calc(100vh - 80px - 115px - 36px - 36px - 45px)' }}>
                <Table aria-label="collapsible table" sx={{ height: '100%' }}>
                    <TableHead sx={{ position: 'sticky', top: 0, zIndex: 15, boxShadow: '0 1px 0 0 #e8eaee' }}>
                        <TableRow sx={{ background: '#fbfbfc', '.MuiTableCell-root': { border: 'none' } }}>
                            <HeadCell cellId="collapseIcon" isSortable={false}></HeadCell>
                            <HeadCell cellId="id">{t('id')}</HeadCell>
                            <HeadCell cellId="icons" isSortable={false}>
                                {t('infos')}
                            </HeadCell>
                            <HeadCell cellId="requester">{t('requester')}</HeadCell>
                            <HeadCell cellId="articles" isSortable={false}>
                                {t('articles')}
                            </HeadCell>
                            <HeadCell cellId="price">{t('price')}</HeadCell>
                            <HeadCell cellId="createdAt">{t('erstellungsdatum')}</HeadCell>
                            <HeadCell cellId="colors">{t('colors')}</HeadCell>
                            <HeadCell cellId="materials">{t('materials')}</HeadCell>
                            <HeadCell cellId="area">{t('Area')}</HeadCell>
                            <HeadCell cellId="status">{t('status')}</HeadCell>
                            <HeadCell cellId="actions" isSortable={false}>
                                {t('actions')}
                            </HeadCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (
                            <tr style={{ height: '100%' }}>
                                <td colSpan={13}>
                                    <Box display="flex" alignItems="center" justifyContent="center" p={2}>
                                        <CircularProgress />
                                    </Box>
                                </td>
                            </tr>
                        ) : rows.length < 1 ? (
                            <tr>
                                <td colSpan={13}>
                                    <Box display="flex" alignItems="center" justifyContent="center" p={2}>
                                        {t('dgNoRowsLabel')}
                                    </Box>
                                </td>
                            </tr>
                        ) : (
                            <>
                                {rows.map((row) => (
                                    <CalcOverviewTableRow
                                        key={row.id}
                                        row={row}
                                        openRow={openRow}
                                        setOpenRow={setOpenRow}
                                        mailConversation={mailConversations?.[row.id]}
                                        handleEditClicked={handleEditClicked}
                                        handleViewClicked={handleViewClicked}
                                        handleDuplicateClicked={handleDuplicateClicked}
                                        handleDeleteClicked={handleDeleteClicked}
                                    />
                                ))}
                                <tr style={{ height: '100%' }}></tr>
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component={Box}
                labelRowsPerPage={t('rowsPerPage') + ': '}
                rowsPerPageOptions={[5, 10, 25, 50]}
                count={paging.total}
                rowsPerPage={paging.pageSize}
                page={paging.page}
                onPageChange={onPageChangeCallback}
                onRowsPerPageChange={onPageSizeChangeCallback}
                labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    boxShadow: '0 -1px 0 0 #e8eaee',
                    height: 45,
                    '.MuiToolbar-root': {
                        height: 45
                    },
                    border: 'none'
                }}
            />
        </Card>
    );
};
export default CalcOverviewTable;
