import { Box, lighten, ListItemButton, ListItemIcon, ListItemText, Stack, styled, Tooltip, Typography, useTheme } from '@mui/material';
import React from 'react';
import { formatSurchargeValue } from 'src/utils/FormatHelpers';
import Label from '../display/Label';
import { RootState } from 'src/redux/store';
import { connect, useDispatch } from 'react-redux';
import RepeatTwoToneIcon from '@mui/icons-material/RepeatTwoTone';
import BlockTwoToneIcon from '@mui/icons-material/BlockTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import { SettingsResource, SurchargeVariableResource } from 'src/backend/coreCalc';
import { getVariableName, getValue } from 'src/utils/CalcHelpers';

const StyledListItemButton = styled(ListItemButton)(
    ({ theme }) => `
        display: grid;
        grid-template-columns: auto 1fr minmax(20%, min-content) auto;
        height: 3rem;
        border-radius: 0;
        
        &.active {
            background: ${theme.colors.primary.lighter};
        }
        &.active:hover {
            background: ${lighten(theme.colors.primary.main, 0.8)};
        }
        &.active.Mui-disabled {
            opacity: 1;
            background: ${theme.colors.alpha.black[7]};
        }
        &.active.Mui-disabled .Mui-checked {
            color: ${theme.colors.alpha.black[30]};
        }
    `
);

interface Props {
    variable: SurchargeVariableResource;
    settings: SettingsResource;
    saveMode: (variableId: number, mode: SurchargeVariableResource.mode) => void;
}

const SurchargeListItem: React.FC<Props> = ({ variable, settings, saveMode }) => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const toggleSurcharge = () => {
        let newMode;
        switch (variable.mode) {
            case SurchargeVariableResource.mode.DISABLED:
                newMode = SurchargeVariableResource.mode.ENABLED;
                break;
            case SurchargeVariableResource.mode.ENABLED:
                newMode = SurchargeVariableResource.mode.USE_CRITERIA;
                break;
            case SurchargeVariableResource.mode.USE_CRITERIA:
                newMode = SurchargeVariableResource.mode.DISABLED;
                break;
            default:
                newMode = SurchargeVariableResource.mode.USE_CRITERIA;
        }
        saveMode(variable.id, newMode);
    };

    const renderModeIndicator = () => {
        let icon;
        switch (variable.mode) {
            case SurchargeVariableResource.mode.USE_CRITERIA:
                icon = <RepeatTwoToneIcon />;
                break;
            case SurchargeVariableResource.mode.ENABLED:
                icon = <CheckCircleTwoToneIcon />;
                break;
            case SurchargeVariableResource.mode.DISABLED:
                icon = <BlockTwoToneIcon />;
                break;
        }
        return (
            <Tooltip title={variable.mode}>
                <Stack sx={{ opacity: 0.6 }}>{icon}</Stack>
            </Tooltip>
        );
    };

    return (
        <StyledListItemButton sx={{ py: 0, px: 2, my: 1 }} className={variable.isApplied && 'active'} onClick={toggleSurcharge}>
            <ListItemIcon sx={{ minWidth: 32, mr: 0.6 }}>{renderModeIndicator()}</ListItemIcon>
            <ListItemText
                primary={<Typography noWrap>{getVariableName(variable)}</Typography>}
                secondary={variable.mode}
                secondaryTypographyProps={{ fontSize: 10 }}
                primaryTypographyProps={{ variant: 'body1' }}
            />
            <Box display="flex" justifyContent="flex-end" pl={1.5}>
                <Label color="primary">{formatSurchargeValue(variable, settings.numberFormat, settings.currency)}</Label>
            </Box>
        </StyledListItemButton>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        settings: state.proCalc.calculation?.settings
    };
};

export default connect(mapStateToProps)(SurchargeListItem);
