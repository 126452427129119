/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BooleanValueDTO } from '../models/BooleanValueDTO';
import type { BooleanValueResource } from '../models/BooleanValueResource';
import type { CalculationDTO } from '../models/CalculationDTO';
import type { CalculationFilterDTO } from '../models/CalculationFilterDTO';
import type { CalculationPageResultResource } from '../models/CalculationPageResultResource';
import type { CalculationResource } from '../models/CalculationResource';
import type { ItemDTO } from '../models/ItemDTO';
import type { ItemResource } from '../models/ItemResource';
import type { ListValueDTO } from '../models/ListValueDTO';
import type { ListValueResource } from '../models/ListValueResource';
import type { NewItemDTO } from '../models/NewItemDTO';
import type { NumberValueDTO } from '../models/NumberValueDTO';
import type { NumberValueResource } from '../models/NumberValueResource';
import type { StringValueDTO } from '../models/StringValueDTO';
import type { StringValueResource } from '../models/StringValueResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class CalculationRestControllerService {

    /**
     * @param calculationId
     * @param variableId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateMasterVariableValue(
        calculationId: string,
        variableId: number,
        requestBody: (BooleanValueDTO | ListValueDTO | NumberValueDTO | StringValueDTO),
    ): CancelablePromise<(BooleanValueResource | ListValueResource | NumberValueResource | StringValueResource)> {
        return __request({
            method: 'PUT',
            path: `/coreCalc/calculations/${calculationId}/variables/${variableId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param itemId
     * @param requestBody
     * @returns ItemResource OK
     * @throws ApiError
     */
    public static updateItem(
        calculationId: string,
        itemId: number,
        requestBody: ItemDTO,
    ): CancelablePromise<ItemResource> {
        return __request({
            method: 'PUT',
            path: `/coreCalc/calculations/${calculationId}/items/${itemId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param itemId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteItem(
        calculationId: string,
        itemId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/coreCalc/calculations/${calculationId}/items/${itemId}`,
        });
    }

    /**
     * @param calculationId
     * @param itemId
     * @param variableId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateItemVariableValue(
        calculationId: string,
        itemId: number,
        variableId: number,
        requestBody: (BooleanValueDTO | ListValueDTO | NumberValueDTO | StringValueDTO),
    ): CancelablePromise<(BooleanValueResource | ListValueResource | NumberValueResource | StringValueResource)> {
        return __request({
            method: 'PUT',
            path: `/coreCalc/calculations/${calculationId}/items/${itemId}/variables/${variableId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns CalculationResource OK
     * @throws ApiError
     */
    public static createCalculation(
        requestBody: CalculationDTO,
    ): CancelablePromise<CalculationResource> {
        return __request({
            method: 'POST',
            path: `/coreCalc/calculations`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param requestBody
     * @returns ItemResource OK
     * @throws ApiError
     */
    public static createItem(
        calculationId: string,
        requestBody: NewItemDTO,
    ): CancelablePromise<ItemResource> {
        return __request({
            method: 'POST',
            path: `/coreCalc/calculations/${calculationId}/items`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param itemId
     * @returns ItemResource OK
     * @throws ApiError
     */
    public static duplicateItem(
        calculationId: string,
        itemId: number,
    ): CancelablePromise<ItemResource> {
        return __request({
            method: 'POST',
            path: `/coreCalc/calculations/${calculationId}/items/${itemId}/duplicates`,
        });
    }

    /**
     * @param page
     * @param size
     * @param requestBody
     * @param sortBy
     * @returns CalculationPageResultResource OK
     * @throws ApiError
     */
    public static getAllCalculations(
        page: number,
        size: number,
        requestBody: CalculationFilterDTO,
        sortBy: string = 'idAsc',
    ): CancelablePromise<CalculationPageResultResource> {
        return __request({
            method: 'POST',
            path: `/coreCalc/calculations/companyCalculations`,
            query: {
                'page': page,
                'size': size,
                'sortBy': sortBy,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @returns CalculationResource OK
     * @throws ApiError
     */
    public static getCalculation(
        calculationId: string,
    ): CancelablePromise<CalculationResource> {
        return __request({
            method: 'GET',
            path: `/coreCalc/calculations/${calculationId}`,
        });
    }

    /**
     * @param calculationId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteCalculation(
        calculationId: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/coreCalc/calculations/${calculationId}`,
        });
    }

}