/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TermResource = {
    type?: TermResource.type;
}

export namespace TermResource {

    export enum type {
        ELASTIC = 'ELASTIC',
        BINARY = 'BINARY',
    }


}
