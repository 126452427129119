/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MetadataDTO } from './MetadataDTO';
import type { NumberValueDTO } from './NumberValueDTO';
import type { VariableDTO } from './VariableDTO';

export type NumberInputVariableDTO = (VariableDTO & {
    name?: string;
    description?: string;
    metadata?: Array<MetadataDTO>;
    sequence?: number;
    section?: NumberInputVariableDTO.section;
    hasUiContract?: boolean;
    alwaysActive?: boolean;
    visibility?: NumberInputVariableDTO.visibility;
    value?: NumberValueDTO;
    defaultValue?: NumberValueDTO;
    unit?: NumberInputVariableDTO.unit;
    min?: number;
    max?: number;
    naturalNumber?: boolean;
});

export namespace NumberInputVariableDTO {

    export enum section {
        GEOMETRY_SECTION = 'GEOMETRY_SECTION',
        COMPONENT_SECTION = 'COMPONENT_SECTION',
        SURFACE_SECTION = 'SURFACE_SECTION',
        CALCULATION_SECTION = 'CALCULATION_SECTION',
        ADDITIONAL_WORK_SECTION = 'ADDITIONAL_WORK_SECTION',
        QUALITY_SECTION = 'QUALITY_SECTION',
    }

    export enum visibility {
        HIDDEN = 'HIDDEN',
        DISABLED = 'DISABLED',
        VISIBLE = 'VISIBLE',
    }

    export enum unit {
        MILLIMETER = 'MILLIMETER',
        CENTIMETER = 'CENTIMETER',
        METER = 'METER',
        KILOMETER = 'KILOMETER',
        SQUARE_MILLIMETER = 'SQUARE_MILLIMETER',
        SQUARE_CENTIMETER = 'SQUARE_CENTIMETER',
        SQUARE_METER = 'SQUARE_METER',
        SQUARE_KILOMETER = 'SQUARE_KILOMETER',
        CUBIC_MILLIMETER = 'CUBIC_MILLIMETER',
        CUBIC_CENTIMETER = 'CUBIC_CENTIMETER',
        CUBIC_METER = 'CUBIC_METER',
        GRAM = 'GRAM',
        KILOGRAM = 'KILOGRAM',
        TONNE = 'TONNE',
        PIECE = 'PIECE',
    }


}
