import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import store from 'src/redux/store';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { BlueprintUpdateTypes, updateBlueprint } from 'src/redux/shared/calcEditor.common';
import { CategoryResource } from 'src/backend/coreCalc';
import SubCategoryForm from './SubCategoryForm';

interface Props {
    category: CategoryResource;
    subCategory: CategoryResource;
    isOpen: boolean;
    setOpen?: (isOpen: boolean) => void;
}

const ChangeSubCategoryDialog: React.FC<Props> = ({ category, subCategory, isOpen, setOpen }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const { t } = useTranslation();
    const [localSubCategory, setLocalSubCategory] = useState<CategoryResource>(subCategory);

    const saveSubCategory = () => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.SUB_CATEGORY, { categoryId: category.id, subCategory: localSubCategory }));
        setOpen(false);
    };

    const removeSubCategory = () => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.DELETE_SUB_CATEGORY, { categoryId: category.id, subCategoryId: localSubCategory.id }));
        setOpen(false);
    };

    const isValid = localSubCategory.name != null && localSubCategory.name.trim() !== '';

    return (
        <Dialog open={isOpen} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
            <DialogTitle sx={{ pt: 3.1, pb: 2.7 }}>
                <Typography variant="h4">Teil ändern</Typography>
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 16, top: 16, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack gap={3} pt={1}>
                    <SubCategoryForm subCategory={localSubCategory} setSubCategory={setLocalSubCategory} />
                    <Stack direction="row" justifyContent="space-between">
                        <Button variant="text" sx={{ mx: -1.5 }} color="error" startIcon={<DeleteTwoToneIcon />} onClick={removeSubCategory}>
                            Löschen
                        </Button>
                        <Button variant="contained" startIcon={<AutoAwesomeTwoToneIcon />} disabled={!isValid} onClick={saveSubCategory}>
                            Änderungen speichern
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ChangeSubCategoryDialog;
