import { Box, Button, List, ListItem, ListItemText, Popover, Stack, styled, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import { t } from 'i18next';
import MarkEmailUnreadTwoToneIcon from '@mui/icons-material/MarkEmailUnreadTwoTone';
import { CalculationMessageResource } from 'src/backend/market';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import GppBadTwoToneIcon from '@mui/icons-material/GppBadTwoTone';
import store from 'src/redux/store';
import { wT } from 'src/utils/wizardTranslations';
import { MessageResource } from 'src/backend/coreCalc';
import { getMessageBackgroundColor, getMessageButtonIndicator, getMessageIcon } from 'src/components/calc-editor/Categories/Messages/CategoryMessages';

const IconWrapper = styled(Box)(
    ({ theme }) => `
        display: inline-flex;
        justify-content: center;
        margin-left: ${theme.spacing(1)};
        margin-right: ${theme.spacing(0.4)};
        .MuiSvgIcon-root {
            font-size: 15px;
            margin: auto;
        }
    `
);
const StyledListItemText = styled(ListItemText)(
    ({ theme }) => `
        .MuiListItemText-primary {
            display: flex;
        }
    `
);

interface Props {
    messages: Array<MessageResource>;
    isSmall?: boolean;
}

const ItemMessages: React.FC<Props> = ({ messages = [], isSmall }) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const ref = useRef<any>(null);
    const s = store.getState();
    const theme = useTheme();

    const hints = useMemo(() => messages.filter((message) => message.type === MessageResource.type.HINT).length, [messages]);
    const warnings = useMemo(() => messages.filter((message) => message.type === MessageResource.type.WARNING).length, [messages]);
    const errors = useMemo(() => messages.filter((message) => message.type === MessageResource.type.ERROR).length, [messages]);

    return (
        messages?.length > 0 && (
            <>
                <Button
                    sx={{ px: 1.2, py: 0.5, flexShrink: 0 }}
                    startIcon={<MarkEmailUnreadTwoToneIcon />}
                    variant="text"
                    color="secondary"
                    onClick={() => setOpen(true)}
                    ref={ref}
                    size={isSmall ? 'small' : 'medium'}
                >
                    {t('generalMessages')}
                    {getMessageButtonIndicator(hints, MessageResource.type.HINT, theme)}
                    {getMessageButtonIndicator(warnings, MessageResource.type.WARNING, theme)}
                    {getMessageButtonIndicator(errors, MessageResource.type.ERROR, theme)}
                </Button>

                <Popover
                    anchorEl={ref.current}
                    onClose={() => setOpen(false)}
                    open={isOpen}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                >
                    <Stack p={2} maxWidth="35rem" width="25rem" maxHeight="50rem">
                        <Typography variant="h3" textAlign="center">
                            {t('categoryMessages')}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom textAlign="center" mb={2}>
                            {t('categoryMessagesExplanation')}
                        </Typography>

                        <List disablePadding sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                            {messages.map((message, index) => (
                                <ListItem key={index} sx={{ bgcolor: getMessageBackgroundColor(message.type, theme), borderRadius: 1 }}>
                                    <Tooltip title={t(message.type)}>
                                        <IconWrapper>{getMessageIcon(message.type)}</IconWrapper>
                                    </Tooltip>
                                    <StyledListItemText primary={message.title || t(message.titleTranslationKey)} secondary={message.message || t(message.messageTranslationKey)} />
                                </ListItem>
                            ))}
                        </List>
                    </Stack>
                </Popover>
            </>
        )
    );
};

export default ItemMessages;
