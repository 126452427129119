import { Alert, Box, Button, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ModuleRestControllerService } from 'src/backend/market';
import { useSelector } from 'src/redux/store';
import { refetchModuleManagement } from 'src/redux/thunks/constants.thunk';
import { slideInLeft } from 'src/utils/animationstyles';
import { useEffect, useState } from 'react';
import CalcOverview from 'src/components/calc-overview/CalcOverview';
import { updatePaging } from 'src/redux/thunks/proCalcs.thunk';
import { startWalkthrough } from 'src/redux/thunks/guidethrough.thunk';
import PROCalcOverviewHeader from './PROCalcOverviewHeader';

const PROCalcOverview = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();
    const { internalCalculation, setInternalCalculationBookingInitiated } = useSelector((state) => state.constants.moduleManagement);
    const userID = useSelector((state) => state.user.currentUser.id);
    const savedMode = localStorage.getItem('calculationsViewMode') || 'gridView';
    const [mode, setMode] = useState<string | null>(savedMode);

    useEffect(() => {
        dispatch(startWalkthrough('internalCalculation'));
    }, []);

    const handleBuyModule = async () => {
        await ModuleRestControllerService.bookInternalCalcModule(userID);
        dispatch(refetchModuleManagement());
    };
    const renderBuyAlert = () => {
        return (
            <Alert
                sx={{ fontWeight: 'bold', my: 1, mt: 4 }}
                severity="warning"
                action={
                    <Button color={'warning'} onClick={handleBuyModule} disabled={setInternalCalculationBookingInitiated}>
                        {t('freischalten')}
                    </Button>
                }
            >
                {t(setInternalCalculationBookingInitiated ? 'internalCalcWaitingForActivation' : 'internalCalcNotYetActivated')}
            </Alert>
        );
    };

    return (
        <>
            <PROCalcOverviewHeader
                mode={mode}
                setMode={(mode) => {
                    setMode(mode);
                    localStorage.setItem('calculationsViewMode', mode);
                    if (mode === 'tableView') dispatch(updatePaging({ pageSize: 10 }));
                }}
                internalCalculationActivated={!!internalCalculation}
            />

            {!internalCalculation ? (
                renderBuyAlert()
            ) : (
                <Stack direction="row" spacing={4} flexGrow={1} justifyContent="space-between" maxWidth="100%">
                    <Stack pt={{ xs: 0, md: 4 }} flexGrow={1} sx={{ '& > div': { height: '100%' } }} maxWidth="100%">
                        {slideInLeft(
                            <Box flexGrow={1} height="100%">
                                <CalcOverview mode={mode} />
                            </Box>
                        )}
                    </Stack>
                </Stack>
            )}
        </>
    );
};
export default PROCalcOverview;
