import {
    Box,
    Button,
    CircularProgress,
    ClickAwayListener,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Popover,
    Stack,
    styled,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { formatDate, formatPrice, getRelativeTime } from 'src/utils/FormatHelpers';
import useDialog from 'src/hooks/useDialog';
import store, { RootState } from 'src/redux/store';
import PageHeader from 'src/components/display/PageHeader';
import { nav } from 'src/statics/navigations';
import GppBadTwoToneIcon from '@mui/icons-material/GppBadTwoTone';
import { wT } from 'src/utils/wizardTranslations';
import { getAllMessages, getComputationValue, getNumberValue } from 'src/utils/CalcHelpers';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import HelpBanner from 'src/components/calc-editor/HelpBanner/HelpBanner';
import { ProCalcSavingStatus } from 'src/redux/slices/proCalc.reducer';
import { BlueprintResource } from 'src/backend/coreCalc';
import { BlueprintUpdateTypes, updateBlueprint } from 'src/redux/shared/calcEditor.common';
import { deleteBlueprint } from 'src/redux/thunks/calcEditors.thunk';

const IconButtonEdit = styled(IconButton)(
    ({ theme }) => `
        padding: ${theme.spacing(0.8)};
  
        .MuiSvgIcon-root {
            font-size: ${theme.typography.pxToRem(20)};
        }
    `
);
const StyledListItemText = styled(ListItemText)(
    ({ theme }) => `
        .MuiListItemText-primary {
            display: flex;
        }
    `
);

interface Props {
    blueprint?: BlueprintResource;
    savingStatus?: ProCalcSavingStatus;
    isPriceLoading?: boolean;
}

const CalcEditorHeader: React.FC<Props> = ({ blueprint, savingStatus, isPriceLoading }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dialog = useDialog();
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();
    const [newName, setNewName] = useState<string>(blueprint.name || '');
    const [isRenaming, setRename] = useState<boolean>(false);
    const [isShowingErrors, setShowingErrors] = useState<boolean>(false);
    const ref = useRef<any>(null);
    const s = store.getState();

    const allMessages = useMemo(() => {
        return []; // todo loop over the categories messages
    }, [blueprint?.categories]);
    const errors = useMemo(
        () => allMessages.filter((message) => message.messageType === 'ERROR' || (message.messageType.startsWith('INVALID') && message.messageType !== 'INVALID_PRICE_ERROR')),
        [allMessages]
    );

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleBack = () => {
        navigate(nav.TOOLS.sub.CALC_EDITOR.path);
    };

    const handleDelete = () => {
        dialog.openDialog({
            severity: 'error',
            okText: t('delete'),
            cancelText: t('notDelete'),
            component: <Typography>{t('areYouSureToDeleteTheBlueprint')}</Typography>,
            title: t('deleteBlueprint'),
            okCallback: async () => {
                await dispatch(deleteBlueprint(blueprint.id));
                handleBack();
                dialog.closeDialog();
            }
        });
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.persist();
        setNewName(event.target.value);
    };

    useEffect(() => {
        setNewName(blueprint?.name);
    }, [blueprint?.name]);

    const handleRename = async (): Promise<void> => {
        setRename(false);
        if (newName === blueprint.name) return;
        dispatch(updateBlueprint(BlueprintUpdateTypes.NAME, newName));
    };

    const handleKeypress = (event) => {
        if (event.key === 'Enter') {
            event.target.blur();
        }
    };

    const price = useMemo(() => getComputationValue(blueprint?.masterComputation, blueprint?.masterVariables), [blueprint?.masterComputation, blueprint?.masterVariables]);

    const errorInfo = () => {
        return (
            <>
                <Button
                    sx={{ px: 1, pt: 0, pb: 0.15, mt: -0.1, flexShrink: 0, fontSize: 12 }}
                    startIcon={<GppBadTwoToneIcon sx={{ fontSize: '15px !important' }} />}
                    endIcon={<KeyboardArrowDownTwoToneIcon sx={{ fontSize: '15px !important', mr: 0.2 }} />}
                    variant="contained"
                    color="error"
                    onClick={() => setShowingErrors(true)}
                    ref={ref}
                >
                    {t('xErrors', { x: errors.length })}
                </Button>

                <Popover
                    anchorEl={ref.current}
                    onClose={() => setShowingErrors(false)}
                    open={isShowingErrors}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                >
                    <List sx={{ maxWidth: '30rem', maxHeight: '50rem' }}>
                        {errors.map((message, index) => (
                            <ListItem key={index}>
                                <StyledListItemText primary={<>{wT(message.messageId + '.short', s)}</>} secondary={wT(message.messageId + '.long', s)} />
                            </ListItem>
                        ))}
                    </List>
                </Popover>
            </>
        );
    };

    return (
        <PageHeader
            handleBack={handleBack}
            mainTitle={
                <Stack overflow="hidden">
                    <Box display="flex" alignItems="center" position="relative">
                        {isRenaming ? (
                            <ClickAwayListener onClickAway={handleRename}>
                                <TextField
                                    value={newName}
                                    size="small"
                                    onBlur={handleRename}
                                    onChange={handleChange}
                                    onKeyDown={handleKeypress}
                                    variant="outlined"
                                    margin="none"
                                    sx={{ m: 0 }}
                                    inputProps={{ sx: { py: 0.6 }, maxLength: 30 }}
                                    fullWidth
                                />
                            </ClickAwayListener>
                        ) : (
                            <Typography variant="h3" component="span" onClick={() => setRename(true)} noWrap maxWidth="34rem">
                                {blueprint.name}
                            </Typography>
                        )}
                        <Box display="flex" alignItems="center" pl={0.5}>
                            <Tooltip arrow placement="top" title={t('rename')}>
                                <IconButtonEdit onClick={() => setRename(true)} color="primary">
                                    <EditTwoToneIcon />
                                </IconButtonEdit>
                            </Tooltip>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Tooltip arrow placement="top" title={t('delete')}>
                                <IconButtonEdit onClick={handleDelete} color="secondary">
                                    <DeleteTwoToneIcon />
                                </IconButtonEdit>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Stack direction="row" alignItems="center" gap={1}>
                        {blueprint?.createdAt && (
                            <Tooltip title={formatDate(blueprint.createdAt)}>
                                <Typography variant="subtitle2">
                                    {t('created')} {getRelativeTime(blueprint.createdAt)}
                                </Typography>
                            </Tooltip>
                        )}
                        {errors.length > 0 && errorInfo()}
                    </Stack>
                </Stack>
            }
            secondaryTitle={<>PRO Calc Studio - {t(savingStatus)}</>}
            tabTitle={t(nav.CALCULATIONS.sub.EDIT.tabTitle, { id: blueprint?.id })}
            sx={{ py: 0, '& > div': { height: '100%' }, position: 'sticky', top: isSmallScreen ? 0 : theme.header.height, zIndex: 106, backgroundColor: 'rgb(249 250 252)' }}
        >
            <Stack flexGrow={1} alignItems="center">
                <HelpBanner />
            </Stack>
            {price != null && (
                <>
                    <Stack direction="row" justifyContent="space-around" alignItems="center" gap={3} height="100%">
                        <Box px={2} py={1.4} textAlign="center">
                            <Typography variant="subtitle2">Test Preis</Typography>
                            <Typography variant="h3" position="relative" sx={{ color: isPriceLoading ? 'transparent' : theme.colors.primary.main }}>
                                {formatPrice(price, false, blueprint.settings?.numberFormat, blueprint.settings?.currency)}
                                {isPriceLoading && (
                                    <Stack sx={{ position: 'absolute', justifyContent: 'center', alignItems: 'center', top: 0, right: 0, bottom: 0, left: 0 }}>
                                        <CircularProgress color="primary" size={25} />
                                    </Stack>
                                )}
                            </Typography>
                        </Box>
                    </Stack>
                </>
            )}
        </PageHeader>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        blueprint: state.calcEditor.blueprint,
        savingStatus: state.calcEditor.savingStatus
    };
};
export default connect(mapStateToProps)(CalcEditorHeader);
