import { Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { CategoryVariableIdentifier, ComputationResource, SettingsResource, VariableResource } from 'src/backend/coreCalc';
import { getComputationValue, getInputValue, getNumberValue, searchForSpecialInputVariable } from 'src/utils/CalcHelpers';
import { formatPrice } from 'src/utils/FormatHelpers';

interface Props {
    computation: ComputationResource;
    settings: SettingsResource;
    variables: Array<VariableResource>;
}

const ComputationPrice: React.FC<Props> = ({ computation, settings = {}, variables }) => {
    const quantityVariable = useMemo(() => searchForSpecialInputVariable(variables, CategoryVariableIdentifier.QUANTITY), [variables]);
    const quantity = useMemo(() => getInputValue(quantityVariable) || 0, [quantityVariable]) as number;
    const totalPrice = useMemo(() => getComputationValue(computation, variables) || 0, [computation, variables]);

    return (
        <Typography color="primary" variant="h3">
            {formatPrice(settings.showUnitPrice ? totalPrice / quantity : totalPrice, false, settings.numberFormat, settings.currency)}
        </Typography>
    );
};

export default ComputationPrice;
