import { Box, Card, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RootState, snackContext, useDispatch } from 'src/redux/store';
import PageHeader from 'src/components/display/PageHeader';
import { useEffect, useMemo, useRef, useState } from 'react';
import { SSA3DViewer } from '@surface-solutions/ssa-3d-viewer';
import { useNavigate, useParams } from 'react-router-dom';
import { nav } from 'src/statics/navigations';
import { ModelStats, NumberWithUnit } from '@surface-solutions/ssa-3d-viewer/dist/context/ViewerContext';
import { FileResource } from 'src/backend/market';
import { deleteFile, loadFile, loadFiles } from 'src/redux/thunks/fileManager.thunk';
import { renderCalculatedValue } from 'src/components/display/CalculatedValues';
import { formatDimensionsInfos, formatValue } from 'src/utils/FormatHelpers';
import i18n from 'src/i18n/i18n';
import LooksTwoTwoToneIcon from '@mui/icons-material/LooksTwoTwoTone';
import Looks3TwoToneIcon from '@mui/icons-material/Looks3TwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import { connect } from 'react-redux';
import { IconButton } from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';
import { CircularProgress } from '@mui/material';
import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone';
import { Button } from '@mui/material';
import { Popover } from '@mui/material';
import { ToggleButtonGroup } from '@mui/material';
import { ToggleButton } from '@mui/material';
import ScaleTwoToneIcon from '@mui/icons-material/ScaleTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import useDialog from 'src/hooks/useDialog';
import { createItemWithFile, getHolesAndThreads } from 'src/redux/thunks/proCalc.thunk';
import { ProCalcHoleRecognitionResult } from 'src/redux/slices/proCalc.reducer';
import NewProCalc from 'src/components/pro-calc/NewProCalc';
import { CalculationResource } from 'src/backend/coreCalc';

interface Props {
    files?: Array<FileResource>;
}

const Viewer3dDetail: React.FC<Props> = ({ files }) => {
    const navigate = useNavigate();
    const [file, setFile] = useState<FileResource>();
    const dispatch = useDispatch();
    const dialog = useDialog();
    const { t } = useTranslation();
    const { id } = useParams();
    const theme = useTheme();
    const [modelStats, setModelStats] = useState<ModelStats>();
    const [holeRecognitionResult, setHoleRecognitionResult] = useState<ProCalcHoleRecognitionResult>();
    const [isMaterialDropdownOpen, setMaterialDropdownOpen] = useState<boolean>(false);
    const [isImportLoading, setImportLoading] = useState<boolean>(false);
    const ref = useRef<any>(null);
    const materials = {
        aluminum: 2750,
        steel: 8000,
        stainlessSteel: 8000
    };
    const [material, setMaterial] = useState<string>('aluminum');

    useEffect(() => {
        loadData();
        if (!files || files?.length < 1) dispatch(loadFiles());
    }, [id]);

    const loadData = async () => {
        setHoleRecognitionResult(null);
        const fileResource: FileResource = await dispatch(loadFile(id));
        setFile(fileResource);
        const result: ProCalcHoleRecognitionResult = await dispatch(getHolesAndThreads(fileResource.url));
        setHoleRecognitionResult(result);
    };

    const nearestFiles = useMemo(() => {
        if (!file || files?.length < 1) return;
        const currentIndex = files.findIndex((f) => f.nodeId === file.nodeId);
        const previous = files[currentIndex - 1];
        const next = files[currentIndex + 1];

        return {
            previous: previous?.nodeId,
            current: file.nodeId,
            next: next?.nodeId
        };
    }, [file, files]);

    const handleOnViewerLoader = (stats: ModelStats) => {
        setModelStats(stats);
    };

    const getValue = (numberWithUnit: NumberWithUnit, showUnit: boolean = true) => {
        if (!showUnit) return formatValue(numberWithUnit.value, i18n.language, 2);
        return formatValue(numberWithUnit.value, i18n.language, 2) + ' ' + numberWithUnit.unit;
    };

    const handleArrowNavigation = (nodeId: string) => {
        navigate(nav.VIEWER_3D.sub.VIEW.path.replace(':id', nodeId));
    };

    const handleCalcImport = async (calculation: CalculationResource) => {
        if (!modelStats) return;

        const defaultCategory = calculation.categories.find((category) => category.defaultCategory);
        if (!defaultCategory) return snackContext.enqueueSnackbar(t('noDefaultCategoryFound'), { variant: 'error' });

        setImportLoading(true);

        await dispatch(
            createItemWithFile(calculation, {
                categoryId: defaultCategory.id,
                fileResource: file,
                modelStats
            })
        );
        navigate(nav.CALCULATIONS.sub.EDIT.path.replace(':id', calculation.id));
    };

    const handleBack = () => {
        navigate(nav.VIEWER_3D.path);
    };

    const handleDelete = () => {
        dialog.openDialog({
            severity: 'error',
            okText: t('lschen'),
            cancelText: t('nichtLschen'),
            component: <Typography>{t('areYouSureYouWantToDeleteThisFile')}</Typography>,
            title: t('deleteFile'),
            okCallback: () => {
                dispatch(deleteFile(file.nodeId));
                handleBack();
                dialog.closeDialog();
            }
        });
    };

    return (
        <>
            <PageHeader
                handleBack={handleBack}
                secondaryTitle={t('viewer3dExplanation')}
                secondaryTitlePosition="below"
                sx={{ zIndex: 55, flexShrink: 0, overflowX: 'auto', '.title-stack': { flexShrink: 0, mr: 1 } }}
            >
                {modelStats && (
                    <Stack direction="row" justifyContent="space-around" alignItems="center" flexGrow={1}>
                        <Stack direction="row" spacing={2}>
                            <Card>
                                <Stack p={1.4}>
                                    <Typography variant="caption" textAlign="center" pb={1} noWrap>
                                        {t('object')}
                                    </Typography>
                                    <Stack direction="row" spacing={2}>
                                        {modelStats?.surfaceArea && renderCalculatedValue(<LooksTwoTwoToneIcon />, t('surfaceArea'), getValue(modelStats.surfaceArea))}
                                        {/* {modelStats?.volume && renderCalculatedValue(<Looks3TwoToneIcon />, t('volume'), getValue(modelStats.volume))} */}
                                        {modelStats?.volume &&
                                            renderCalculatedValue(
                                                <ScaleTwoToneIcon />,
                                                <Button
                                                    color="secondary"
                                                    sx={{ display: 'flex', height: '15.6px', alignItems: 'center', color: 'inherit', fontWeight: 'inherit', fontSize: 'inherit', p: 0 }}
                                                    onClick={() => setMaterialDropdownOpen(true)}
                                                    ref={ref}
                                                >
                                                    {t('weight')} - {t(material)} <ArrowDropDownTwoToneIcon />
                                                </Button>,
                                                getValue({ value: modelStats.volume.value * materials[material], unit: 'kg', baseValue: null })
                                            )}
                                    </Stack>

                                    <Popover
                                        anchorEl={ref.current}
                                        onClose={() => setMaterialDropdownOpen(false)}
                                        open={isMaterialDropdownOpen}
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                    >
                                        <Stack p={3} pt={2} spacing={1}>
                                            <Typography variant="h3">{t('material')}</Typography>
                                            <ToggleButtonGroup value={material} onChange={(event, value) => setMaterial(value)} exclusive>
                                                {Object.keys(materials).map((material) => (
                                                    <ToggleButton key={material} value={material}>
                                                        {t(material)}
                                                    </ToggleButton>
                                                ))}
                                            </ToggleButtonGroup>
                                        </Stack>
                                    </Popover>
                                </Stack>
                            </Card>
                            <Card>
                                <Stack p={1.4}>
                                    <Typography variant="caption" textAlign="center" pb={1} noWrap>
                                        {t('boundingBox')}
                                    </Typography>
                                    <Stack direction="row" spacing={2}>
                                        {modelStats?.boundingBox?.surfaceArea && renderCalculatedValue(<LooksTwoTwoToneIcon />, t('surfaceArea'), getValue(modelStats.boundingBox.surfaceArea))}
                                        {modelStats?.boundingBox &&
                                            renderCalculatedValue(
                                                <AssessmentTwoToneIcon />,
                                                t('lengthWidthHeight'),
                                                `${getValue(modelStats.boundingBox.length, false)} × ` +
                                                    `${getValue(modelStats.boundingBox.width, false)} × ` +
                                                    `${getValue(modelStats.boundingBox.height, false)} ${modelStats.boundingBox.length.unit}`
                                            )}
                                    </Stack>
                                </Stack>
                            </Card>
                            <Card>
                                <Stack p={1.4}>
                                    <Typography variant="caption" textAlign="center" pb={1} noWrap>
                                        {t('convexHull')}
                                    </Typography>
                                    <Stack direction="row" spacing={2}>
                                        {modelStats?.convexHull?.surfaceArea && renderCalculatedValue(<LooksTwoTwoToneIcon />, t('surfaceArea'), getValue(modelStats.convexHull.surfaceArea))}
                                    </Stack>
                                </Stack>
                            </Card>
                            <Card sx={{ display: 'flex' }}>
                                <Stack p={1.4} alignItems="center">
                                    <Typography variant="caption" textAlign="center" pb={1} noWrap>
                                        {t('openings')}
                                    </Typography>
                                    <Stack direction="row" spacing={2} flexGrow={1} alignItems="center">
                                        {holeRecognitionResult ? (
                                            <>
                                                {renderCalculatedValue(<Looks3TwoToneIcon />, t('threads'), formatDimensionsInfos(holeRecognitionResult?.threadDimensions, true))}
                                                {renderCalculatedValue(<Looks3TwoToneIcon />, t('holes'), formatDimensionsInfos(holeRecognitionResult?.holeDimensions))}
                                            </>
                                        ) : (
                                            <CircularProgress size={25} />
                                        )}
                                    </Stack>
                                </Stack>
                            </Card>
                        </Stack>
                    </Stack>
                )}
                <Box display="flex" alignItems="center" pl={0.5} ml="auto" sx={{ display: 'block', whiteSpace: 'nowrap', gap: theme.spacing(2) }}>
                    <Button sx={{ mr: 1 }} startIcon={<DeleteTwoToneIcon />} variant="text" color="secondary" onClick={handleDelete}>
                        {t('delete')}
                    </Button>

                    <NewProCalc
                        navigateAfterCreation={false}
                        displayInitiator={(createCalc) => (
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setImportLoading(true);
                                    createCalc();
                                }}
                                disabled={!modelStats || isImportLoading || !holeRecognitionResult}
                                sx={{ ml: 1, whiteSpace: 'nowrap' }}
                            >
                                {isImportLoading ? <CircularProgress color="inherit" size={23} /> : t('createCalcFrom3dFile')}
                            </Button>
                        )}
                        onCalcCreate={handleCalcImport}
                    />
                </Box>
            </PageHeader>
            <Stack direction="row" mx={{ xs: -2, md: -4 }} mt={{ xs: -1.5, md: 0 }} mb={-4} flexGrow={1} overflow="hidden">
                {file && <SSA3DViewer url={file.url} fileName={file.name} language={i18n.language} onViewerLoaded={handleOnViewerLoader} />}

                {nearestFiles?.previous && (
                    <IconButton color="primary" onClick={() => handleArrowNavigation(nearestFiles.previous)} sx={{ position: 'absolute', left: 0, alignSelf: 'center' }}>
                        <ArrowBackIosNewTwoToneIcon sx={{ fontSize: 32 }} />
                    </IconButton>
                )}
                {nearestFiles?.next && (
                    <IconButton color="primary" onClick={() => handleArrowNavigation(nearestFiles.next)} sx={{ position: 'absolute', right: 0, alignSelf: 'center' }}>
                        <ArrowForwardIosTwoToneIcon sx={{ fontSize: 32 }} />
                    </IconButton>
                )}
            </Stack>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        files: state.fileManager.files
    };
};
export default connect(mapStateToProps)(Viewer3dDetail);
