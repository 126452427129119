//INTERFACE AND INITIAL STATE
import { GridSortModel } from '@mui/x-data-grid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CalculationFilterDTO, ShortCalculationResource } from 'src/backend/coreCalc';
import { AiMailConversationResource } from 'src/backend/market';

interface ICalculationsState {
    calculations: Array<ShortCalculationResource>;
    mailConversations: { [uniqueId: string]: AiMailConversationResource };
    isLoading: boolean;
    paging: {
        page: number;
        pageSize: number;
        total: number;
    };
    filter: CalculationFilterDTO;
    sort: GridSortModel;
    isAutoReloadCalculations: boolean;
}

const initFilters: CalculationFilterDTO = {
    status: null,
    searchString: ''
};

const initialState: ICalculationsState = {
    calculations: [],
    mailConversations: {},
    isLoading: false,
    paging: { page: 0, pageSize: 10, total: 0 },
    filter: initFilters,
    sort: [{ field: 'createdAt', sort: 'desc' }],
    isAutoReloadCalculations: true
};

const reducers = {
    setProCalcs: (state, action) => {
        state.calculations = action.payload;
    },
    setMailConversations: (state, action) => {
        state.mailConversations = action.payload;
    },
    setLoading: (state, action) => {
        state.isLoading = action.payload;
    },
    setAutoReloadCalculations: (state, action) => {
        state.isAutoReloadCalculations = action.payload;
    },
    setFilter: (state: ICalculationsState, action: PayloadAction<CalculationFilterDTO>) => {
        state.filter = action.payload;
    },
    setPaging: (state: ICalculationsState, action: PayloadAction<{ page?: number; pageSize?: number; total?: number }>) => {
        state.paging = { ...state.paging, ...action.payload };
    },
    setSortModel: (state: ICalculationsState, action: PayloadAction<GridSortModel>) => {
        state.sort = action.payload;
    }
};

//EXPORTS
export const slice = createSlice({
    name: 'proCalcs',
    initialState,
    reducers: reducers
});
export const reducer = slice.reducer;
export const { setProCalcs, setFilter, setPaging, setSortModel, setMailConversations, setLoading, setAutoReloadCalculations } = slice.actions;
