import { Box, Button, Card, Stack, styled, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { ShortCategoryResource } from 'src/backend/coreCalc';
import { getText, renderImage } from 'src/utils/CalcHelpers';

const ItemListBox = styled(Box)(
    ({ theme }) => `
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
        gap: ${theme.spacing(1.5)};
    `
);
const Item = styled(Card)<{ selected?: boolean }>(
    ({ theme, selected }) => `
        display: flex;
        justify-content: center;
        height: 5rem;

        .MuiButtonBase-root {
            color: unset;
            border-radius: ${theme.shape.borderRadius}px;
            height: 5rem;
            padding: ${theme.spacing(0.8)};
            padding-right: ${theme.spacing(2)};
            flex-grow: 1;
            justify-content: flex-start;
        }

        .MuiTypography-root {
            font-weight: normal;
        }

        img {
            height: 100%;
            margin-left: ${theme.spacing(1)};
            margin-right: ${theme.spacing(2)};
            object-fit: contain;
        }
    `
);

interface Props {
    categories: Array<ShortCategoryResource>;
    selectedCategory: ShortCategoryResource;
    searchText?: string;
    isFragment?: boolean;
    handleSubCategorySelection: (subCategory: ShortCategoryResource) => void;
}

const SubCategoryArea: React.FC<Props> = ({ categories, selectedCategory, handleSubCategorySelection, searchText, isFragment }) => {
    const filteredSubCategories = useMemo(() => {
        const subCategories: Array<{ category: ShortCategoryResource; subCategory: ShortCategoryResource }> = [];
        if (selectedCategory) {
            (selectedCategory.subCategories || []).forEach((subCategory) => {
                subCategories.push({ category: selectedCategory, subCategory });
            });
        } else {
            (categories || []).forEach((category) => {
                (category.subCategories || []).forEach((subCategory) => {
                    subCategories.push({ category, subCategory });
                });
            });
        }

        return subCategories.filter(({ subCategory }) => (getText(subCategory) || '').toLowerCase().includes(searchText.trim().toLowerCase()));
    }, [categories, selectedCategory, searchText]);

    return (
        <Stack id={'itemAreaAllParts'} mt={1} minHeight={isFragment ? '5rem' : '25rem'} maxHeight="25rem" width={isFragment ? '65rem' : 'auto'} maxWidth="100%">
            <ItemListBox p={2} pt={1} overflow="auto" maxHeight="100%">
                {filteredSubCategories.map(({ category, subCategory }) => (
                    <Item key={'item' + subCategory.id}>
                        <Button onClick={() => handleSubCategorySelection(subCategory)} variant="text" color="secondary">
                            {renderImage(subCategory.translationKey)}
                            <Typography variant="h5" textOverflow="ellipsis" overflow="hidden" textAlign="left">
                                {getText(subCategory)}
                            </Typography>
                        </Button>
                    </Item>
                ))}
            </ItemListBox>
        </Stack>
    );
};

export default SubCategoryArea;
