/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SummaryResource } from '../models/SummaryResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class SummaryRestControllerService {

    /**
     * @param internalCalculationUniqueId
     * @returns SummaryResource OK
     * @throws ApiError
     */
    public static getInternalCalculationSummary(
        internalCalculationUniqueId: string,
    ): CancelablePromise<SummaryResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/summary/internalCalculationsByUniqueId/${internalCalculationUniqueId}`,
        });
    }

    /**
     * @param internalCalculationId
     * @returns SummaryResource OK
     * @throws ApiError
     */
    public static getInternalCalculationSummary1(
        internalCalculationId: number,
    ): CancelablePromise<SummaryResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/summary/internalCalculations/${internalCalculationId}`,
        });
    }

}