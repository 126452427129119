//INTERFACE AND INITIAL STATE
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { BlueprintUpdateTypes } from '../shared/calcEditor.common';
import { BlueprintResource, ComputationResource, CategoryResource, MessageResource, ValueResource, VariableResource } from 'src/backend/coreCalc';

export enum CalcEditorSavingStatus {
    LOADING = 'calcEditorSaving',
    LOADED = 'calcEditorSaved',
    ERROR = 'calcEditorSaveError'
}
export type CalcEditorError = {
    type: 'badRequest' | 'notAllowed' | 'locked' | 'unknown';
    data?: string;
};
export type CalcEditorUpdateLoading = { [updateType in BlueprintUpdateTypes]?: boolean };
export type CalcEditorUpdateError = { [updateType in BlueprintUpdateTypes]?: any };

interface ICalcEditorState {
    blueprint: BlueprintResource;
    history: Array<BlueprintResource>;
    savingStatus: CalcEditorSavingStatus;
    isLoading: boolean;
    isUpdateLoading: CalcEditorUpdateLoading;
    error: CalcEditorError;
    updateErrors: CalcEditorUpdateError;
    disableCategoryValidation: boolean;
}

const initialState: ICalcEditorState = {
    blueprint: {},
    history: [],
    savingStatus: CalcEditorSavingStatus.LOADED,
    isLoading: true,
    isUpdateLoading: {},
    error: null,
    updateErrors: {},
    disableCategoryValidation: false
};

export const slice = createSlice({
    name: 'calcEditor',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setBlueprint: (state, action: PayloadAction<BlueprintResource>) => {
            state.blueprint = action.payload;
        },
        resetBlueprint: (state) => {
            state.blueprint = {};
            state.isLoading = true;
            state.error = null;
        },
        setBlueprintValue: (state, action: PayloadAction<{ path: string; value: any; blueprintId?: number }>) => {
            if (!state.blueprint || isWrongBlueprintId(state, action)) return;
            set(state.blueprint, action.payload.path, action.payload.value);
        },
        setSavingStatus: (state, action: PayloadAction<CalcEditorSavingStatus>) => {
            state.savingStatus = action.payload;
        },
        setUpdateLoading: (state, action: PayloadAction<{ updateType: BlueprintUpdateTypes; isLoading: boolean }>) => {
            state.isUpdateLoading[action.payload.updateType] = action.payload.isLoading;
        },
        clearUpdateLoading: (state) => {
            Object.keys(state.isUpdateLoading).forEach((updateType) => {
                state.isUpdateLoading[updateType] = false;
            });
        },
        setDisablePartValidation: (state, action: PayloadAction<boolean>) => {
            state.disableCategoryValidation = action.payload;
        },
        addMasterVariable: (state, action: PayloadAction<VariableResource>) => {
            if (!state.blueprint || isWrongBlueprintId(state, action)) return;
            state.blueprint.masterVariables.push(action.payload);
        },
        deleteMasterVariable: (state, action: PayloadAction<number>) => {
            if (!state.blueprint || isWrongBlueprintId(state, action)) return;
            const variables = state.blueprint.masterVariables;
            state.blueprint.masterVariables = variables.filter((variable) => variable.id !== action.payload);
        },
        setMasterVariable: (state, action: PayloadAction<VariableResource>) => {
            if (!state.blueprint || isWrongBlueprintId(state, action)) return;
            const variableIndex = state.blueprint.masterVariables.findIndex((variable) => variable.id === action.payload.id);
            if (variableIndex === -1) return;
            state.blueprint.masterVariables[variableIndex] = action.payload;
        },
        setMasterVariableValue: (state, action: PayloadAction<{ variableId: number; value: ValueResource }>) => {
            if (!state.blueprint || isWrongBlueprintId(state, action)) return;
            const variableIndex = state.blueprint.masterVariables.findIndex((variable) => variable.id === action.payload.variableId);
            if (variableIndex === -1) return;
            state.blueprint.masterVariables[variableIndex].value = action.payload.value;
        },
        setCategory: (state, action: PayloadAction<CategoryResource>) => {
            if (!state.blueprint?.categories) return;
            const categoryIndex = state.blueprint.categories.findIndex((category) => category.id === action.payload.id);
            if (categoryIndex === -1) return;
            state.blueprint.categories[categoryIndex] = { ...state.blueprint.categories[categoryIndex], ...action.payload };
        },
        setCategoryName: (state, action: PayloadAction<{ categoryId: number; name: string }>) => {
            if (!state.blueprint?.categories) return;
            const categoryIndex = state.blueprint.categories.findIndex((category) => category.id === action.payload.categoryId);
            if (categoryIndex === -1) return;
            state.blueprint.categories[categoryIndex].name = action.payload.name;
        },
        addCategory: (state, action: PayloadAction<CategoryResource>) => {
            if (!state.blueprint?.categories) return;
            state.blueprint.categories.push(action.payload);
        },
        addTmpCategory: (state) => {
            if (!state.blueprint?.categories) return;
            state.blueprint.categories.push({ id: -1, name: 'TMP_LOADER' });
        },
        deleteCategory: (state, action: PayloadAction<number>) => {
            if (!state.blueprint?.categories) return;
            state.blueprint.categories = state.blueprint.categories.filter((category) => category.id !== action.payload);
        },
        addCategoryComputation: (state, action: PayloadAction<{ categoryId: number; computation: ComputationResource }>) => {
            if (!state.blueprint?.categories) return;
            const categoryIndex = state.blueprint.categories.findIndex((category) => category.id === action.payload.categoryId);
            if (categoryIndex === -1) return;
            state.blueprint.categories[categoryIndex].supportedComputations.push(action.payload.computation);
        },
        deleteCategoryComputation: (state, action: PayloadAction<{ categoryId: number; computationId: number }>) => {
            if (!state.blueprint?.categories) return;
            const categoryIndex = state.blueprint.categories.findIndex((category) => category.id === action.payload.categoryId);
            if (categoryIndex === -1) return;
            const supportedComputations = state.blueprint.categories[categoryIndex].supportedComputations;
            state.blueprint.categories[categoryIndex].supportedComputations = supportedComputations.filter((computation) => computation.id !== action.payload.computationId);
        },
        setCategoryComputation: (state, action: PayloadAction<{ categoryId: number; computation: ComputationResource }>) => {
            if (!state.blueprint?.categories) return;
            const categoryIndex = state.blueprint.categories.findIndex((category) => category.id === action.payload.categoryId);
            if (categoryIndex === -1) return;
            const basisIndex = state.blueprint.categories[categoryIndex].supportedComputations.findIndex((basis) => basis.id === action.payload.computation.id);
            if (basisIndex === -1) return;
            state.blueprint.categories[categoryIndex].supportedComputations[basisIndex] = action.payload.computation;
        },
        setCategoryComputationSelection: (state, action: PayloadAction<{ categoryId: number; computationId: number }>) => {
            if (!state.blueprint?.categories) return;
            const categoryIndex = state.blueprint.categories.findIndex((category) => category.id === action.payload.categoryId);
            if (categoryIndex === -1) return;
            state.blueprint.categories[categoryIndex].selectedComputationId = action.payload.computationId;
        },
        setCategoryVariable: (state, action: PayloadAction<{ categoryId: number; variable: VariableResource }>) => {
            if (!state.blueprint?.categories) return;
            const categoryIndex = state.blueprint.categories.findIndex((category) => category.id === action.payload.categoryId);
            if (categoryIndex === -1) return;
            const variableIndex = state.blueprint.categories[categoryIndex].variables.findIndex((variable) => variable.id === action.payload.variable.id);
            if (variableIndex === -1) return;
            state.blueprint.categories[categoryIndex].variables[variableIndex] = action.payload.variable;
        },
        addCategoryVariable: (state, action: PayloadAction<{ categoryId: number; variable: VariableResource }>) => {
            if (!state.blueprint?.categories) return;
            const categoryIndex = state.blueprint.categories.findIndex((category) => category.id === action.payload.categoryId);
            if (categoryIndex === -1) return;
            state.blueprint.categories[categoryIndex].variables.push(action.payload.variable);
        },
        deleteCategoryVariable: (state, action: PayloadAction<{ categoryId: number; variableId: number }>) => {
            if (!state.blueprint?.categories) return;
            const categoryIndex = state.blueprint.categories.findIndex((category) => category.id === action.payload.categoryId);
            if (categoryIndex === -1) return;
            const variables = state.blueprint.categories[categoryIndex].variables;
            state.blueprint.categories[categoryIndex].variables = variables.filter((variable) => variable.id !== action.payload.variableId);
        },
        setCategoryVariableValue: (state, action: PayloadAction<{ categoryId: number; variableId: number; value: ValueResource }>) => {
            if (!state.blueprint?.categories) return;
            const categoryIndex = state.blueprint.categories.findIndex((category) => category.id === action.payload.categoryId);
            if (categoryIndex === -1) return;
            const variableIndex = state.blueprint.categories[categoryIndex].variables.findIndex((variable) => variable.id === action.payload.variableId);
            if (variableIndex === -1) return;
            state.blueprint.categories[categoryIndex].variables[variableIndex].value = action.payload.value;
        },
        setCategoryMessage: (state, action: PayloadAction<{ categoryId: number; message: MessageResource }>) => {
            if (!state.blueprint?.categories) return;
            const categoryIndex = state.blueprint.categories.findIndex((category) => category.id === action.payload.categoryId);
            if (categoryIndex === -1) return;
            const messageIndex = state.blueprint.categories[categoryIndex].messages.findIndex((message) => message.id === action.payload.message.id);
            if (messageIndex === -1) return;
            state.blueprint.categories[categoryIndex].messages[messageIndex] = action.payload.message;
        },
        addCategoryMessage: (state, action: PayloadAction<{ categoryId: number; message: MessageResource }>) => {
            if (!state.blueprint?.categories) return;
            const categoryIndex = state.blueprint.categories.findIndex((category) => category.id === action.payload.categoryId);
            if (categoryIndex === -1) return;
            state.blueprint.categories[categoryIndex].messages.push(action.payload.message);
        },
        deleteCategoryMessage: (state, action: PayloadAction<{ categoryId: number; messageId: number }>) => {
            if (!state.blueprint?.categories) return;
            const categoryIndex = state.blueprint.categories.findIndex((category) => category.id === action.payload.categoryId);
            if (categoryIndex === -1) return;
            const messages = state.blueprint.categories[categoryIndex].messages;
            state.blueprint.categories[categoryIndex].messages = messages.filter((message) => message.id !== action.payload.messageId);
        },
        setSubCategory: (state, action: PayloadAction<{ categoryId: number; subCategory: CategoryResource }>) => {
            if (!state.blueprint?.categories) return;
            const categoryIndex = state.blueprint.categories.findIndex((category) => category.id === action.payload.categoryId);
            if (categoryIndex === -1) return;
            const subCategoryIndex = state.blueprint.categories[categoryIndex].subCategories.findIndex((category) => category.id === action.payload.subCategory.id);
            if (subCategoryIndex === -1) return;
            state.blueprint.categories[categoryIndex].subCategories[subCategoryIndex] = action.payload.subCategory;
        },
        addSubCategory: (state, action: PayloadAction<{ categoryId: number; subCategory: CategoryResource }>) => {
            if (!state.blueprint?.categories) return;
            const categoryIndex = state.blueprint.categories.findIndex((category) => category.id === action.payload.categoryId);
            if (categoryIndex === -1) return;
            if (!state.blueprint.categories[categoryIndex].subCategories) state.blueprint.categories[categoryIndex].subCategories = [];
            state.blueprint.categories[categoryIndex].subCategories.push(action.payload.subCategory);
        },
        deleteSubCategory: (state, action: PayloadAction<{ categoryId: number; subCategoryId: number }>) => {
            if (!state.blueprint?.categories) return;
            const categoryIndex = state.blueprint.categories.findIndex((category) => category.id === action.payload.categoryId);
            if (categoryIndex === -1) return;
            const subCategories = state.blueprint.categories[categoryIndex].subCategories;
            state.blueprint.categories[categoryIndex].subCategories = subCategories.filter((category) => category.id !== action.payload.subCategoryId);
        },
        handleError: (state, action: PayloadAction<any>) => {
            const error = action.payload;
            console.error('handleError', error);

            if (error.status === 423) {
                const userId = (error.body?.message || '').split(':')[1];
                state.error = { type: 'locked', data: userId };
            } else if (error.status === 400) {
                state.error = { type: 'badRequest' };
            } else if (error.status === 403) {
                state.error = { type: 'notAllowed' };
            } else {
                state.error = { type: 'unknown', data: error?.status };
            }
        },
        resetError: (state) => {
            state.error = null;
        },
        saveBlueprintRecord: (state) => {
            // Record the previous state to provide rollback on failure
            state.history.push(state.blueprint);
        },
        handleUpdateSuccess: (state, action: PayloadAction<BlueprintUpdateTypes>) => {
            state.savingStatus = CalcEditorSavingStatus.LOADED;
            state.isUpdateLoading[action.payload] = false;
            state.error = null;
        },
        handleUpdateFailure: (state, action: PayloadAction<{ updateType: BlueprintUpdateTypes; error: any }>) => {
            state.savingStatus = CalcEditorSavingStatus.ERROR;
            state.isUpdateLoading[action.payload.updateType] = false;
            // Restore previous state and set error
            state.blueprint = state.history.pop() ?? state.blueprint;
            state.isUpdateLoading[action.payload.updateType] = action.payload.error;
            slice.caseReducers.handleError(state, { payload: action.payload.error, type: 'handleError' });
        }
    }
});
export const reducer = slice.reducer;

const isWrongBlueprintId = (state, action: PayloadAction<any>) => {
    if (!action.payload.blueprintId) return false;
    return state.blueprint?.id !== action.payload.blueprintId;
};

export const {
    addCategory,
    addCategoryComputation,
    addCategoryMessage,
    addCategoryVariable,
    addMasterVariable,
    addSubCategory,
    addTmpCategory,
    clearUpdateLoading,
    deleteCategory,
    deleteCategoryComputation,
    deleteCategoryMessage,
    deleteCategoryVariable,
    deleteMasterVariable,
    deleteSubCategory,
    handleError,
    handleUpdateFailure,
    handleUpdateSuccess,
    resetBlueprint,
    resetError,
    saveBlueprintRecord,
    setBlueprint,
    setBlueprintValue,
    setCategory,
    setCategoryComputation,
    setCategoryComputationSelection,
    setCategoryMessage,
    setCategoryName,
    setCategoryVariable,
    setCategoryVariableValue,
    setDisablePartValidation,
    setLoading,
    setMasterVariable,
    setMasterVariableValue,
    setSavingStatus,
    setSubCategory,
    setUpdateLoading
} = slice.actions;
