/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BooleanValueResource } from './BooleanValueResource';
import type { ListValueResource } from './ListValueResource';
import type { MetadataResource } from './MetadataResource';
import type { NumberValueResource } from './NumberValueResource';
import type { StringValueResource } from './StringValueResource';
import type { VariableResource } from './VariableResource';

export type BooleanInputVariableResource = (VariableResource & {
    name?: string;
    description?: string;
    metadata?: Array<MetadataResource>;
    sequence?: number;
    section?: BooleanInputVariableResource.section;
    hasUiContract?: boolean;
    alwaysActive?: boolean;
    visibility?: BooleanInputVariableResource.visibility;
    defaultValue?: (BooleanValueResource | ListValueResource | NumberValueResource | StringValueResource);
});

export namespace BooleanInputVariableResource {

    export enum section {
        GEOMETRY_SECTION = 'GEOMETRY_SECTION',
        COMPONENT_SECTION = 'COMPONENT_SECTION',
        SURFACE_SECTION = 'SURFACE_SECTION',
        CALCULATION_SECTION = 'CALCULATION_SECTION',
        ADDITIONAL_WORK_SECTION = 'ADDITIONAL_WORK_SECTION',
        QUALITY_SECTION = 'QUALITY_SECTION',
    }

    export enum visibility {
        HIDDEN = 'HIDDEN',
        DISABLED = 'DISABLED',
        VISIBLE = 'VISIBLE',
    }


}
