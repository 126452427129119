import { Stack, Button, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PriceChangeTwoToneIcon from '@mui/icons-material/PriceChangeTwoTone';
import LocalOfferTwoToneIcon from '@mui/icons-material/LocalOfferTwoTone';
import RuleTwoToneIcon from '@mui/icons-material/RuleTwoTone';
import CalcBuilderDialog from '../CalcBuilder/CalcBuilderDialog';
import { useMemo, useState } from 'react';
import InputFieldsDialog from '../Categories/InputFields/InputFieldsDialog';
import SurchargesDialog from '../Surcharges/SurchargesDialog';
import { useDispatch } from 'react-redux';
import { BlueprintUpdateTypes, updateBlueprint } from 'src/redux/shared/calcEditor.common';
import { BlueprintResource, InputVariableResource, TermResource, TermVariableResource, VariableResource } from 'src/backend/coreCalc';
import { getTerm, getVariable, isInputVariable } from 'src/utils/CalcHelpers';

const StyledStack = styled(Stack)(
    ({ theme }) => `
        & > .MuiButtonBase-root {
            flex-direction: column;
        }
        & > .MuiButtonBase-root > .MuiButton-startIcon {
            margin: 4px 0;
            margin-bottom: 2px;
        }
        & > .MuiButtonBase-root > .MuiButton-startIcon > .MuiSvgIcon-root {
            font-size: 24px;
        }
        
    `
);

interface Props {
    blueprint: BlueprintResource;
}

const MasterCalcButtons: React.FC<Props> = ({ blueprint }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isCalcBuilderOpen, setCalcBuilderOpen] = useState<boolean>(false);
    const [isSurchargesDialogOpen, setSurchargesDialogOpen] = useState<boolean>(false);
    const [isInputFieldsDialogOpen, setInputFieldsDialogOpen] = useState<boolean>(false);

    const computationResultVariable = useMemo(
        () => getVariable(blueprint?.masterComputation?.resultVariableInternalIdentifier, blueprint?.masterVariables),
        [blueprint?.masterComputation, blueprint?.masterVariables]
    );
    const masterTerm = (computationResultVariable as TermVariableResource)?.term;

    const inputVariables = useMemo(() => {
        const section = 'allMasterInputVariables';
        const sections: { [section: string]: Array<InputVariableResource> } = {};

        (blueprint.masterVariables || []).forEach((variable) => {
            if (!isInputVariable(variable)) return;
            if (!sections[section]) sections[section] = [];
            sections[section].push(variable);
        });

        return sections;
    }, [blueprint.masterVariables]);

    const createVariable = (variable: VariableResource) => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.CREATE_MASTER_VARIABLE, variable));
    };
    const saveVariable = (variable: VariableResource) => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.MASTER_VARIABLE, variable));
    };
    const removeVariable = (variableId: number) => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.DELETE_MASTER_VARIABLE, variableId));
    };

    const saveComputation = (name: string, term: TermResource) => {
        setCalcBuilderOpen(false);
        saveVariable({ ...computationResultVariable, term } as TermVariableResource);
    };

    return (
        <StyledStack gap={2} direction="row" alignItems="center">
            <Button sx={{ mx: -1 }} color="secondary" startIcon={<PriceChangeTwoToneIcon />} onClick={() => setCalcBuilderOpen(true)}>
                Kalkulation
            </Button>
            {masterTerm && blueprint?.masterVariables && (
                <CalcBuilderDialog
                    hideName
                    term={masterTerm}
                    variables={blueprint.masterVariables}
                    isOpen={isCalcBuilderOpen}
                    setOpen={setCalcBuilderOpen}
                    onSave={saveComputation}
                    createVariable={createVariable}
                    saveVariable={saveVariable}
                    removeVariable={removeVariable}
                />
            )}

            <Button sx={{ mx: -1 }} color="secondary" startIcon={<LocalOfferTwoToneIcon />} onClick={() => setSurchargesDialogOpen(true)}>
                Aufschläge
            </Button>
            <SurchargesDialog
                variables={blueprint?.masterVariables}
                isOpen={isSurchargesDialogOpen}
                setOpen={setSurchargesDialogOpen}
                createVariable={createVariable}
                saveVariable={saveVariable}
                removeVariable={removeVariable}
            />

            <Button sx={{ mx: -1 }} color="secondary" startIcon={<RuleTwoToneIcon />} onClick={() => setInputFieldsDialogOpen(true)}>
                Eingabefelder
            </Button>
            <InputFieldsDialog
                variables={blueprint?.masterVariables}
                inputVariablesGroupedBySection={inputVariables}
                isOpen={isInputFieldsDialogOpen}
                setOpen={setInputFieldsDialogOpen}
                createVariable={createVariable}
                saveVariable={saveVariable}
                removeVariable={removeVariable}
            />
        </StyledStack>
    );
};

export default MasterCalcButtons;
