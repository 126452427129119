import {
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    IconButton,
    MenuItem,
    Popover,
    Select,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import useDialog from 'src/hooks/useDialog';
import PageHeader from 'src/components/display/PageHeader';
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import TableRowsTwoToneIcon from '@mui/icons-material/TableRowsTwoTone';
import { SearchTwoTone } from '@mui/icons-material';
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';
import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone';
import usePrevious from 'src/utils/usePrevious';
import { debounce, isEqual } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { RootState } from 'src/redux/store';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
import { CalculationFilterDTO, CalculationResource } from 'src/backend/coreCalc';
import { updateFilter } from 'src/redux/thunks/proCalcs.thunk';
import NewProCalc from 'src/components/pro-calc/NewProCalc';

interface Props {
    mode?: string;
    setMode?: Function;
    isLoading: boolean;
    filter: CalculationFilterDTO;
    internalCalculationActivated: boolean;
}

const PROCalcOverviewHeader: React.FC<Props> = ({ mode, setMode, isLoading, filter, internalCalculationActivated }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dialog = useDialog();
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();
    const [localFilter, setLocalFilter] = useState<CalculationFilterDTO>(filter);
    const prevFilter = usePrevious(localFilter);
    const ref = useRef<any>(null);
    const [isFilterOpen, setFilterOpen] = useState<boolean>(false);
    const [isNewProCalcDialogOpen, setNewProCalcDialogOpen] = useState<boolean>(false);
    const [filterAmount, setFilterAmount] = useState<number>(0);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const updateFilterWrapper = (filter: CalculationFilterDTO) => {
        dispatch(updateFilter(filter));
    };
    const debouncedFilterCallback = useCallback(debounce(updateFilterWrapper, 500), []);

    useEffect(() => {
        if (prevFilter == null) return;
        debouncedFilterCallback(localFilter);
    }, [localFilter]);

    useEffect(() => {
        if (isEqual(localFilter, filter)) return;
        setLocalFilter(filter);
    }, [filter]);

    useEffect(() => {
        if (isSmallScreen) setMode('gridView');
    }, [isSmallScreen]);

    const handleViewOrientation = (event: any, newValue: string | null) => {
        if (!newValue) return;
        setMode(newValue);
    };

    const updateFilterProperty = (event, filterName) => {
        setLocalFilter({ ...localFilter, [filterName]: event.target.value });
    };

    const clearSearch = () => {
        setLocalFilter({ ...localFilter, searchString: '' });
    };

    const resetFilter = () => {
        setLocalFilter({ searchString: '', status: null });
    };

    useEffect(() => {
        let amount = 0;
        if (!localFilter) return;
        if (isSmallScreen && !!localFilter.searchString) amount++;
        if (!!filter.status) amount++;
        // if (!!localFilter.distanceFilter?.from || !!localFilter.distanceFilter?.to) amount++;
        setFilterAmount(amount);
    }, [localFilter, isSmallScreen]);

    return (
        <PageHeader secondaryTitle={t('internalCalcExplanation')} secondaryTitlePosition="below" sx={{ '.title-stack': { mr: 0 } }}>
            <Divider orientation="vertical" flexItem sx={{ my: -4, mx: 4 }} />

            {!isSmallScreen && (
                <TextField
                    value={localFilter.searchString}
                    inputProps={{ sx: { py: 1.54 } }}
                    InputProps={{
                        startAdornment: isLoading ? <CircularProgress size={24} sx={{ display: 'flex', mr: 1 }} /> : <SearchTwoTone sx={{ opacity: 0.6, mr: 1 }} />,
                        endAdornment: localFilter.searchString && (
                            <IconButton sx={{ opacity: 0.6, ml: 1 }} onClick={clearSearch}>
                                <ClearTwoToneIcon />
                            </IconButton>
                        )
                    }}
                    placeholder={t('interneKalkulationenDurchsuchen')}
                    sx={{ width: '20rem', '.MuiInputBase-root': { pr: '4px' } }}
                    onChange={(event) => updateFilterProperty(event, 'searchString')}
                />
            )}

            <Stack sx={{ mx: 2, position: 'relative' }}>
                <Button
                    ref={ref}
                    variant="outlined"
                    color="secondary"
                    onClick={() => setFilterOpen(true)}
                    endIcon={<ArrowDropDownTwoToneIcon sx={{ height: '1.6rem', width: '1.6rem' }} />}
                    sx={{ py: { xs: 0.15, md: 1.15 }, borderColor: 'rgba(0, 0, 0, 0.23)' }}
                >
                    {t(isSmallScreen ? 'filterAndSearch' : 'filter')}
                </Button>

                <Popover
                    disableScrollLock
                    anchorEl={ref.current}
                    onClose={() => setFilterOpen(false)}
                    open={isFilterOpen}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    PaperProps={{
                        sx: { display: 'flex' }
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            setFilterOpen(false);
                        }}
                        sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], zIndex: 5000 }}
                    >
                        <CloseTwoToneIcon />
                    </IconButton>
                    <Box sx={{ width: 450 }} p={3}>
                        {isSmallScreen && (
                            <>
                                <Typography variant="h4" gutterBottom textAlign="center" mb={3}>
                                    {t('search')}
                                </Typography>

                                <TextField
                                    value={localFilter.searchString}
                                    inputProps={{ sx: { py: 1.54 } }}
                                    InputProps={{
                                        startAdornment: isLoading ? <CircularProgress size={24} sx={{ display: 'flex', mr: 1 }} /> : <SearchTwoTone sx={{ opacity: 0.6, mr: 1 }} />,
                                        endAdornment: localFilter.searchString && (
                                            <IconButton sx={{ opacity: 0.6, ml: 1 }} onClick={clearSearch}>
                                                <ClearTwoToneIcon />
                                            </IconButton>
                                        )
                                    }}
                                    placeholder={t('interneKalkulationenDurchsuchen')}
                                    sx={{ mb: 5, '.MuiInputBase-root': { pr: '4px' } }}
                                    fullWidth
                                    onChange={(event) => updateFilterProperty(event, 'searchString')}
                                />
                            </>
                        )}

                        <Typography variant="h4" gutterBottom textAlign="center" mb={3}>
                            {t('filter')}
                        </Typography>

                        <FormControl sx={{ flex: 1, mb: 2 }} fullWidth>
                            <Typography variant="caption">{t('status')}</Typography>
                            <Select value={localFilter.status || t('alle')} fullWidth onChange={(event) => updateFilterProperty(event, 'status')} renderValue={(selected) => t(selected)}>
                                <MenuItem value={null}>{t('alle')}</MenuItem>
                                {Object.values(CalculationResource.status).map((status) => (
                                    <MenuItem key={status} value={status}>
                                        {t(status)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* <Typography variant="caption">{t('distance')} (km)</Typography>
                        <Slider
                            min={0}
                            max={1000}
                            value={[cFilter.distanceFilter?.from / 1000 || 0, cFilter.distanceFilter?.to / 1000 || 0]}
                            onChange={(event, value) => {
                                setCFilter({
                                    ...cFilter,
                                    distanceFilter: {
                                        from: value[0] * 1000,
                                        to: value[1] * 1000
                                    }
                                });
                            }}
                            valueLabelDisplay="auto"
                        /> */}

                        <Box display="flex" justifyContent="center" mt={2}>
                            <Button variant="contained" startIcon={<RefreshTwoToneIcon />} disabled={filterAmount < 1} onClick={resetFilter}>
                                {t('resetFilter')}
                            </Button>
                        </Box>
                    </Box>
                </Popover>

                {filterAmount > 0 && (
                    <Typography variant="subtitle2" sx={{ position: 'absolute', bottom: 0, transform: 'translateY(100%)', pt: 0.5, fontSize: '0.8rem', display: 'flex', alignItems: 'center' }}>
                        <span style={{ color: theme.colors.primary.main, marginRight: 4, fontSize: '0.55rem' }}>●</span>
                        {filterAmount} {t('activeFilter')}
                    </Typography>
                )}
            </Stack>

            {!isSmallScreen && (
                <ToggleButtonGroup color="primary" value={mode} exclusive onChange={handleViewOrientation} sx={{ mr: 'auto' }}>
                    <ToggleButton disableRipple value="gridView">
                        <GridViewTwoToneIcon />
                    </ToggleButton>
                    <ToggleButton disableRipple value="tableView">
                        <TableRowsTwoToneIcon />
                    </ToggleButton>
                </ToggleButtonGroup>
            )}

            <NewProCalc
                displayInitiator={(createCalc) => (
                    <Button
                        size={isSmallScreen ? 'small' : 'large'}
                        variant="contained"
                        onClick={() => createCalc()}
                        disabled={!internalCalculationActivated || isLoading}
                        sx={{ ml: 1, whiteSpace: 'nowrap' }}
                    >
                        {isLoading ? <CircularProgress color="inherit" size={20} /> : t('kalkulationErstellen')}
                    </Button>
                )}
            />
        </PageHeader>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        isLoading: state.proCalcs.isLoading,
        filter: state.proCalcs.filter
    };
};
export default connect(mapStateToProps)(PROCalcOverviewHeader);
