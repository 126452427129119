import { Stack, Typography, useTheme } from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { nav } from 'src/statics/navigations';
import CalcOverviewGrid from './CalcOverviewGrid';
import CalcOverviewTable from './CalcOverviewTable';
import { loadProCalcs, updateSortModel } from 'src/redux/thunks/proCalcs.thunk';
import { AiMailConversationResource } from 'src/backend/market';
import useDialog from 'src/hooks/useDialog';
import { RootState } from 'src/redux/store';
import { ShortCalculationResource } from 'src/backend/coreCalc';
import { deleteProCalc, duplicateProCalc } from 'src/redux/thunks/proCalcs.thunk';

interface Props {
    paging: { page: number; pageSize: number; total: number; rowCount: number };
    calculations: Array<ShortCalculationResource>;
    mailConversations: { [uniqueId: string]: AiMailConversationResource };
    isLoading: boolean;
    sort: GridSortModel;
    mode: String | null;
}

const CalcOverview: React.FC<Props> = ({ calculations, mailConversations, paging, isLoading, sort, mode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dialog = useDialog();
    const { t } = useTranslation();
    const theme = useTheme();

    useEffect(() => {
        dispatch(loadProCalcs());
        const intervalId = setInterval(() => dispatch(loadProCalcs()), 60000); // every minute

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const handleEditClicked = (row: ShortCalculationResource) => {
        navigate(nav.CALCULATIONS.sub.EDIT.path.replace(':id', row.id));
    };

    const handleViewClicked = (row: ShortCalculationResource) => {
        navigate(nav.CALCULATIONS.sub.VIEW.path.replace(':id', row.id));
    };

    const handleDuplicateClicked = (row: ShortCalculationResource) => {
        dialog.openDialog({
            severity: 'info',
            okText: t('duplizieren'),
            cancelText: t('nichtDuplizieren'),
            component: <Typography>{t('bistDuSicherDasDuDieseKalkulationDuplizierenMchtes')}</Typography>,
            title: t('kalulationDuplizieren'),
            okCallback: async () => {
                dispatch(duplicateProCalc(row.id));
                dialog.closeDialog();
            }
        });
    };

    const handleDeleteClicked = (calculationId: string) => {
        dialog.openDialog({
            severity: 'error',
            okText: t('lschen'),
            cancelText: t('nichtLschen'),
            component: <Typography>{t('bistDuSicherDassDuDieseKalkulationLschenMchtest')}</Typography>,
            title: t('kalulationLschen'),
            okCallback: () => {
                dispatch(deleteProCalc(calculationId));
                dialog.closeDialog();
            }
        });
    };

    const handleSortModelChange = (sortModel: GridSortModel) => {
        dispatch(updateSortModel(sortModel));
    };

    return (
        <>
            {mode === 'gridView' ? (
                <CalcOverviewGrid
                    calculations={calculations}
                    paging={paging}
                    isLoading={isLoading}
                    mailConversations={mailConversations}
                    handleEditClicked={handleEditClicked}
                    handleViewClicked={handleViewClicked}
                    handleDuplicateClicked={handleDuplicateClicked}
                    handleDeleteClicked={handleDeleteClicked}
                />
            ) : (
                <Stack height="100%" minHeight="25rem">
                    <CalcOverviewTable
                        calculations={calculations}
                        paging={paging}
                        isLoading={isLoading}
                        sort={sort}
                        mailConversations={mailConversations}
                        handleEditClicked={handleEditClicked}
                        handleViewClicked={handleViewClicked}
                        handleDuplicateClicked={handleDuplicateClicked}
                        handleDeleteClicked={handleDeleteClicked}
                        handleSortModelChange={handleSortModelChange}
                    />
                </Stack>
            )}
        </>
    );
};
const mapStateToProps = (state: RootState) => ({
    calculations: state.proCalcs.calculations,
    mailConversations: state.proCalcs.mailConversations,
    paging: state.proCalcs.paging,
    isLoading: state.proCalcs.isLoading,
    sort: state.proCalcs.sort
});
export default connect(mapStateToProps)(CalcOverview);
