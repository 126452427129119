import { Box, Button, styled, Tooltip, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import { ANALYZING, CREATED, DIRTY, INVALID } from 'src/statics/statics';
import { useState } from 'react';
import { AiMailConversationMetadataResource, AiMailConversationResource } from 'src/backend/market';
import { disableAutoReloadCalculations, enableAutoReloadCalculations } from 'src/redux/thunks/proCalcs.thunk';
import { useDispatch } from 'react-redux';
import AiEmailMissingParameterDialog from 'src/components/pro-calc/AiEmail/AiEmailMissingParameterDialog';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { CalculationResource } from 'src/backend/coreCalc';

const StyledBox = styled(Box)(
    ({ theme }) => `
        display: inline-flex;
        align-items: center;
        gap: 5px;
        padding: 4px 9px;
        border-radius: ${theme.spacing(1)};
        font-weight: bold;
        overflow: hidden;
        max-width: 100%;

        .MuiSvgIcon-root {
            font-size: 1.3rem;
        }
    `
);

interface Props {
    status: CalculationResource.status;
    mailConversation?: AiMailConversationResource;
}

const CalculationStatus: React.FC<Props> = ({ status, mailConversation }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [isMissingParameterDialogOpen, setMissingParameterDialogOpen] = useState<boolean>(false);

    const lastMail = mailConversation?.conversation ? mailConversation.conversation[mailConversation.conversation.length - 1] : null;

    if (status === CalculationResource.status.UNOPENED) {
        return (
            <Tooltip title={t('calcStatusUnopenedExplanation')}>
                <div>
                    <StyledBox className="calculation-status unopened" color={theme.colors.primary.main} bgcolor={theme.colors.primary.lighter} sx={{ borderRight: '5px solid', borderRadius: '5px' }}>
                        <NotificationsActiveTwoToneIcon color="inherit" sx={{ fontSize: '1.8rem !important' }} />
                        <Typography variant="subtitle2" noWrap color="inherit" fontWeight={700}>
                            {t('unopened')}
                        </Typography>
                    </StyledBox>
                </div>
            </Tooltip>
        );
    }

    if (mailConversation?.metadata && lastMail) {
        const isPendingAnswer = lastMail.mailType === 'OutgoingAiCalculationMail' && lastMail.sent;
        if (isPendingAnswer) {
            return (
                <Tooltip title={t('calcStatusSuccessExplanation')}>
                    <div>
                        <StyledBox className="calculation-status" color={theme.colors.warning.main} bgcolor={theme.colors.warning.lighter}>
                            <WarningTwoToneIcon color="warning" />
                            <Typography variant="subtitle2" noWrap color="inherit">
                                {t('waitForReply')}
                            </Typography>
                        </StyledBox>
                    </div>
                </Tooltip>
            );
        }

        const needsClarification = mailConversation.metadata.aiDataType === AiMailConversationMetadataResource.aiDataType.PART_AI_DATA && !mailConversation.metadata.dataComplete;
        if (needsClarification) {
            return (
                <>
                    <Button
                        variant="contained"
                        color="warning"
                        startIcon={<WarningTwoToneIcon />}
                        onClick={(event) => {
                            event.stopPropagation();
                            setMissingParameterDialogOpen(true);
                            dispatch(disableAutoReloadCalculations());
                        }}
                        sx={{ maxWidth: '100%' }}
                    >
                        <Typography noWrap>{t('clarifyInquiry')}</Typography>
                    </Button>
                    <AiEmailMissingParameterDialog
                        mailConversation={mailConversation}
                        isOpen={isMissingParameterDialogOpen}
                        close={() => {
                            setMissingParameterDialogOpen(false);
                            dispatch(enableAutoReloadCalculations());
                        }}
                    />
                </>
            );
        }
    }

    switch (status) {
        case INVALID:
            return (
                <Tooltip title={t('calcStatusInvalidExplanation')}>
                    <div>
                        <StyledBox title={t(status)} className="calculation-status" color={theme.colors.error.main} bgcolor={theme.colors.error.lighter}>
                            <CloseTwoToneIcon color="error" />
                            <Typography variant="subtitle2" noWrap color="inherit">
                                {t(status)}
                            </Typography>
                        </StyledBox>
                    </div>
                </Tooltip>
            );
        case CalculationResource.status.CALCULATING:
            return (
                <Tooltip title={t('calcStatusSuccessExplanation')}>
                    <div>
                        <StyledBox title={t(status)} className="calculation-status" color={theme.colors.success.main} bgcolor={theme.colors.success.lighter}>
                            <CheckTwoToneIcon color="success" />
                            <Typography variant="subtitle2" noWrap color="inherit">
                                {t(status)}
                            </Typography>
                        </StyledBox>
                    </div>
                </Tooltip>
            );
        case CREATED:
            return (
                <Tooltip title={t('calcStatusSuccessExplanation')}>
                    <div>
                        <StyledBox title={t(status)} className="calculation-status" color={theme.colors.success.main} bgcolor={theme.colors.success.lighter}>
                            <CheckTwoToneIcon color="success" />
                            <Typography variant="subtitle2" noWrap color="inherit">
                                {t(status)}
                            </Typography>
                        </StyledBox>
                    </div>
                </Tooltip>
            );
        case DIRTY:
            return (
                <Tooltip title={t('calcStatusDirtyExplanation')}>
                    <div>
                        <StyledBox title={t(status)} className="calculation-status" color={theme.colors.info.main} bgcolor={theme.colors.info.lighter}>
                            <CheckTwoToneIcon color="info" />
                            <Typography variant="subtitle2" noWrap color="inherit">
                                {t(status)}
                            </Typography>
                        </StyledBox>
                    </div>
                </Tooltip>
            );
        case ANALYZING:
            return (
                <Tooltip title={t('calcStatusAnalyzingExplanation')}>
                    <div>
                        <StyledBox title={t(status)} className="calculation-status" color={theme.colors.secondary.main} bgcolor={theme.colors.secondary.lighter}>
                            <CheckTwoToneIcon color="secondary" />
                            <Typography variant="subtitle2" noWrap color="inherit">
                                {t(status)}
                            </Typography>
                        </StyledBox>
                    </div>
                </Tooltip>
            );
        case ANALYZING:
            return (
                <Tooltip title={t('calcStatusAnalyzingExplanation')}>
                    <div>
                        <StyledBox title={t(status)} className="calculation-status" color={theme.colors.secondary.main} bgcolor={theme.colors.secondary.lighter}>
                            <CheckTwoToneIcon color="secondary" />
                            <Typography variant="subtitle2" noWrap color="inherit">
                                {t(status)}
                            </Typography>
                        </StyledBox>
                    </div>
                </Tooltip>
            );
        default:
            return (
                <Tooltip title={t('calcStatusNotFoundExplanation')}>
                    <div>
                        <StyledBox title={t('statusNotFound')} className="calculation-status" color={theme.colors.secondary.main} bgcolor={theme.colors.secondary.lighter}>
                            <CloseTwoToneIcon color="secondary" />
                            <Typography variant="subtitle2" noWrap color="inherit">
                                {t('statusNotFound')}
                            </Typography>
                        </StyledBox>
                    </div>
                </Tooltip>
            );
    }
};

export default CalculationStatus;
