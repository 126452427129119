import { Button, Divider, Stack, Typography } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RuleTwoToneIcon from '@mui/icons-material/RuleTwoTone';
import { ProvidedVariableResource, VariableResource } from 'src/backend/coreCalc';
import Stats from 'src/components/display/Stats/Stats';

interface Props {
    variables?: Array<VariableResource>;
}

const ItemStats: React.FC<Props> = ({ variables }) => {
    const { t } = useTranslation();

    const statisticVariables = useMemo(() => {
        return (variables || []).filter((variable) => variable.type === VariableResource.type.PROVIDED_VARIABLE && (variable as ProvidedVariableResource).isDisplayedInStats);
    }, [variables]);

    return statisticVariables.length > 0 ? (
        <Stack width="100%" maxWidth="35rem" className="item-calculated-values">
            <Typography variant="h3" fontSize={18} textAlign="center" pt={2}>
                {t('stats')}
            </Typography>
            <Stats statisticVariables={statisticVariables} />
        </Stack>
    ) : (
        <></>
    );
};

export default ItemStats;
