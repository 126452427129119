import { CalculationResultResource } from 'src/backend/internalCalc';
import { cloneDeep, debounce, DebouncedFunc } from 'lodash';
import { AppDispatch, AppThunk, RootState } from '../store';
import { Selector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAllMessages } from 'src/utils/CalcHelpers';
import {
    CalcEditorSavingStatus,
    CalcEditorUpdateLoading,
    addCategoryComputation,
    addCategoryMessage,
    addCategoryVariable,
    addMasterVariable,
    addSubCategory,
    addTmpCategory,
    deleteCategory,
    deleteCategoryComputation,
    deleteCategoryMessage,
    deleteCategoryVariable,
    deleteMasterVariable,
    deleteSubCategory,
    handleUpdateFailure,
    handleUpdateSuccess,
    saveBlueprintRecord,
    setBlueprintValue,
    setCategory,
    setCategoryComputation,
    setCategoryComputationSelection,
    setCategoryMessage,
    setCategoryName,
    setCategoryVariable,
    setCategoryVariableValue,
    setMasterVariable,
    setMasterVariableValue,
    setSavingStatus,
    setSubCategory,
    setUpdateLoading
} from '../slices/calcEditor.reducer';
import {
    createCategory,
    createCategoryComputation,
    createCategoryMessage,
    createCategoryVariable,
    createMasterVariable,
    createSubCategory,
    duplicateCategory,
    removeCategory,
    removeCategoryComputation,
    removeCategoryMessage,
    removeCategoryVariable,
    removeMasterVariable,
    removeSubCategory,
    updateBlueprintName,
    updateCategory,
    updateCategoryComputation,
    updateCategoryComputationSelection,
    updateCategoryMessage,
    updateCategoryName,
    updateCategoryVariable,
    updateCategoryVariableValue,
    updateMasterComputation,
    updateMasterVariable,
    updateMasterVariableValue,
    updateSubCategory
} from '../thunks/calcEditor.thunk';
import { CategoryResource, CategoryTemplateResource, ComputationResource, MessageResource, ValueResource, VariableResource } from 'src/backend/coreCalc';

export enum BlueprintUpdateTypes {
    NAME = 'name',
    MASTER_COMPUTATION = 'masterComputation',
    MASTER_VARIABLE = 'masterVariable',
    CREATE_MASTER_VARIABLE = 'createMasterVariable',
    DELETE_MASTER_VARIABLE = 'deleteMasterVariable',
    MASTER_VARIABLE_VALUE = 'masterVariableValue',
    CATEGORY = 'category',
    CATEGORY_NAME = 'categoryName',
    CREATE_CATEGORY = 'createCategory',
    DUPLICATE_CATEGORY = 'duplicateCategory',
    DELETE_CATEGORY = 'deleteCategory',
    CREATE_CATEGORY_COMPUTATION = 'createCategoryComputation',
    DELETE_CATEGORY_COMPUTATION = 'deleteCategoryComputation',
    CATEGORY_COMPUTATION = 'categoryComputation',
    CATEGORY_COMPUTATION_SELECTION = 'categoryComputationSelection',
    CATEGORY_VARIABLE = 'categoryVariable',
    CREATE_CATEGORY_VARIABLE = 'createCategoryVariable',
    DELETE_CATEGORY_VARIABLE = 'deleteCategoryVariable',
    CATEGORY_VARIABLE_VALUE = 'categoryVariableValue',
    CATEGORY_MESSAGE = 'categoryMessage',
    CREATE_CATEGORY_MESSAGE = 'createCategoryMessage',
    DELETE_CATEGORY_MESSAGE = 'deleteCategoryMessage',
    SUB_CATEGORY = 'subCategory',
    CREATE_SUB_CATEGORY = 'createSubCategory',
    DELETE_SUB_CATEGORY = 'deleteSubCategory'
}

type BlueprintUpdateValueMap = {
    [BlueprintUpdateTypes.NAME]: string;
    [BlueprintUpdateTypes.MASTER_COMPUTATION]: ComputationResource;
    [BlueprintUpdateTypes.MASTER_VARIABLE]: VariableResource;
    [BlueprintUpdateTypes.CREATE_MASTER_VARIABLE]: VariableResource;
    [BlueprintUpdateTypes.DELETE_MASTER_VARIABLE]: number;
    [BlueprintUpdateTypes.MASTER_VARIABLE_VALUE]: { variableId: number; value: ValueResource };
    [BlueprintUpdateTypes.CATEGORY]: CategoryResource;
    [BlueprintUpdateTypes.CATEGORY_NAME]: { categoryId: number; name: string };
    [BlueprintUpdateTypes.CREATE_CATEGORY]: CategoryTemplateResource;
    [BlueprintUpdateTypes.DUPLICATE_CATEGORY]: number;
    [BlueprintUpdateTypes.DELETE_CATEGORY]: number;
    [BlueprintUpdateTypes.CREATE_CATEGORY_COMPUTATION]: { categoryId: number; computation: ComputationResource; resultVariable: VariableResource };
    [BlueprintUpdateTypes.DELETE_CATEGORY_COMPUTATION]: { categoryId: number; computationId: number };
    [BlueprintUpdateTypes.CATEGORY_COMPUTATION]: { categoryId: number; computation: ComputationResource };
    [BlueprintUpdateTypes.CATEGORY_COMPUTATION_SELECTION]: { categoryId: number; computationId: number };
    [BlueprintUpdateTypes.CATEGORY_VARIABLE]: { categoryId: number; variable: VariableResource };
    [BlueprintUpdateTypes.CREATE_CATEGORY_VARIABLE]: { categoryId: number; variable: VariableResource };
    [BlueprintUpdateTypes.DELETE_CATEGORY_VARIABLE]: { categoryId: number; variableId: number };
    [BlueprintUpdateTypes.CATEGORY_VARIABLE_VALUE]: { categoryId: number; variableId: number; value: ValueResource };
    [BlueprintUpdateTypes.CATEGORY_MESSAGE]: { categoryId: number; message: MessageResource };
    [BlueprintUpdateTypes.CREATE_CATEGORY_MESSAGE]: { categoryId: number; message: MessageResource };
    [BlueprintUpdateTypes.DELETE_CATEGORY_MESSAGE]: { categoryId: number; messageId: number };
    [BlueprintUpdateTypes.SUB_CATEGORY]: { categoryId: number; subCategory: CategoryResource };
    [BlueprintUpdateTypes.CREATE_SUB_CATEGORY]: { categoryId: number; subCategory: CategoryResource };
    [BlueprintUpdateTypes.DELETE_SUB_CATEGORY]: { categoryId: number; subCategoryId: number };
};

interface BlueprintUpdateInfo<T extends BlueprintUpdateTypes> {
    optimisticUpdateFunction: (value: BlueprintUpdateValueMap[T]) => void;
    updateFunction: (blueprint: any, value: BlueprintUpdateValueMap[T]) => void;
    debounce?: boolean;
}
export const BlueprintUpdateInfos: Record<BlueprintUpdateTypes, BlueprintUpdateInfo<BlueprintUpdateTypes>> = {
    [BlueprintUpdateTypes.NAME]: {
        optimisticUpdateFunction: (value) => setBlueprintValue({ path: 'name', value }),
        updateFunction: updateBlueprintName
    },
    [BlueprintUpdateTypes.MASTER_COMPUTATION]: {
        optimisticUpdateFunction: (value) => setBlueprintValue({ path: 'computation', value }),
        updateFunction: updateMasterComputation
    },
    [BlueprintUpdateTypes.MASTER_VARIABLE]: {
        optimisticUpdateFunction: setMasterVariable,
        updateFunction: updateMasterVariable
    },
    [BlueprintUpdateTypes.CREATE_MASTER_VARIABLE]: {
        optimisticUpdateFunction: addMasterVariable,
        updateFunction: createMasterVariable
    },
    [BlueprintUpdateTypes.DELETE_MASTER_VARIABLE]: {
        optimisticUpdateFunction: deleteMasterVariable,
        updateFunction: removeMasterVariable
    },
    [BlueprintUpdateTypes.MASTER_VARIABLE_VALUE]: {
        optimisticUpdateFunction: setMasterVariableValue,
        updateFunction: updateMasterVariableValue
    },
    [BlueprintUpdateTypes.CATEGORY]: {
        optimisticUpdateFunction: setCategory,
        updateFunction: updateCategory
    },
    [BlueprintUpdateTypes.CATEGORY_NAME]: {
        optimisticUpdateFunction: setCategoryName,
        updateFunction: updateCategoryName
    },
    [BlueprintUpdateTypes.CREATE_CATEGORY]: {
        optimisticUpdateFunction: addTmpCategory,
        updateFunction: createCategory
    },
    [BlueprintUpdateTypes.DUPLICATE_CATEGORY]: {
        optimisticUpdateFunction: addTmpCategory,
        updateFunction: duplicateCategory
    },
    [BlueprintUpdateTypes.DELETE_CATEGORY]: {
        optimisticUpdateFunction: deleteCategory,
        updateFunction: removeCategory
    },
    [BlueprintUpdateTypes.CREATE_CATEGORY_COMPUTATION]: {
        optimisticUpdateFunction: addCategoryComputation,
        updateFunction: createCategoryComputation
    },
    [BlueprintUpdateTypes.DELETE_CATEGORY_COMPUTATION]: {
        optimisticUpdateFunction: deleteCategoryComputation,
        updateFunction: removeCategoryComputation
    },
    [BlueprintUpdateTypes.CATEGORY_COMPUTATION]: {
        optimisticUpdateFunction: setCategoryComputation,
        updateFunction: updateCategoryComputation
    },
    [BlueprintUpdateTypes.CATEGORY_COMPUTATION_SELECTION]: {
        optimisticUpdateFunction: setCategoryComputationSelection,
        updateFunction: updateCategoryComputationSelection
    },
    [BlueprintUpdateTypes.CATEGORY_VARIABLE]: {
        optimisticUpdateFunction: setCategoryVariable,
        updateFunction: updateCategoryVariable,
        debounce: true
    },
    [BlueprintUpdateTypes.CREATE_CATEGORY_VARIABLE]: {
        optimisticUpdateFunction: addCategoryVariable,
        updateFunction: createCategoryVariable
    },
    [BlueprintUpdateTypes.DELETE_CATEGORY_VARIABLE]: {
        optimisticUpdateFunction: deleteCategoryVariable,
        updateFunction: removeCategoryVariable
    },
    [BlueprintUpdateTypes.CATEGORY_VARIABLE_VALUE]: {
        optimisticUpdateFunction: setCategoryVariableValue,
        updateFunction: updateCategoryVariableValue
    },
    [BlueprintUpdateTypes.CATEGORY_MESSAGE]: {
        optimisticUpdateFunction: setCategoryMessage,
        updateFunction: updateCategoryMessage
    },
    [BlueprintUpdateTypes.CREATE_CATEGORY_MESSAGE]: {
        optimisticUpdateFunction: addCategoryMessage,
        updateFunction: createCategoryMessage
    },
    [BlueprintUpdateTypes.DELETE_CATEGORY_MESSAGE]: {
        optimisticUpdateFunction: deleteCategoryMessage,
        updateFunction: removeCategoryMessage
    },
    [BlueprintUpdateTypes.SUB_CATEGORY]: {
        optimisticUpdateFunction: setSubCategory,
        updateFunction: updateSubCategory
    },
    [BlueprintUpdateTypes.CREATE_SUB_CATEGORY]: {
        optimisticUpdateFunction: addSubCategory,
        updateFunction: createSubCategory
    },
    [BlueprintUpdateTypes.DELETE_SUB_CATEGORY]: {
        optimisticUpdateFunction: deleteSubCategory,
        updateFunction: removeSubCategory
    }
};

/*
####################
##  UPDATE LOGIC  ##
####################
*/
type QueueElement = {
    updateType: BlueprintUpdateTypes;
    value: any;
};
type DebouncedUpdate = {
    [updateType in BlueprintUpdateTypes]?: { debounceFunction: DebouncedFunc<() => void>; value: any };
};

let updatesQueue: Array<QueueElement> = [];
let debouncedUpdates: DebouncedUpdate = {};
let isProcessingQueue = false;

const debounceUpdate = (updateType: BlueprintUpdateTypes, value: any, dispatch: AppDispatch, getState: () => RootState, delay: number = 500) => {
    // If there's no debounced function for this updateType, create one
    if (!debouncedUpdates[updateType]) {
        debouncedUpdates[updateType] = createDebounceUpdate(updateType, value, dispatch, getState, delay);
    }
    // Update the value for this updateType and invoke the debounced function
    debouncedUpdates[updateType].value = value;
    debouncedUpdates[updateType].debounceFunction();
};

const createDebounceUpdate = (updateType: BlueprintUpdateTypes, value: any, dispatch: AppDispatch, getState: () => RootState, delay: number) => {
    const debounceFunction = debounce(async () => {
        if (!debouncedUpdates[updateType]) return;
        // Value can not be used, debouncedUpdates[updateType].value assures that we always get the most recent value
        await enqueueAndProcessUpdate(updateType, debouncedUpdates[updateType].value, dispatch, getState);
        delete debouncedUpdates[updateType];
    }, delay);
    return { debounceFunction, value };
};

const enqueueAndProcessUpdate = async (updateType: BlueprintUpdateTypes, value: any, dispatch: AppDispatch, getState: () => RootState) => {
    // Add the update to the queue
    updatesQueue.push({ updateType, value });
    if (!isProcessingQueue) {
        isProcessingQueue = true;
        await processQueue(dispatch, getState);
        isProcessingQueue = false;
    }
};

const processQueue = async (dispatch: AppDispatch, getState: () => RootState) => {
    // Process each item in the queue sequentially
    while (updatesQueue.length > 0) {
        const { updateType, value } = updatesQueue.shift()!;
        await processUpdate(dispatch, getState, updateType, value);
    }
};

const processUpdate = async (dispatch: AppDispatch, getState: () => RootState, updateType: BlueprintUpdateTypes, value: any) => {
    try {
        const blueprint = cloneDeep(getState().calcEditor.blueprint);
        const updateFunction = BlueprintUpdateInfos[updateType].updateFunction;
        await dispatch(updateFunction(blueprint, value));
        dispatch(handleUpdateSuccess(updateType));
    } catch (error) {
        dispatch(handleUpdateFailure({ updateType, error }));
    }
};

const optimisticUpdate = <T extends BlueprintUpdateTypes>(dispatch: AppDispatch, updateType: T, value: BlueprintUpdateValueMap[T]) => {
    dispatch(saveBlueprintRecord());
    const optimisticUpdateFunction = BlueprintUpdateInfos[updateType].optimisticUpdateFunction;
    dispatch(optimisticUpdateFunction(value));
};

export const updateBlueprint = <T extends BlueprintUpdateTypes>(updateType: T, value: BlueprintUpdateValueMap[T]): AppThunk => {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(setSavingStatus(CalcEditorSavingStatus.LOADING));
        dispatch(setUpdateLoading({ updateType, isLoading: true }));

        // Perform the optimistic update for immediate UI feedback
        optimisticUpdate(dispatch, updateType, value);

        if (BlueprintUpdateInfos[updateType]?.debounce === true) {
            // Debounce the update to prevent rapid successive updates of the same type
            debounceUpdate(updateType, value, dispatch, getState);
        } else {
            enqueueAndProcessUpdate(updateType, value, dispatch, getState); // don't drop an update
        }
    };
};

export const cancelBlueprintUpdates = (): AppThunk => {
    return () => {
        // Clear all debounced updates
        Object.keys(debouncedUpdates).forEach((updateType: BlueprintUpdateTypes) => {
            if (debouncedUpdates[updateType] && debouncedUpdates[updateType].debounceFunction.cancel) {
                debouncedUpdates[updateType].debounceFunction.cancel();
            }
        });
        debouncedUpdates = {}; // Reset the debouncedUpdates object

        // Clear the updates queue
        updatesQueue = [];

        // Reset the queue processing flag
        isProcessingQueue = false;
    };
};

/*
#################
##  SELECTORS  ##
#################
*/

const selectCostResult: Selector<RootState, CalculationResultResource> = (state) => state.calcEditor.blueprint?.costResult || {};
export const selectBlueprintMessages = createSelector([selectCostResult], (costResult) => {
    const allMessages = getAllMessages(costResult);
    return {
        all: allMessages,
        hints: allMessages.filter((message) => message.messageType === 'HINT'),
        warnings: allMessages.filter((message) => message.messageType === 'WARNING'),
        errors: allMessages.filter((message) => message.messageType === 'ERROR' || (message.messageType.startsWith('INVALID') && message.messageType !== 'INVALID_PRICE_ERROR'))
    };
});

const selectIsUpdateLoading: Selector<RootState, CalcEditorUpdateLoading> = (state) => state.calcEditor.isUpdateLoading || {};
export const selectBlueprintPriceLoading = createSelector([selectIsUpdateLoading], (isUpdateLoading) => {
    if (isUpdateLoading[BlueprintUpdateTypes.CREATE_CATEGORY] || isUpdateLoading[BlueprintUpdateTypes.DUPLICATE_CATEGORY] || isUpdateLoading[BlueprintUpdateTypes.DELETE_CATEGORY]) return true;
    return false;
});
