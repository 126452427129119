import { styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputVariableResource } from 'src/backend/coreCalc';
import { getValue } from 'src/utils/CalcHelpers';

const DefaultIndicatorSpan = styled('span')(
    ({ theme }) => `
        margin-top: 3px;
        margin-left: 4px;
        font-size: 12px;
        pointer-events: none;
        opacity: 0.5;
    `
);

interface Props {
    variable: InputVariableResource;
    left?: number;
}

const DefaultIndicator: React.FC<Props> = ({ variable, left }) => {
    const { t } = useTranslation();

    const hasValue = !!getValue(variable.value);
    const isDefault = hasValue && getValue(variable.value) === getValue(variable.defaultValue);

    return isDefault && <DefaultIndicatorSpan style={{ position: left ? 'absolute' : 'unset', left }}>(Default)</DefaultIndicatorSpan>;
};

export default DefaultIndicator;
