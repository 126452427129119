import { Box, useMediaQuery, useTheme } from '@mui/material';
import { FC, ReactNode, useEffect, useState } from 'react';
import { Outlet, useMatch } from 'react-router-dom';
import Header from './Header/HeaderNavBar';
import Footer from './Footer/FooterNavBar';
import { StyledBox } from './Navigator.styles';
import { getCurrentRouteConstant } from 'src/utils/RouteHelper';
import { connect, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import MobileDemoHeader from './Header/MobileDemoHeader';
import FullRegistrationLoadingPage from 'src/pages/Profile/FullUserDataInput/FullRegistrationLoadingPage';
import CalculationMigrationWaitingPage from 'src/components/display/CalculationMigrationWaitingPage';

interface AccentSidebarLayoutProps {
    children?: ReactNode;
    isFullRegistrationLoading?: boolean;
}

const AccentSidebarLayout: FC<AccentSidebarLayoutProps> = ({ isFullRegistrationLoading }) => {
    const theme = useTheme();
    const useSidebar = false;

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isFullyRegistered: boolean = useSelector((state: RootState) => state.user.currentUser.lastName !== null);
    const isDemonstrationAccount: boolean = useSelector((state: RootState) => state.user.currentUser.demonstrationAccount);
    const isMergeTableMigrated: boolean = useSelector((state: RootState) => state.user.currentUser.company?.mergeTableMigrated);

    const routeConstant = getCurrentRouteConstant();

    const inOnboardingWizard = useMatch('/wizard/onboarding/calculation/*');
    if (inOnboardingWizard) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    width: '100%',
                    height: '100vh'
                }}
            >
                <Box overflow={'hidden'} flexGrow={1} display={'flex'} flexDirection={'column'}>
                    <Outlet />
                </Box>
            </Box>
        );
    }

    return (
        <>
            {isFullRegistrationLoading && <FullRegistrationLoadingPage />}
            {isFullyRegistered && isMergeTableMigrated === false && <CalculationMigrationWaitingPage />}
            {!isSmallScreen && <Header />}
            {isSmallScreen && isDemonstrationAccount && <MobileDemoHeader />}
            <Box
                sx={{
                    position: 'relative',
                    zIndex: 5,
                    flex: 1,
                    display: 'flex',
                    '@media screen': {
                        pt: isSmallScreen ? (isDemonstrationAccount ? '50px' : 0) : theme.header.height
                    },
                    [theme.breakpoints.up('lg')]: {
                        pl: useSidebar ? theme.sidebar.width : '0px'
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        width: '100%'
                    }}
                >
                    <StyledBox
                        flexGrow={1}
                        display={'flex'}
                        flexDirection={'column'}
                        sx={{
                            '@media screen': {
                                pb: isSmallScreen ? theme.header.height : 4,
                                mb: isSmallScreen && !routeConstant?.hideFooter ? '20px' : 0
                            }
                        }}
                    >
                        <Outlet />
                    </StyledBox>
                </Box>
            </Box>
            {isSmallScreen && !routeConstant?.hideFooter && <Footer />}
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        isFullRegistrationLoading: state.user.isFullRegistrationLoading
    };
};
export default connect(mapStateToProps)(AccentSidebarLayout);
