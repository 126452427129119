import { Typography, Stack, useTheme, Switch, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BooleanInputVariableResource, InputVariableResource, VariableResource } from 'src/backend/coreCalc';
import { createValue, getBooleanValue, getStringValue } from 'src/utils/CalcHelpers';

interface Props {
    variable: BooleanInputVariableResource;
    variables: Array<VariableResource>;
    setVariable: (variable: BooleanInputVariableResource) => void;
    setValid: (isValid: boolean) => void;
    isSmall?: boolean;
    isDisabled: boolean;
}

const TextInputVariableForm: React.FC<Props> = ({ variable, variables, setVariable, setValid, isSmall, isDisabled }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();

    const handleDefaultChange = (event) => {
        setVariable({
            ...variable,
            defaultValue: createValue(event.target.value)
        });
    };

    return (
        variable && <TextField label={t('defaultTextValue')} value={getStringValue(variable.defaultValue)} size="small" disabled={isDisabled} onChange={handleDefaultChange} fullWidth sx={{ mt: 2 }} />
    );
};

export default TextInputVariableForm;
