import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import store from 'src/redux/store';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import { BlueprintUpdateTypes, updateBlueprint } from 'src/redux/shared/calcEditor.common';
import { CategoryResource } from 'src/backend/coreCalc';
import SubCategoryForm from './SubCategoryForm';

interface Props {
    category: CategoryResource;
    isOpen: boolean;
    setOpen?: (isOpen: boolean) => void;
}

const CreateSubCategoryDialog: React.FC<Props> = ({ category, isOpen, setOpen }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [subCategory, setSubCategory] = useState<CategoryResource>();

    const createSubCategory = () => {
        setLoading(true);
        dispatch(updateBlueprint(BlueprintUpdateTypes.CREATE_SUB_CATEGORY, { categoryId: category.id, subCategory: { id: -1, ...subCategory } }));
    };

    useEffect(() => {
        if (!isLoading) return;
        const foundTempId = (category.subCategories || []).some((category) => category.id === -1);

        if (!foundTempId) {
            setLoading(false); // Stop loading when temp ID is gone
            setOpen(false);
        }
    }, [category.subCategories, isLoading]);

    return (
        <Dialog open={isOpen} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
            <DialogTitle sx={{ pt: 3.1, pb: 2.7 }}>
                <Typography variant="h4">Teil erstellen</Typography>
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 16, top: 16, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack gap={3} pt={1}>
                    <SubCategoryForm subCategory={subCategory} setSubCategory={setSubCategory} />
                    <Button variant="contained" startIcon={<AutoAwesomeTwoToneIcon />} sx={{ ml: 'auto' }} onClick={createSubCategory} disabled={isLoading}>
                        {isLoading ? <CircularProgress color="inherit" size={20} /> : 'Neues Teil erstellen'}
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default CreateSubCategoryDialog;
