import { Typography, Stack, Divider, Button, Dialog, DialogTitle, IconButton, DialogContent, styled, Box, useTheme } from '@mui/material';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import Accordion from 'src/components/display/Accordion/Accordion';
import { useEffect, useMemo, useState } from 'react';
import InputFieldListItem from './InputFieldListItem';
import CreateVariableDialog from '../../Variable/CreateVariableDialog';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import { VariableResource, VariableTypeTemplateResource } from 'src/backend/coreCalc';
import { InputVariablesGroupedBySection } from '../../CalcEditor.types';

const StyledStack = styled(Stack)(
    ({ theme }) => `
        height: 100%;
        .MuiButtonBase-root.accordion-button {
            padding-left: 24px;
            padding-right: 24px;
        }
    `
);
const FieldsWrapper = styled(Box)(
    ({ theme }) => `
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: ${theme.spacing(2.5)};
        padding-top: ${theme.spacing(0)};
        gap: ${theme.spacing(1)};
    `
);

interface Props {
    isOpen: boolean;
    setOpen?: (isOpen: boolean) => void;
    variables: Array<VariableResource>;
    inputVariablesGroupedBySection: InputVariablesGroupedBySection;
    calcRelevantVariableInternalIdentifiers?: Array<string>;
    variableTypeTemplates: Array<VariableTypeTemplateResource>;
    createVariable: (variable: VariableResource) => void;
    saveVariable: (variable: VariableResource) => void;
    removeVariable: (variableId: number) => void;
}

const InputFieldsDialog: React.FC<Props> = ({
    isOpen,
    setOpen,
    variables,
    inputVariablesGroupedBySection = {},
    calcRelevantVariableInternalIdentifiers,
    variableTypeTemplates,
    createVariable,
    saveVariable,
    removeVariable
}) => {
    const theme = useTheme();
    const [openAccordionId, setOpenAccordionId] = useState<string>();
    const [isCreateInputFieldOpen, setCreateInputFieldOpen] = useState<boolean>(false);

    useEffect(() => {
        setOpenAccordionId(Object.keys(inputVariablesGroupedBySection)[0]);
    }, []);

    const inputVariableTemplate = useMemo(() => (variableTypeTemplates || []).find((template) => template.id === 'INPUT_VARIABLE'), [variableTypeTemplates]);

    return (
        <Dialog open={isOpen} onClose={() => setOpen(false)} fullWidth maxWidth="md" PaperProps={{ sx: { width: '100%' } }}>
            <DialogTitle>
                <Typography sx={{ fontSize: '1.875rem', fontWeight: 700, mt: 0.5, mb: 1, pr: 7 }} gutterBottom>
                    Eingabefelder anpassen
                </Typography>
                <Typography sx={{ fontSize: 14, lineHeight: 1.3 }} variant="subtitle1">
                    Wähle aus, wie der Preis für diese Kategorie berechnet werden soll. Wenn keine der vordefinierten Kalkulationen für dich geeignet ist, kannst du deine eigene Kalkulation erstellen.
                </Typography>
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 16, top: 16, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ px: 0, pb: 0 }}>
                <StyledStack>
                    <Divider />
                    {Object.entries(inputVariablesGroupedBySection).map(([section, inputVariables]) => (
                        <Accordion key={section} accordionHeader={section} accordionId={section} openAccordionId={openAccordionId} setOpenAccordionId={setOpenAccordionId}>
                            <FieldsWrapper>
                                {inputVariables.map((variable) => (
                                    <InputFieldListItem
                                        key={variable.id}
                                        variable={variable}
                                        variables={variables}
                                        calcRelevantVariableInternalIdentifiers={calcRelevantVariableInternalIdentifiers}
                                        createVariable={createVariable}
                                        saveVariable={saveVariable}
                                        removeVariable={removeVariable}
                                    />
                                ))}
                            </FieldsWrapper>
                        </Accordion>
                    ))}
                    <Divider />
                    <Button startIcon={<AddBoxTwoToneIcon />} onClick={() => setCreateInputFieldOpen(true)} sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                        Eigenes Eingabefeld erstellen
                    </Button>
                    {inputVariableTemplate && isCreateInputFieldOpen && (
                        <CreateVariableDialog
                            template={inputVariableTemplate}
                            isOpen={isCreateInputFieldOpen}
                            setOpen={setCreateInputFieldOpen}
                            variables={variables}
                            createVariable={createVariable}
                        />
                    )}
                </StyledStack>
            </DialogContent>
        </Dialog>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        variableTypeTemplates: state.templates.collection?.variableTypeTemplates
    };
};
export default connect(mapStateToProps)(InputFieldsDialog);
