import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import DragAndDropFileInput from 'src/components/input/DragAndDropFileInput';
import { snackContext } from 'src/redux/store';
import { ALLOWED_3D_FILE_TYPES, ALLOWED_GENERAL_FILE_TYPES } from 'src/statics/statics';
import { SSA3DViewer } from '@surface-solutions/ssa-3d-viewer';
import { ModelStats } from '@surface-solutions/ssa-3d-viewer/dist/context/ViewerContext';
import { addFile, addFileWithThumbnail } from 'src/redux/thunks/fileManager.thunk';
import { ProCalcUpdateTypes, updateProCalc } from 'src/redux/shared/proCalc.common';
import { ShortCategoryResource } from 'src/backend/coreCalc';
import { FileResource } from 'src/backend/market';
import { getFileExtension } from 'src/utils/CalcHelpers';

interface Props {
    categories: Array<ShortCategoryResource>;
}

const ItemSelector: React.FC<Props> = ({ categories }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [tmp3dFiles, setTmp3dFiles] = useState<Array<File>>([]);
    const [amountOfFiles, setAmountOfFiles] = useState<number>(0);
    const [currentAnalyzedFile, setCurrentAnalyzedFile] = useState<number>(0);
    const [areFilesLoading, setFilesLoading] = useState<boolean>(false);
    const allowedFileTypes = [...ALLOWED_GENERAL_FILE_TYPES, ...ALLOWED_3D_FILE_TYPES];

    const defaultCategory = useMemo(() => (categories || []).find((category) => category.defaultCategory), [categories]);

    const handleFileUpload = async (fileList: FileList) => {
        const files = Array.from(fileList);
        if (!files) return;

        const threeDFiles: Array<File> = [];
        setFilesLoading(true);
        setAmountOfFiles(files.length);
        setCurrentAnalyzedFile(0);

        for (const file of files) {
            const fileExtension = getFileExtension(file.name);

            if (!allowedFileTypes.includes(fileExtension)) {
                return snackContext.enqueueSnackbar(t('fileHasInvalidFileExtension'), { variant: 'error' });
            }

            if (ALLOWED_GENERAL_FILE_TYPES.includes(fileExtension)) {
                const fileResource: FileResource = await dispatch(addFile(file, file.name));
                dispatch(
                    updateProCalc(ProCalcUpdateTypes.CREATE_ITEM_WITH_FILE, {
                        categoryId: defaultCategory.id,
                        fileResource
                    })
                );

                setCurrentAnalyzedFile(currentAnalyzedFile + 1);
            } else if (ALLOWED_3D_FILE_TYPES.includes(fileExtension)) {
                threeDFiles.push(file);
            }
        }

        if (threeDFiles.length > 0) setTmp3dFiles(threeDFiles);
        setFilesLoading(false);
    };

    const handleOnViewerLoader = async (modelStats: ModelStats) => {
        const file = tmp3dFiles[0];
        if (!file) return;

        const fileResource: FileResource = await dispatch(addFileWithThumbnail(file, file.name, modelStats.screenshot));

        dispatch(
            updateProCalc(ProCalcUpdateTypes.CREATE_ITEM_WITH_FILE, {
                categoryId: defaultCategory.id,
                fileResource: fileResource,
                modelStats
            })
        );

        const files = [...tmp3dFiles];
        files.shift(); // remove file
        setTmp3dFiles(files);
        setCurrentAnalyzedFile(currentAnalyzedFile + 1);
    };

    useEffect(() => {
        const handleDragEvent = (event) => {
            event.preventDefault();
        };
        const handleDrop = (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (event.dataTransfer.files && event.dataTransfer.files[0]) {
                handleFileUpload(event.dataTransfer.files);
            }
        };
        window.addEventListener('drop', handleDrop);
        window.addEventListener('dragenter', handleDragEvent);
        window.addEventListener('dragleave', handleDragEvent);
        window.addEventListener('dragover', handleDragEvent);

        return () => {
            window.removeEventListener('drop', handleDrop);
            window.removeEventListener('dragenter', handleDragEvent);
            window.removeEventListener('dragleave', handleDragEvent);
            window.removeEventListener('dragover', handleDragEvent);
        };
    }, []);

    return (
        defaultCategory && (
            <>
                <Stack p={2} flexGrow={1}>
                    <DragAndDropFileInput
                        style={{ flexGrow: 1 }}
                        accept={allowedFileTypes.join(', ')}
                        primaryText={t('dragAndDropYour3dFilesHere')}
                        secondaryText={allowedFileTypes.join(' | ').toUpperCase()}
                        fileSelected={handleFileUpload}
                    />
                    {tmp3dFiles[0] && (
                        <div style={{ position: 'absolute', opacity: 0 }}>
                            <SSA3DViewer file={tmp3dFiles[0]} onViewerLoaded={handleOnViewerLoader} />
                        </div>
                    )}
                </Stack>

                {(areFilesLoading || tmp3dFiles.length > 0) && (
                    <Stack position="absolute" left={0} right={0} bottom={0} top={0} alignItems="center" justifyContent="center" bgcolor={theme.colors.alpha.white[70]} zIndex={100}>
                        <CircularProgress />
                        <Typography mt={2} variant="h5">
                            {amountOfFiles > 1 ? t('analyzeFileXOfZ', { x: currentAnalyzedFile + 1, z: amountOfFiles }) : t('analyzeFile')}
                        </Typography>
                    </Stack>
                )}
            </>
        )
    );
};

export default ItemSelector;
