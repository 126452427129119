import { Button, Card, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from 'src/components/display/PageHeader';
import { nav } from 'src/statics/navigations';
import CalculateTwoToneIcon from '@mui/icons-material/CalculateTwoTone';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import useDialog from '../../../hooks/useDialog';
import { RootState, snackContext, useDispatch } from '../../../redux/store';
import { startWalkthrough } from '../../../redux/thunks/guidethrough.thunk';
import { BlueprintResource } from 'src/backend/coreCalc';
import { formatDate } from 'src/utils/FormatHelpers';
import SurfLoader from 'src/components/display/Loader/SurfLoader';
import NewCalcEditorDialog from 'src/components/calc-editor/NewCalcEditorDialog';
import { deleteBlueprint, loadBlueprints } from 'src/redux/thunks/calcEditors.thunk';
import { connect } from 'react-redux';

interface Props {
    blueprints: Array<BlueprintResource>;
}

const CalcEditorOverview: React.FC<Props> = ({ blueprints }) => {
    const dialog = useDialog();
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [isNewCalcEditorDialogOpen, setNewCalcEditorDialogOpen] = useState<boolean>(false);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        setLoading(true);
        dispatch(startWalkthrough('calculationConfiguration'));
        dispatch(loadBlueprints()).then(() => {
            setLoading(false);
        });
    }, []);

    const handleBack = () => {
        navigate(nav.TOOLS.path);
    };
    const handleDelete = (blueprintId: string) => {
        dialog.openDialog({
            severity: 'error',
            okText: t('delete'),
            cancelText: t('notDelete'),
            component: <Typography>{t('areYouSureToDeleteTheBlueprint')}</Typography>,
            title: t('deleteBlueprint'),
            okCallback: async () => {
                try {
                    await dispatch(deleteBlueprint(blueprintId));
                    snackContext.enqueueSnackbar(t('blueprintSuccessfullyDeleted'), { variant: 'success' });
                } catch (error) {
                    snackContext.enqueueSnackbar(t('errorWhileDeletingBlueprint'), { variant: 'error' });
                }
                dialog.closeDialog();
            }
        });
    };
    const handleEdit = (blueprintId: string) => {
        navigate(nav.TOOLS.sub.CALC_EDITOR.sub.EDIT.path.replace(':id', blueprintId));
    };

    return (
        <>
            <PageHeader mainTitle="Kalkulationskonfiguration" handleBack={isSmallScreen && handleBack}>
                <Button onClick={() => setNewCalcEditorDialogOpen(true)} size="large" variant="contained">
                    {t('createBlueprint')}
                </Button>
            </PageHeader>

            <NewCalcEditorDialog isOpen={isNewCalcEditorDialogOpen} setOpen={setNewCalcEditorDialogOpen} />

            {isLoading ? (
                <Stack width="100%" flexGrow={1} display="flex" justifyContent="center" alignItems="center">
                    <SurfLoader />
                </Stack>
            ) : (
                <Stack justifyContent="start" pt={4} gap={2} flexGrow={1}>
                    {(blueprints || []).length < 1 && (
                        <Stack alignItems="center" justifyContent="center" height="30rem" bgcolor={theme.colors.alpha.black[7]} borderRadius={5}>
                            <Typography>Keine Kalkulationsgrundlagen vorhanden</Typography>
                        </Stack>
                    )}
                    {(blueprints || []).map((blueprint) => {
                        return (
                            <Card key={blueprint.id} sx={{ minHeight: '170px' }}>
                                <Stack p={2} sx={{ height: '100%' }}>
                                    <Stack direction="row" justifyContent="space-between">
                                        <CalculateTwoToneIcon color="primary" />
                                        <Typography>{formatDate(blueprint.createdAt)}</Typography>
                                    </Stack>
                                    <Typography sx={{ mt: 1, mb: 1 }} variant="h3">
                                        {blueprint.name}
                                    </Typography>
                                    <Stack direction="row" sx={{ mt: 'auto', ml: 'auto' }} gap={1}>
                                        <Button color="error" onClick={() => handleDelete(blueprint.id)} startIcon={<DeleteForeverTwoToneIcon />}>
                                            Delete
                                        </Button>
                                        <Button variant="contained" onClick={() => handleEdit(blueprint.id)}>
                                            Editieren
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Card>
                        );
                    })}
                </Stack>
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        blueprints: state.calcEditors.blueprints
    };
};
export default connect(mapStateToProps)(CalcEditorOverview);
