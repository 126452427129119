import { Button, Divider, IconButton, List, Popover, Stack, Typography } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/redux/store';
import { InternalCalculationResource, SurchargeOverrideStorageResource } from 'src/backend/internalCalc';
import { API_CONFIG } from 'src/config';
import SurchargeListItem from '../SurchargeListItem';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import LocalOfferTwoToneIcon from '@mui/icons-material/LocalOfferTwoTone';
import { SurchargeVariableResource, VariableResource } from 'src/backend/coreCalc';
import { updateProCalc, ProCalcUpdateTypes } from 'src/redux/shared/proCalc.common';

interface Props {
    variables?: Array<VariableResource>;
}

const MasterSurcharges: React.FC<Props> = ({ variables }) => {
    const { t }: { t: any } = useTranslation();
    const dispatch = useDispatch();
    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);

    const surchargeVariables = useMemo(() => {
        return (variables || []).filter((variable) => variable.type === VariableResource.type.SURCHARGE_VARIABLE) as Array<SurchargeVariableResource>;
    }, [variables]);

    const appliedSurchargeVariables = useMemo(() => {
        return (surchargeVariables || []).filter((variable) => variable.isApplied) as Array<SurchargeVariableResource>;
    }, [variables]);

    const saveMode = (variableId: number, mode: SurchargeVariableResource.mode) => {
        dispatch(updateProCalc(ProCalcUpdateTypes.MASTER_SURCHARGE_VARIABLE_MODE, { variableId, mode }));
    };

    return (
        surchargeVariables?.length > 0 && (
            <>
                <Button sx={{ px: 1.2, py: 0.5, flexShrink: 0 }} startIcon={<LocalOfferTwoToneIcon />} variant="text" color="secondary" onClick={() => setOpen(true)} ref={ref}>
                    {t('surcharges')}
                    {appliedSurchargeVariables.length > 0 && ` (${appliedSurchargeVariables.length})`}
                </Button>

                <Popover
                    anchorEl={ref.current}
                    onClose={() => setOpen(false)}
                    open={isOpen}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', p: 0.6, right: 0, top: 0, color: (theme) => theme.palette.grey[500] }}>
                        <CloseTwoToneIcon />
                    </IconButton>
                    <Stack p={2} width="25rem" maxWidth="100%">
                        <Typography variant="h3" textAlign="center">
                            {t('masterSurcharges')}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom textAlign="center">
                            {t('masterSurchargesExplanation')}
                        </Typography>

                        <Divider sx={{ mt: 2, mb: 1.5 }} />

                        <List disablePadding component="div" sx={{ mx: -2 }}>
                            {surchargeVariables.map((variable, index) => (
                                <SurchargeListItem key={index} variable={variable} saveMode={saveMode} />
                            ))}
                        </List>
                    </Stack>
                </Popover>
            </>
        )
    );
};

export default MasterSurcharges;
