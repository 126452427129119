import { Typography, Stack, useTheme, Switch } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BooleanInputVariableResource, InputVariableResource, VariableResource } from 'src/backend/coreCalc';
import { createValue, getBooleanValue } from 'src/utils/CalcHelpers';

interface Props {
    variable: BooleanInputVariableResource;
    variables: Array<VariableResource>;
    setVariable: (variable: BooleanInputVariableResource) => void;
    setValid: (isValid: boolean) => void;
    isSmall?: boolean;
    isDisabled: boolean;
}

const BooleanInputVariableForm: React.FC<Props> = ({ variable, variables, setVariable, setValid, isSmall, isDisabled }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();

    const handleDefaultChange = (event) => {
        setVariable({
            ...variable,
            defaultValue: createValue(event.target.checked)
        });
    };

    return (
        variable && (
            <Stack>
                <Typography variant="subtitle2" gutterBottom sx={{ mt: 4 }}>
                    {t('defaultBooleanValue')}
                </Typography>
                <Switch sx={{ ml: -1.1 }} checked={getBooleanValue(variable.defaultValue)} onChange={handleDefaultChange} disabled={isDisabled} />
            </Stack>
        )
    );
};

export default BooleanInputVariableForm;
