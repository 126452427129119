/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CategoryVariableIdentifier {
    RELATIVE_SURCHARGES = 'relativeSurcharges',
    ABSOLUTE_SURCHARGES = 'absoluteSurcharges',
    BOUNDING_BOX = 'boundingBox',
    LARGEST_WIDTH = 'largestWidth',
    LARGEST_HEIGHT = 'largestHeight',
    LARGEST_LENGTH = 'largestLength',
    OBJECT_SURFACE_AREA = 'objectSurfaceArea',
    QM_PRICE = 'qmPrice',
    KG_PRICE = 'kgPrice',
    QUANTITY = 'quantity',
    EXTRACTED_QUANTITY = 'extractedQuantity',
    AREA_CALCULATION_PRICE = 'areaCalculationPrice',
    WEIGHT_CALCULATION_PRICE = 'weightCalculationPrice',
    UNIT_WEIGHT = 'unitWeight',
}