import { Typography, Stack, IconButton, styled, useTheme, ListItemButton, ListItemIcon, Checkbox, ListItemText, lighten } from '@mui/material';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import { useState } from 'react';
import ChangeVariableDialog from '../../Variable/ChangeVariableDialog';
import { useDispatch } from 'react-redux';
import { InputVariableResource, ProvidedVariableResource, VariableResource } from 'src/backend/coreCalc';
import { getVariableName } from 'src/utils/CalcHelpers';

const StyledListItemButton = styled(ListItemButton)(
    ({ theme }) => `
        &.active {
            background: ${theme.colors.primary.lighter};
        }
        &.active:hover {
            background: ${lighten(theme.colors.primary.main, 0.8)};
        }
        &.active.Mui-disabled {
            opacity: 1;
            background: ${theme.colors.alpha.black[7]};
        }
        &.active.Mui-disabled .Mui-checked {
            color: ${theme.colors.alpha.black[30]};
        }
    `
);

interface Props {
    variable: ProvidedVariableResource;
    variables: Array<VariableResource>;
    createVariable: (variable: VariableResource) => void;
    saveVariable: (variable: VariableResource) => void;
    removeVariable: (variableId: number) => void;
}

const CategoryStatsListItem: React.FC<Props> = ({ variable, variables, createVariable, saveVariable, removeVariable }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [isOpen, setOpen] = useState<boolean>(false);

    const isActive = variable.isDisplayedInStats;

    const handleClick = () => {
        saveVariable({
            ...variable,
            isDisplayedInStats: !variable.isDisplayedInStats
        } as ProvidedVariableResource);
    };

    return (
        <>
            <StyledListItemButton className={isActive && 'active'} onClick={handleClick}>
                <ListItemIcon sx={{ minWidth: 32, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Checkbox edge="start" checked={isActive} tabIndex={-1} disableRipple />
                    {/* {isLoading && <Stack sx={{ position: 'absolute', background: theme.colors.alpha.white[100] }}><CircularProgress size={20}/></Stack>} */}
                </ListItemIcon>
                <ListItemText primary={getVariableName(variable)} primaryTypographyProps={{ variant: 'body1' }} />
                <IconButton
                    color="secondary"
                    sx={{ mr: -0.5, pointerEvents: 'all' }}
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setOpen(true);
                    }}
                >
                    <SettingsTwoToneIcon />
                </IconButton>
            </StyledListItemButton>
            <ChangeVariableDialog
                variable={variable}
                variables={variables}
                isOpen={isOpen}
                setOpen={setOpen}
                createVariable={createVariable}
                saveVariable={saveVariable}
                removeVariable={removeVariable}
            />
        </>
    );
};

export default CategoryStatsListItem;
