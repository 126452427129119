import { Box, IconButton, Tooltip, styled, useTheme } from '@mui/material';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getInputValue, getVariableValue, searchForProvidedVariable, searchForSpecialInputVariable } from 'src/utils/CalcHelpers';
import NumberInput from 'src/components/input/NumberInput';
import AutoFixHighTwoToneIcon from '@mui/icons-material/AutoFixHighTwoTone';
import CachedTwoToneIcon from '@mui/icons-material/CachedTwoTone';
import { CategoryVariableIdentifier, NumberInputVariableResource, NumberValueResource, ValueResource, VariableResource } from 'src/backend/coreCalc';

const StyledNumberInput = styled(NumberInput)(
    ({ theme }) => `
        .MuiInputBase-input {
            padding: 6px 0;
            font-weight: 700;
            font-size: 16px;
        }
        .MuiOutlinedInput-notchedOutline {
            border-color: ${theme.colors.alpha.black[10]};
        }
        .MuiTypography-subtitle1 {
            font-weight: 700;
            font-size: 16px;
            color: unset;
        }

        &.small .MuiButtonBase-root.plus,
        &.small .MuiButtonBase-root.minus {
            min-width: 1.7rem;
        }
        &.small .MuiInputBase-input,
        &.small .MuiTypography-subtitle1 {
            font-size: 14px;
        }
    `
);

interface Props {
    variables?: Array<VariableResource>;
    disabled?: boolean;
    isSmall?: boolean;
    saveVariableValue: (variableId: number, value: ValueResource) => void;
}

const QuantityInput: React.FC<Props> = ({ variables, disabled, isSmall, saveVariableValue }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();

    const quantityVariable = useMemo(() => searchForSpecialInputVariable(variables, CategoryVariableIdentifier.QUANTITY) as NumberInputVariableResource, [variables]);
    if (!quantityVariable) return <div></div>;

    const extractQuantityVariable = useMemo(() => searchForProvidedVariable(variables, CategoryVariableIdentifier.EXTRACTED_QUANTITY), [variables]);
    const quantity = useMemo(() => (getInputValue(quantityVariable) || 0) + '', [quantityVariable]);

    const [width, setWidth] = useState<number>(quantity.length);
    const min = quantityVariable?.min || 1;
    const max = quantityVariable?.max || 1000;

    const isExtracted = !!extractQuantityVariable?.value;
    const isUsingExtracted = getVariableValue(extractQuantityVariable) == quantity;

    const changeQuantity = (value): void => {
        saveQuantity(value);
        setWidth(value?.length);
    };
    const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>): void => {
        changeQuantity(event.target.value);
    };

    const saveQuantity = (newQuantity) => {
        if (!newQuantity) return;
        const parsedQuantity = parseInt(newQuantity);
        if (parsedQuantity < min) return;
        if (parsedQuantity > max) return;
        const newQuantityValue: NumberValueResource = { ...quantityVariable?.value, numberValue: parsedQuantity };
        saveVariableValue(quantityVariable.id, newQuantityValue);
    };

    const handleQuantityBlur = (): void => {
        if (!quantity || isNaN(+quantity)) return changeQuantity(min + '');
        const parsedQuantity = parseInt(quantity);
        if (parsedQuantity < min) changeQuantity(min + '');
        if (parsedQuantity > max) changeQuantity(max + '');
    };

    const resetQuantity = (): void => {
        return changeQuantity(getVariableValue(extractQuantityVariable));
    };

    return (
        <StyledNumberInput
            size="small"
            inputProps={{ min, max }}
            value={quantity}
            onChange={handleQuantityChange}
            onBlur={handleQuantityBlur}
            onKeyDown={(event) => {
                if (event.key === '.' || event.key === ',') event.preventDefault();
            }}
            sx={{ width: (width + t('pcs').length + (isSmall ? 7 : 9) + (isExtracted ? 2 : 0)) * 10 + 'px' }}
            startIcon={
                isExtracted && (
                    <Box ml={0.8} mb="-2px">
                        {isUsingExtracted ? (
                            <Tooltip title={t('valueWasExtracted')}>
                                <AutoFixHighTwoToneIcon sx={{ cursor: 'help', color: theme.colors.success.dark, mb: -0.3 }} />
                            </Tooltip>
                        ) : (
                            <Tooltip title={t('resetToExtractedValue')}>
                                <IconButton onClick={resetQuantity} sx={{ m: '-8px' }}>
                                    <CachedTwoToneIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                )
            }
            unit={t('pcs')}
            onFocus={(e) => e.target.select()}
            disabled={disabled}
            className={isSmall ? 'small' : ''}
        />
    );
};

export default QuantityInput;
