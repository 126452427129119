import { Typography, Stack, useTheme, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TermBuilder from '../../TermBuilder/TermBuilder';
import { TermVariableResource, VariableResource } from 'src/backend/coreCalc';
import { getVariableName } from 'src/utils/CalcHelpers';

interface Props {
    variable: TermVariableResource;
    variables: Array<VariableResource>;
    setVariable: (variable: VariableResource) => void;
    setValid: (isValid: boolean) => void;
    isSmall?: boolean;
    isDisabled: boolean;
}

const DeclarationVariableForm: React.FC<Props> = ({ variable, variables, setVariable, setValid, isSmall, isDisabled }) => {
    const dispatch = useDispatch();
    const theme = useTheme();

    useEffect(() => {
        if (!variable.term) setVariable({ ...variable, term: {} } as TermVariableResource);
    }, []);

    useEffect(() => {
        if (!variable) return setValid(false);
        const isInvalid = getVariableName(variable) == null || getVariableName(variable).trim() === '' || variable.term == null || JSON.stringify(variable.term) === '{}';
        setValid(!isInvalid);
    }, [variable]);

    const handleNameChange = (event) => {
        setVariable({ ...variable, displayName: event.target.value });
    };

    const setTerm = (term) => {
        setVariable({ ...variable, term } as TermVariableResource);
    };

    return (
        variable && (
            <Stack>
                <TextField label="Name" value={getVariableName(variable)} onChange={handleNameChange} variant="outlined" fullWidth size={isSmall ? 'small' : 'medium'} disabled={isDisabled} />
                <Typography variant="h4" sx={{ fontSize: 20, mt: 3, mb: 0.5 }} gutterBottom>
                    Wie soll dein Wert berechnet werden?
                </Typography>
                {variable.term && <TermBuilder term={variable.term} setTerm={setTerm} variables={variables} isAlwaysFullWidth={true} minInputWidth="12rem" disabled={isDisabled} />}
            </Stack>
        )
    );
};

export default DeclarationVariableForm;
