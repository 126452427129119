/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyResource } from '../models/CompanyResource';
import type { NewGeneralFileDto } from '../models/NewGeneralFileDto';
import type { PresignedUrlResource } from '../models/PresignedUrlResource';
import type { ReceivingMailDto } from '../models/ReceivingMailDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class CompanyManagementRestControllerService {

    /**
     * @param companyId
     * @param requestBody
     * @returns CompanyResource OK
     * @throws ApiError
     */
    public static setReceivingMail(
        companyId: number,
        requestBody: ReceivingMailDto,
    ): CancelablePromise<CompanyResource> {
        return __request({
            method: 'PUT',
            path: `/marketplace/companies/${companyId}/receivingMail`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param companyId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static setLogo1(
        companyId: number,
        requestBody: NewGeneralFileDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/companies/${companyId}/logo`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns PresignedUrlResource OK
     * @throws ApiError
     */
    public static getCompanyLogoUploadUrl(
        requestBody: NewGeneralFileDto,
    ): CancelablePromise<PresignedUrlResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/companies/imageUploadUrl`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}