import { CalculationMessageResource, CalculationParameterResource, CalculationResultResource, FileResource, GeneralSettingResource } from 'src/backend/market';
import {
    BooleanCalcParamResource,
    ConstantDoubleCalcParamResource,
    ConstantIntegerCalcParamResource,
    DoubleCalcParamResource,
    EnumerationCalcParamResource,
    IntegerCalcParamResource,
    SectionDefinitionResource,
    StringCalcParamResource
} from 'src/backend/internalCalc';
import { GuiStatusResource } from 'src/backend/market';
import store from 'src/redux/store';
import { ALLOWED_3D_FILE_TYPES, ALLOWED_GENERAL_FILE_TYPES, COLOR_GUI_IDENTIFIER, COLOR_SYSTEMS_GUI, EXCLUDED_EDITOR_FIELDS } from 'src/statics/statics';
import { wT } from 'src/utils/wizardTranslations';
import { every, isEmpty, groupBy, forEach, omitBy, map, cloneDeep, sortBy, isString, isBoolean } from 'lodash';
import { formatValue } from './FormatHelpers';
import {
    BooleanValueResource,
    ComputationResource,
    ComputationTemplateResource,
    CriterionResource,
    CustomerResource,
    HolderResource,
    InputVariableResource,
    InternalIdentifierHolderResource,
    ListValueResource,
    NumberInputVariableResource,
    NumberValueResource,
    ProvidedVariableResource,
    StringValueResource,
    TermResource,
    TermVariableResource,
    ValueHolderResource,
    ValueResource,
    VariableResource
} from 'src/backend/coreCalc';
import { t } from 'i18next';
import { InputVariablesGroupedBySection } from 'src/components/calc-editor/CalcEditor.types';
import { listVariablesInTerm } from './TermHelper';
import * as yup from 'yup';

export type CalcParamResource =
    | BooleanCalcParamResource
    | ConstantDoubleCalcParamResource
    | ConstantIntegerCalcParamResource
    | DoubleCalcParamResource
    | EnumerationCalcParamResource
    | IntegerCalcParamResource
    | StringCalcParamResource;

export interface ParameterArray extends Array<CalcParamResource> {}

export interface Section {
    id: string;
    groupedParameters: ParameterArray | Array<any>;
    numberOfCells: number;
}

export const transformToCalculationParameter = (params: { [key: string]: any }): CalculationParameterResource[] => {
    const calculationParameters: Array<CalculationParameterResource> = [];
    forEach(params, (value, key) => {
        calculationParameters.push({
            name: key,
            value: value + ''
        });
    });
    return calculationParameters;
};

export const displayField = (
    param: CalcParamResource,
    value: string,
    guiStates: Array<GuiStatusResource>,
    numberFormat: string,
    renderField: (label: string, value: string, key?: string, unit?: string) => JSX.Element
) => {
    if (value == '') return <></>;
    const s = store.getState();

    const fieldState = guiStates?.find((x) => x.affectedObject === param.name);
    if (fieldState?.status === 'INVISIBLE') return <></>;

    if (param.type === 'boolean' || param.type === 'enumeration') {
        return renderField(wT(param.name, s), wT(value, s));
    }

    if (param.type === 'integer' || param.type === 'double') {
        value = formatValue(value, numberFormat);
    }

    return renderField(wT(param.name, s), value, null, wT((param as IntegerCalcParamResource).unit, s));
};

export const renderParameters = (
    parameters: ParameterArray,
    values: { [name: string]: string } | null,
    guiStates: Array<GuiStatusResource>,
    numberFormat: string,
    renderField: (label: string, value: string, key?: string, unit?: string) => JSX.Element
) => {
    const s = store.getState();

    const params = [...parameters];
    params.sort((a, b) => {
        if (a.sequence === b.sequence) {
            if (!('subSequence' in a) || !('subSequence' in b) || a.subSequence == undefined) return 1;
            return a.subSequence - b.subSequence;
        }
        return a.sequence - b.sequence;
    });

    //Group params per descriptor groups
    let groupedParams = groupBy(params, 'guiDescriptor');
    //remove excluded groupsCalcParamResource
    groupedParams = omitBy(groupedParams, (group, key) => EXCLUDED_EDITOR_FIELDS.includes(key));

    return map(groupedParams, (group, key) => {
        //if every member is a boolean member, render a multiple choice param
        // if (group.every((x) => x.type === 'boolean')) {
        //     const value = group
        //         .filter((param) => {
        //             const booleanValue = 'value' in param ? param.value : values[param.name];
        //             return booleanValue === 'true';
        //         })
        //         .map((param) => wT(param.name, s))
        //         .join(', ');

        //     return renderField(wT(key, s), value, key);
        // }

        if (key === COLOR_GUI_IDENTIFIER) {
            let colorSystem, colorValue;
            if (values) {
                colorSystem = values[COLOR_SYSTEMS_GUI];
                colorValue = values[colorSystem];
            } else {
                const colorSystemParam: any = group.find((param) => param.name === COLOR_SYSTEMS_GUI);
                colorSystem = colorSystemParam?.value;
                const colorValueParam: any = group.find((param) => param.name === colorSystem);
                colorValue = colorValueParam?.value;
            }

            return renderField(wT(key, s) + ' (' + wT(colorSystem, s) + ')', colorValue, key);
        }

        //else render all the params in a single group
        // return (
        //     <Fragment key={key}>
        //         {group.map((parameter, index) => {
        //             const parameterValue = 'value' in parameter ? parameter.value : values[parameter.name];
        //             return <Fragment key={key + index}>{displayField(parameter, parameterValue, guiStates, numberFormat, renderField)}</Fragment>;
        //         })}
        //     </Fragment>
        // );
    });
};

const getNumberOfCells = (guiDescriptor, parameters) => {
    if (guiDescriptor === COLOR_GUI_IDENTIFIER) return 1;
    if (parameters.every((x) => x.type === 'boolean')) return 1;
    return parameters.length;
};

export const getSections = (parameters: ParameterArray, sectionDefinitions: Array<SectionDefinitionResource>): Array<Section> => {
    const sectionSortInfos = {};
    (sectionDefinitions || []).forEach((definition) => {
        // sectionSortInfos[definition.id ?? definition.sectionDefinitionId] = definition.sequence;
    });
    const parametersClone = cloneDeep(parameters) || [];
    //@ts-ignore
    const calcTypeParam = parametersClone.find((param) => param.name === 'kalkulationsart');
    if (calcTypeParam) calcTypeParam.section = 'geometryPackageSection';

    const allParameters = sortBy(parametersClone, ['sequence']) as ParameterArray;
    let groupedParameters = groupBy(allParameters, 'guiDescriptor') as { [guiDescriptor: string]: ParameterArray };
    groupedParameters = omitBy(groupedParameters, (group, key) => EXCLUDED_EDITOR_FIELDS.includes(key));

    const sections: { [sectionId: string]: Section } = Object.entries(groupedParameters).reduce((accumulator, [guiDescriptor, parameters]) => {
        const id = parameters[0].section || 'general';

        if (accumulator[id]) {
            accumulator[id].groupedParameters.push(...parameters);
            accumulator[id].numberOfCells += getNumberOfCells(guiDescriptor, parameters);
        } else accumulator[id] = { id, groupedParameters: [...parameters], numberOfCells: getNumberOfCells(guiDescriptor, parameters) };
        return accumulator;
    }, {});

    const sortedEntries = Object.entries(sections).sort(([idA], [idB]) => {
        return sectionSortInfos[idA] - sectionSortInfos[idB];
    });
    const sortedObject = Object.fromEntries(sortedEntries);
    return Object.values(sortedObject);
};

export const getAllMessages = (costResult: CalculationResultResource) => {
    const messages = {};

    (costResult?.messages || []).forEach((message) => {
        messages[message.messageId] = message;
    });

    (costResult?.subCalculationResults || []).forEach((subCalcResult) => {
        (subCalcResult?.messages || []).forEach((message) => {
            messages[message.messageId] = message;
        });
    });

    return Object.values(messages) as Array<CalculationMessageResource>;
};

export const findGeneralSetting = (settingName: string, generalSettings: Array<GeneralSettingResource> = []) => {
    if (!settingName) return;
    return generalSettings.find((setting) => setting.name === settingName)?.value;
};

export const getGeneralSetting = (settingName: string, costResult: CalculationResultResource, partCostResult: CalculationResultResource = null) => {
    if (!settingName) return;
    if (partCostResult) {
        const generalSettingValue = findGeneralSetting(settingName, partCostResult.generalSettings);
        if (generalSettingValue) return generalSettingValue;
    }
    return findGeneralSetting(settingName, costResult?.generalSettings);
};

export const measureInputValueWidth = (input: HTMLInputElement): number => {
    const span = document.createElement('span');
    span.style.visibility = 'hidden';
    span.style.padding = window.getComputedStyle(input, null).getPropertyValue('padding');
    span.style.fontFamily = input.style.fontFamily;
    span.style.fontSize = input.style.fontSize;
    span.style.fontWeight = input.style.fontWeight;
    span.style.fontStyle = input.style.fontStyle;
    span.style.letterSpacing = input.style.letterSpacing;
    span.style.textTransform = input.style.textTransform;
    span.style.boxSizing = input.style.boxSizing;
    span.style.position = 'fixed';
    span.innerText = input.value;
    document.body.appendChild(span);
    const width = span.getBoundingClientRect().width + input.offsetLeft;
    document.body.removeChild(span);
    return width;
};

export const getVariable = (variableInternalIdentifier: string, variables: Array<VariableResource>) => {
    return (variables || []).find((variable) => variable.internalIdentifier === variableInternalIdentifier);
};

export const getVariableValue = (variable: VariableResource) => {
    if (!variable) return;
    return getValue(variable.value);
};

export const getVariableFromHolder = (holder: HolderResource, variables: Array<VariableResource>) => {
    if (!holder || holder.type !== HolderResource.type.INTERNAL_IDENTIFIER_HOLDER) return;
    return getVariable((holder as InternalIdentifierHolderResource).internalIdentifier, variables);
};

export const getHolderData = (holder: HolderResource, variables: Array<VariableResource>) => {
    if (!holder) return;
    if (holder.type === HolderResource.type.VALUE_HOLDER) {
        return getValue((holder as ValueHolderResource).value);
    }
    return getVariableName(getVariableFromHolder(holder, variables));
};

export const getValue = (value: ValueResource) => {
    if (!value) return;

    switch (value.type) {
        case ValueResource.type.STRING_VALUE:
            return (value as StringValueResource).stringValue;

        case ValueResource.type.NUMBER_VALUE:
            return (value as NumberValueResource).numberValue;

        case ValueResource.type.LIST_VALUE:
            return (value as ListValueResource).listValue;

        case ValueResource.type.BOOLEAN_VALUE:
            return (value as BooleanValueResource).booleanValue;
    }
};
export const getNumberValue = (value: ValueResource) => {
    return getValue(value) as number;
};
export const getStringValue = (value: ValueResource) => {
    return getValue(value) as string;
};
export const getBooleanValue = (value: ValueResource) => {
    return getValue(value) as boolean;
};
export const getListValue = (value: ValueResource) => {
    return getValue(value) as Array<string>;
};

export const getInputValue = (inputVariable: InputVariableResource) => {
    return getValue(inputVariable?.value) || getValue(inputVariable?.defaultValue);
};

export const renderImage = (url: string) => {
    if (!url) return <img alt="Missing Image" src="/static/images/missingImage/missingImage.svg" />;
    return <img src={url} />;
};

export const getText = (resource: any) => {
    return resource?.name || t(resource?.translationKey);
};
export const getVariableName = (variable: VariableResource) => {
    if (variable?.displayName != null) return variable?.displayName;
    if (variable?.translationKey) return t(variable?.translationKey);
    return variable?.internalIdentifier;
};

export const isCustomerUnset = (customer: CustomerResource) => {
    if (!customer) return true;
    return every(customer, isEmpty) && every(customer.address, isEmpty);
};
export const getFullName = (customer: CustomerResource) => {
    return `${customer?.firstName || ''} ${customer?.lastName || ''}`.trim();
};
export const getCustomerInfo = (customer: CustomerResource) => {
    return isCustomerUnset(customer) ? t('unspecifiedCustomer') : getCustomerText(customer) || t('unspecifiedCompany');
};
export const getCustomerText = (customer: CustomerResource) => {
    return customer?.companyName || getFullName(customer) || customer?.email;
};

export const isSpecialInputVariable = (variable: VariableResource, internalIdentifier: string) => {
    if (!isInputVariable(variable)) return false;
    const inputVariable = variable as InputVariableResource;
    return inputVariable.hasUiContract && inputVariable.internalIdentifier === internalIdentifier;
};

export const searchForSpecialInputVariable = (variables: Array<VariableResource>, internalIdentifier: string): InputVariableResource | null => {
    return (variables || []).find((variable) => isSpecialInputVariable(variable, internalIdentifier));
};

export const searchForProvidedVariable = (variables: Array<VariableResource>, internalIdentifier: string): ProvidedVariableResource | null => {
    return (variables || []).find((variable) => variable.type === VariableResource.type.PROVIDED_VARIABLE && variable.internalIdentifier === internalIdentifier);
};

export const getCalcRelevantVariableInternalIdentifiers = (computations: Array<ComputationResource>, variables: Array<VariableResource>) => {
    const identifiers = new Set<string>();
    (computations || []).forEach((computation) => {
        const term = getTerm(computation, variables);
        const FoundVariablesResult = listVariablesInTerm(term, variables);
        for (const internalIdentifier of FoundVariablesResult.keys()) {
            identifiers.add(internalIdentifier);
        }
    });
    return [...identifiers];
};
export const getInputVariablesGroupedBySection = (variables: Array<VariableResource>): InputVariablesGroupedBySection => {
    const sections: InputVariablesGroupedBySection = {};
    (variables || []).forEach((variable) => {
        if (!isInputVariable(variable)) return;
        const inputVariable = variable as InputVariableResource;
        if (inputVariable.hasUiContract) return;
        const section = inputVariable.section;
        if (!sections[section]) sections[section] = [];
        sections[section].push(variable);
    });
    return sections;
};

export const isHolderValid = (holder: HolderResource): boolean => {
    if (holder.type === HolderResource.type.INTERNAL_IDENTIFIER_HOLDER) {
        return !!(holder as InternalIdentifierHolderResource).internalIdentifier;
    }
    if (holder.type === HolderResource.type.VALUE_HOLDER) {
        return !!getValue((holder as ValueHolderResource).value);
    }
    return false;
};

export const isCriteriaValid = (criteria: Array<CriterionResource>): boolean => {
    const isInvalid = (criteria || []).some((criterion) => {
        return isHolderValid(criterion.left) || isHolderValid(criterion.right);
    });
    return !isInvalid;
};

export const getSpecificNodeId = (
    fileResource: FileResource
): {
    threeDFileId?: string;
    pdfFileId?: string;
    dwgFileId?: string;
    dxfFileId?: string;
} => {
    const fileExtension = getFileExtension(fileResource.name);

    if (ALLOWED_3D_FILE_TYPES.includes(fileExtension)) return { threeDFileId: fileResource.nodeId };
    if (!ALLOWED_GENERAL_FILE_TYPES.includes(fileExtension)) return {};
    if (fileExtension === '.dxf') return { dxfFileId: fileResource.nodeId };
    if (fileExtension === '.dwg') return { dwgFileId: fileResource.nodeId };
    if (fileExtension === '.pdf') return { pdfFileId: fileResource.nodeId };

    return {};
};

export const getFileExtension = (fileName: string) => {
    if (!fileName) return;
    return '.' + fileName.split('.').pop().toLowerCase();
};

export const createValue = (genericValue: number | string | boolean) => {
    const value = isString(genericValue)
        ? ({ type: ValueResource.type.STRING_VALUE, stringValue: genericValue } as StringValueResource)
        : isBoolean(genericValue)
        ? ({ type: ValueResource.type.BOOLEAN_VALUE, booleanValue: genericValue } as BooleanValueResource)
        : ({ type: ValueResource.type.NUMBER_VALUE, numberValue: genericValue } as NumberValueResource);
    return value;
};
export const createTermVariable = (term: TermResource, displayName?: string) => {
    return { type: VariableResource.type.TERM_VARIABLE, displayName, term } as TermVariableResource;
};
export const createValueHolder = (genericValue: number | string) => {
    return { type: HolderResource.type.VALUE_HOLDER, value: createValue(genericValue) } as ValueHolderResource;
};
export const createInternalIdentifierHolder = (variable: VariableResource) => {
    if (!variable?.internalIdentifier) return;
    return { type: HolderResource.type.INTERNAL_IDENTIFIER_HOLDER, internalIdentifier: variable.internalIdentifier } as InternalIdentifierHolderResource;
};
export const getTerm = (computation: ComputationResource, variables: Array<VariableResource>) => {
    if (!computation) return;
    return (getVariable(computation.resultVariableInternalIdentifier, variables) as TermVariableResource)?.term;
};
export const getComputationValue = (computation: ComputationResource, variables: Array<VariableResource>) => {
    if (!computation) return;
    const value = (getVariable(computation.resultVariableInternalIdentifier, variables) as TermVariableResource)?.value;
    return getNumberValue(value);
};

export const getInputVariableTypes = () => {
    return Object.values(VariableResource.type).filter((type) => type.endsWith('INPUT_VARIABLE'));
};

export const isInputVariable = (variable: VariableResource) => {
    return getInputVariableTypes().includes(variable?.type);
};

export const createNumberValidator = (variable: NumberInputVariableResource) => {
    return yup
        .number()
        .typeError(t('inputMustBeANumberAndCanNotBeEmpty'))
        .min(variable.min, t('numberHasToBeGreaterThan', { minValue: variable.min }))
        .max(variable.max, t('numberHasToBeSmallerThan', { maxValue: variable.max }))
        .when('naturalNumber', {
            is: true,
            then: (schema) => schema.integer(t('numberMusBeAnInteger'))
        });
};
