import { Typography, Stack, Button, styled, useTheme, InputAdornment, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import Accordion from 'src/components/display/Accordion/Accordion';
import { useEffect, useMemo, useRef, useState } from 'react';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import PriceFinderDialog from 'src/components/price-finder/PriceFinderDialog';
import { updateBlueprint, BlueprintUpdateTypes } from 'src/redux/shared/calcEditor.common';
import { useDispatch } from 'react-redux';
import { cloneDeep, isEqual, get, set } from 'lodash';
import { getTermAsString } from 'src/utils/TermHelper';
import HandymanTwoToneIcon from '@mui/icons-material/HandymanTwoTone';
import CalcBuilderDialog from '../../CalcBuilder/CalcBuilderDialog';
import { ComputationResource, ComputationTemplateResource, RuleBasedVariableResource, RuleResource, TermResource, TermVariableResource, VariableResource } from 'src/backend/coreCalc';
import { getTerm, getVariable, getVariableName } from 'src/utils/CalcHelpers';
import SummarizedRuleInput from 'src/components/input/RuleInputs/SummarizedRuleInput';

const StyledSelect = styled(Select)(
    ({ theme }) => `
        flex-grow: 1;
        width: 15rem;
        color: ${theme.colors.alpha.white[100]};
        background-color: ${theme.colors.primary.main};

        .MuiSelect-select.MuiOutlinedInput-input {
            display: flex;
            align-items: center;
            padding-top: 0;
            padding-bottom: 0;
            min-height: 3.4rem;
        }
        .MuiSvgIcon-root {
            color: inherit;
        }
        .MuiOutlinedInput-notchedOutline {
            border: none;
        }
    `
);

interface Props {
    categoryId?: number;
    variables?: Array<VariableResource>;
    calcRelevantVariableInternalIdentifiers?: Array<string>;
    isSelected?: boolean;
    computation: ComputationResource;
    computationTemplate: ComputationTemplateResource;
    openAccordionId: string;
    setOpenAccordionId: (newId: string) => void;
    saveVariable: (variable: VariableResource) => void;
    createVariable: (variable: VariableResource) => void;
    removeVariable: (variableId: number) => void;
}

const ComputationItem: React.FC<Props> = ({
    categoryId,
    variables,
    calcRelevantVariableInternalIdentifiers,
    isSelected,
    computation,
    computationTemplate,
    openAccordionId,
    setOpenAccordionId,
    createVariable,
    saveVariable,
    removeVariable
}) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [isPriceFinderOpen, setPriceFinderOpen] = useState<boolean>(false);
    const [isCalcBuilderOpen, setCalcBuilderOpen] = useState<boolean>(false);
    const [priceVariable, setPriceVariable] = useState<RuleBasedVariableResource>();
    const priceVariableRef = useRef(priceVariable);

    const pathToReferenceVariable = 'a.a.a.a.flexibleData.internalIdentifier';
    const pathToPriceVariable = 'a.a.a.b.flexibleData.internalIdentifier';

    const computationResultVariable = useMemo(() => getVariable(computation.resultVariableInternalIdentifier, variables), [computation, variables]);
    const term = (computationResultVariable as TermVariableResource)?.term;
    const computationTemplateTerm = (computationTemplate?.result as TermVariableResource)?.term;

    const referenceVariables = useMemo(() => {
        if (!computationTemplate) return [];
        return (variables || []).filter((variable) => computationTemplate.allowedReferenceVariableInternalIdentifiers.includes(variable.internalIdentifier));
    }, [variables, computationTemplate]);

    const referenceVariable = useMemo(() => {
        const variableInternalIdentifier = get(term, pathToReferenceVariable);
        return getVariable(variableInternalIdentifier, referenceVariables);
    }, [computation, variables]);

    const isEasyMode = useMemo(() => {
        if (!computation?.sourceTemplateId) return false;
        if (!priceVariable || !referenceVariable || !computationTemplateTerm) return false;

        // Check if variableInternalIdentifier is in referenceVariableInternalIdentifiers
        if (!computationTemplate.allowedReferenceVariableInternalIdentifiers.includes(referenceVariable.internalIdentifier)) {
            return false;
        }
        const templateTerm = cloneDeep(computationTemplateTerm);

        // Set the variableInternalIdentifier of the template term to match term's variable identifier name for comparison
        set(templateTerm, pathToReferenceVariable, referenceVariable.internalIdentifier);

        return isEqual(term, templateTerm);
    }, [priceVariable, referenceVariable, computation, term, computationTemplate]);

    const getVariableDescription = (variable: VariableResource) => {
        if (variable.type === VariableResource.type.TERM_VARIABLE) {
            return getTermAsString((variable as TermVariableResource).term, variables, false);
        }
        return variable.type.toString();
    };

    const selectComputation = () => {
        dispatch(
            updateBlueprint(BlueprintUpdateTypes.CATEGORY_COMPUTATION_SELECTION, {
                categoryId,
                computationId: computation.id
            })
        );
    };

    const saveComputation = (name: string, term: TermResource) => {
        setCalcBuilderOpen(false);
        if (name !== computation.name) {
            dispatch(
                updateBlueprint(BlueprintUpdateTypes.CATEGORY_COMPUTATION, {
                    categoryId,
                    computation: { ...computation, name }
                })
            );
        }

        dispatch(
            updateBlueprint(BlueprintUpdateTypes.CATEGORY_VARIABLE, {
                categoryId,
                variable: { ...computationResultVariable, term } as TermVariableResource
            })
        );
    };

    const renderPriceFinderHelper = () => {
        return (
            <>
                <Button
                    color="secondary"
                    sx={{
                        textAlign: 'left',
                        p: 0.4,
                        fontSize: 12,
                        lineHeight: 1.2,
                        fontWeight: 500,
                        '.MuiButton-startIcon': { mr: 0.4 }
                    }}
                    startIcon={<HelpTwoToneIcon sx={{ fontSize: '18px !important' }} />}
                    onClick={() => setPriceFinderOpen(true)}
                >
                    Für Hilfe bei der m²-Preisfindung, hier klicken
                </Button>
                <PriceFinderDialog isOpen={isPriceFinderOpen} setOpen={setPriceFinderOpen} />
            </>
        );
    };
    const handleChange = (event: SelectChangeEvent<any>) => {
        if (!isEasyMode) return;
        const clonedTerm = cloneDeep(term);
        set(clonedTerm, pathToReferenceVariable, event.target.value);

        dispatch(
            updateBlueprint(BlueprintUpdateTypes.CATEGORY_VARIABLE, {
                categoryId,
                variable: { ...computationResultVariable, term: clonedTerm } as TermVariableResource
            })
        );
    };

    const isDynamicPrice = priceVariable?.rules?.length > 1;

    const handlePriceChange = (rules: Array<RuleResource>) => {
        setPriceVariable({ ...priceVariable, rules });
    };

    const description = getTermAsString(isEasyMode ? term['a']?.['a']?.['a'] : term, variables, false);

    useEffect(() => {
        if (priceVariable) return;
        const variableInternalIdentifier = get(term, pathToPriceVariable);
        setPriceVariable(getVariable(variableInternalIdentifier, variables));
    }, [term]);

    useEffect(() => {
        priceVariableRef.current = priceVariable;
    }, [priceVariable]);

    const savePriceVariable = () => {
        const currentPriceVariable = priceVariableRef.current;
        if (!currentPriceVariable?.internalIdentifier) return;
        const savedPriceVariable = getVariable(currentPriceVariable.internalIdentifier, variables);
        if (isEqual(currentPriceVariable, savedPriceVariable)) return;
        saveVariable(currentPriceVariable);
    };

    useEffect(() => {
        return () => {
            savePriceVariable();
        };
    }, []);

    useEffect(() => {
        if (openAccordionId !== computation.id + '') {
            savePriceVariable();
        }
    }, [openAccordionId, computation.id]);

    return (
        <Accordion
            accordionHeader={
                <Stack direction="row" alignItems="center" justifyContent="space-between" flexGrow={1}>
                    <Stack alignItems="flex-start">
                        <Typography variant="h4" lineHeight={1.1}>
                            {computation.name}
                        </Typography>
                        <Typography variant="subtitle2" lineHeight={1.2}>
                            {description}
                        </Typography>
                    </Stack>
                </Stack>
            }
            isSelected={isSelected}
            onSelection={selectComputation}
            accordionId={computation.id + ''}
            openAccordionId={openAccordionId}
            setOpenAccordionId={setOpenAccordionId}
            isLoading={computation.id === -1}
            hideFirstBorder={true}
        >
            <Stack px="24px" py="16px">
                {isEasyMode ? (
                    <Stack direction="row" alignItems="flex-start" gap={3}>
                        <StyledSelect value={referenceVariable?.internalIdentifier ?? ''} onChange={handleChange}>
                            {referenceVariables.map((variable) => (
                                <MenuItem key={variable.id} value={variable.internalIdentifier}>
                                    <Stack alignItems="flex-start" overflow="hidden">
                                        <Typography fontSize={14} fontWeight={700} lineHeight={1.3}>
                                            {getVariableName(variable)}
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={12} lineHeight={1.47} color="inherit" sx={{ opacity: 0.9, maxWidth: '100%' }} noWrap>
                                            {getVariableDescription(variable) || '-'}
                                        </Typography>
                                    </Stack>
                                </MenuItem>
                            ))}
                        </StyledSelect>
                        <Typography color="primary" sx={{ fontSize: 74, fontWeight: 200, mt: '-32px' }}>
                            ×
                        </Typography>
                        <SummarizedRuleInput
                            rules={priceVariable.rules}
                            startAdornment={
                                isDynamicPrice ? null : (
                                    <InputAdornment position="start" sx={{ pl: 0.8 }}>
                                        €
                                    </InputAdornment>
                                )
                            }
                            variables={variables}
                            inputLabel={getVariableName(priceVariable)}
                            dialogTitle={'Dynamischer Preis'}
                            dialogSubtitle={
                                'Wähle aus, wie der Preis für diese Kategorie berechnet werden soll. Wenn keine der vordefinierten Kalkulationen für dich geeignet ist, ' +
                                'kannst du deine eigene Kalkulation erstellen.'
                            }
                            setRules={handlePriceChange}
                            helperText={renderPriceFinderHelper()}
                        />
                        <Typography color="primary" sx={{ fontSize: 74, fontWeight: 200, mt: '-32px' }}>
                            =
                        </Typography>
                        <Typography color="primary" sx={{ fontSize: 47, fontWeight: 400, mt: '-6px' }}>
                            Preis
                        </Typography>
                    </Stack>
                ) : (
                    <Typography color="primary" fontWeight={600} fontSize={22} mt={-1} mb={-1}>
                        Preis = {getTermAsString(term, variables)}
                    </Typography>
                )}
                <Stack mt={2}>
                    <Button color="secondary" sx={{ mr: 'auto', ml: -1.5, mb: -0.5 }} startIcon={<HandymanTwoToneIcon />} onClick={() => setCalcBuilderOpen(true)}>
                        Kalkulation anpassen
                    </Button>

                    {isCalcBuilderOpen && (
                        <CalcBuilderDialog
                            isOpen={isCalcBuilderOpen}
                            setOpen={setCalcBuilderOpen}
                            variables={variables}
                            calcRelevantVariableInternalIdentifiers={calcRelevantVariableInternalIdentifiers}
                            name={computation.name}
                            term={term}
                            onSave={saveComputation}
                            createVariable={createVariable}
                            saveVariable={saveVariable}
                            removeVariable={removeVariable}
                        />
                    )}
                </Stack>
            </Stack>
        </Accordion>
    );
};

export default ComputationItem;
