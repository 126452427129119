import { useTheme, TextField, Typography, Switch, Stack, InputAdornment, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InputVariableResource, NumberInputVariableResource, VariableResource } from 'src/backend/coreCalc';
import { createNumberValidator, createValue, getNumberValue, getStringValue, getText } from 'src/utils/CalcHelpers';
import { isNumber } from 'lodash';
import { formatUnit } from 'src/utils/FormatHelpers';

interface Props {
    variable: NumberInputVariableResource;
    variables: Array<VariableResource>;
    setVariable: (variable: NumberInputVariableResource) => void;
    setValid: (isValid: boolean) => void;
    isSmall?: boolean;
    isDisabled: boolean;
}

const NumberInputVariableForm: React.FC<Props> = ({ variable, variables, setVariable, setValid, isSmall, isDisabled }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();
    const [error, setError] = useState<string>('');
    const [defaultValue, setDefaultValue] = useState<string>('');

    const numberSchema = useMemo(() => createNumberValidator(variable), [variable.min, variable.max, variable.naturalNumber]);

    const handleMinChange = (event) => {
        setVariable({
            ...variable,
            min: isNaN(event.target.value) ? null : parseInt(event.target.value)
        });
    };

    const handleMaxChange = (event) => {
        setVariable({
            ...variable,
            max: isNaN(event.target.value) ? null : parseInt(event.target.value)
        });
    };

    const handleNaturalNumberChange = (event) => {
        setVariable({
            ...variable,
            naturalNumber: event.target.checked
        });
    };

    const handleDefaultChange = async (event) => {
        const newValue = event.target.value;
        setDefaultValue(newValue);
        try {
            await numberSchema.validate(newValue);
            setVariable({
                ...variable,
                defaultValue: createValue(parseFloat(newValue))
            });
            setError('');
        } catch (error) {
            setError(error.message);
        }
    };
    const handleUnitChange = async (event) => {
        setVariable({
            ...variable,
            unit: event.target.value
        });
    };

    useEffect(() => {
        setDefaultValue(getNumberValue(variable.defaultValue) + '');
    }, []);

    return (
        variable && (
            <Stack gap={3} pt={4}>
                <FormControl fullWidth sx={{ mt: 4 }}>
                    <InputLabel id="select-default-value" size="small">
                        {t('unitSelection')}
                    </InputLabel>
                    <Select labelId="select-default-value" label={t('unitSelection')} value={variable.unit || ''} onChange={handleUnitChange} size="small" disabled={isDisabled}>
                        {Object.values(NumberInputVariableResource.unit).map((option, index) => (
                            <MenuItem key={index} value={option}>
                                {t(option)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField type="number" label={t('minValue')} value={variable.min} size="small" disabled={isDisabled} onChange={handleMinChange} fullWidth />
                <TextField type="number" label={t('maxValue')} value={variable.max} size="small" disabled={isDisabled} onChange={handleMaxChange} fullWidth />

                <Stack>
                    <Typography variant="subtitle2" gutterBottom>
                        {t('isNaturalNumber')}
                    </Typography>
                    <Switch sx={{ ml: -1.1 }} checked={variable.naturalNumber} onChange={handleNaturalNumberChange} disabled={isDisabled} />
                </Stack>

                <TextField
                    type="number"
                    label={t('defaultNumberValue')}
                    value={defaultValue}
                    size="small"
                    disabled={isDisabled}
                    onChange={handleDefaultChange}
                    fullWidth
                    error={!!error}
                    helperText={error}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{formatUnit(variable.unit)}</InputAdornment>
                    }}
                    inputProps={{
                        min: variable.min,
                        max: variable.max
                    }}
                />
            </Stack>
        )
    );
};

export default NumberInputVariableForm;
