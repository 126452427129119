import { Button, Stack, styled, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ShortCategoryResource } from 'src/backend/coreCalc';
import MuiIcon from 'src/components/display/MuiIcon/MuiIcon';
import store, { RootState } from 'src/redux/store';
import { getText, renderImage } from 'src/utils/CalcHelpers';
import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone';

const Category = styled(Button)<{ selected?: boolean }>(
    ({ theme, selected }) => `
        position: relative;
        flex-direction: row;
        align-items: center;
        height: 2.5rem;
        gap: ${theme.spacing(0.6)};
        padding: ${theme.spacing(0.8)};
        margin-bottom: ${theme.spacing(0.8)};
        color: ${selected ? theme.colors.primary.main : 'unset'};
        border-radius: ${selected ? 0 : theme.shape.borderRadius}px;
        margin-right: ${theme.spacing(2)};

        .MuiTypography-root {
            font-weight: ${selected ? '700' : '500'};
        }

        img {
            height: 100%;
            margin-right: ${theme.spacing(1)};
            object-fit: contain;
            filter: ${selected ? 'invert(29%) sepia(100%) saturate(1755%) hue-rotate(208deg) brightness(102%) contrast(101%)' : 'none'};
        }

        ${
            selected &&
            `
            &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: -1px;
                background: ${theme.colors.primary.main};
                height: 1px;
                width: 100%;
            }
        `
        }
    `
);

interface Props {
    categories: Array<ShortCategoryResource>;
    selectedCategory?: ShortCategoryResource;
    setSelectedCategory?: (category: ShortCategoryResource) => void;
    isFragment?: boolean;
}

const CategoryArea: React.FC<Props> = ({ categories, selectedCategory, setSelectedCategory, isFragment }) => {
    const { t } = useTranslation();
    const s = store.getState();

    const filteredCategories = useMemo(() => {
        return categories.filter((category) => category.subCategories?.length > 0);
    }, [categories]);

    return (
        <Stack direction="row" flexWrap="wrap" p={2} pb={1.2} id={'categoryAreaList'}>
            <Category onClick={() => setSelectedCategory(null)} selected={!selectedCategory} variant="text" color="secondary">
                <CategoryTwoToneIcon />
                <Typography variant="h5">{'Alle Teile'}</Typography>
            </Category>
            {filteredCategories.map((category) => (
                <Category key={category.id} onClick={() => setSelectedCategory(category)} selected={selectedCategory === category} variant="text" color="secondary">
                    <MuiIcon icon={category.icon} />
                    <Typography variant="h5">{getText(category)}</Typography>
                </Category>
            ))}
        </Stack>
    );
};
export default CategoryArea;
