/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type HolderResource = {
    type?: HolderResource.type;
}

export namespace HolderResource {

    export enum type {
        VALUE_HOLDER = 'VALUE_HOLDER',
        INTERNAL_IDENTIFIER_HOLDER = 'INTERNAL_IDENTIFIER_HOLDER',
    }


}
