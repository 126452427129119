/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BoughtModulesResource } from '../models/BoughtModulesResource';
import type { BuyModuleDto } from '../models/BuyModuleDto';
import type { MaxUserCountDto } from '../models/MaxUserCountDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ModuleRestControllerService {

    /**
     * @param userId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static setMaxUserCount(
        userId: number,
        requestBody: MaxUserCountDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/modules/${userId}/maxUserCount`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static setMaxRegularCustomerCount(
        userId: number,
        requestBody: MaxUserCountDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/modules/${userId}/maxRegularCustomerCount`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @returns any OK
     * @throws ApiError
     */
    public static bookRegularCustomerModule(
        userId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/marketplace/modules/${userId}/regularCustomerModule`,
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static buyRegularCustomerModule(
        userId: number,
        requestBody: BuyModuleDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/marketplace/modules/${userId}/regularCustomerModule/bought`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @returns any OK
     * @throws ApiError
     */
    public static bookPortalAccess(
        userId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/marketplace/modules/${userId}/portalAccess`,
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static activateDeactivateDemoAccount(
        userId: number,
        requestBody: BuyModuleDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/marketplace/modules/${userId}/portalAccess/bought`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @returns any OK
     * @throws ApiError
     */
    public static bookInternalCalcModule(
        userId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/marketplace/modules/${userId}/internalCalcModule`,
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static buyInternalCalcModule(
        userId: number,
        requestBody: BuyModuleDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/marketplace/modules/${userId}/internalCalcModule/bought`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @returns BoughtModulesResource OK
     * @throws ApiError
     */
    public static getBoughtModules(
        userId: number,
    ): CancelablePromise<BoughtModulesResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/modules/${userId}`,
        });
    }

}