import { Typography, Stack, useTheme, TextField, InputAdornment, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CriterionResource, RuleResource, SurchargeVariableResource, VariableResource } from 'src/backend/coreCalc';
import { createValueHolder, getVariableName } from 'src/utils/CalcHelpers';
import CriteriaInput from 'src/components/input/CriteriaInput/CriteriaInput';
import SummarizedRuleInput from 'src/components/input/RuleInputs/SummarizedRuleInput';

interface Props {
    variable: SurchargeVariableResource;
    variables: Array<VariableResource>;
    setVariable: (variable: SurchargeVariableResource) => void;
    setValid: (isValid: boolean) => void;
    isSmall?: boolean;
    isDisabled: boolean;
}

const SurchargeVariableForm: React.FC<Props> = ({ variable, variables, setVariable, setValid, isSmall, isDisabled }) => {
    const dispatch = useDispatch();
    const theme = useTheme();

    useEffect(() => {
        if (!variable?.setValueRules || variable.setValueRules.length < 1)
            setVariable({
                ...variable,
                setValueRules: [{ isDefault: true, valueHolder: createValueHolder(0) }],
                criteriaWhenSurchargeApplies: []
            });
    }, []);

    useEffect(() => {
        if (!variable) return setValid(false);
        const isInvalid = getVariableName(variable) == null || getVariableName(variable).trim() === '' || variable.setValueRules == null || variable.setValueRules.length < 1;
        setValid(!isInvalid);
    }, [variable]);

    const isDynamicSurcharge = variable?.setValueRules?.length > 1;

    const handleNameChange = (event) => {
        setVariable({
            ...variable,
            displayName: event.target.value
        });
    };
    const handleSurchargeTypeChange = (event) => {
        setVariable({
            ...variable,
            isAbsoluteSurcharge: event.target.value === 'true'
        });
    };
    const handleSurchargeRuleValuesChange = (rules: Array<RuleResource>) => {
        setVariable({
            ...variable,
            setValueRules: rules
        });
    };

    const handleSurchargeCriteriaChange = (criteria: Array<CriterionResource>) => {
        setVariable({
            ...variable,
            criteriaWhenSurchargeApplies: criteria
        });
    };

    return (
        variable && (
            <Stack>
                <Stack direction="row" gap={3}>
                    <TextField label="Name" value={getVariableName(variable)} onChange={handleNameChange} variant="outlined" fullWidth size={isSmall ? 'small' : 'medium'} disabled={isDisabled} />
                    <FormControl fullWidth>
                        <InputLabel id="surcharge-type">Aufschlagsart</InputLabel>
                        <Select
                            labelId="surcharge-type"
                            label="Aufschlagsart"
                            value={variable.isAbsoluteSurcharge ? 'true' : 'false'}
                            onChange={handleSurchargeTypeChange}
                            size={isSmall ? 'small' : 'medium'}
                            disabled={isDisabled}
                        >
                            <MenuItem value="false">Relativer Aufschlag (%)</MenuItem>
                            <MenuItem value="true">Absoluter Aufschlag (€)</MenuItem>
                        </Select>
                    </FormControl>
                    <SummarizedRuleInput
                        rules={variable.setValueRules}
                        startAdornment={
                            isDynamicSurcharge ? null : (
                                <InputAdornment position="start" sx={{ pl: 0.8 }}>
                                    {variable.isAbsoluteSurcharge ? '€' : '%'}
                                </InputAdornment>
                            )
                        }
                        variables={variables}
                        isDisabled={isDisabled}
                        inputLabel={'Aufschlagswert'}
                        dialogTitle={'Dynamischer Aufschlagswert'}
                        dialogSubtitle={
                            'Wähle aus, wie der Preis für diese Kategorie berechnet werden soll. Wenn keine der vordefinierten Kalkulationen für dich geeignet ist, ' +
                            'kannst du deine eigene Kalkulation erstellen.'
                        }
                        setRules={handleSurchargeRuleValuesChange}
                        isSmall={isSmall}
                        sx={{ width: '100%' }}
                    />
                </Stack>
                <Typography variant="h4" sx={{ fontSize: 17, mt: 4, mb: 0.5, opacity: 0.7 }} gutterBottom>
                    Wann soll der Wert angewandt werden?
                </Typography>
                <Typography sx={{ fontSize: 14, lineHeight: 1.3, mb: 2 }} variant="subtitle1">
                    Wähle aus, wie der Wert für diese Kategorie berechnet werden soll. Wenn keine der vordefinierten Kalkulationen für dich geeignet ist, kannst du deine eigene Kalkulation erstellen.
                </Typography>
                <CriteriaInput criteria={variable.criteriaWhenSurchargeApplies} variables={variables} setCriteria={handleSurchargeCriteriaChange} />
            </Stack>
        )
    );
};

export default SurchargeVariableForm;
