import { Typography, useTheme, Box, Dialog, DialogContent, IconButton, DialogTitle } from '@mui/material';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import TemplateSelectionItem from 'src/components/display/TemplateSelectionItem/TemplateSelectionItem';
import { RootState } from 'src/redux/store';
import { connect } from 'react-redux';
import CreateVariableDialog from './CreateVariableDialog';
import { VariableResource, VariableTypeTemplateResource } from 'src/backend/coreCalc';

interface Props {
    variables: Array<VariableResource>;
    variableTypeTemplates: Array<VariableTypeTemplateResource>;
    isOpen: boolean;
    setOpen?: (isOpen: boolean) => void;
    createVariable?: (variable: VariableResource) => void;
}

const CreateVariableOverviewDialog: React.FC<Props> = ({ variables, variableTypeTemplates, isOpen, setOpen, createVariable }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [selectedTemplated, setSelectedTemplated] = useState<VariableTypeTemplateResource>();

    return (
        <>
            <Dialog open={isOpen} maxWidth="md" onClose={() => setOpen(false)}>
                <DialogTitle sx={{ pt: 3.1, pb: 2.7 }}>
                    <Typography variant="h4">Variable erstellen</Typography>
                    <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 16, top: 16, color: (theme) => theme.palette.grey[500] }}>
                        <CloseTwoToneIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
                        {(variableTypeTemplates || []).map((template) => (
                            <Fragment key={template.name}>
                                <TemplateSelectionItem
                                    onClick={() => {
                                        setSelectedTemplated(template);
                                        setOpen(false);
                                    }}
                                    template={template}
                                />
                            </Fragment>
                        ))}
                    </Box>
                </DialogContent>
            </Dialog>
            {selectedTemplated && (
                <CreateVariableDialog template={selectedTemplated} isOpen={!!selectedTemplated} setOpen={() => setSelectedTemplated(null)} createVariable={createVariable} variables={variables} />
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        variableTypeTemplates: state.templates.collection?.variableTypeTemplates
    };
};
export default connect(mapStateToProps)(CreateVariableOverviewDialog);
