import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InternalCalculationFilterBoundariesResource, InternalCalculationStatusResource } from 'src/backend/internalCalc';
import { BoughtModulesResource, CustomerTypeResource, RegularCustomerFilterBoundariesResource } from '../../backend/market';

export interface IConstantsReducer {
    //TYPES AND STATES
    customerTypes: CustomerTypeResource[];
    internalCalculationStates: InternalCalculationStatusResource[];
    //FILTER BOUNDARIES
    customerFilterBoundaries: RegularCustomerFilterBoundariesResource | undefined;
    internalCalculationBoundaries: InternalCalculationFilterBoundariesResource | undefined;
    //OTHERS
    wizardTranslations: { [key: string]: string } | undefined;
    moduleManagement: BoughtModulesResource | undefined;
}

const initialState: IConstantsReducer = {
    customerTypes: [],
    internalCalculationStates: [],

    customerFilterBoundaries: undefined,
    internalCalculationBoundaries: undefined,

    moduleManagement: undefined,
    wizardTranslations: undefined
};

const reducers = {
    //STATES
    setCustomerTypes: (state: IConstantsReducer, action: PayloadAction<CustomerTypeResource[]>) => {
        state.customerTypes = action.payload;
    },
    setInternalCalculationStates: (state: IConstantsReducer, action: PayloadAction<InternalCalculationStatusResource[]>) => {
        state.internalCalculationStates = action.payload;
    },
    //BOUNDARIES
    setInternalCalculationBoundaries: (state: IConstantsReducer, action: PayloadAction<InternalCalculationFilterBoundariesResource>) => {
        state.internalCalculationBoundaries = action.payload;
    },
    setCustomerFilterBoundaries: (state: IConstantsReducer, action: PayloadAction<RegularCustomerFilterBoundariesResource>) => {
        state.customerFilterBoundaries = action.payload;
    },
    //OTHERS
    setModuleManagement: (state: IConstantsReducer, action: PayloadAction<BoughtModulesResource>) => {
        state.moduleManagement = action.payload;
    },
    setWizardTranslations: (state: IConstantsReducer, action: PayloadAction<{ [key: string]: string }>) => {
        state.wizardTranslations = action.payload;
    }
};

export const slice = createSlice({
    name: 'constants',
    initialState,
    reducers: reducers
});
export const reducer = slice.reducer;
