import { Typography, Card, Stack, Divider } from '@mui/material';
import MasterCalcButtons from './MasterCalcButtons';
import { getTermAsString } from 'src/utils/TermHelper';
import { RootState } from 'src/redux/store';
import { connect } from 'react-redux';
import { BlueprintResource } from 'src/backend/coreCalc';
import { getTerm } from 'src/utils/CalcHelpers';
import { useMemo } from 'react';

interface Props {
    blueprint: BlueprintResource;
}

const MasterCalc: React.FC<Props> = ({ blueprint }) => {
    const masterTerm = useMemo(() => getTerm(blueprint.masterComputation, blueprint.masterVariables), [blueprint.masterComputation, blueprint.masterVariables]);
    return (
        <>
            <Typography mt={4} color="secondary" fontSize={20} fontWeight="bold">
                Gesamtpreis
            </Typography>
            <Typography mb={2} color="secondary" fontSize={14}>
                Hier kannst du Parameter und Berechnungen anlegen, die den Gesamtpreis beinflussen. Zum Beispiel kannst du für 'Express'-Lieferungen eine 10%ige Preiserhöhung festlegen im Vergleich
                zur 'Standard'-Option, bei der der Preis unverändert bleibt.
            </Typography>
            <Card sx={{ mb: 2 }}>
                <Stack p={1} px={2} gap={2} direction="row" alignItems="center">
                    <Stack mr="auto" flexGrow={1}>
                        <Typography color="secondary" fontSize={14} fontWeight={600}>
                            Wie stellt sich der aktuelle Gesamtpreis zusammen?
                        </Typography>
                        <Typography color="primary" fontSize={28} fontWeight={600} lineHeight={1.4}>
                            {getTermAsString(masterTerm, blueprint.masterVariables, false)}
                        </Typography>
                    </Stack>
                    <Typography variant="subtitle2" fontSize={14}>
                        Aktuelles Beispiel: unkalkulierbar
                    </Typography>
                    <Divider flexItem orientation="vertical" sx={{ my: -2, mr: 'auto' }} />
                    <MasterCalcButtons blueprint={blueprint} />
                </Stack>
            </Card>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        blueprint: state.calcEditor.blueprint
    };
};
export default connect(mapStateToProps)(MasterCalc);
