import { Avatar, Button, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Popover, Stack, styled, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useRef, useState } from 'react';
import { t } from 'i18next';
import { isString } from 'lodash';
import DocumentScannerTwoToneIcon from '@mui/icons-material/DocumentScannerTwoTone';
import { snackContext, useDispatch } from 'src/redux/store';
import { CategoryResource, ValueResource } from 'src/backend/coreCalc';
import DragAndDropFileInput from 'src/components/input/DragAndDropFileInput';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import { ALLOWED_3D_FILE_TYPES, ALLOWED_GENERAL_FILE_TYPES } from 'src/statics/statics';
import { BlueprintUpdateTypes, updateBlueprint } from 'src/redux/shared/calcEditor.common';
import { addFile, addFileWithThumbnail } from 'src/redux/thunks/fileManager.thunk';
import { FileResource } from 'src/backend/market';
import { getFileExtension, getSpecificNodeId, getVariable } from 'src/utils/CalcHelpers';
import { ModelStats } from '@surface-solutions/ssa-3d-viewer/dist/context/ViewerContext';
import { SSA3DViewer } from '@surface-solutions/ssa-3d-viewer';
import OpenFile from 'src/components/display/OpenFile/OpenFile';
import { getHolesAndThreads } from 'src/redux/thunks/proCalc.thunk';

const StyledListItemText = styled(ListItemText)(
    ({ theme }) => `
        padding-right: ${theme.spacing(4)};
        
        .MuiTypography-root {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    `
);

interface Props {
    category: CategoryResource;
    saveVariableValue: (variableId: number, value: ValueResource) => void;
    isSmall?: boolean;
}

const TestFileExtraction: React.FC<Props> = ({ category, saveVariableValue, isSmall }) => {
    const ref = useRef<any>(null);
    const dispatch = useDispatch();
    const theme = useTheme();
    const [isOpen, setOpen] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [tmp3dFile, setTmp3dFile] = useState<File>();
    const allowedFileTypes = [...ALLOWED_GENERAL_FILE_TYPES, ...ALLOWED_3D_FILE_TYPES];

    const isDefaultCategory = category.defaultCategory;

    const handleFileUpload = async (fileList: FileList) => {
        const files = Array.from(fileList);
        if (!files) return;

        const file = files[0];
        const fileExtension = getFileExtension(file.name);

        if (!allowedFileTypes.includes(fileExtension)) {
            return snackContext.enqueueSnackbar(t('fileHasInvalidFileExtension'), { variant: 'error' });
        }
        setLoading(true);

        if (ALLOWED_GENERAL_FILE_TYPES.includes(fileExtension)) {
            const fileResource: FileResource = await dispatch(addFile(file, file.name));
            const nodeIds = getSpecificNodeId(fileResource);
            dispatch(updateBlueprint(BlueprintUpdateTypes.CATEGORY, { id: category.id, ...nodeIds, dataSourceOriginalFileName: file.name }));
            setLoading(false);
            snackContext.enqueueSnackbar(t('noDataWasExtracted'), { variant: 'warning' });
        } else if (ALLOWED_3D_FILE_TYPES.includes(fileExtension)) {
            setTmp3dFile(file);
        }
    };
    const handleRemoveFile = () => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.CATEGORY, { id: category.id, threeDFileId: '', pdfFileId: '', dwgFileId: '', dxfFileId: '', dataSourceOriginalFileName: '' }));
    };

    const updateVariableValue = (baseValue: number | string, extractedVariableIdentifier: string, inputVariableIdentifier?: string) => {
        const value = isString(baseValue) ? { type: ValueResource.type.STRING_VALUE, stringValue: baseValue } : { type: ValueResource.type.NUMBER_VALUE, numberValue: Math.round(baseValue) };
        const variable = getVariable(extractedVariableIdentifier, category.variables);
        if (variable) {
            saveVariableValue(variable.id, value);
        }
        if (inputVariableIdentifier) {
            const inputVariable = getVariable(inputVariableIdentifier, category.variables);
            if (!inputVariable) return;
            saveVariableValue(inputVariable.id, value);
        }
    };

    const handleOnViewerLoader = async (modelStats: ModelStats) => {
        const fileResource: FileResource = await dispatch(addFileWithThumbnail(tmp3dFile, tmp3dFile.name, modelStats.screenshot));
        const nodeIds = getSpecificNodeId(fileResource);
        dispatch(updateBlueprint(BlueprintUpdateTypes.CATEGORY, { id: category.id, ...nodeIds, dataSourceOriginalFileName: tmp3dFile.name }));

        updateVariableValue(modelStats.surfaceArea.baseValue, 'extractedObjectSurfaceArea');
        updateVariableValue(modelStats.volume.baseValue, 'extractedObjectVolume');
        updateVariableValue(modelStats.convexHull.surfaceArea.baseValue, 'extractedConvexHullSurfaceArea');
        updateVariableValue(modelStats.boundingBox.surfaceArea.baseValue, 'extractedBoundingBoxSurfaceArea');
        updateVariableValue(modelStats.boundingBox.length.baseValue, 'extractedLargestLength', 'largestLength');
        updateVariableValue(modelStats.boundingBox.width.baseValue, 'extractedLargestWidth', 'largestWidth');
        updateVariableValue(modelStats.boundingBox.height.baseValue, 'extractedLargestHeight', 'largestHeight');

        const result = await dispatch(getHolesAndThreads(fileResource.url));
        if (result?.holes != null && result?.threads != null) {
            updateVariableValue(result.holes, 'extractedHoles', 'holes');
            updateVariableValue(result.threads, 'extractedThreads', 'threads');

            if (Array.isArray(result.holeDimensions) && result.holeDimensions.length > 0) {
                updateVariableValue(JSON.stringify(result.holeDimensions), 'extractedHoleDimensions');
            }
            if (Array.isArray(result.threadDimensions) && result.threadDimensions.length > 0) {
                updateVariableValue(JSON.stringify(result.threadDimensions), 'extractedThreadDimensions');
            }
        }

        setTmp3dFile(null);
        setLoading(false);
    };

    const nodeId = category?.threeDFileId || category?.pdfFileId || category?.dwgFileId || category?.dxfFileId;

    return (
        isDefaultCategory && (
            <>
                <Button
                    sx={{ px: 1.2, py: 0.5, flexShrink: 0 }}
                    startIcon={<DocumentScannerTwoToneIcon />}
                    variant="text"
                    color="secondary"
                    onClick={() => setOpen(true)}
                    ref={ref}
                    size={isSmall ? 'small' : 'medium'}
                >
                    {t('testFileExtraction')}
                </Button>

                <Popover
                    anchorEl={ref.current}
                    onClose={() => setOpen(false)}
                    open={isOpen}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                >
                    <Stack p={3} width="25rem" maxWidth="100%">
                        <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', p: 0.6, right: 0, top: 0, color: (theme) => theme.palette.grey[500] }}>
                            <CloseTwoToneIcon />
                        </IconButton>
                        <Typography variant="h3" textAlign="center">
                            {t('testFileExtraction')}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom textAlign="center" mb={2}>
                            {t('testFileExtractionDescription')}
                        </Typography>

                        {!!nodeId && (
                            <>
                                <List disablePadding sx={{ mx: -3, p: 0, pb: 2 }}>
                                    <ListItem
                                        sx={{ p: 0 }}
                                        secondaryAction={
                                            <IconButton edge="end" aria-label="delete" sx={{ mr: -0.5 }} onClick={handleRemoveFile}>
                                                <DeleteTwoToneIcon />
                                            </IconButton>
                                        }
                                    >
                                        <OpenFile
                                            nodeId={nodeId}
                                            displayElement={(openFile) => (
                                                <ListItemButton sx={{ pl: 2.5, borderRadius: 0 }} onClick={() => openFile()}>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <InsertDriveFileTwoToneIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <Tooltip title={category.dataSourceOriginalFileName}>
                                                        <StyledListItemText primary={category.dataSourceOriginalFileName} />
                                                    </Tooltip>
                                                </ListItemButton>
                                            )}
                                        />
                                    </ListItem>
                                </List>
                                <Typography variant="h5" gutterBottom textAlign="center">
                                    {t('uploadNewTestFile')}
                                </Typography>
                            </>
                        )}

                        <DragAndDropFileInput primaryText={t('uploadTestFileHere')} isMultiple={false} fileSelected={handleFileUpload} disabled={isLoading} />
                    </Stack>
                </Popover>

                {tmp3dFile && (
                    <div style={{ position: 'absolute', opacity: 0 }}>
                        <SSA3DViewer file={tmp3dFile} onViewerLoaded={handleOnViewerLoader} />
                    </div>
                )}
            </>
        )
    );
};

export default TestFileExtraction;
