import { Typography, Stack, useTheme, TextField, Collapse, Button, IconButton, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import HeadlineButton from 'src/components/display/HeadlineButton/HeadlineButton';
import ButtonSelector from 'src/components/input/ButtonSelector/ButtonSelector';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InputVariableResource, VariableResource } from 'src/backend/coreCalc';
import { getInputVariableTypes, getStringValue, getVariableName } from 'src/utils/CalcHelpers';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { FileResource } from 'src/backend/market';
import { snackContext } from 'src/redux/store';
import { addFile } from 'src/redux/thunks/fileManager.thunk';

import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { UploadBeforeHandler, UploadBeforeReturn } from 'suneditor-react/dist/types/upload';
import SingleSelectInputVariableForm from './SingleSelectInputVariableForm';
import BooleanInputVariableForm from './BooleanInputVariableForm';
import NumberInputVariableForm from './NumberInputVariableForm';
import MultiSelectInputVariableForm from './MultiSelectInputVariableForm';
import TextInputVariableForm from './TextInputVariableForm';

interface Props {
    variable: InputVariableResource;
    variables: Array<VariableResource>;
    setVariable: (variable: InputVariableResource) => void;
    setValid: (isValid: boolean) => void;
    isSmall?: boolean;
    isDisabled: boolean;
}

const InputVariableForm: React.FC<Props> = ({ variable, variables, setVariable, setValid, isSmall, isDisabled }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();
    const [isCriteriaOpen, setCriteriaOpen] = useState<boolean>(false);
    const [isSubValid, setSubValid] = useState<boolean>(true);
    const variableRef = useRef(variable);

    useEffect(() => {
        variableRef.current = variable;
    }, [variable]);

    useEffect(() => {
        if (!isSubValid) return setValid(false);
        if (!variable) return setValid(false);
        const isInvalid = getVariableName(variable) == null || getVariableName(variable).trim() === '' || variable.description == null || variable.description.trim() === '';
        setValid(!isInvalid);
    }, [variable, isSubValid]);

    const inputTypes = useMemo(() => {
        return getInputVariableTypes().map((type) => ({ value: type, label: type }));
    }, []);

    const handleNameChange = (event) => {
        setVariable({
            ...variable,
            displayName: event.target.value
        });
    };
    const handleInputTypeChange = (value) => {
        setVariable({
            type: value,
            displayName: variable.displayName,
            description: variable.description
        });
    };

    const uploadFiles = async (files: Array<File>, uploadHandler: UploadBeforeHandler) => {
        const uploadResponse = { errorMessage: null, result: [] };
        for (const file of files) {
            try {
                const fileResource: FileResource = await dispatch(addFile(file, file.name));
                const fileInfo = { url: fileResource.url, name: fileResource.name, size: 0 };
                uploadResponse.result.push(fileInfo);
            } catch (error) {
                const errorMessage = t('errorWhileUploadingImage');
                uploadResponse.errorMessage = errorMessage;
                snackContext.enqueueSnackbar(errorMessage, { variant: 'error' });
            }
        }

        uploadHandler(uploadResponse);
    };

    const handleImageUploadBefore = (files: Array<File>, info: object, uploadHandler: UploadBeforeHandler): UploadBeforeReturn => {
        uploadFiles(files, uploadHandler);
        return undefined;
    };

    const handleDescriptionChange = (newDescription: string) => {
        setVariable({
            ...variableRef.current, // ref has to used for a bugfix
            description: newDescription
        });
    };
    const handleSectionChange = (event) => {
        setVariable({
            ...variable,
            section: event.target.value
        });
    };

    return (
        variable && (
            <Stack pt={1}>
                <TextField
                    label="Name"
                    value={getVariableName(variable)}
                    onChange={handleNameChange}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    size={isSmall ? 'small' : 'medium'}
                    disabled={isDisabled}
                />
                <Stack mt={3}>
                    <Typography variant="subtitle2">Beschreibung</Typography>
                    <SunEditor
                        setOptions={{ buttonList: [...buttonList.formatting, ['image']] }}
                        defaultValue={variable.description}
                        height="10rem"
                        onImageUploadBefore={handleImageUploadBefore}
                        onChange={handleDescriptionChange}
                        disable={isDisabled}
                    />
                </Stack>

                <Typography variant="subtitle2" sx={{ mt: 4, mb: 1 }} gutterBottom>
                    Was soll abgefragt werden?
                </Typography>
                <ButtonSelector items={inputTypes} value={variable.type} setValue={handleInputTypeChange} disabled={isDisabled} />

                <FormControl fullWidth sx={{ mt: 4 }}>
                    <InputLabel id="select-default-value" size="small">
                        Zu welcher Gruppe gehört dieses Eingabefeld?
                    </InputLabel>
                    <Select
                        labelId="select-default-value"
                        label="Zu welcher Gruppe gehört dieses Eingabefeld?"
                        value={variable.section}
                        onChange={handleSectionChange}
                        size="small"
                        disabled={isDisabled}
                    >
                        {Object.values(InputVariableResource.section).map((option, index) => (
                            <MenuItem key={index} value={option}>
                                {t(option)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {variable.type === VariableResource.type.BOOLEAN_INPUT_VARIABLE && (
                    <BooleanInputVariableForm variable={variable} variables={variables} setVariable={setVariable} setValid={setSubValid} isDisabled={isDisabled} />
                )}
                {variable.type === VariableResource.type.NUMBER_INPUT_VARIABLE && (
                    <NumberInputVariableForm variable={variable} variables={variables} setVariable={setVariable} setValid={setSubValid} isDisabled={isDisabled} />
                )}
                {variable.type === VariableResource.type.SINGLE_SELECT_INPUT_VARIABLE && (
                    <SingleSelectInputVariableForm variable={variable} variables={variables} setVariable={setVariable} setValid={setSubValid} isDisabled={isDisabled} />
                )}
                {variable.type === VariableResource.type.MULTI_SELECT_INPUT_VARIABLE && (
                    <MultiSelectInputVariableForm variable={variable} variables={variables} setVariable={setVariable} setValid={setSubValid} isDisabled={isDisabled} />
                )}
                {variable.type === VariableResource.type.TEXT_INPUT_VARIABLE && (
                    <TextInputVariableForm variable={variable} variables={variables} setVariable={setVariable} setValid={setSubValid} isDisabled={isDisabled} />
                )}

                <Stack mt={7}>
                    <HeadlineButton isOpen={isCriteriaOpen} setOpen={setCriteriaOpen}>
                        Aktionen (Optional)
                    </HeadlineButton>

                    <Collapse in={isCriteriaOpen} timeout="auto" unmountOnExit>
                        <Typography sx={{ fontSize: 14, lineHeight: 1.3, mb: 3 }} variant="subtitle1">
                            Wähle aus, wie der Preis für diese Kategorie berechnet werden soll. Wenn keine der vordefinierten Kalkulationen für dich geeignet ist, kannst du deine eigene Kalkulation
                            erstellen.
                        </Typography>

                        <Stack bgcolor={theme.colors.alpha.black[7]} alignItems="center" justifyContent="center" height="8rem" fontSize={14} sx={{ opacity: 0.6, borderRadius: 1 }}>
                            <GridViewTwoToneIcon sx={{ fontSize: 28, opacity: 0.6 }} />
                            Keine Kriterien gesetzt
                        </Stack>
                    </Collapse>
                </Stack>
            </Stack>
        )
    );
};

export default InputVariableForm;
