import { Button, IconButton, Popover, Stack, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import AttachmentsInput from 'src/components/input/AttachmentsInput/AttachmentsInput';
import { RootState } from 'src/redux/store';
import { ProCalcUpdateTypes, updateProCalc } from 'src/redux/shared/proCalc.common';
import { AttachmentResource } from 'src/backend/coreCalc';

interface Props {
    attachments?: Array<AttachmentResource>;
}

const MasterAttachments: React.FC<Props> = ({ attachments }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);

    const addAttachment = async (attachment: AttachmentResource) => {
        dispatch(updateProCalc(ProCalcUpdateTypes.CREATE_MASTER_ATTACHMENT, attachment));
    };

    const removeAttachment = (id: number) => {
        dispatch(updateProCalc(ProCalcUpdateTypes.DELETE_MASTER_ATTACHMENT, id));
    };

    return (
        <>
            <Button sx={{ px: 1.2, py: 0.5, flexShrink: 0 }} startIcon={<AttachFileTwoToneIcon />} variant="text" color="secondary" onClick={() => setOpen(true)} ref={ref}>
                {t('attachments')} {attachments?.length > 0 && '(' + attachments.length + ')'}
            </Button>

            <Popover
                anchorEl={ref.current}
                onClose={() => setOpen(false)}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', p: 0.6, right: 0, top: 0, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
                <Stack p={2} width="25rem" maxWidth="100%">
                    <Typography variant="h3" textAlign="center">
                        {t('attachments')}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom textAlign="center">
                        {t('masterAttachmentsExplanation')}
                    </Typography>
                    <AttachmentsInput attachments={attachments} addAttachment={addAttachment} removeAttachment={removeAttachment} />
                </Stack>
            </Popover>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        calculation: state.proCalc.calculation
    };
};
export default connect(mapStateToProps)(MasterAttachments);
