import { styled, ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { EnumerationCalcParamItemResource } from 'src/backend/internalCalc';
import store from 'src/redux/store';
import { wT } from 'src/utils/wizardTranslations';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '15px',
    padding: '0',
    marginBottom: '10px',

    '& .MuiToggleButtonGroup-grouped': {
        background: `${theme.colors.alpha.black[5]}`,
        border: `1px solid ${theme.colors.alpha.black[10]}`,
        borderRadius: '1rem',
        color: theme.colors.alpha.black[70],
        padding: '7px 15px',
        flexGrow: 1,
        fontWeight: 'normal',

        '&.Mui-selected': {
            backgroundColor: theme.colors.primary.main,
            color: theme.colors.alpha.white[100],
            borderColor: 'transparent !important',
            fontWeight: 'bold'
        },
        '&.Mui-selected:hover': {
            backgroundColor: theme.colors.primary.main
        },
        '&.Mui-disabled': {
            border: 0
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
            border: `1px solid ${theme.colors.alpha.black[10]}`
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius
        },

        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(1)
        }
    }
}));

type Props = {
    items: Array<EnumerationCalcParamItemResource | { value: string; label?: string; icon?: ReactNode }>;
    value: string;
    setValue: (newValue: string) => void;
    disabled?: boolean;
} & ToggleButtonGroupProps;

const ButtonSelector: React.FC<Props> = ({ items, value, setValue, disabled, ...props }) => {
    const { t } = useTranslation();
    const s = store.getState();

    const getName = (name: string) => {
        if (name === 'noSelection') {
            return t('keineAuswahl');
        }
        return wT(name, s);
    };

    const isEnumerationItem = (item: any): item is EnumerationCalcParamItemResource => {
        return (item as EnumerationCalcParamItemResource).id !== undefined;
    };

    return (
        <StyledToggleButtonGroup {...props} value={value} onChange={(event, newValue) => setValue(newValue)} exclusive disabled={disabled}>
            {items.map((item) =>
                isEnumerationItem(item) ? (
                    <ToggleButton key={item.id} value={item.id} aria-label={item.id}>
                        {getName(item.id)}
                    </ToggleButton>
                ) : (
                    <ToggleButton key={item.value} value={item.value} aria-label={item.value}>
                        {item.icon}
                        {t(item.label || item.value)}
                    </ToggleButton>
                )
            )}
        </StyledToggleButtonGroup>
    );
};

export default ButtonSelector;
