import { GridSortModel } from '@mui/x-data-grid';
import { AppDispatch, RootState, snackContext } from '../store';
import { AiMailConversationRestControllerService } from 'src/backend/market';
import { setAutoReloadCalculations, setFilter, setLoading, setMailConversations, setPaging, setProCalcs, setSortModel } from '../slices/proCalcs.reducer';
import { CalculationRestControllerService } from 'src/backend/coreCalc';
import { handleError, setProCalc } from '../slices/proCalc.reducer';
import i18n from 'src/i18n/i18n';
import { loadMailConversation } from './proCalc.thunk';

export const createProCalc = (blueprintId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
        const calc = await CalculationRestControllerService.createCalculation({ blueprintId });
        dispatch(setLoading(false));
        return calc;
    } catch (error) {
        dispatch(setLoading(false));
    }
};
export const duplicateProCalc = (calculationId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(setLoading(true));
        // await CalculationRestControllerService.d(calculationId); todo
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setLoading(false));
    }
};

export const deleteProCalc = (calculationId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
        await CalculationRestControllerService.deleteCalculation(calculationId);
        snackContext.enqueueSnackbar(i18n.t('calculationWasDeleted'), { variant: 'success' });
        dispatch(loadProCalcs());
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setLoading(false));
    }
};

export const loadProCalc = (calculationId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
        const calc = await CalculationRestControllerService.getCalculation(calculationId);
        await dispatch(loadMailConversation(calculationId));
        dispatch(setProCalc(calc));
        dispatch(setLoading(false));
        return calc;
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(handleError(error));
    }
};

export const getRecentProCalcs = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
        const result = await CalculationRestControllerService.getAllCalculations(0, 20, {}, 'createdAtDesc');
        dispatch(setLoading(false));
        return result.data;
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(handleError(error));
    }
};

const getSortingString = (calculationsSort: GridSortModel) => {
    if (calculationsSort.length === 0) return '';
    const sort = calculationsSort[0].sort.charAt(0).toUpperCase() + calculationsSort[0].sort.slice(1);
    return `${calculationsSort[0].field}${sort}`;
};

export const loadProCalcs = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const isAutoReloadCalculations = getState().proCalcs.isAutoReloadCalculations;
    if (!isAutoReloadCalculations) return;

    dispatch(setLoading(true));
    const paging = getState().proCalcs.paging;
    const filter = getState().proCalcs.filter;
    const sort = getState().proCalcs.sort;

    try {
        const calcResult = await CalculationRestControllerService.getAllCalculations(paging.page, paging.pageSize, filter, getSortingString(sort));
        const mailConversationsList = await AiMailConversationRestControllerService.getConversationStreamOverview({
            calculationIds: calcResult.data.map((calc) => calc.id)
        });
        const mailConversations = {};
        mailConversationsList.forEach((mailConversation) => {
            mailConversations[mailConversation.calculationId] = mailConversation;
        });
        dispatch(setProCalcs(calcResult.data));
        dispatch(setMailConversations(mailConversations));
        dispatch(updatePaging({ total: calcResult.totalElements }, false));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setLoading(false));
    }
};

export const enableAutoReloadCalculations = () => async (dispatch: AppDispatch) => {
    dispatch(setAutoReloadCalculations(true));
};
export const disableAutoReloadCalculations = () => async (dispatch: AppDispatch) => {
    dispatch(setAutoReloadCalculations(false));
};

//PAGING, FILTERING AND SORTING
export const updatePaging =
    (paging: { page?: number; pageSize?: number; total?: number }, reload: boolean = true) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        const currentPaging = getState().proCalcs.paging;
        dispatch(
            setPaging({
                page: paging.page ?? currentPaging.page,
                pageSize: paging.pageSize ?? currentPaging.pageSize,
                total: paging.total ?? currentPaging.total
            })
        );
        if (reload) dispatch(loadProCalcs());
    };
export const updateSortModel = (sortModel: GridSortModel) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setSortModel(sortModel));
    dispatch(loadProCalcs());
};
export const updateFilter = (filter: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setFilter(filter));
    dispatch(loadProCalcs());
};
