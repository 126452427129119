/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ValueDTO } from './ValueDTO';
import type { VariableDTO } from './VariableDTO';

export type ProvidedVariableDTO = (VariableDTO & {
    isDisplayedInStats?: boolean;
    unit?: ProvidedVariableDTO.unit;
    value?: ValueDTO;
});

export namespace ProvidedVariableDTO {

    export enum unit {
        MILLIMETER = 'MILLIMETER',
        CENTIMETER = 'CENTIMETER',
        METER = 'METER',
        KILOMETER = 'KILOMETER',
        SQUARE_MILLIMETER = 'SQUARE_MILLIMETER',
        SQUARE_CENTIMETER = 'SQUARE_CENTIMETER',
        SQUARE_METER = 'SQUARE_METER',
        SQUARE_KILOMETER = 'SQUARE_KILOMETER',
        CUBIC_MILLIMETER = 'CUBIC_MILLIMETER',
        CUBIC_CENTIMETER = 'CUBIC_CENTIMETER',
        CUBIC_METER = 'CUBIC_METER',
        GRAM = 'GRAM',
        KILOGRAM = 'KILOGRAM',
        TONNE = 'TONNE',
        PIECE = 'PIECE',
    }


}
