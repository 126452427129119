/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AttachmentResource } from './AttachmentResource';
import type { BooleanInputVariableResource } from './BooleanInputVariableResource';
import type { ComputationResource } from './ComputationResource';
import type { CustomerResource } from './CustomerResource';
import type { DeclaredVariableResource } from './DeclaredVariableResource';
import type { ItemResource } from './ItemResource';
import type { MultiSelectInputVariableResource } from './MultiSelectInputVariableResource';
import type { NumberInputVariableResource } from './NumberInputVariableResource';
import type { ProvidedVariableResource } from './ProvidedVariableResource';
import type { RoundedVariableResource } from './RoundedVariableResource';
import type { RuleBasedVariableResource } from './RuleBasedVariableResource';
import type { SettingsResource } from './SettingsResource';
import type { ShortCategoryResource } from './ShortCategoryResource';
import type { SingleSelectInputVariableResource } from './SingleSelectInputVariableResource';
import type { SurchargeVariableResource } from './SurchargeVariableResource';
import type { TermVariableResource } from './TermVariableResource';
import type { TextInputVariableResource } from './TextInputVariableResource';

export type CalculationResource = {
    id?: string;
    name?: string;
    createdAt?: string;
    updatedAt?: string;
    status?: CalculationResource.status;
    settings?: SettingsResource;
    customer?: CustomerResource;
    masterComputation?: ComputationResource;
    masterVariables?: Array<(BooleanInputVariableResource | DeclaredVariableResource | MultiSelectInputVariableResource | NumberInputVariableResource | ProvidedVariableResource | RoundedVariableResource | RuleBasedVariableResource | SingleSelectInputVariableResource | SurchargeVariableResource | TermVariableResource | TextInputVariableResource)>;
    masterAttachments?: Array<AttachmentResource>;
    items?: Array<ItemResource>;
    categories?: Array<ShortCategoryResource>;
}

export namespace CalculationResource {

    export enum status {
        UNOPENED = 'UNOPENED',
        PENDING_MAIL_ANSWER = 'PENDING_MAIL_ANSWER',
        NEEDS_MAIL_CLARIFICATION = 'NEEDS_MAIL_CLARIFICATION',
        CALCULATING = 'CALCULATING',
        SHIPPING = 'SHIPPING',
        COMPLETED = 'COMPLETED',
    }


}
