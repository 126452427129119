import { Button, Stack, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { VariableResource, ValueResource, CriterionResource } from 'src/backend/coreCalc';
import { cloneDeep } from 'lodash';
import CriterionInput from './CriterionInput';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';

interface Props {
    criteria: Array<CriterionResource>;
    variables: Array<VariableResource>;
    isDisabled?: boolean;
    setCriteria: (criteria: Array<CriterionResource>) => void;
}

const CriteriaInput: React.FC<Props> = ({ criteria, variables, isDisabled, setCriteria }) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const addCriterion = () => {
        setCriteria([
            ...criteria,
            {
                left: null,
                right: null,
                operator: CriterionResource.operator.EQUALS,
                connector: CriterionResource.connector.AND
            }
        ]);
    };

    const updateCriterion = (updatedCriterion: CriterionResource, oldCriterion: CriterionResource) => {
        const criterionIndex = criteria.findIndex((criterion) => criterion === oldCriterion);
        const clonedCriteria = cloneDeep(criteria);
        clonedCriteria[criterionIndex] = updatedCriterion;
        setCriteria(clonedCriteria);
    };

    const removeCriterion = (criterionToRemove: CriterionResource) => {
        setCriteria(criteria.filter((criterion) => criterion !== criterionToRemove));
    };

    return (
        <Stack gap={1.5} maxWidth="100%">
            {criteria.map((criterion, index) => (
                <CriterionInput key={index} criterion={criterion} variables={variables} isDisabled={isDisabled} updateCriterion={updateCriterion} removeCriterion={removeCriterion} />
            ))}
            <Button startIcon={<AddBoxTwoToneIcon />} onClick={addCriterion} sx={{ ml: -1.3, mr: 'auto' }} size="small" disabled={isDisabled}>
                Kriterium hinzufügen
            </Button>
        </Stack>
    );
};

export default CriteriaInput;
