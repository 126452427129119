import { Box, Button, Select, Stack, Typography, styled } from '@mui/material';

export const VariableItem = styled(Stack)(
    ({ theme }) => `
        align-items: center;
        gap: ${theme.spacing(1)};
        height: 52px;
        padding: ${theme.spacing(0.8, 1.5)};
        box-shadow: 0 0 18px 0 rgb(211 211 211 / 53%);
        border-radius: 8px;
    `
);
export const SubCategoryItem = styled(Stack)(
    ({ theme }) => `
        justify-content: space-between;
        align-items: center;
        gap: ${theme.spacing(1)};
        height: 36px;
        padding: ${theme.spacing(0.8, 1.5)};
        margin-top: ${theme.spacing(0.8)};
        margin-bottom: ${theme.spacing(0.8)};
        box-shadow: 0 0 18px 0 rgb(211 211 211 / 53%);
        border-radius: 8px;
    `
);
export const UsedInCalcTag = styled(Typography)(
    ({ theme }) => `
        background: ${theme.colors.info.main};
        color: ${theme.colors.alpha.white[100]};
        padding: 2px 5px;
        border-radius: 5px;
        font-size: 11px;
        font-weight: 600;
        margin-right: ${theme.spacing(-0.6)};
        white-space: nowrap;
    `
);

export const OperatorSelect = styled(Select)(
    ({ theme }) => `
        font-family: 'Azeret Mono', monospace !important;
        font-size: 1.6rem;

        &.MuiInputBase-root .MuiSelect-select.MuiOutlinedInput-input {
            padding: 0px 10px;
        }
        & .MuiOutlinedInput-notchedOutline {
            font-weight: bold;
            border: none;
            outline: none;
        }
        & .MuiSvgIcon-root {
            top: auto;
            bottom: -7px;
            left: auto;
            right: auto;
            width: 100%;
            height: 0.8em;
        }
    `
);
