import { Button, Divider, Stack, Typography, useTheme } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RuleTwoToneIcon from '@mui/icons-material/RuleTwoTone';
import { ProvidedVariableResource, VariableResource } from 'src/backend/coreCalc';
import Stats from 'src/components/display/Stats/Stats';
import BarChartTwoToneIcon from '@mui/icons-material/BarChartTwoTone';
import CategoryStatsDialog from './CategoryStatsDialog';

interface Props {
    variables?: Array<VariableResource>;
    createVariable: (variable: VariableResource) => void;
    saveVariable: (variable: VariableResource) => void;
    removeVariable: (variableId: number) => void;
}

const CategoryStats: React.FC<Props> = ({ variables, createVariable, saveVariable, removeVariable }) => {
    const { t } = useTranslation();
    const ref = useRef<any>(null);
    const theme = useTheme();
    const [isDialogOpen, setDialogOpen] = useState<boolean>(false);

    const statisticVariables = useMemo(() => {
        return (variables || []).filter((variable) => variable.type === VariableResource.type.PROVIDED_VARIABLE && (variable as ProvidedVariableResource).isDisplayedInStats);
    }, [variables]);

    return (
        <Stack width="100%" maxWidth="35rem" className="item-calculated-values">
            <Typography variant="h3" fontSize={18} textAlign="center" pt={2}>
                {t('stats')}
            </Typography>
            {statisticVariables.length > 0 ? (
                <Stats statisticVariables={statisticVariables} />
            ) : (
                <Stack height="100%" alignItems="center" justifyContent="center" sx={{ m: 2, gap: 0.4, opacity: 0.6, borderRadius: 1, bgcolor: theme.colors.alpha.black[7] }}>
                    <BarChartTwoToneIcon sx={{ fontSize: 30, opacity: 0.6 }} />
                    <Typography fontSize={14}>Keine Statistiken vorhanden</Typography>
                </Stack>
            )}
            <Divider sx={{ mt: 'auto' }} />
            <Button ref={ref} sx={{ mx: 'auto' }} size="small" startIcon={<RuleTwoToneIcon />} onClick={() => setDialogOpen(true)}>
                Statistik anpassen
            </Button>
            <CategoryStatsDialog variables={variables} isOpen={isDialogOpen} setOpen={setDialogOpen} createVariable={createVariable} saveVariable={saveVariable} removeVariable={removeVariable} />
        </Stack>
    );
};

export default CategoryStats;
