import { Box, Button, Card, CircularProgress, Divider, Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import store, { RootState } from 'src/redux/store';
import RuleTwoToneIcon from '@mui/icons-material/RuleTwoTone';
import InputFieldsDialog from './InputFields/InputFieldsDialog';
import CategorySurcharges from './CategorySurcharges';
import { updateBlueprint, BlueprintUpdateTypes } from 'src/redux/shared/calcEditor.common';
import { CategoryResource, CategoryVariableIdentifier, SettingsResource, ValueResource, VariableResource } from 'src/backend/coreCalc';
import QuantityInput from 'src/components/input/QuantityInput/QuantityInput';
import ChangeableText from 'src/components/input/ChangeableText/ChangeableText';
import ComputationPrice from 'src/components/display/ComputationPrice/ComputationPrice';
import { getCalcRelevantVariableInternalIdentifiers, getInputVariablesGroupedBySection } from 'src/utils/CalcHelpers';
import InputSections from 'src/components/input/InputSections/InputSections';
import { CalcEditorUpdateLoading } from 'src/redux/slices/calcEditor.reducer';
import CategoryStats from './Stats/CategoryStats';
import CategoryMessages from './Messages/CategoryMessages';
import TestFileExtraction from './TestFileExtraction/TestFileExtraction';
import ComputationSelection from './Computation/ComputationSelection';
import SubCategories from './SubCategories/SubCategories';

const Header = styled(Box)(
    ({ theme }) => `
        display: grid;
        grid-template-columns: auto auto 1fr auto;
    `
);
const Center = styled(Stack)(
    ({ theme }) => `
        flex-direction: row;
        min-height: 20.2rem;
        max-height: 60rem;
    `
);
const Footer = styled(Stack)(
    ({ theme }) => `
        flex-direction: row;
        align-items: center;
        padding: ${theme.spacing(1)} ${theme.spacing(2)};
        overflow: auto;
    `
);

interface Props {
    category: CategoryResource;
    settings: SettingsResource;
    isUpdateLoading: CalcEditorUpdateLoading;
}

const Category: React.FC<Props> = ({ category, settings, isUpdateLoading }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const { t } = useTranslation();
    const inputFieldsRef = useRef<any>(null);
    const [isInputFieldsDialogOpen, setInputFieldsDialogOpen] = useState<boolean>(false);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const calcRelevantVariableInternalIdentifiers = useMemo(
        () => getCalcRelevantVariableInternalIdentifiers(category.supportedComputations, category.variables),
        [category.supportedComputations, category.variables]
    );
    const inputVariablesGroupedBySection = useMemo(() => getInputVariablesGroupedBySection(category.variables), [category.variables]);
    const usedComputation = useMemo(
        () => category.supportedComputations.find((computation) => computation.id === category.selectedComputationId),
        [category.supportedComputations, category.selectedComputationId]
    );

    const handleSaveName = (newName: string) => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.CATEGORY_NAME, { categoryId: category.id, name: newName }));
    };

    const removeCategory = () => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.DELETE_CATEGORY, category.id));
    };

    const duplicateCategory = () => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.DUPLICATE_CATEGORY, category.id));
    };

    const createVariable = (variable: VariableResource) => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.CREATE_CATEGORY_VARIABLE, { categoryId: category.id, variable }));
    };
    const saveVariable = (variable: VariableResource) => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.CATEGORY_VARIABLE, { categoryId: category.id, variable }));
    };
    const removeVariable = (variableId: number) => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.DELETE_CATEGORY_VARIABLE, { categoryId: category.id, variableId }));
    };
    const saveVariableValue = (variableId: number, value: ValueResource) => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.CATEGORY_VARIABLE_VALUE, { categoryId: category.id, variableId, value }));
    };

    return (
        <Card sx={{ overflow: 'hidden' }}>
            <Header alignItems="center" px={2} py={1} overflow="auto !important" maxWidth="100%">
                <QuantityInput variables={category.variables} isSmall={isSmallScreen} saveVariableValue={saveVariableValue} />
                <ChangeableText sx={{ ml: 2 }} text={category.name || t(category.translationKey, s)} saveText={handleSaveName} isSmall={isSmallScreen} />

                <ComputationSelection
                    category={category}
                    calcRelevantVariableInternalIdentifiers={calcRelevantVariableInternalIdentifiers}
                    createVariable={createVariable}
                    saveVariable={saveVariable}
                    removeVariable={removeVariable}
                />

                <ComputationPrice computation={usedComputation} variables={category.variables} settings={settings} />
            </Header>

            <Stack>
                <Divider />
                <Center direction="row">
                    <SubCategories category={category} />
                    <Divider flexItem orientation="vertical" />
                    <Stack style={{ display: 'flex', width: '100%' }}>
                        <InputSections
                            variables={category.variables}
                            inputVariablesGroupedBySection={inputVariablesGroupedBySection}
                            calcRelevantVariableInternalIdentifiers={calcRelevantVariableInternalIdentifiers}
                            saveVariableValue={saveVariableValue}
                        />
                        <Divider sx={{ mt: 'auto' }} />
                        <Button ref={inputFieldsRef} sx={{ mx: 'auto' }} size="small" startIcon={<RuleTwoToneIcon />} onClick={() => setInputFieldsDialogOpen(true)}>
                            Eingabefelder anpassen
                        </Button>
                        <InputFieldsDialog
                            variables={category.variables}
                            inputVariablesGroupedBySection={inputVariablesGroupedBySection}
                            calcRelevantVariableInternalIdentifiers={calcRelevantVariableInternalIdentifiers}
                            isOpen={isInputFieldsDialogOpen}
                            setOpen={setInputFieldsDialogOpen}
                            createVariable={createVariable}
                            saveVariable={saveVariable}
                            removeVariable={removeVariable}
                        />
                    </Stack>
                    <Divider flexItem orientation="vertical" />
                    <CategoryStats variables={category.variables} createVariable={createVariable} saveVariable={saveVariable} removeVariable={removeVariable} />
                </Center>
                <Divider />
                <Footer>
                    <Stack direction="row" gap={isSmallScreen ? 0.5 : 2}>
                        <CategorySurcharges variables={category.variables} createVariable={createVariable} saveVariable={saveVariable} removeVariable={removeVariable} />
                        <CategoryMessages categoryId={category.id} messages={category.messages} variables={category.variables} isSmall={isSmallScreen} />
                        <TestFileExtraction isSmall={isSmallScreen} category={category} saveVariableValue={saveVariableValue} />
                    </Stack>

                    {!category.defaultCategory && (
                        <Stack id={'deleteDuplicateButtons'} direction="row" ml="auto">
                            <Button sx={{ px: 1.2, py: 0.5 }} startIcon={<DeleteTwoToneIcon />} variant="text" color="secondary" onClick={removeCategory} size={isSmallScreen ? 'small' : 'medium'}>
                                {t('delete')}
                            </Button>
                            <Button
                                sx={{ px: 1.2, py: 0.5 }}
                                disabled={isUpdateLoading[BlueprintUpdateTypes.DUPLICATE_CATEGORY]}
                                startIcon={isUpdateLoading[BlueprintUpdateTypes.DUPLICATE_CATEGORY] ? <CircularProgress color="inherit" size={20} sx={{ p: 0.25 }} /> : <ContentCopyTwoToneIcon />}
                                variant="text"
                                onClick={duplicateCategory}
                                size={isSmallScreen ? 'small' : 'medium'}
                            >
                                {t('duplicate')}
                            </Button>
                        </Stack>
                    )}
                </Footer>
            </Stack>
        </Card>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        isUpdateLoading: state.calcEditor.isUpdateLoading
    };
};
export default connect(mapStateToProps)(Category);
