import StraightenTwoToneIcon from '@mui/icons-material/StraightenTwoTone';
import ViewInArTwoToneIcon from '@mui/icons-material/ViewInArTwoTone';
import FormatPaintTwoToneIcon from '@mui/icons-material/FormatPaintTwoTone';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import FormatColorFillTwoToneIcon from '@mui/icons-material/FormatColorFillTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import TextureTwoToneIcon from '@mui/icons-material/TextureTwoTone';
import CheckBoxOutlineBlankTwoToneIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
import ViewModuleTwoToneIcon from '@mui/icons-material/ViewModuleTwoTone';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import LinearScaleTwoToneIcon from '@mui/icons-material/LinearScaleTwoTone';
import BuildTwoToneIcon from '@mui/icons-material/BuildTwoTone';
import DonutSmallTwoToneIcon from '@mui/icons-material/DonutSmallTwoTone';
import HorizontalRuleTwoToneIcon from '@mui/icons-material/HorizontalRuleTwoTone';
import ViewWeekTwoToneIcon from '@mui/icons-material/ViewWeekTwoTone';
import CallMadeTwoToneIcon from '@mui/icons-material/CallMadeTwoTone';
import CreateNewFolderTwoToneIcon from '@mui/icons-material/CreateNewFolderTwoTone';
import GpsFixedTwoToneIcon from '@mui/icons-material/GpsFixedTwoTone';
import CalculateTwoToneIcon from '@mui/icons-material/CalculateTwoTone';
import CallSplitTwoToneIcon from '@mui/icons-material/CallSplitTwoTone';
import FormatAlignJustifyTwoToneIcon from '@mui/icons-material/FormatAlignJustifyTwoTone';
import InputTwoToneIcon from '@mui/icons-material/InputTwoTone';
import StarHalfTwoToneIcon from '@mui/icons-material/StarHalfTwoTone';
import PlaylistAddCircleTwoToneIcon from '@mui/icons-material/PlaylistAddCircleTwoTone';
import FenceTwoToneIcon from '@mui/icons-material/FenceTwoTone';
import RadioTwoToneIcon from '@mui/icons-material/RadioTwoTone';
import OpacityTwoToneIcon from '@mui/icons-material/OpacityTwoTone';
import DirectionsBikeTwoToneIcon from '@mui/icons-material/DirectionsBikeTwoTone';
import ConstructionTwoToneIcon from '@mui/icons-material/ConstructionTwoTone';
import UnfoldMoreTwoToneIcon from '@mui/icons-material/UnfoldMoreTwoTone';
import RadioButtonCheckedTwoToneIcon from '@mui/icons-material/RadioButtonCheckedTwoTone';
import LayersTwoToneIcon from '@mui/icons-material/LayersTwoTone';
import BorderOuterTwoToneIcon from '@mui/icons-material/BorderOuterTwoTone';
import EngineeringTwoToneIcon from '@mui/icons-material/EngineeringTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import HexagonTwoToneIcon from '@mui/icons-material/HexagonTwoTone';

interface Props {
    icon: string;
}

const MuiIcon: React.FC<Props> = ({ icon }) => {
    switch (icon) {
        case 'StraightenTwoTone':
            return <StraightenTwoToneIcon />;
        case 'ViewInArTwoTone':
            return <ViewInArTwoToneIcon />;
        case 'FormatPaintTwoTone':
            return <FormatPaintTwoToneIcon />;
        case 'BallotTwoTone':
            return <BallotTwoToneIcon />;
        case 'FormatColorFillTwoTone':
            return <FormatColorFillTwoToneIcon />;
        case 'CloseTwoTone':
            return <CloseTwoToneIcon />;
        case 'TextureTwoTone':
            return <TextureTwoToneIcon />;
        case 'CheckBoxOutlineBlankTwoTone':
            return <CheckBoxOutlineBlankTwoToneIcon />;
        case 'ViewModuleTwoTone':
            return <ViewModuleTwoToneIcon />;
        case 'CircleTwoTone':
            return <CircleTwoToneIcon />;
        case 'LinearScaleTwoTone':
            return <LinearScaleTwoToneIcon />;
        case 'BuildTwoTone':
            return <BuildTwoToneIcon />;
        case 'DonutSmallTwoTone':
            return <DonutSmallTwoToneIcon />;
        case 'HorizontalRuleTwoTone':
            return <HorizontalRuleTwoToneIcon />;
        case 'ViewWeekTwoTone':
            return <ViewWeekTwoToneIcon />;
        case 'CallMadeTwoTone':
            return <CallMadeTwoToneIcon />;
        case 'CreateNewFolderTwoTone':
            return <CreateNewFolderTwoToneIcon />;
        case 'GpsFixedTwoTone':
            return <GpsFixedTwoToneIcon />;
        case 'CalculateTwoTone':
            return <CalculateTwoToneIcon />;
        case 'CallSplitTwoTone':
            return <CallSplitTwoToneIcon />;
        case 'FormatAlignJustifyTwoTone':
            return <FormatAlignJustifyTwoToneIcon />;
        case 'InputTwoTone':
            return <InputTwoToneIcon />;
        case 'StarHalfTwoTone':
            return <StarHalfTwoToneIcon />;
        case 'PlaylistAddCircleTwoTone':
            return <PlaylistAddCircleTwoToneIcon />;
        case 'FenceTwoTone':
            return <FenceTwoToneIcon />;
        case 'RadioTwoTone':
            return <RadioTwoToneIcon />;
        case 'OpacityTwoTone':
            return <OpacityTwoToneIcon />;
        case 'DirectionsBikeTwoTone':
            return <DirectionsBikeTwoToneIcon />;
        case 'ConstructionTwoTone':
            return <ConstructionTwoToneIcon />;
        case 'UnfoldMoreTwoTone':
            return <UnfoldMoreTwoToneIcon />;
        case 'RadioButtonCheckedTwoTone':
            return <RadioButtonCheckedTwoToneIcon />;
        case 'LayersTwoTone':
            return <LayersTwoToneIcon />;
        case 'BorderOuterTwoTone':
            return <BorderOuterTwoToneIcon />;
        case 'EngineeringTwoTone':
            return <EngineeringTwoToneIcon />;
        case 'SettingsTwoTone':
            return <SettingsTwoToneIcon />;
        case 'HexagonTwoTone':
            return <HexagonTwoToneIcon />;
        default:
            return <></>;
    }
};

export default MuiIcon;
