import { Popover, Stack, Typography } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import HelpOutlineTwoToneIcon from '@mui/icons-material/HelpOutlineTwoTone';
import { wT } from 'src/utils/wizardTranslations';
import store from 'src/redux/store';
import { getVariableName } from 'src/utils/CalcHelpers';
import { InputFieldLabel, InputFieldLabelButton } from './InputSections.styles';
import { InputVariableResource } from 'src/backend/coreCalc';

interface Props {
    variable: InputVariableResource;
    labelPrefix?: string | JSX.Element;
    labelSuffix?: string | JSX.Element;
    showExplanationFallback?: boolean;
}

const InfoLabel: React.FC<Props> = ({ variable, labelPrefix, labelSuffix }) => {
    const ref = useRef<any>(null);
    const popoverRef = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const s = store.getState();

    const label = getVariableName(variable);

    const descriptionProps = useMemo(() => {
        if (!variable.description) return {};
        // const translation = wT(guiDescriptorExplanation, s, showExplanationFallback); // todo what should we do about the existing ones descriptionTranslationKey?

        return {
            dangerouslySetInnerHTML: {
                __html: variable.description
            }
        };
    }, [variable.description]);

    if (labelSuffix != null && typeof labelSuffix === 'string') labelSuffix = ' (' + wT(labelSuffix, s, true) + ')';

    const isImageLoaded = (image) => {
        return new Promise((resolve) => {
            image.onload = (event) => resolve(event);
            image.onerror = (event) => resolve(event);
        });
    };

    const handleOpen = (event) => {
        setOpen(true);

        setTimeout(() => {
            const images = Array.from(document.querySelectorAll('.html-explanation img')) as Array<HTMLImageElement>;

            Promise.all(images.filter((img) => !img.complete).map((img) => isImageLoaded(img))).then(() => {
                if (popoverRef.current) popoverRef.current.updatePosition();
            });
        }, 1);
    };

    return descriptionProps?.dangerouslySetInnerHTML?.__html ? (
        <>
            <InputFieldLabelButton size="small" variant="text" color="secondary" onClick={handleOpen} ref={ref} tabIndex={-1}>
                <Typography fontSize="unset" lineHeight="unset" mr={0.35} noWrap display="flex" title={wT(label, s, true)}>
                    {labelPrefix}
                    {label}
                    {labelSuffix}
                </Typography>
                <HelpOutlineTwoToneIcon />
            </InputFieldLabelButton>
            <Popover
                anchorEl={ref.current}
                onClose={() => setOpen(false)}
                open={isOpen}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                action={popoverRef}
            >
                <Stack p={2} maxWidth="45rem" className="html-explanation">
                    <Typography variant="h3" gutterBottom>
                        {getVariableName(variable)}
                    </Typography>
                    <div {...descriptionProps}></div>
                </Stack>
            </Popover>
        </>
    ) : (
        <InputFieldLabel title={wT(label, s, true)} noWrap>
            {labelPrefix}
            {wT(label, s, true)}
            {labelSuffix}
        </InputFieldLabel>
    );
};
export default InfoLabel;
