import { DeleteTwoTone, MoreVertTwoTone } from '@mui/icons-material';
import { alpha, Autocomplete, Divider, IconButton, InputAdornment, ListItemIcon, ListItemText, Menu, MenuItem, Select, Stack, styled, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { recursivelyRemoveTerm } from 'src/utils/TermHelper';
import { formatVariable } from 'src/utils/FormatHelpers';
import { BinaryTermResource, ElasticTermResource, HolderResource, InternalIdentifierHolderResource, TermResource, ValueHolderResource, ValueResource, VariableResource } from 'src/backend/coreCalc';
import { TermOperators } from '../CalcEditor.types';
import { createInternalIdentifierHolder, createValueHolder, getHolderData, getNumberValue, getValue, getVariable, getVariableName } from 'src/utils/CalcHelpers';
import { OperatorSelect } from '../CalcEditor.styles';

const StyledAutocomplete = styled(Autocomplete)<{ nested: number }>(
    ({ theme, nested }) => `
        flex-grow: 1;
        margin: ${nested ? '0 10px' : '2px 0'};

        & .MuiOutlinedInput-root {
            padding: ${theme.spacing(0.7, 1.4)};
            border-radius: ${theme.shape.borderRadius}px;
            align-items: center;
            background: ${nested ? theme.colors.alpha.black[5] : ''};
            font-family: 'Azeret Mono', monospace !important;
            font-size: 16px;
            font-weight: bold;
            outline: none;
            flex-wrap: nowrap;
        }

        & .MuiOutlinedInput-notchedOutline {
            border-color: ${nested ? theme.colors.alpha.black[7] : ''};
        }
        & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
            border-color: ${theme.colors.error.main};
        }
        
        & .MuiInputAdornment-root .MuiButtonBase-root {
            margin: 0 -10px;
        }
    `
);
const Bracket = styled(Typography)(
    ({ theme }) => `
        display: flex;
        height: 2rem;
        width: 1.3rem;
        justify-content: center;
        align-items: center;
        font-size: 3.7rem;
        font-weight: 200;
        margin-top: -2px;
        font-family: 'Azeret Mono', monospace !important;
    `
);
const OperatorSymbol = styled(Typography)(
    ({ theme }) => `
        display: flex;
        height: 24px;
        width: 24px;
        align-items: center;
        justify-content: center;
        padding-bottom: 2px;
        font-weight: 600;
        font-size: 1.48rem;
        line-height: 1;
        border-radius: 0.3rem;
        border: 2px solid;
        background: ${alpha(theme.palette.text.primary, 0.3)};
        opacity: 0.6;
    `
);

interface Props {
    mappedVariables?: { [name: string]: VariableResource };
    singleTerm: TermResource;
    updateSingleTerm: (term: TermResource) => void;
    fullTerm?: TermResource;
    updateFullTerm?: (term: TermResource) => void;
    level?: number;
    minInputWidth?: string;
    disabled?: boolean;
}

const TermBuilderItem: React.FC<Props> = ({ mappedVariables = {}, singleTerm, updateSingleTerm, fullTerm, updateFullTerm, level, minInputWidth = '7rem', disabled }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedTerm, setSelectedTerm] = useState<TermResource | null>(null);
    const [autocompleteValue, setAutocompleteValue] = useState<string>('');

    const hasMultipleTerms = !!fullTerm['b'];

    const flexibleData = useMemo(() => {
        if (singleTerm.type !== TermResource.type.ELASTIC) return;
        return (singleTerm as ElasticTermResource)?.flexibleData;
    }, [singleTerm, mappedVariables]);

    useEffect(() => {
        if (!flexibleData) return;
        const holderData = getHolderData(flexibleData, Object.values(mappedVariables));
        setAutocompleteValue(holderData ? holderData + '' : '');
    }, [singleTerm, mappedVariables]);

    const hasTermError = useMemo(() => {
        if (!flexibleData || flexibleData.type === HolderResource.type.INTERNAL_IDENTIFIER_HOLDER) return;
        const value = (flexibleData as ValueHolderResource).value;
        return getValue(value) == null;
    }, [singleTerm, mappedVariables]);

    const addOperatorTerm = (operator: BinaryTermResource.operator) => {
        const parentTerm = {
            type: TermResource.type.BINARY,
            operator,
            a: { ...singleTerm },
            b: { type: TermResource.type.ELASTIC, flexibleData: createValueHolder(0) } as ElasticTermResource
        } as BinaryTermResource;
        updateSingleTerm(parentTerm);
        setAnchorEl(null);
    };
    const removeTerm = () => {
        const fullTermCopy = { ...fullTerm };
        const newTerm = recursivelyRemoveTerm(fullTermCopy, selectedTerm);
        updateFullTerm(newTerm);
        setSelectedTerm(null);
        setAnchorEl(null);
    };

    const renderTermOptionButton = () => {
        return (
            <IconButton
                disabled={disabled}
                color="secondary"
                size="small"
                onClick={(event) => {
                    setSelectedTerm(singleTerm);
                    setAnchorEl(event.currentTarget);
                }}
            >
                <MoreVertTwoTone />
            </IconButton>
        );
    };

    const handleAutocompleteChange = (event, value) => {
        if (!event) return;

        const isVariable = value.id != null || !!mappedVariables[value];
        let flexibleData: HolderResource;
        if (isVariable) {
            const variable = value.id != null ? (value as VariableResource) : mappedVariables[value];
            flexibleData = createInternalIdentifierHolder(variable);
        } else {
            value = value.replaceAll(',', '.');
            value = value === '' || value == null || isNaN(value) ? null : parseInt(value);
            flexibleData = createValueHolder(value);
        }
        updateSingleTerm({ type: TermResource.type.ELASTIC, flexibleData } as ElasticTermResource);
    };
    const handleAutocompleteBlur = () => {
        if (hasTermError) {
            updateSingleTerm({ type: TermResource.type.ELASTIC, flexibleData: createValueHolder(0) } as ElasticTermResource);
        }
    };

    const renderTerm = () => {
        if (singleTerm.type === TermResource.type.BINARY) {
            const binaryTerm = singleTerm as BinaryTermResource;
            return (
                <Stack direction="row" alignItems="center" gap={0.5}>
                    <Bracket>(</Bracket>
                    <TermBuilderItem
                        mappedVariables={mappedVariables}
                        updateFullTerm={updateFullTerm}
                        fullTerm={fullTerm}
                        singleTerm={binaryTerm.a}
                        updateSingleTerm={(term) => {
                            const termCopy = { ...binaryTerm };
                            termCopy.a = term;
                            updateSingleTerm(termCopy);
                        }}
                        minInputWidth={minInputWidth}
                        disabled={disabled}
                    />
                    <Tooltip title="Mathematisches Zeichen anpassen" placement="top">
                        <OperatorSelect
                            value={binaryTerm.operator}
                            onChange={(event) => {
                                const termCopy = { ...binaryTerm };
                                termCopy.operator = event.target.value as BinaryTermResource.operator;
                                updateSingleTerm(termCopy);
                            }}
                            renderValue={(value) => {
                                const operator = TermOperators.find((operator) => operator.type === value);
                                return operator?.symbol;
                            }}
                            disabled={disabled}
                        >
                            {TermOperators.map((operator) => (
                                <MenuItem key={operator.symbol} value={operator.type}>
                                    <OperatorSymbol>{operator.symbol}</OperatorSymbol>
                                </MenuItem>
                            ))}
                        </OperatorSelect>
                    </Tooltip>

                    <TermBuilderItem
                        mappedVariables={mappedVariables}
                        updateFullTerm={updateFullTerm}
                        fullTerm={fullTerm}
                        level={level + 1}
                        singleTerm={binaryTerm.b}
                        updateSingleTerm={(term) => {
                            const termCopy = { ...binaryTerm };
                            termCopy.b = term;
                            updateSingleTerm(termCopy);
                        }}
                        minInputWidth={minInputWidth}
                        disabled={disabled}
                    />
                    <Bracket>)</Bracket>
                    {level === 0 && renderTermOptionButton()}
                </Stack>
            );
        }

        return (
            <StyledAutocomplete
                disabled={disabled}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip title="Feldoptionen anzeigen" placement="top">
                                        {renderTermOptionButton()}
                                    </Tooltip>
                                </InputAdornment>
                            ),
                            title: autocompleteValue
                        }}
                        error={hasTermError}
                        inputProps={{
                            ...params.inputProps,
                            title: autocompleteValue
                        }}
                    />
                )}
                sx={{ minWidth: minInputWidth }}
                nested={hasMultipleTerms ? 1 : 0}
                noOptionsText={t('noOptions')}
                forcePopupIcon={false}
                disableClearable
                freeSolo
                value={autocompleteValue}
                onInputChange={handleAutocompleteChange}
                onBlur={handleAutocompleteBlur}
                options={Object.values(mappedVariables)}
                getOptionLabel={(option: any) => getVariableName(option) || option}
                renderOption={(props, option: VariableResource) => (
                    <li {...props} key={option.id}>
                        <Stack alignItems="flex-start" overflow="hidden">
                            <Typography fontSize={14} fontWeight={700} lineHeight={1.3} sx={{ textOverflow: 'ellipsis', maxWidth: '100%', overflow: 'hidden' }}>
                                {getVariableName(option)}
                            </Typography>
                            <Typography variant="subtitle2" fontSize={12} lineHeight={1.47} color="inherit" sx={{ opacity: 0.9 }}>
                                {formatVariable(option, Object.values(mappedVariables))}
                            </Typography>
                        </Stack>
                    </li>
                )}
            />
        );
    };

    const renderMenu = () => {
        const isGroup = selectedTerm === null ? false : selectedTerm.type === TermResource.type.BINARY;

        return (
            <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
                {TermOperators.map((operator) => (
                    <MenuItem key={operator.symbol} onClick={() => addOperatorTerm(operator.type)}>
                        <ListItemIcon>
                            <OperatorSymbol>{operator.symbol}</OperatorSymbol>
                        </ListItemIcon>
                        <ListItemText>{operator.name}</ListItemText>
                    </MenuItem>
                ))}

                {!isGroup && hasMultipleTerms && <Divider />}
                {!isGroup && hasMultipleTerms && (
                    <MenuItem onClick={removeTerm}>
                        <ListItemIcon style={{ color: 'red' }}>
                            <DeleteTwoTone />
                        </ListItemIcon>
                        <ListItemText style={{ color: 'red' }}>Löschen</ListItemText>
                    </MenuItem>
                )}
            </Menu>
        );
    };

    return (
        <Stack flexGrow={1}>
            {renderMenu()}
            {renderTerm()}
        </Stack>
    );
};
export default TermBuilderItem;
