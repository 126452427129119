import { InternalCalculationRestControllerService } from 'src/backend/internalCalc';
import { BoughtModulesResource, ModuleRestControllerService, RegularCustomerRestControllerService } from '../../backend/market';
import { BackendService } from '../../backend/summary/BackendService';
import { slice } from '../slices/constants.reducer';
import { slice as customerSlice } from '../slices/customer.reducer';
import { AppDispatch, AppThunk, RootState } from '../store';
import { loadNumberFormats } from './user.thunk';

export const fetchConstants = (): AppThunk => async (dispatch, getState) => {
    const userId = getState().user.currentUser.id;
    const currentLanguageCode = getState().user.currentUser.languageCode;
    const promises = [];

    //REGULAR CUSTOMERS
    promises.push(RegularCustomerRestControllerService.getAllCustomerTypes(userId).then((customerTypes) => dispatch(slice.actions.setCustomerTypes(customerTypes))));
    promises.push(
        RegularCustomerRestControllerService.getRegularCustomerFilterBoundaries(userId).then((customerFilterBoundaries) => {
            dispatch(slice.actions.setCustomerFilterBoundaries(customerFilterBoundaries));
            dispatch(
                customerSlice.actions.updateFilter({
                    searchString: '',
                    customerTypeIds: [],
                    salesVolume: [
                        {
                            minSalesVolumeIncl:
                                customerFilterBoundaries.salesVolumeBoundaries.min === customerFilterBoundaries.salesVolumeBoundaries.max ? 0 : customerFilterBoundaries.salesVolumeBoundaries.min,
                            maxSalesVolumeIncl: customerFilterBoundaries.salesVolumeBoundaries.max
                        }
                    ],
                    inquiryCount: [
                        {
                            minInquiryCountIncl:
                                customerFilterBoundaries.inquiryCountBoundaries.min === customerFilterBoundaries.inquiryCountBoundaries.max ? 0 : customerFilterBoundaries.inquiryCountBoundaries.min,
                            maxInquiryCountIncl: customerFilterBoundaries.inquiryCountBoundaries.max
                        }
                    ]
                })
            );
        })
    );

    //INTERNAL CALCULATIONS
    // promises.push(
    //     InternalCalculationRestControllerService.getInternalCalculationFilterBoundaries(userId).then((internalCalcBoundaries) => {
    //         dispatch(slice.actions.setInternalCalculationBoundaries(internalCalcBoundaries));
    //     })
    // );
    // promises.push(
    //     InternalCalculationRestControllerService.getAllPossibleInternalCalculationStates().then((internalCalcStates) => dispatch(slice.actions.setInternalCalculationStates(internalCalcStates)))
    // );
    // todo instead of a states endpoint, just a enum (provided from the backend generation)

    //WIZARD TRANSLATIONS
    // promises.push(BackendService.loadDictionary(currentLanguageCode).then((dictionary) => dispatch(slice.actions.setWizardTranslations(dictionary))));

    promises.push(dispatch(loadNumberFormats()));

    const promiseAll = await Promise.all(promises);
    return promiseAll;
};

export const setModuleManagement =
    (moduleManagement: BoughtModulesResource): AppThunk =>
    async (dispatch, getState) => {
        dispatch(slice.actions.setModuleManagement(moduleManagement));
    };

export const refetchModuleManagement = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const newModuleManagement = await ModuleRestControllerService.getBoughtModules(getState().user.currentUser.id);
    dispatch(slice.actions.setModuleManagement(newModuleManagement));
};

export const refetchDictionary = (newLanguageCode) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const newDictionary = await BackendService.loadDictionary(newLanguageCode);
    dispatch(slice.actions.setWizardTranslations(newDictionary));
};
