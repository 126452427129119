import { Typography, Stack, Divider, Dialog, DialogTitle, IconButton, DialogContent, styled, Box, useTheme } from '@mui/material';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useMemo } from 'react';
import { VariableResource } from 'src/backend/coreCalc';
import CategoryStatsListItem from './CategoryStatsListItem';

const StatsWrapper = styled(Box)(
    ({ theme }) => `
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: ${theme.spacing(2.5)};
        gap: ${theme.spacing(1)};
    `
);

interface Props {
    isOpen: boolean;
    setOpen?: (isOpen: boolean) => void;
    variables: Array<VariableResource>;
    createVariable: (variable: VariableResource) => void;
    saveVariable: (variable: VariableResource) => void;
    removeVariable: (variableId: number) => void;
}

const CategoryStatsDialog: React.FC<Props> = ({ isOpen, setOpen, variables, createVariable, saveVariable, removeVariable }) => {
    const theme = useTheme();
    const providedVariables = useMemo(() => (variables || []).filter((variable) => variable.type === VariableResource.type.PROVIDED_VARIABLE), [variables]);

    return (
        <Dialog open={isOpen} onClose={() => setOpen(false)} fullWidth maxWidth="md" PaperProps={{ sx: { width: '100%' } }}>
            <DialogTitle>
                <Typography sx={{ fontSize: '1.875rem', fontWeight: 700, mt: 0.5, mb: 1, pr: 7 }} gutterBottom>
                    Statistiken anpassen
                </Typography>
                <Typography sx={{ fontSize: 14, lineHeight: 1.3 }} variant="subtitle1">
                    Wähle aus, wie der Preis für diese Kategorie berechnet werden soll. Wenn keine der vordefinierten Kalkulationen für dich geeignet ist, kannst du deine eigene Kalkulation erstellen.
                </Typography>
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 16, top: 16, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ px: 0, pb: 0 }}>
                <Stack>
                    <Divider />
                    <StatsWrapper>
                        {providedVariables.map((variable) => (
                            <CategoryStatsListItem
                                key={variable.id}
                                variable={variable}
                                variables={variables}
                                createVariable={createVariable}
                                saveVariable={saveVariable}
                                removeVariable={removeVariable}
                            />
                        ))}
                    </StatsWrapper>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default CategoryStatsDialog;
