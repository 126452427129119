import { Stack, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import store, { useDispatch } from 'src/redux/store';
import { InputVariableResource, ValueResource, VariableResource } from 'src/backend/coreCalc';
import { InputVariablesGroupedBySection } from 'src/components/calc-editor/CalcEditor.types';
import InputSection from './InputSection';

interface Props {
    variables: Array<VariableResource>;
    inputVariablesGroupedBySection?: InputVariablesGroupedBySection;
    calcRelevantVariableInternalIdentifiers?: Array<string>;
    saveVariableValue: (variableId: number, value: ValueResource) => void;
}

const InputSections: React.FC<Props> = ({ variables, inputVariablesGroupedBySection, calcRelevantVariableInternalIdentifiers, saveVariableValue }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();
    const s = store.getState();

    const [openAccordionId, setOpenAccordionId] = useState<string>(InputVariableResource.section.GEOMETRY_SECTION);

    return (
        <Stack width="100%" maxWidth="100%">
            {Object.entries(inputVariablesGroupedBySection).map(([section, inputVariables]) => (
                <InputSection
                    key={section}
                    section={section}
                    variables={variables}
                    inputVariables={inputVariables}
                    calcRelevantVariableInternalIdentifiers={calcRelevantVariableInternalIdentifiers}
                    saveVariableValue={saveVariableValue}
                    openAccordionId={openAccordionId}
                    setOpenAccordionId={setOpenAccordionId}
                />
            ))}
        </Stack>
    );
};

export default InputSections;
