/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { VariableResource } from './VariableResource';

export type ProvidedVariableResource = (VariableResource & {
    isDisplayedInStats?: boolean;
    unit?: ProvidedVariableResource.unit;
});

export namespace ProvidedVariableResource {

    export enum unit {
        MILLIMETER = 'MILLIMETER',
        CENTIMETER = 'CENTIMETER',
        METER = 'METER',
        KILOMETER = 'KILOMETER',
        SQUARE_MILLIMETER = 'SQUARE_MILLIMETER',
        SQUARE_CENTIMETER = 'SQUARE_CENTIMETER',
        SQUARE_METER = 'SQUARE_METER',
        SQUARE_KILOMETER = 'SQUARE_KILOMETER',
        CUBIC_MILLIMETER = 'CUBIC_MILLIMETER',
        CUBIC_CENTIMETER = 'CUBIC_CENTIMETER',
        CUBIC_METER = 'CUBIC_METER',
        GRAM = 'GRAM',
        KILOGRAM = 'KILOGRAM',
        TONNE = 'TONNE',
        PIECE = 'PIECE',
    }


}
