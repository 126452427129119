import { Box, Card, Collapse, Stack, Typography, styled, useTheme } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { CalculationPartResource, CalculationResultResource, InternalCalculationResource } from 'src/backend/internalCalc';
import PaletteTwoToneIcon from '@mui/icons-material/PaletteTwoTone';
import ViewInArTwoToneIcon from '@mui/icons-material/ViewInArTwoTone';
import { CalculatedValues, renderCalculatedValue } from 'src/components/display/CalculatedValues';
import { DelayedRenderOfComponent } from 'src/utils/DelayRenderOfComponent';
import SummarizeCalcBar from '../SummaryBar/SummarizeCalcBar';
import { COLOR_SYSTEMS_GUI, MATERIAL, PRICE_DISPLAY, PRICE_DISPLAY_TOTAL_PRICE, TMP_LOADER_ITEM, VARIABLE_VALUE_POSITIONS_PRICE } from 'src/statics/statics';
import store, { RootState } from 'src/redux/store';
import { wT } from 'src/utils/wizardTranslations';
import { formatPrice } from 'src/utils/FormatHelpers';
import Item from './Item/Item';
import ItemSelector from './ItemSelector/ItemSelector';
import HeadlineButton from 'src/components/display/HeadlineButton/HeadlineButton';
import { CalculationResource, ItemResource } from 'src/backend/coreCalc';
import { ViewerDetails, ViewerTypes } from '../ProCalc.types';

const CalculatedValuesWrapper = styled(Box)(
    ({ theme }) => `
        display: grid;
        gap: ${theme.spacing(2)};
        max-width: 100%;
        overflow: hidden;
        justify-content: space-between;
        grid-template-columns: repeat(6, auto);
        margin-left: auto;
        text-align: left;
        font-size: 0.9rem;

        .calculated-value {
            max-width: 15rem;
        }

        .MuiAvatar-root {
            color: ${theme.colors.secondary.light};
            background-color: ${theme.colors.secondary.lighter};
        }

        ${theme.breakpoints.down('md')} {
            grid-template-columns: repeat(3, auto);
        }
        ${theme.breakpoints.down('sm')} {
            grid-template-columns: repeat(2, auto);
        }
        
        @media print {
            grid-template-columns: repeat(6, auto);
        }
    `
);

interface Props {
    calculation?: CalculationResource;
}

const Positions: React.FC<Props> = ({ calculation }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const [isOpen, setOpen] = useState<boolean>(true);
    const [currentViewer, setCurrentViewer] = useState<{ item: ItemResource; dialogType: ViewerTypes }>();

    const itemsWithFile = useMemo(() => (calculation.items || []).filter((item) => item.threeDFileId || item.dxfFileId || item.pdfFileId), [calculation.items]);
    const viewerDetails: ViewerDetails = useMemo(() => {
        if (!currentViewer) return {};
        const currentIndex = itemsWithFile.findIndex((item) => item.id === currentViewer.item.id);

        return {
            dialogType: currentViewer.dialogType,
            previous: itemsWithFile[currentIndex - 1],
            current: currentViewer.item,
            next: itemsWithFile[currentIndex + 1]
        };
    }, [itemsWithFile, currentViewer]);

    const delay = (calculation.items || []).length > 10 ? 700 : (calculation.items || []).length > 5 ? 300 : 0;

    const calculatedPartValues = useMemo(() => {
        const materials = new Set();
        const colors = new Set();

        (calculation.items || []).forEach((item) => {
            // const values = {};
            // (part.setParameters || []).forEach((param) => (values[param.name] = param.value));
            // materials.add(wT(values[MATERIAL], s));
            // const colorSystem = values[COLOR_SYSTEMS_GUI];
            // const color = values[colorSystem];
            // colors.add(`${wT(color, s)} (${wT(colorSystem, s)})`);
        });

        return {
            materials: [...materials].join(', '),
            colors: [...colors].join(', ')
        };
    }, [calculation.items]);

    // const calculatedValues = useMemo(() => {
    //     if (!calculation.costResult) return;
    //     return {
    //         powder: calculation.costResult.totalAmountOfPowder,
    //         weight: calculation.costResult.totalWeight,
    //         surface: calculation.costResult.surface?.value,
    //         volume: calculation.costResult.totalVolume
    //     };
    // }, [calculation.costResult]);

    // const positionsPrice = useMemo(() => {
    //     if (!calculation.costResult || !calculation.costResult.variableValues) return;
    //     const positions = calculation.costResult.variableValues.find((value) => value.name === VARIABLE_VALUE_POSITIONS_PRICE);
    //     return positions?.value;
    // }, [calculation.costResult]);

    const renderLoaderItem = (key?: any) => (
        <Card key={key} sx={{ p: 2, minHeight: '25rem' }}>
            {t('loadingItem')}
        </Card>
    );

    return (
        calculation && (
            <Stack>
                <HeadlineButton isOpen={isOpen} setOpen={setOpen} variant="page">
                    {t('positions')}
                    {/* 
                    <CalculatedValuesWrapper>
                        {calculatedPartValues.colors && renderCalculatedValue(<PaletteTwoToneIcon />, t('colors'), calculatedPartValues.colors)}
                        {calculatedPartValues.materials && renderCalculatedValue(<ViewInArTwoToneIcon />, t('materials'), calculatedPartValues.materials)}
                        <CalculatedValues calculatedValues={calculatedValues} costResult={calculation.costResult} />
                    </CalculatedValuesWrapper> todo */}

                    {/* {positionsPrice != null && (
                        <Stack ml="auto">
                            <Typography color="primary" fontSize={20}>
                                {formatPrice(positionsPrice, true)}
                            </Typography>
                        </Stack>
                    )} todo */}
                </HeadlineButton>

                <Stack mb={3} flexGrow={1} gap={isOpen ? 4 : 2}>
                    {(calculation.items || []).map((item, index) =>
                        item.id === -1 ? (
                            renderLoaderItem('tmp' + index)
                        ) : (
                            <DelayedRenderOfComponent
                                key={item.id}
                                wait={index * delay}
                                component={<Item item={item} viewerDetails={viewerDetails} setCurrentViewer={setCurrentViewer} showCompact={!isOpen} settings={calculation.settings} />}
                                loadingComponent={renderLoaderItem()}
                            />
                        )
                    )}
                    <ItemSelector categories={calculation.categories} activate3dUpload={true} />
                </Stack>

                {/* <SummarizeCalcBar
                    costResult={calculation.costResult}
                    parts={parts}
                    masterAttachments={calculation?.calculationMetadata?.attachments}
                    uniqueId={calculation?.uniqueId}
                    validationStatus={status}
                    showUnitPrice={showUnitPrice}
                /> */}
            </Stack>
        )
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        calculation: state.proCalc.calculation
    };
};
export default connect(mapStateToProps)(Positions);
