import { Box, Card, Chip, Divider, Link, Stack, styled, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import CallTwoToneIcon from '@mui/icons-material/CallTwoTone';
import AlternateEmailTwoToneIcon from '@mui/icons-material/AlternateEmailTwoTone';
import RecordVoiceOverTwoToneIcon from '@mui/icons-material/RecordVoiceOverTwoTone';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import AssistantDirectionTwoToneIcon from '@mui/icons-material/AssistantDirectionTwoTone';
import { formatDate, formatDistance, formatPrice, formatSurchargeValue, getAddressLine1Set, getAddressLine2Set } from 'src/utils/FormatHelpers';
import { useTranslation } from 'react-i18next';
import { LinearProgressWithLabel } from '../LinearProgressWithLabel';
import { CURRENCY_SETTING_NAME, NUMBER_FORMAT_SETTING_NAME } from 'src/statics/statics';
import { SummaryPersonDataResource, SummaryResource, UserResource } from 'src/backend/market';
import { wT } from 'src/utils/wizardTranslations';
import store, { useSelector } from 'src/redux/store';
import { DateTime } from 'luxon';
import { MessagesSummary } from './MessagesSummary';
import { getGeneralSetting } from 'src/utils/CalcHelpers';

const renderOutputWithIcon = (icon, label, value) => {
    return (
        <Box display="flex" alignItems="center" sx={{ '.MuiSvgIcon-root': { fontSize: '1.8em', opacity: 0.7 } }}>
            {icon}
            <Box ml={1}>
                <Typography variant="subtitle2" noWrap fontSize="0.929em" lineHeight={1.2}>
                    {label}
                </Typography>
                <Typography variant="h5" lineHeight={1.23}>
                    {value}
                </Typography>
            </Box>
        </Box>
    );
};

const CalculatedValuesWrapper = styled(Box)(
    ({ theme }) => `
        display: grid;
        gap: ${theme.spacing(2)};
        max-width: 100%;
        overflow: hidden;
        justify-content: space-between;
        grid-template-columns: repeat(6, auto);

        .calculated-value {
            max-width: 15rem;
        }

        ${theme.breakpoints.down('md')} {
            grid-template-columns: repeat(3, auto);
        }
        ${theme.breakpoints.down('sm')} {
            grid-template-columns: repeat(2, auto);
        }
        
        @media print {
            grid-template-columns: repeat(6, auto);
        }
    `
);
interface Props {
    summary: SummaryResource;
    className?: string;
    isPublic?: boolean;
    showPrice?: boolean;
}

export const SummaryPage: React.FC<Props> = ({ className, summary, isPublic, showPrice }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const s = store.getState();
    const user = useSelector<UserResource>((state) => state.user.currentUser);

    const showPriceRange = isPublic;
    const numberFormat = useMemo(() => getGeneralSetting(NUMBER_FORMAT_SETTING_NAME, summary.calculationResult), [summary.calculationResult]);
    const currency = useMemo(() => getGeneralSetting(CURRENCY_SETTING_NAME, summary.calculationResult), [summary.calculationResult]);

    const price = summary.calculationResult?.priceNonRounded || summary.calculationResult?.price;
    const minPrice = summary.calculationResult?.priceMinNonRounded || summary.calculationResult?.priceMin;
    const maxPrice = summary.calculationResult?.priceMaxNonRounded || summary.calculationResult?.priceMax;

    const getPrice = (price) => formatPrice(price, false, numberFormat, currency);

    const priceTotal = showPriceRange ? (
        <>
            {getPrice(minPrice)} - {getPrice(maxPrice)}
        </>
    ) : (
        getPrice(price)
    );

    const companyName = summary.contactPerson?.company;
    const fullName = summary.contactPerson?.name || null;
    const addressLine1 = getAddressLine1Set(summary.contactPerson) || null;
    const addressLine2 = getAddressLine2Set(summary.contactPerson) || null;

    const phone = summary.contactPerson?.phone;
    const email = summary.contactPerson?.email;
    const customerNotes = summary.contactPerson?.note;
    const hasContactDetails = companyName || fullName || addressLine1 || addressLine2 || phone || email || customerNotes;

    let contactViaIcon = <CallTwoToneIcon />;
    let contactViaText = '-';
    if (summary.contactPerson?.contactedVia === 'PHONE') {
        contactViaIcon = <CallTwoToneIcon />;
        contactViaText = t('phone');
    }
    if (summary.contactPerson?.contactedVia === 'EMAIL') {
        contactViaIcon = <AlternateEmailTwoToneIcon />;
        contactViaText = t('email');
    }
    if (summary.contactPerson?.contactedVia === 'IN_PERSON') {
        contactViaIcon = <RecordVoiceOverTwoToneIcon />;
        contactViaText = t('inPerson');
    }
    if (summary.contactPerson?.contactedVia === 'WEB') {
        contactViaIcon = <LanguageTwoToneIcon />;
        contactViaText = t('web');
    }

    const distance = summary.contactPerson?.distanceDuration?.distance ? formatDistance(summary.contactPerson.distanceDuration.distance) : '-';

    const hoursSinceDeliveryDate = summary.deliveryDate ? DateTime.fromISO(summary.deliveryDate).diffNow().as('hours') : 0;
    const hoursBeforeWishDate = summary.wishDate ? DateTime.fromISO(summary.wishDate).diffNow().as('hours') : 0;

    const sumHours = Math.abs(hoursSinceDeliveryDate) + Math.abs(hoursBeforeWishDate);

    const deliveryPercentage = hoursSinceDeliveryDate > 0 ? 0 : hoursBeforeWishDate <= 0 ? 100 : (100 * Math.abs(hoursSinceDeliveryDate)) / sumHours;

    // todo
    // const materials = [...new Set(summary.materialArray || [])].join(', ');
    // const colorStrings = (summary.colorSummary || []).map(color => `${wT(color.value, s)} (${wT(color.system, s)})`);
    // const colors = [...new Set(colorStrings)].join(', ');

    // const calculatedValues = useMemo(() => {
    //     return (summary.parts).reduce((sum, part) => {
    //         const result = part.calculationResult;
    //         const variableValues = result.variableValues || [];
    //         const powder = result.totalAmountOfPowder || parseFloat(variableValues.find(value => value.name === VARIABLE_VALUE_GEOMETRY_POWDER)?.value);
    //         const weight = result.totalWeight || parseFloat(variableValues.find(value => value.name === VARIABLE_VALUE_GEOMETRY_WEIGHT)?.value);
    //         const surface = result.surface?.value ?? parseFloat(variableValues.find(value => value.name === VARIABLE_VALUE_GEOMETRY_SURFACE)?.value);
    //         const volume = result.totalVolume || parseFloat(variableValues.find(value => value.name === VARIABLE_VALUE_GEOMETRY_VOLUME)?.value);
    //         const numberOfPieces = parseInt(part.partSummary.numberOfPieces) || 1;

    //         sum.powder += (powder || 0) * numberOfPieces;
    //         sum.weight += (weight || 0) * numberOfPieces;
    //         sum.surface += (surface || 0) * numberOfPieces;
    //         sum.volume += (volume || 0) * numberOfPieces;

    //         return sum;
    //     }, { powder: 0, weight: 0, surface: 0, volume: 0 });
    // }, [summary.calculationResult]);

    return (
        <Card className={className} sx={{ width: '70rem', maxWidth: { xs: '95vw', md: '90vw', lg: '80vw' }, flexShrink: 0 }}>
            <Stack p={{ xs: 4, md: 6, lg: '2.5cm' }}>
                <Stack direction="row" gap={4} justifyContent="space-between" flexWrap="wrap-reverse">
                    <Stack maxWidth="100%">
                        {/* <Typography variant="h1" gutterBottom>{t(summary.summaryType)}</Typography> */}
                        <Typography variant="h2" color="text.secondary" width={350} maxWidth="100%" noWrap>
                            #{summary.id}
                            {summary.name && summary.id !== summary.name && ' | ' + summary.name}
                        </Typography>
                    </Stack>
                    <Stack>
                        <img src={summary.companyLogo} height={40} />
                    </Stack>
                </Stack>

                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={{ xs: 4, md: 8 }}
                    mt={4}
                    alignItems={{ xs: 'stretch', md: 'center' }}
                    justifyContent="space-between"
                    className="contact-wrapper"
                >
                    <Stack>
                        <Typography variant="h4" textTransform="uppercase" noWrap mb={0.6}>
                            {t('contact')}
                        </Typography>
                        {!hasContactDetails && (
                            <Typography fontSize="1em" lineHeight={1.4}>
                                {t('notAvailable')}
                            </Typography>
                        )}
                        {companyName && (
                            <Stack direction="row" alignItems="center" spacing={0.5}>
                                <Typography fontSize="1em" lineHeight={1.4}>
                                    {companyName}
                                </Typography>
                                {summary.contactPerson.type === SummaryPersonDataResource.type.REGULAR_CUSTOMER && (
                                    <Typography
                                        fontSize="0.85em"
                                        fontWeight="bold"
                                        lineHeight={1}
                                        bgcolor={theme.colors.warning.lighter}
                                        color={theme.colors.warning.main}
                                        p={0.4}
                                        borderRadius={0.6}
                                        className="regular-customer"
                                    >
                                        {t('regularCustomer')}
                                    </Typography>
                                )}
                            </Stack>
                        )}
                        {fullName && (
                            <Typography fontSize="1em" lineHeight={1.4}>
                                {fullName}
                            </Typography>
                        )}
                        {addressLine1 && (
                            <Typography fontSize="1em" lineHeight={1.4}>
                                {addressLine1}
                            </Typography>
                        )}
                        {addressLine2 && (
                            <Typography fontSize="1em" lineHeight={1.4}>
                                {addressLine2}
                            </Typography>
                        )}
                        {(phone || email) && <Divider sx={{ my: 1 }} />}
                        {phone && (
                            <Typography fontSize="1em" lineHeight={1.4}>
                                {phone}
                            </Typography>
                        )}
                        {email && (
                            <Typography fontSize="1em" lineHeight={1.4}>
                                {email}
                            </Typography>
                        )}
                        {customerNotes && (
                            <Typography fontSize="1em" lineHeight={1.4}>
                                {t('customerNotes')}: {customerNotes}
                            </Typography>
                        )}
                    </Stack>

                    <Stack spacing={3}>
                        <Stack direction="row" justifyContent="space-between" rowGap={2} columnGap={4} flexWrap="wrap">
                            <Stack spacing={2}>
                                {renderOutputWithIcon(<EventTwoToneIcon />, t('erstellungsdatum'), formatDate(summary.createdAt))}
                                {renderOutputWithIcon(<AssistantDirectionTwoToneIcon />, t('distance'), distance)}
                            </Stack>
                            <Stack spacing={2}>
                                {renderOutputWithIcon(<EditTwoToneIcon />, t('lastModified'), formatDate(summary.updatedAt))}
                                {renderOutputWithIcon(contactViaIcon, t('contactedVia'), contactViaText)}
                            </Stack>
                        </Stack>

                        {summary.wishDate && summary.deliveryDate && (
                            <Stack>
                                <Stack direction="row" spacing={2} justifyContent="space-between" mb={0.6}>
                                    <Stack>
                                        <Typography fontSize="0.929em" lineHeight={1.2} noWrap color="text.secondary">
                                            {t('anlieferung')}
                                        </Typography>
                                        <Typography fontSize="1em" lineHeight={1.4} noWrap fontWeight="bold">
                                            {formatDate(summary.deliveryDate, 'dd.MM.yyyy') || '-'}
                                        </Typography>
                                    </Stack>

                                    {summary.throughputDays != null && (
                                        <Stack justifyContent="flex-end">
                                            <Typography fontSize="1em" lineHeight={1.4} noWrap color="text.secondary">
                                                ⇠ {summary.throughputDays || ''} {t(summary.throughputDays === 0 ? 'sameDay' : summary.throughputDays > 1 ? 'days' : 'day')} ⇢
                                            </Typography>
                                        </Stack>
                                    )}

                                    <Stack textAlign="right">
                                        <Typography fontSize="0.929em" lineHeight={1.2} noWrap color="text.secondary">
                                            {t('abholung')}
                                        </Typography>
                                        <Typography fontSize="1em" lineHeight={1.4} noWrap fontWeight="bold">
                                            {formatDate(summary.wishDate, 'dd.MM.yyyy') || '-'}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <LinearProgressWithLabel value={deliveryPercentage} className="delivery-percentage" />
                            </Stack>
                        )}
                    </Stack>
                </Stack>

                <Typography variant="h4" mt={4} mb={1.6} textTransform="uppercase">
                    {t('summary')}
                </Typography>
                {(summary.note || summary.commonAttachments?.length > 0) && (
                    <>
                        {summary.note && (
                            <Stack direction="row">
                                <Typography fontSize="0.929em" lineHeight={1.4} color="text.secondary" mr={0.4}>
                                    {t('notes')}:
                                </Typography>
                                <Typography fontSize="0.929em" lineHeight={1.4} sx={{ wordBreak: 'break-word' }}>
                                    {summary.note}
                                </Typography>
                            </Stack>
                        )}
                        {summary.commonAttachments?.length > 0 && (
                            <Stack direction="row">
                                <Typography fontSize="0.929em" lineHeight={1.4} color="text.secondary">
                                    {t('attachments')}:
                                </Typography>
                                <Typography fontSize="0.929em" lineHeight={1.4}>
                                    <Box display="flex" flexWrap="wrap">
                                        {summary.commonAttachments.map((attachment, index) => (
                                            <Box key={index} display="flex" ml={0.4}>
                                                {/* <OpenAttachment
                                                    attachment={attachment}
                                                    displayElement={(openAttachment) => (
                                                        <Link
                                                            component="button"
                                                            variant="body1"
                                                            fontSize="1em"
                                                            lineHeight={1.4}
                                                            onClick={() => {
                                                                openAttachment();
                                                            }}
                                                        >
                                                            {attachment.originalFileName}
                                                        </Link>
                                                    )}
                                                /> */}
                                                {summary.commonAttachments.length - 1 !== index && ', '}
                                            </Box>
                                        ))}
                                    </Box>
                                </Typography>
                            </Stack>
                        )}
                        <Divider sx={{ mt: 1, mb: 2 }} />
                    </>
                )}
                <CalculatedValuesWrapper>
                    {/* {renderCalculatedValue(<PaletteTwoToneIcon />, t('colors'), colors || '-')}
                {renderCalculatedValue(<ViewInArTwoToneIcon />, t('materials'), materials || '-')}
                <CalculatedValues calculatedValues={calculatedValues} showZero={true} costResult={summary.calculationResult}/> */}
                </CalculatedValuesWrapper>
                <Divider sx={{ mt: 2, mb: 1 }} />
                <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1} flexWrap="wrap-reverse">
                    <Stack direction="row" gap={1} flexWrap="wrap">
                        {(summary.calculationResult?.surchargeReport || []).map(
                            (surcharge, index) =>
                                surcharge.value != 0 && (
                                    <Chip
                                        key={index}
                                        label={
                                            <>
                                                {surcharge.description}{' '}
                                                {/* <span style={{ fontWeight: 'bold' }}>{formatSurchargeValue(surcharge.value, surcharge.absoluteSurcharge, numberFormat, currency)}</span> todo */}
                                            </>
                                        }
                                        sx={{ fontSize: '0.929em', height: 26, px: 0.2, backgroundColor: theme.colors.alpha.black[7] }}
                                    />
                                )
                        )}
                        {(summary.priceManipulationParameterValues || []).map(
                            (paramValue, index) =>
                                paramValue.value != '0' && (
                                    <Chip
                                        key={index}
                                        label={
                                            <>
                                                {wT(paramValue.parameter.label, s)} <span style={{ fontWeight: 'bold' }}>{paramValue.value + paramValue.parameter.unit}</span>
                                            </>
                                        }
                                        sx={{ fontSize: '0.929em', height: 26, px: 0.2, backgroundColor: theme.colors.alpha.black[7] }}
                                    />
                                )
                        )}
                    </Stack>
                    <Typography variant="h3" color="primary">
                        {priceTotal}
                    </Typography>
                </Stack>

                {/* {summary.parts?.length > 0 && (
                    <>
                        <Typography variant="h4" mt={4} textTransform="uppercase">
                            {t('articles')}
                        </Typography>
                        {summary.parts.map((part, index) => (
                            <PartSummary
                                key={index}
                                part={part}
                                numberFormat={numberFormat}
                                currency={currency}
                                className={index === 0 ? 'first' : ''}
                                showPriceRange={showPriceRange}
                                hidePrice={isPublic || !showPrice}
                                costResult={summary.calculationResult}
                            />
                        ))}
                    </>
                )} */}

                <MessagesSummary summary={summary} />

                {summary.companyResource && (
                    <>
                        <Divider sx={{ mt: 3, mb: 1 }} />
                        <Stack>
                            <Typography variant="h6" fontSize="0.786em" lineHeight={1.4}>
                                {summary.companyResource?.name}
                            </Typography>
                            <Typography variant="subtitle2" fontSize="0.786em" lineHeight={1.4}>
                                {getAddressLine1Set(summary.companyResource)}
                            </Typography>
                            <Typography variant="subtitle2" fontSize="0.786em" lineHeight={1.4}>
                                {getAddressLine2Set(summary.companyResource)}
                            </Typography>
                        </Stack>
                    </>
                )}
            </Stack>
        </Card>
    );
};
