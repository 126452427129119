import { Typography, IconButton, useTheme, InputAdornment, Dialog, DialogTitle, DialogContent, SxProps, Theme } from '@mui/material';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { ReactNode, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import { HolderResource, RuleResource, VariableResource } from 'src/backend/coreCalc';
import { createValueHolder, getHolderData } from 'src/utils/CalcHelpers';
import RuleInputs from './RuleInputs';
import HolderInput from '../HolderInput/HolderInput';

interface Props {
    rules: Array<RuleResource>;
    variables: Array<VariableResource>;
    isSmall?: boolean;
    isDisabled?: boolean;
    startAdornment?: ReactNode;
    helperText?: ReactNode;
    inputLabel: string;
    dialogTitle: string;
    dialogSubtitle: string;
    sx?: SxProps<Theme>;
    setRules: (rules: Array<RuleResource>) => void;
}

const SummarizedRuleInput: React.FC<Props> = ({ rules, variables, isSmall, isDisabled, startAdornment, helperText, inputLabel, dialogTitle, dialogSubtitle, sx, setRules }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [isOpen, setOpen] = useState<boolean>(false);

    const areMultipleRules = rules?.length > 1;

    const valueHolder = useMemo(() => {
        if (areMultipleRules) {
            const values = rules.map((rule) => getHolderData(rule.valueHolder, variables));
            return createValueHolder(values.join(' | '));
        }
        const defaultRule = (rules || []).find((rule) => rule.isDefault);
        return defaultRule?.valueHolder;
    }, [rules, areMultipleRules]);

    const changeDefaultRuleValue = (valueHolder: HolderResource) => {
        if (areMultipleRules) return;

        const clonedRules = cloneDeep(rules);
        const defaultRuleIndex = (clonedRules || []).findIndex((rule) => rule.isDefault);
        if (defaultRuleIndex === -1) return;
        clonedRules[defaultRuleIndex].valueHolder = valueHolder;

        setRules(clonedRules);
    };

    return (
        <>
            <HolderInput
                label={inputLabel}
                holder={valueHolder}
                setHolder={changeDefaultRuleValue}
                variables={variables}
                disabled={isDisabled || areMultipleRules}
                startAdornment={startAdornment}
                endAdornment={
                    <InputAdornment position="end" sx={{ mr: -1 }}>
                        <IconButton color="primary" onClick={() => setOpen(true)} size={isSmall ? 'small' : 'medium'} disabled={isDisabled}>
                            <SettingsTwoToneIcon />
                        </IconButton>
                    </InputAdornment>
                }
                helperText={helperText}
                size={isSmall ? 'small' : 'medium'}
                sx={sx}
            />

            <Dialog open={isOpen} fullWidth maxWidth="sm" PaperProps={{ sx: { width: '100%' } }} onClose={() => setOpen(false)}>
                <DialogTitle>
                    <Typography sx={{ fontSize: '1.875rem', fontWeight: 700, mt: 0.5, mb: 1, pr: 7 }} gutterBottom>
                        {dialogTitle}
                    </Typography>
                    <Typography sx={{ fontSize: 14, lineHeight: 1.3 }} variant="subtitle1">
                        {dialogSubtitle}
                    </Typography>
                    <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 16, top: 16, color: (theme) => theme.palette.grey[500] }} disabled={isDisabled}>
                        <CloseTwoToneIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ pb: 0 }}>
                    <RuleInputs rules={rules} variables={variables} setRules={setRules} isDisabled={isDisabled} />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default SummarizedRuleInput;
