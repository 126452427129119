import { Box, ClickAwayListener, IconButton, Stack, styled, SxProps, TextField, Theme, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import store from 'src/redux/store';

const IconButtonEdit = styled(IconButton)(
    ({ theme }) => `
        margin-top: -1px;
        margin-right: ${theme.spacing(0.5)};
        padding: ${theme.spacing(0.8)};
  
        .MuiSvgIcon-root {
            font-size: ${theme.typography.pxToRem(15)};
        }
    `
);

interface Props {
    sx?: SxProps<Theme>;
    isSmall?: boolean;
    text?: string;
    saveText?: (text: string) => void;
}

const ChangeableText: React.FC<Props> = ({ sx, isSmall, text = '', saveText }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const { t } = useTranslation();
    const [inputText, setInputText] = useState<string>(text);
    const [isRenaming, setRename] = useState<boolean>(false);

    useEffect(() => setInputText(text), [text]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.persist();
        setInputText(event.target.value);
    };

    const handleKeypress = (event) => {
        if (event.key === 'Enter') {
            event.target.blur();
        }
    };

    const handleRename = async (): Promise<void> => {
        if (inputText !== text) saveText(inputText);
        setRename(false);
    };

    return (
        <Stack direction="row" alignItems="center" sx={sx}>
            {isRenaming ? (
                <ClickAwayListener onClickAway={handleRename}>
                    <TextField
                        value={inputText}
                        size="small"
                        onBlur={handleRename}
                        onChange={handleChange}
                        onKeyDown={handleKeypress}
                        variant="outlined"
                        sx={{ m: 0 }}
                        inputProps={{ sx: { px: 1, py: 0.5 }, maxLength: 50 }}
                    />
                </ClickAwayListener>
            ) : (
                <Typography
                    id="piecesAndNameStack"
                    variant={isSmall ? 'h5' : 'h4'}
                    mb={isSmall && '-2px'}
                    component="span"
                    onClick={() => {
                        setRename(true);
                    }}
                    noWrap
                >
                    {text}
                </Typography>
            )}
            <Box display="flex" alignItems="center" pl={0.2}>
                <Tooltip arrow placement="top" title={t('rename')}>
                    <IconButtonEdit onClick={() => setRename(true)}>
                        <EditTwoToneIcon color="primary" />
                    </IconButtonEdit>
                </Tooltip>
            </Box>
        </Stack>
    );
};

export default ChangeableText;
