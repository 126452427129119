/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CustomerResource } from './CustomerResource';
import type { ShortItemResource } from './ShortItemResource';
import type { ShortSettingsResource } from './ShortSettingsResource';

export type ShortCalculationResource = {
    id?: string;
    name?: string;
    createdAt?: string;
    updatedAt?: string;
    status?: ShortCalculationResource.status;
    settings?: ShortSettingsResource;
    customer?: CustomerResource;
    items?: Array<ShortItemResource>;
    value?: number;
    bringDate?: string;
    wishDate?: string;
    distance?: number;
}

export namespace ShortCalculationResource {

    export enum status {
        UNOPENED = 'UNOPENED',
        PENDING_MAIL_ANSWER = 'PENDING_MAIL_ANSWER',
        NEEDS_MAIL_CLARIFICATION = 'NEEDS_MAIL_CLARIFICATION',
        CALCULATING = 'CALCULATING',
        SHIPPING = 'SHIPPING',
        COMPLETED = 'COMPLETED',
    }


}
