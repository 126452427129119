import { Box, Button, IconButton, Stack, styled, Typography, useTheme } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import store, { RootState } from 'src/redux/store';
import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone';
import { CategoryResource, ComputationTemplateResource, VariableResource } from 'src/backend/coreCalc';
import ComputationDialog from './ComputationDialog';

interface Props {
    category?: CategoryResource;
    calcRelevantVariableInternalIdentifiers?: Array<string>;
    computationTemplates?: Array<ComputationTemplateResource>;
    saveVariable: (variable: VariableResource) => void;
    createVariable: (variable: VariableResource) => void;
    removeVariable: (variableId: number) => void;
}

const ComputationSelection: React.FC<Props> = ({ category, calcRelevantVariableInternalIdentifiers, computationTemplates, saveVariable, createVariable, removeVariable }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState<boolean>(false);

    const computationName = useMemo(() => {
        const computation = (category?.supportedComputations || []).find((computation) => computation.id === category.selectedComputationId);
        return computation?.name;
    }, [category?.supportedComputations, category?.selectedComputationId]);

    return (
        <Stack mx="auto" direction="row" gap={0.8} alignItems="center">
            <Typography variant="subtitle2">Kalkulation:</Typography>
            <Button variant="contained" sx={{ fontSize: 16, lineHeight: 1, minWidth: '8rem' }} color="primary" endIcon={<ArrowDropDownTwoToneIcon />} onClick={() => setOpen(true)}>
                {computationName}
            </Button>
            <ComputationDialog
                category={category}
                calcRelevantVariableInternalIdentifiers={calcRelevantVariableInternalIdentifiers}
                computationTemplates={computationTemplates}
                createVariable={createVariable}
                saveVariable={saveVariable}
                removeVariable={removeVariable}
                isOpen={isOpen}
                setOpen={(isOpen) => setOpen(isOpen)}
            />
        </Stack>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        computationTemplates: state.templates.collection?.computationTemplates
    };
};
export default connect(mapStateToProps)(ComputationSelection);
