import { Stack, useTheme, MenuItem, Select, styled, IconButton, FormControl } from '@mui/material';
import { useDispatch } from 'react-redux';
import { VariableResource, CriterionResource, HolderResource } from 'src/backend/coreCalc';
import VariableSelectionInput from '../VariableSelectionInput/VariableSelectionInput';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useTranslation } from 'react-i18next';
import HolderInput from '../HolderInput/HolderInput';
import { createInternalIdentifierHolder, getHolderData, getVariableFromHolder } from 'src/utils/CalcHelpers';

const StyledStack = styled(Stack)(
    ({ theme }) => `
        flex-grow: 1;
        max-width: 100%;
        gap: ${theme.spacing(1.5)};

        & > * {
            flex-grow: 1
        }
    `
);

interface Props {
    criterion: CriterionResource;
    variables: Array<VariableResource>;
    isDisabled?: boolean;
    updateCriterion: (updatedCriterion: CriterionResource, oldCriterion: CriterionResource) => void;
    removeCriterion: (criterionToRemove: CriterionResource) => void;
}

const CriterionInput: React.FC<Props> = ({ criterion, variables, isDisabled, updateCriterion, removeCriterion }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleLeftChange = (variable: VariableResource) => {
        updateCriterion({ ...criterion, left: createInternalIdentifierHolder(variable) }, criterion);
    };
    const handleOperatorSelection = (event) => {
        updateCriterion({ ...criterion, operator: event.target.value }, criterion);
    };
    const handleRightChange = (right: HolderResource) => {
        updateCriterion({ ...criterion, right }, criterion);
    };

    return (
        <StyledStack direction="row">
            <VariableSelectionInput
                selectedVariableInternalIdentifier={getVariableFromHolder(criterion.left, variables)?.internalIdentifier}
                variables={variables}
                onVariableSelection={handleLeftChange}
                label="Bezugsvariable"
                isDisabled={isDisabled}
                size="small"
                sx={{ width: '40%' }}
            />
            <FormControl disabled={isDisabled} sx={{ width: '20%' }}>
                <Select value={criterion.operator || ''} onChange={handleOperatorSelection} displayEmpty disabled={isDisabled} size="small" renderValue={(selected) => t(selected)}>
                    {Object.values(CriterionResource.operator).map((operator) => (
                        <MenuItem key={operator} value={operator}>
                            {t(operator)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <HolderInput label="Wert/Variable" holder={criterion.right} setHolder={handleRightChange} variables={variables} disabled={isDisabled} size="small" sx={{ width: '40%' }} />
            <IconButton color="secondary" sx={{ mr: -0.5 }} onClick={() => removeCriterion(criterion)}>
                <CloseTwoToneIcon />
            </IconButton>
        </StyledStack>
    );
};

export default CriterionInput;
