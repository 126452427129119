import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ALLOWED_3D_FILE_TYPES, IMG_MIME_TYPES, PDF_MIME_TYPE } from 'src/statics/statics';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import SurfLoader from 'src/components/display/Loader/SurfLoader';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { FileResource } from 'src/backend/market';
import { loadFile } from 'src/redux/thunks/fileManager.thunk';
import { SSA3DViewer } from '@surface-solutions/ssa-3d-viewer';
import i18n from 'src/i18n/i18n';
import ThreeDViewerDialog from '../ThreeDViewerDialog/ThreeDViewerDialog';
import { getFileExtension } from 'src/utils/CalcHelpers';

export type FileResourceWrapper = FileResource & {
    isImageLoaded?: boolean;
    fileExtension?: string;
};

interface Props {
    nodeId: string;
    displayElement: (openFile: Function) => JSX.Element;
}

const OpenFile: React.FC<Props> = ({ nodeId, displayElement }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [openedFile, setOpenedFile] = useState<FileResourceWrapper>(null);

    const openFile = async () => {
        setOpenedFile({ name: t('loadingFile') });
        const fileResource = await dispatch(loadFile(nodeId));
        setTimeout(() => {
            setOpenedFile({ ...fileResource, fileExtension: getFileExtension(fileResource.name) });
        }, 500);
    };

    return (
        <>
            {displayElement(openFile)}

            {ALLOWED_3D_FILE_TYPES.includes(openedFile?.fileExtension) ? (
                <ThreeDViewerDialog fileResource={openedFile} isOpen={!!openedFile} setOpen={() => setOpenedFile(null)} />
            ) : (
                <Dialog
                    onClose={() => setOpenedFile(null)}
                    open={!!openedFile}
                    maxWidth="xl"
                    PaperProps={openedFile?.fileType === PDF_MIME_TYPE ? { sx: { width: '100%', height: '100%' } } : { sx: { minWidth: '25rem' } }}
                >
                    {!!openedFile && (
                        <>
                            <DialogTitle sx={{ pr: 7 }}>
                                {openedFile.name}
                                <IconButton aria-label="close" onClick={() => setOpenedFile(null)} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
                                    <CloseTwoToneIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent sx={{ overflow: 'hidden', minHeight: '20rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {openedFile.url ? (
                                    IMG_MIME_TYPES.includes(openedFile.fileType) ? (
                                        <img
                                            src={openedFile.url}
                                            width="100%"
                                            onLoad={() => {
                                                setOpenedFile({ ...openedFile, isImageLoaded: true });
                                            }}
                                        />
                                    ) : openedFile.fileType === PDF_MIME_TYPE ? (
                                        <object data={openedFile?.url} type={openedFile.fileType} width="100%" height="100%">
                                            <a href={openedFile?.url}>{openedFile?.url}</a>
                                        </object>
                                    ) : ALLOWED_3D_FILE_TYPES.includes(openedFile.fileExtension) ? (
                                        <SSA3DViewer url={openedFile.url} fileName={openedFile.name} language={i18n.language} />
                                    ) : (
                                        <Button href={openedFile?.url} target="_blank" download={true} variant="contained" color="primary">
                                            {t('downloadFile')}
                                        </Button>
                                    )
                                ) : (
                                    <Stack alignItems="center" justifyContent="center" height="100%">
                                        <SurfLoader />
                                    </Stack>
                                )}
                            </DialogContent>
                        </>
                    )}
                </Dialog>
            )}
        </>
    );
};

export default OpenFile;
