/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class DemoAccountRestControllerService {

    /**
     * @param userId
     * @returns any OK
     * @throws ApiError
     */
    public static createRegularCustomerPortalSetup(
        userId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/marketplace/demoAccounts/${userId}/regularCustomerPortalSetup`,
        });
    }

    /**
     * @param userId
     * @returns any OK
     * @throws ApiError
     */
    public static createDemoInternalCalculationsSetup(
        userId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/marketplace/demoAccounts/${userId}/internalCalculationsSetup`,
        });
    }

    /**
     * @param userId
     * @returns any OK
     * @throws ApiError
     */
    public static createInternalCalcModuleSetup(
        userId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/marketplace/demoAccounts/${userId}/internalCalcModuleSetup`,
        });
    }

    /**
     * @param userId
     * @returns any OK
     * @throws ApiError
     */
    public static createNewDemoSetup(
        userId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/marketplace/demoAccounts/${userId}/demoSetup`,
        });
    }

}