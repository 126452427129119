import { Autocomplete, Stack, SxProps, TextField, Theme, Typography } from '@mui/material';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HolderResource, NumberValueResource, ValueHolderResource, VariableResource } from 'src/backend/coreCalc';
import { createInternalIdentifierHolder, createValueHolder, getHolderData, getValue, getVariableName } from 'src/utils/CalcHelpers';
import { formatVariable } from 'src/utils/FormatHelpers';

interface Props {
    holder: HolderResource;
    setHolder: (holder: HolderResource) => void;
    variables: Array<VariableResource>;
    label?: string;
    disabled?: boolean;
    size?: 'small' | 'medium';
    sx?: SxProps<Theme>;
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
    helperText?: ReactNode;
}

const HolderInput: React.FC<Props> = ({ holder, setHolder, variables, label, disabled, size, sx, startAdornment, endAdornment, helperText }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<string>('');

    const mappedVariables = useMemo(() => {
        const vars: { [label: string]: VariableResource } = {};
        (variables || []).forEach((variable) => {
            vars[getVariableName(variable)] = variable;
        });
        return vars;
    }, [variables]);

    useEffect(() => {
        const holderData = getHolderData(holder, variables);
        setValue(holderData == null ? '' : holderData + '');
    }, [holder]);

    const hasError = useMemo(() => {
        if (!holder || holder.type === HolderResource.type.INTERNAL_IDENTIFIER_HOLDER) return;
        const value = (holder as ValueHolderResource).value;
        return getValue(value) == null;
    }, [holder]);

    const handleAutocompleteChange = (event, value) => {
        if (!event) return;
        const isVariable = value.id != null || !!mappedVariables[value];
        if (isVariable) {
            const variable = value.id != null ? (value as VariableResource) : mappedVariables[value];
            setHolder(createInternalIdentifierHolder(variable));
        } else {
            value = value.replaceAll(',', '.');
            value = value === '' || value == null || isNaN(value) ? null : parseInt(value);
            setHolder(createValueHolder(value));
        }
    };

    const handleAutocompleteBlur = () => {
        if (hasError) {
            setHolder(createValueHolder(0));
        }
    };

    return (
        <Autocomplete
            disabled={disabled}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={hasError}
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                        title: value
                    }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment,
                        endAdornment
                    }}
                    helperText={helperText}
                />
            )}
            noOptionsText={t('noOptions')}
            forcePopupIcon={false}
            disableClearable
            freeSolo
            size={size}
            sx={sx}
            value={value}
            onInputChange={handleAutocompleteChange}
            onBlur={handleAutocompleteBlur}
            options={Object.values(mappedVariables)}
            getOptionLabel={(option: any) => getVariableName(option) || option}
            renderOption={(props, option: VariableResource) => (
                <li {...props} key={option.id}>
                    <Stack alignItems="flex-start" overflow="hidden">
                        <Typography fontSize={14} fontWeight={700} lineHeight={1.3} sx={{ textOverflow: 'ellipsis', maxWidth: '100%', overflow: 'hidden' }}>
                            {getVariableName(option)}
                        </Typography>
                        <Typography variant="subtitle2" fontSize={12} lineHeight={1.47} color="inherit" sx={{ opacity: 0.9 }}>
                            {formatVariable(option, variables)}
                        </Typography>
                    </Stack>
                </li>
            )}
        />
    );
};
export default HolderInput;
