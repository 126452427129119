/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TermDTO = {
    type: TermDTO.type;
}

export namespace TermDTO {

    export enum type {
        ELASTIC = 'ELASTIC',
        BINARY = 'BINARY',
    }


}
