import { lazy } from 'react';
import Invite from 'src/pages/Auth/Invite/Invite';
import VerifyEmail from 'src/pages/Auth/Verification/VerifyEmail';
import Dashboard from 'src/pages/Core/Dashboard/Dashboard';
import EditProfile from 'src/pages/Profile/Edit';
import OptInOut from 'src/pages/OptInOut/OptInOut';
import { Loader } from 'src/utils/RouteHelper';
import accountChildren from './accountNavigation';
import baseChildren from './baseNavigation';
import calculationsChildren from './calculationsNavigation';
import toolsChildren from './toolsNavigation';
import viewer3d from './viewer3dNavigation';
import AutoAnalyze3d from 'src/pages/Core/AutoAnalyze3d/AutoAnalyze3d';

const FullRegistration = Loader(lazy(() => import('src/pages/Profile/FullUserDataInput')));

export interface NavItems {
    [navIndex: string]: NavItem;
}

export interface NavItem {
    caption: string;
    tabTitle: string;
    path: string;
    element?: React.ReactNode;
    hideFooter?: boolean;
    icon?: any;
    protected?: boolean;
    requiredRoles?: Array<string>;
    sub?: NavItems;
}

export const nav: NavItems = {
    DASHBOARD: {
        caption: 'dashboard',
        tabTitle: 'dashboardTabTitle',
        path: '/',
        protected: true,
        element: <Dashboard />
    },
    CALCULATIONS: {
        caption: 'calculations',
        tabTitle: 'calculationsTabTitle',
        path: '/calculations/',
        protected: true,
        sub: calculationsChildren
    },
    VIEWER_3D: {
        caption: 'viewer3d',
        tabTitle: 'viewer3dTabTitle',
        path: '/viewer/',
        protected: true,
        sub: viewer3d
    },
    TOOLS: {
        caption: 'tools',
        tabTitle: 'toolsTabTitle',
        path: '/tools/',
        protected: true,
        sub: toolsChildren
    },
    ACCOUNT: {
        caption: 'account',
        tabTitle: 'accountTabTitle',
        path: '/account/',
        sub: accountChildren
    },
    VERIFY_EMAIL: {
        caption: 'verifyMail',
        tabTitle: 'verifyMailTabTitle',
        path: '/verifyMail/:verificationId',
        element: <VerifyEmail />
    },
    INVITE: {
        caption: 'acceptInvite',
        tabTitle: 'acceptInviteTabTitle',
        path: '/acceptInvite/:inviteId',
        element: <Invite />
    },
    FULL_REGISTRATION: {
        caption: 'fullRegistration',
        tabTitle: 'fullRegistrationTabTitle',
        path: '/full-registration/',
        protected: true,
        element: <FullRegistration />
    },
    EDIT_PROFILE: {
        caption: 'editProfile',
        tabTitle: 'editProfileTabTitle',
        path: '/edit-profile/',
        protected: true,
        element: <EditProfile />
    },
    OPT_IN_OUT: {
        caption: 'optInOut',
        tabTitle: 'optInOutTabTitle',
        path: '/optInOut/:mail',
        element: <OptInOut />
    },
    AUTO_ANALYZE_3D: {
        caption: 'autoAnalyze3d',
        tabTitle: 'autoAnalyze3dTabTitle',
        path: '/auto-analyze/',
        protected: true,
        element: <AutoAnalyze3d />
    },
    BASE: {
        caption: 'base',
        tabTitle: 'baseTabTitle',
        path: '*',
        sub: baseChildren
    }
};
