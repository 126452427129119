// noinspection AllyPlainJsInspection

import { Box, Card, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DashboardWrapper } from './Dashboard.styles';
import InternalCalcs from './Cards/InternalCalcs';
import Profile from './Cards/Profile';
import PageHeader from 'src/components/display/PageHeader';
import { useEffect } from 'react';
import { startWalkthrough, stopWalkthrough } from '../../../redux/thunks/guidethrough.thunk';
import { useDispatch } from 'react-redux';
import ExplanationVideo from './Cards/ExplanationVideo';

function Dashboard() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(startWalkthrough('dashboard'));

        return () => {
            dispatch(stopWalkthrough());
        };
    }, []);

    return (
        <>
            <PageHeader secondaryTitle={t('dashboardExplanation')} secondaryTitlePosition="below" />
            <DashboardWrapper>
                <Stack direction={{ xs: 'column-reverse', xl: 'row' }} spacing={2}>
                    <Stack spacing={2} justifyContent="space-between" width="100%">
                        <InternalCalcs />

                        <Card sx={{ display: { xl: 'none' } }}>
                            <Stack p={2} height={{ sm: '40vw' }}>
                                <ExplanationVideo />
                            </Stack>
                        </Card>

                        {/*<Upgrade/>*/}
                    </Stack>

                    <Box width={{ xs: '100%', xl: '20%' }} ml="auto">
                        <Profile />
                    </Box>
                </Stack>
            </DashboardWrapper>
        </>
    );
}

export default Dashboard;
