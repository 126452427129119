import { Avatar, Box, Stack, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RuleTwoToneIcon from '@mui/icons-material/RuleTwoTone';
import { ProvidedVariableResource } from 'src/backend/coreCalc';
import { getNumberValue, getValue, getVariableName } from 'src/utils/CalcHelpers';
import ScaleTwoToneIcon from '@mui/icons-material/ScaleTwoTone';
import LooksTwoTwoToneIcon from '@mui/icons-material/LooksTwoTwoTone';
import Looks3TwoToneIcon from '@mui/icons-material/Looks3TwoTone';
import HelpCenterTwoToneIcon from '@mui/icons-material/HelpCenterTwoTone';
import { formatValue } from 'src/utils/FormatHelpers';

export const AvatarLabelInfo = styled(Avatar)(
    ({ theme }) => `
        background-color: ${theme.colors.info.lighter};
        color: ${theme.colors.info.main};
        width: ${theme.spacing(3.72)};
        height: ${theme.spacing(3.72)};

        @media print {
            box-shadow: inset 0 0 0 1px ${theme.colors.info.light};
            width: ${theme.spacing(2.95)};
            height: ${theme.spacing(2.95)};

            .MuiSvgIcon-root {
                font-size: 1.1rem;
            }
        }
    `
);

const unitMap = {
    MILLIMETER: { icon: RuleTwoToneIcon, label: 'mm' },
    CENTIMETER: { icon: RuleTwoToneIcon, label: 'cm' },
    SQUARE_METER: { icon: LooksTwoTwoToneIcon, label: 'm²' },
    CUBIC_METER: { icon: Looks3TwoToneIcon, label: 'm³' },
    KILOGRAM: { icon: ScaleTwoToneIcon, label: 'kg' }
};

interface Props {
    statisticVariable: ProvidedVariableResource;
    showIcon?: boolean;
}

const Statistic: React.FC<Props> = ({ statisticVariable, showIcon = true }) => {
    const { t } = useTranslation();

    const getIconFromUnit = (unit: ProvidedVariableResource.unit) => {
        const unitObject = unitMap[unit as keyof typeof unitMap];
        const IconComponent = unitObject?.icon;
        return IconComponent ? <IconComponent /> : null;
    };
    const getUnitLabel = (unit: ProvidedVariableResource.unit) => {
        const unitObject = unitMap[unit as keyof typeof unitMap];
        return unitObject?.label;
    };

    const unitLabel = getUnitLabel(statisticVariable.unit);
    const icon = getIconFromUnit(statisticVariable.unit) || <HelpCenterTwoToneIcon />;
    const value = getValue(statisticVariable.value) as number | string;
    const formattedValue = value ? `${formatValue(value)} ${unitLabel}` : '-';

    return showIcon ? (
        <Box key={statisticVariable.id} display="flex" alignItems="center" maxWidth="100%" overflow="hidden" className="calculated-value">
            <AvatarLabelInfo sx={{ mr: 1 }} variant="rounded">
                {icon}
            </AvatarLabelInfo>
            <Box maxWidth="100%" overflow="hidden">
                <Typography variant="subtitle2" noWrap fontSize="0.929em" lineHeight={1.2}>
                    {getVariableName(statisticVariable)}
                </Typography>
                <Typography variant="h5" lineHeight={1.23} title={formattedValue} noWrap>
                    {formattedValue}
                </Typography>
            </Box>
        </Box>
    ) : (
        <Stack key={statisticVariable.id} className="calculated-value">
            <Typography variant="subtitle2" noWrap fontSize="0.929em" lineHeight={1.2}>
                {getVariableName(statisticVariable)}
            </Typography>
            <Typography variant="h5" lineHeight={1.23} title={formattedValue}>
                {formattedValue}
            </Typography>
        </Stack>
    );
};

export default Statistic;
