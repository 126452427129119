import { Avatar, Button, CircularProgress, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, styled, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { t } from 'i18next';
import DragAndDropFileInput from '../DragAndDropFileInput';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone';
import { useDispatch } from 'react-redux';
import { AttachmentResource } from 'src/backend/coreCalc';
import { FileResource } from 'src/backend/market';
import OpenFile from 'src/components/display/OpenFile/OpenFile';
import { addFile, loadFile } from 'src/redux/thunks/fileManager.thunk';

const StyledListItemText = styled(ListItemText)(
    ({ theme }) => `
        padding-right: ${theme.spacing(4)};
        &.unexpectedError .MuiListItemText-secondary,
        &.unsupportedFileExtension .MuiListItemText-secondary {
            color: ${theme.colors.error.main};
        }
        .MuiTypography-root {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    `
);

enum AttachmentStatus {
    UPLOADING = 'uploading',
    UNSUPPORTED_FILE_EXTENSION = 'unsupportedFileExtension',
    UNEXPECTED_ERROR = 'unexpectedError'
}

type Attachment = AttachmentResource & {
    status?: AttachmentStatus;
};

interface Props {
    attachments: Array<AttachmentResource>;
    addAttachment: (attachment: AttachmentResource) => void;
    removeAttachment: (attachmentId: number) => void;
}

const AttachmentsInput: React.FC<Props> = ({ attachments = [], addAttachment, removeAttachment }) => {
    const dispatch = useDispatch();
    // const [unsavedAttachments, setUnsavedAttachments] = useState<Array<Attachment>>([]);
    const [isDownloading, setDownloading] = useState<boolean>(false);
    const [localAttachments, setLocalAttachments] = useState<Array<Attachment>>(attachments);

    const handleFileUpload = (fileList: FileList) => {
        const files = Array.from(fileList);
        if (!files) return;

        files.forEach(async (file) => {
            const newAttachment: Attachment = {
                fileName: file.name,
                status: AttachmentStatus.UPLOADING
            };
            setLocalAttachments([...localAttachments, newAttachment]);

            const fileName = file.name.toLowerCase();
            if (!fileName.endsWith('.pdf') && !fileName.endsWith('.jpg') && !fileName.endsWith('.png')) {
                newAttachment.status = AttachmentStatus.UNSUPPORTED_FILE_EXTENSION;
                return;
            }

            try {
                const fileResource: FileResource = await dispatch(addFile(file, file.name));
                newAttachment.id = -1;
                newAttachment.attachmentId = fileResource.nodeId;
                newAttachment.status = null;
                addAttachment(newAttachment);
            } catch (error) {
                newAttachment.status = AttachmentStatus.UNEXPECTED_ERROR;
            }
        });
    };
    const handleDownloadAttachment = async (attachment: AttachmentResource) => {
        setDownloading(true);
        const fileResource = await dispatch(loadFile(attachment.attachmentId));
        if (!fileResource?.url) return;
        const response = await fetch(fileResource?.url, {
            headers: {
                'Content-Type': fileResource.fileType
            }
        });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileResource.name);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setDownloading(false);
    };

    const downloadAll = () => {
        localAttachments.forEach((attachment) => {
            handleDownloadAttachment(attachment);
        });
    };

    const handleRemoveAttachment = (attachment: Attachment) => {
        if (attachment.id) removeAttachment(attachment.id);
        setLocalAttachments(localAttachments.filter((a) => a !== attachment));
    };

    const renderActions = (attachment: Attachment) => {
        return (
            <>
                {attachment.id && attachment.id !== -1 && (
                    <IconButton edge="end" aria-label="download" sx={{ mr: -0.25 }} onClick={() => handleDownloadAttachment(attachment)} disabled={isDownloading}>
                        {isDownloading ? <CircularProgress color="inherit" size="24px" sx={{ p: '3px' }} /> : <FileDownloadTwoToneIcon />}
                    </IconButton>
                )}
                <IconButton edge="end" aria-label="delete" sx={{ mr: -0.5 }} onClick={() => handleRemoveAttachment(attachment)} disabled={attachment.id === -1}>
                    <DeleteTwoToneIcon />
                </IconButton>
            </>
        );
    };
    const renderListItemButton = (attachment: Attachment) => {
        const itemAvatar = (
            <ListItemAvatar>
                <Avatar>
                    <InsertDriveFileTwoToneIcon />
                </Avatar>
            </ListItemAvatar>
        );
        const isSavedInCoreCalc = attachment.id && attachment.id !== -1;

        return attachment.attachmentId ? (
            <OpenFile
                nodeId={attachment.attachmentId}
                displayElement={(openFile) => (
                    <ListItemButton sx={{ pl: 2.5, borderRadius: 0 }} onClick={() => openFile()} disabled={!isSavedInCoreCalc}>
                        {itemAvatar}
                        <Tooltip title={attachment.fileName}>
                            <StyledListItemText primary={attachment.fileName} secondary={!isSavedInCoreCalc && t('savingAttachment')} />
                        </Tooltip>
                    </ListItemButton>
                )}
            />
        ) : (
            <ListItemButton sx={{ pl: 2.5, borderRadius: 0 }} disabled>
                {itemAvatar}
                <Tooltip title={attachment.fileName}>
                    <StyledListItemText primary={attachment.fileName} secondary={t(attachment.status)} className={attachment.status} />
                </Tooltip>
            </ListItemButton>
        );
    };

    return (
        <>
            {localAttachments?.length > 0 && (
                <>
                    <Divider sx={{ my: 2, mb: 1.5 }} />
                    <List disablePadding sx={{ maxHeight: '50rem', mx: -2, p: 0, pb: 1.5 }}>
                        {localAttachments.map((attachment, index) => (
                            <ListItem key={attachment.id + '' + index} sx={{ p: 0 }} secondaryAction={renderActions(attachment)}>
                                {renderListItemButton(attachment)}
                            </ListItem>
                        ))}
                        {attachments?.length > 2 && (
                            <ListItem sx={{ p: 0 }}>
                                <Button size="small" color="inherit" variant="outlined" sx={{ m: 'auto' }} onClick={downloadAll}>
                                    Download all
                                </Button>
                            </ListItem>
                        )}
                    </List>
                </>
            )}
            <DragAndDropFileInput style={{ marginTop: attachments?.length > 0 ? 0 : 18 }} fileSelected={handleFileUpload} />
        </>
    );
};

export default AttachmentsInput;
