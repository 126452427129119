import { useEffect, useRef, useState } from 'react';
import { Box, Button, Checkbox, Chip, ListItemText, MenuItem, Select, SelectProps, Typography } from '@mui/material';
import { debounce } from 'lodash';

type Props = {
    value?: Array<any>;
    options: Array<any>;
    surchargeButton?: string;
    getTranslation?: Function;
} & SelectProps;

const MultiSelect = ({ options, surchargeButton, getTranslation, ...props }: Props) => {
    const selectRef = useRef(null);
    const chipRefs = useRef([]);
    const [overflowCount, setOverflowCount] = useState<number>(0);

    const checkChipOverflow = (chip, isPreviousOverflowing, offsetForLabel = 0) => {
        if (!chip) return false;
        chip.style.display = 'inline-flex';
        const isOverflowing = isPreviousOverflowing || chip.offsetLeft + chip.clientWidth + offsetForLabel > chip.parentElement.clientWidth;

        if (isOverflowing) {
            chip.style.display = 'none';
            return true;
        }
        return false;
    };

    const checkChipsOverflow = () => {
        const chips = chipRefs.current || [];
        const offsetForLabel = 26;
        let overflowCount = 0;
        setOverflowCount(0);

        chips.forEach((chip, index) => {
            const isPreviousOverflowing = overflowCount > 0;
            const isOverflowing = checkChipOverflow(chip, isPreviousOverflowing);
            if (isOverflowing) {
                overflowCount++;
                const previousChip = chips[index - 1];
                if (overflowCount === 1 && previousChip) {
                    const isPreviousOverflowing = checkChipOverflow(previousChip, false, offsetForLabel);
                    if (isPreviousOverflowing) overflowCount++;
                }
            }
        });

        setOverflowCount(overflowCount);
    };

    useEffect(() => {
        checkChipsOverflow();
    }, [props.value]);

    const debounceCheckChipsOverflow = debounce(checkChipsOverflow, 150);
    useEffect(() => {
        if (!selectRef.current) return;
        const resizeObserver = new ResizeObserver(debounceCheckChipsOverflow);
        resizeObserver.observe(selectRef.current);
        return () => resizeObserver.disconnect();
    }, [selectRef.current]);

    return (
        <Select
            {...props}
            multiple
            renderValue={(selected: Array<any>) => (
                <Box display="flex" gap={0.5} width="100%" overflow="hidden" margin="auto 0" sx={props?.size === 'small' ? { px: 1.4, py: 0.8 } : { px: 1.4, py: 1.2 }} className="MuiChipGroup">
                    {selected.map((value, index) => (
                        <Chip key={value} size={props?.size} label={getTranslation ? getTranslation(value) : value} ref={(element) => (chipRefs.current[index] = element)} />
                    ))}
                    {overflowCount > 0 && (
                        <Typography alignSelf="center" color="secondary" ml={0.5} fontWeight="bold">
                            +{overflowCount}
                        </Typography>
                    )}

                    {surchargeButton && (
                        <Button variant="text" sx={{ px: 0.8, py: 0.2 }}>
                            {surchargeButton}
                        </Button>
                    )}
                </Box>
            )}
            displayEmpty
            inputProps={{ sx: { p: 0, display: 'flex' } }}
            sx={{ '.MuiSelect-select.MuiInputBase-input': { minHeight: props?.size === 'small' ? '37.13px' : '53.59px' } }}
            ref={selectRef}
        >
            {options.map((option, index) => {
                return (
                    <MenuItem value={option} key={'multiSelect' + option + index} sx={{ py: 0, margin: '3px 0 !important' }}>
                        <Checkbox edge="start" checked={props.value && props.value.includes(option)} tabIndex={-1} disableRipple />
                        <ListItemText primary={!!getTranslation ? getTranslation(option) : option} />
                    </MenuItem>
                );
            })}
        </Select>
    );
};

export default MultiSelect;
