import { Typography, Stack, Divider, Button, Dialog, DialogTitle, IconButton, DialogContent, styled, useTheme } from '@mui/material';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useMemo, useState } from 'react';
import CalcBuilderDialog from '../../CalcBuilder/CalcBuilderDialog';
import ComputationItem from './ComputationItem';
import { useDispatch } from 'react-redux';
import { BlueprintUpdateTypes, updateBlueprint } from 'src/redux/shared/calcEditor.common';
import { CategoryResource, VariableResource, TermResource, ComputationTemplateResource } from 'src/backend/coreCalc';
import { createTermVariable } from 'src/utils/CalcHelpers';

const StyledStack = styled(Stack)(
    ({ theme }) => `
        height: 100%;
        .MuiButton-root.accordion-button {
            padding-left: 24px;
            padding-right: 24px;
        }
    `
);

interface Props {
    category?: CategoryResource;
    calcRelevantVariableInternalIdentifiers?: Array<string>;
    computationTemplates?: Array<ComputationTemplateResource>;
    isOpen: boolean;
    setOpen?: (isOpen: boolean) => void;
    saveVariable: (variable: VariableResource) => void;
    createVariable: (variable: VariableResource) => void;
    removeVariable: (variableId: number) => void;
}

const ComputationDialog: React.FC<Props> = ({ category, calcRelevantVariableInternalIdentifiers, computationTemplates, isOpen, setOpen, saveVariable, createVariable, removeVariable }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [openAccordionId, setOpenAccordionId] = useState<string>();
    const [isCalcBuilderOpen, setCalcBuilderOpen] = useState<boolean>(false);

    const mappedComputationTemplates = useMemo(() => {
        const templates: { [id: string]: ComputationTemplateResource } = {};
        (computationTemplates || []).forEach((template) => {
            templates[template.id] = template;
        });
        return templates;
    }, [computationTemplates]);

    const createComputation = (name: string, term: TermResource) => {
        setCalcBuilderOpen(false);
        dispatch(
            updateBlueprint(BlueprintUpdateTypes.CREATE_CATEGORY_COMPUTATION, {
                categoryId: category.id,
                computation: { id: -1, name },
                resultVariable: createTermVariable(term)
            })
        );
    };

    return (
        <Dialog open={isOpen} fullWidth maxWidth="md" PaperProps={{ sx: { width: '100%' } }} onClose={() => setOpen(false)}>
            <DialogTitle>
                <Typography sx={{ fontSize: '1.875rem', fontWeight: 700, mt: 0.5, mb: 1, pr: 7 }} gutterBottom>
                    Kalkulation von "{category.name}"
                </Typography>
                <Typography sx={{ fontSize: 14, lineHeight: 1.3 }} variant="subtitle1">
                    Wähle aus, wie der Preis für diese Kategorie berechnet werden soll. Wenn keine der vordefinierten Kalkulationen für dich geeignet ist, kannst du deine eigene Kalkulation erstellen.
                </Typography>
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 16, top: 16, color: theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ px: 0, pb: 0 }}>
                <StyledStack>
                    <Divider />
                    {category.supportedComputations.map((computation) => (
                        <ComputationItem
                            key={computation.id + computation.name}
                            categoryId={category.id}
                            isSelected={category.selectedComputationId === computation.id}
                            variables={category.variables}
                            calcRelevantVariableInternalIdentifiers={calcRelevantVariableInternalIdentifiers}
                            computation={computation}
                            computationTemplate={mappedComputationTemplates[computation.sourceTemplateId]}
                            openAccordionId={openAccordionId}
                            setOpenAccordionId={setOpenAccordionId}
                            createVariable={createVariable}
                            saveVariable={saveVariable}
                            removeVariable={removeVariable}
                        />
                    ))}
                    <Divider sx={{ mt: 'auto' }} />
                    <Button startIcon={<AddBoxTwoToneIcon />} onClick={() => setCalcBuilderOpen(true)} sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                        Eigene Kalkulation erstellen
                    </Button>
                    {isCalcBuilderOpen && (
                        <CalcBuilderDialog
                            isOpen={isCalcBuilderOpen}
                            setOpen={setCalcBuilderOpen}
                            variables={category.variables}
                            calcRelevantVariableInternalIdentifiers={calcRelevantVariableInternalIdentifiers}
                            onSave={createComputation}
                            creationMode
                            createVariable={createVariable}
                            saveVariable={saveVariable}
                            removeVariable={removeVariable}
                        />
                    )}
                </StyledStack>
            </DialogContent>
        </Dialog>
    );
};

export default ComputationDialog;
