import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import SurfLoader from 'src/components/display/Loader/SurfLoader';
import { RootState, snackContext } from 'src/redux/store';
import PROCalcHeader from './PROCalcHeader';
import useDialog from 'src/hooks/useDialog';
import { nav } from 'src/statics/navigations';
import { startWalkthrough } from 'src/redux/thunks/guidethrough.thunk';
import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowCircleUpTwoTone';
import Master from 'src/components/pro-calc/Master/Master';
import Positions from 'src/components/pro-calc/Positions/Positions';
import { ProCalcError, ProCalcSavingStatus, resetError, resetProCalc } from 'src/redux/slices/proCalc.reducer';
import { UserManagementRestControllerService } from 'src/backend/market';
import { loadProCalc } from 'src/redux/thunks/proCalcs.thunk';
import { cancelProCalcUpdates } from 'src/redux/shared/proCalc.common';
import { CalculationResource } from 'src/backend/coreCalc';

interface ICreateCalc {
    calculation: CalculationResource;
    savingStatus: ProCalcSavingStatus;
    isLoading: boolean;
    error: ProCalcError;
}

const PROCalc: React.FC<ICreateCalc> = ({ calculation, savingStatus, isLoading, error }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { t } = useTranslation();
    const theme = useTheme();
    const dialog = useDialog();
    const [isVisible, setIsVisible] = useState(false);
    const savingStatusRef = useRef(savingStatus);

    useEffect(() => {
        dispatch(startWalkthrough('internalCalculationEdit'));
        window.addEventListener('scroll', () => {
            if (window.scrollY > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        });
    }, []);

    // const blocker = useCustomBlocker(() => savingStatusRef.current === ProCalcSavingStatus.LOADING, savingStatusRef.current === ProCalcSavingStatus.LOADING);

    // useEffect(() => {
    //     if (blocker.state === 'blocked') {
    //         const confirmNavigation = window.confirm('areYourSureYouWannaLeaveChangesMayNotBeSaved');
    //         if (confirmNavigation) blocker.proceed();
    //         else blocker.reset();
    //     }
    // }, [blocker.state]);

    useEffect(() => {
        dispatch(resetProCalc());
        dispatch(cancelProCalcUpdates());
        if (id != null) {
            dispatch(loadProCalc(id));
        }

        const handleOnBeforeUnload = (event: BeforeUnloadEvent) => {
            if (savingStatusRef.current === ProCalcSavingStatus.LOADING) {
                event.preventDefault();
                return (event.returnValue = '');
            }
        };

        window.addEventListener('beforeunload', handleOnBeforeUnload, { capture: true });

        return () => {
            window.removeEventListener('beforeunload', handleOnBeforeUnload, { capture: true });
            dialog.closeDialog();
            dispatch(resetProCalc());
            dispatch(cancelProCalcUpdates());
        };
    }, []);

    useEffect(() => {
        savingStatusRef.current = savingStatus;
    }, [savingStatus]);

    useEffect(() => {
        if (!error || isLoading) return;

        if (error.type === 'locked') {
            const link = nav.CALCULATIONS.sub.VIEW.path.replace(':id', calculation?.id);
            const title = t('lockedByOtherUserTitle');
            const okText = t('goToSummary');

            UserManagementRestControllerService.getUserById(parseInt(error.data))
                .then((user) => {
                    const component = <Typography>{t('lockedByOtherUserExplanation', { user: user.fullName })}</Typography>;
                    openDialog(link, component, title, okText);
                })
                .catch(() => {
                    const component = <Typography>{t('lockedByOtherUserExplanation', { user: error.data })}</Typography>;
                    openDialog(link, component, title, okText);
                });
        } else if (error.type === 'notAllowed') {
            const link = nav.CALCULATIONS.path;
            const component = <Typography>{t('notAllowedCalcErrorExplanation', { id })}</Typography>;
            openDialog(link, component, t('notAllowedCalcErrorTitle'), t('toCalcOverview'));
        } else if (error.type === 'badRequest' || error.type === 'unknown') {
            const link = nav.CALCULATIONS.sub.VIEW.path.replace(':id', id);
            const component = <Typography>{t('unexpectedCalcErrorExplanation', { id })}</Typography>;
            openDialog(link, component, t('unexpectedCalcErrorTitle'), t('goToSummary'));
        } else if (error.type === 'unknown') {
            snackContext.enqueueSnackbar(`${t('Ein Fehler ist aufgetreten')} - ${error.data} ERROR`, { variant: 'error' });
        }
    }, [error]);

    const openDialog = (link: string, component: ReactNode, title: string, okText: string) => {
        dialog.openDialog({
            severity: 'error',
            okText,
            showCancel: false,
            disableClickAway: true,
            component,
            title,
            okCallback: () => {
                dialog.closeDialog();
                navigate(link);
                dispatch(resetError());
            }
        });
    };

    function renderLoader() {
        return (
            <Box width="100%" flexGrow={1} display="flex" justifyContent="center" alignItems="center">
                <SurfLoader />
            </Box>
        );
    }

    return (
        <>
            <Stack sx={{ mb: 9, flexGrow: 1 }}>
                <PROCalcHeader />
                <Stack flex={1} pt={4} pb={4}>
                    {isLoading ? (
                        renderLoader()
                    ) : (
                        <Stack height="100%">
                            <Master />
                            <Positions />
                        </Stack>
                    )}
                </Stack>
                {isVisible && (
                    <Button
                        variant="contained"
                        startIcon={<ArrowCircleUpTwoToneIcon />}
                        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                        sx={{ position: 'fixed', right: theme.spacing(2), bottom: theme.spacing(2) }}
                    >
                        {t('scrollToTop')}
                    </Button>
                )}
            </Stack>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        calculation: state.proCalc.calculation,
        savingStatus: state.proCalc.savingStatus,
        error: state.proCalc.error,
        isLoading: state.proCalcs.isLoading
    };
};
export default connect(mapStateToProps)(PROCalc);
