import { useTheme } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CustomerDisplay from './CustomerDisplay';
import { startWalkthrough } from '../../../../redux/thunks/guidethrough.thunk';
import { CustomerResource } from 'src/backend/coreCalc';
import CustomerPopover from './CustomerPopover';
import { RegularCustomerResource } from 'src/backend/market';
import { ProCalcUpdateTypes, updateProCalc } from 'src/redux/shared/proCalc.common';

interface Props {
    customer?: CustomerResource;
}

const Customer: React.FC<Props> = ({ customer }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();
    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [linkedRegularCustomer, setLinkedRegularCustomer] = useState<RegularCustomerResource>(null);

    const saveCustomer = (newCustomer: CustomerResource) => {
        console.log('#######', newCustomer);

        dispatch(updateProCalc(ProCalcUpdateTypes.CUSTOMER, newCustomer));
    };

    return (
        <>
            <CustomerDisplay
                customer={customer || {}}
                linkedRegularCustomer={linkedRegularCustomer}
                buttonRef={ref}
                setOpen={() => {
                    setOpen(true);
                    dispatch(startWalkthrough('customerDetails'));
                }}
            />

            <CustomerPopover
                customer={customer || {}}
                buttonRef={ref}
                isOpen={isOpen}
                setOpen={setOpen}
                saveCustomer={saveCustomer}
                linkedRegularCustomer={linkedRegularCustomer}
                setLinkedRegularCustomer={setLinkedRegularCustomer}
            />
        </>
    );
};

export default Customer;
