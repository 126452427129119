import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    List,
    ListItem,
    Popover,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
    styled,
    useTheme
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import store, { snackContext } from 'src/redux/store';
import { useDispatch } from 'react-redux';
import { AiMailConversationMetadataResource, AiMailConversationResource, AiMailResource, MailConfigurationDto } from 'src/backend/market';
import { wT } from 'src/utils/wizardTranslations';
import { debounce } from 'lodash';
import AiEmailSingleOutput from './AiEmailSingleOutput';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { loadGeneratedAnswerMail, sendAnswerMail, updateAnswerMail } from 'src/redux/thunks/proCalc.thunk';

const InputArea = styled(Stack)(
    ({ theme }) => `
        border: 1px solid ${theme.colors.alpha.black[30]};
        border-radius: 6px;
  
        .MuiOutlinedInput-root {
            margin: -1px;
            padding-left: ${theme.spacing(2)};
            padding-right: ${theme.spacing(2)};
        }
        .MuiOutlinedInput-notchedOutline {
            border-color: transparent;
        }
    `
);

interface Props {
    isOpen: boolean;
    close: () => void;
    mailConversation: AiMailConversationResource;
}

const AiEmailMissingParameterDialog: React.FC<Props> = ({ isOpen, close, mailConversation }) => {
    const { t } = useTranslation();
    const s = store.getState();
    const dispatch = useDispatch();
    const theme = useTheme();
    const ref = useRef<any>(null);
    const [generatedAnswerMail, setGeneratedAnswerMail] = useState<AiMailResource>();
    const [isSendAnswerLoading, setSendAnswerLoading] = useState<boolean>(false);
    const [isUpdateAnswerLoading, setUpdateAnswerLoading] = useState<boolean>(false);
    const [isPersonSettingsOpen, setPersonSettingsOpen] = useState<boolean>(false);
    const [currentAnswerMail, setCurrentAnswerMail] = useState<MailConfigurationDto>({});

    useEffect(() => {
        if (mailConversation.metadata.dataComplete || mailConversation.metadata?.aiDataType !== AiMailConversationMetadataResource.aiDataType.PART_AI_DATA) return;

        dispatch(loadGeneratedAnswerMail(mailConversation.calculationId))
            .then(async (generatedMail) => {
                if (!generatedMail) return;
                setGeneratedAnswerMail(generatedMail);
                const savedDraftMail = (mailConversation?.conversation || []).find((mail) => mail.mailType === 'OutgoingAiCalculationMail' && !mail.sent);
                const mail = savedDraftMail || generatedMail;

                const current = {
                    senderName: mailConversation.metadata.senderName || '',
                    senderGender: convertGenderType(mailConversation.metadata.gender),
                    subject: mail.subject,
                    toAddress: savedDraftMail?.to || generatedMail.from,
                    mailContentText: mail.bodyText
                };
                setCurrentAnswerMail(current);

                setUpdateAnswerLoading(true);
                await dispatch(updateAnswerMail(mailConversation.calculationId, current));
                setUpdateAnswerLoading(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const convertGenderType = (gender: AiMailConversationMetadataResource.gender): MailConfigurationDto.senderGender => {
        return gender === AiMailConversationMetadataResource.gender.FEMALE
            ? MailConfigurationDto.senderGender.FEMALE
            : gender === AiMailConversationMetadataResource.gender.MALE
            ? MailConfigurationDto.senderGender.MALE
            : MailConfigurationDto.senderGender.UNKNOWN;
    };

    const saveDraftMail = async (updatedAnswerMail: MailConfigurationDto) => {
        await dispatch(updateAnswerMail(mailConversation.calculationId, updatedAnswerMail));
        setUpdateAnswerLoading(false);
    };
    const debouncedSaveDraftMail = useCallback(debounce(saveDraftMail, 300), [mailConversation.calculationId]);

    const updateAnswer = (key: string, value: string) => {
        setUpdateAnswerLoading(true);
        const updatedAnswerMail = { ...currentAnswerMail, [key]: value };
        setCurrentAnswerMail(updatedAnswerMail);
        debouncedSaveDraftMail(updatedAnswerMail);
    };

    const handleMailContentTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateAnswer('mailContentText', event.target.value);
    };
    const handleResetClick = () => {
        updateAnswer('mailContentText', generatedAnswerMail.bodyText);
    };
    const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateAnswer('subject', event.target.value);
    };
    const handleToAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateAnswer('toAddress', event.target.value);
    };
    const handleSenderNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateAnswer('senderName', event.target.value);
    };
    const handleSenderGenderChange = (event) => {
        updateAnswer('senderGender', event.target.value);
    };

    const handleSendAnswerClick = () => {
        setSendAnswerLoading(true);
        dispatch(sendAnswerMail(mailConversation.calculationId))
            .then(() => {
                setSendAnswerLoading(false);
                close();
                snackContext.enqueueSnackbar('Success', { variant: 'success' });
            })
            .catch(() => {
                setSendAnswerLoading(false);
                close();
            });
    };

    const renderMissingParameters = () => {
        const missingDataList = mailConversation.metadata.missingData;
        return (
            <List sx={{ listStyleType: 'disc', p: 0, pl: 2 }}>
                {missingDataList.map((missingDataEntry, index) => (
                    <ListItem key={index} sx={{ display: 'list-item', p: 0, color: theme.colors.warning.dark }}>
                        {missingDataEntry.partName}: {missingDataEntry.missingData.map(({ fieldName }) => wT(fieldName, s)).join(', ')}
                    </ListItem>
                ))}
            </List>
        );
    };

    return (
        <Dialog
            open={isOpen}
            onClose={(e: React.MouseEvent) => {
                e.stopPropagation();
                close();
            }}
            fullWidth
            maxWidth="md"
            PaperProps={{
                onClick: (e) => e.stopPropagation(),
                sx: { border: '6px solid', borderColor: theme.colors.warning.main }
            }}
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    background: theme.colors.warning.main,
                    color: theme.colors.alpha.white[100],
                    fontSize: '1.2rem',
                    mt: '-6px',
                    ml: '-6px'
                }}
            >
                <WarningTwoToneIcon sx={{ mr: 1 }} />
                {t('clarifyInquiryMissingParameters')}
                <IconButton aria-label="close" onClick={() => close()} color="inherit" sx={{ position: 'absolute', right: 8, top: 6 }}>
                    <CloseTwoToneIcon />
                </IconButton>
            </DialogTitle>
            {mailConversation && (
                <DialogContent sx={{ pt: '18px !important' }}>
                    <Typography variant="caption">{t('emailConversation')}</Typography>
                    <Typography variant="h4">{mailConversation.conversation[0]?.subject}</Typography>
                    <Stack sx={{ my: 2.4 }}>
                        {mailConversation.conversation.map((mail) => (
                            <AiEmailSingleOutput key={mail.id} mail={mail} senderName={currentAnswerMail.senderName} />
                        ))}
                    </Stack>
                    <Typography variant="h4">{t('reply')}</Typography>
                    <Typography mb={1} color="text.secondary">
                        {t('replyExplanation')}
                    </Typography>
                    <Typography mb={2}>
                        {t('followingParametersAreMissing')}: {renderMissingParameters()}
                    </Typography>
                    <InputArea divider={<Divider sx={{ mx: 2 }} />}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <Typography variant="caption" mt={0.3} mr={2}>
                                        An
                                    </Typography>
                                ),
                                endAdornment: (
                                    <>
                                        <Tooltip title="Personeneigenschaften anpassen">
                                            <Button
                                                ref={ref}
                                                color="inherit"
                                                sx={{ display: 'flex', flexShrink: 0, bgcolor: theme.colors.alpha.black[7], borderRadius: 1, py: 0.5, pl: 1, fontWeight: 'normal' }}
                                                endIcon={<EditTwoToneIcon sx={{ fontSize: '18px !important' }} />}
                                                onClick={() => setPersonSettingsOpen(true)}
                                            >
                                                {currentAnswerMail.senderName || 'Unbekannter Name'}, {currentAnswerMail.senderGender || 'Unbekanntes Geschlecht'}
                                            </Button>
                                        </Tooltip>
                                        <Popover
                                            anchorEl={ref.current}
                                            onClose={() => setPersonSettingsOpen(false)}
                                            open={isPersonSettingsOpen}
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        >
                                            <Stack p={3} pt={2} gap={2}>
                                                <Typography variant="h3">{t('adjustPersonProperties')}</Typography>
                                                <TextField label="Name" value={currentAnswerMail.senderName} onChange={handleSenderNameChange} />
                                                <ToggleButtonGroup value={currentAnswerMail.senderGender} onChange={handleSenderGenderChange} exclusive fullWidth>
                                                    <ToggleButton value={MailConfigurationDto.senderGender.FEMALE}>{MailConfigurationDto.senderGender.FEMALE}</ToggleButton>
                                                    <ToggleButton value={MailConfigurationDto.senderGender.MALE}>{MailConfigurationDto.senderGender.MALE}</ToggleButton>
                                                    <ToggleButton value={MailConfigurationDto.senderGender.UNKNOWN}>{MailConfigurationDto.senderGender.UNKNOWN}</ToggleButton>
                                                </ToggleButtonGroup>
                                            </Stack>
                                        </Popover>
                                    </>
                                )
                            }}
                            value={currentAnswerMail.toAddress}
                            onChange={handleToAddressChange}
                        />
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <Typography variant="caption" mt={0.3} mr={2}>
                                        {t('subject')}
                                    </Typography>
                                )
                            }}
                            value={currentAnswerMail.subject}
                            onChange={handleSubjectChange}
                        />
                        <TextField multiline rows={20} value={currentAnswerMail.mailContentText} onChange={handleMailContentTextChange} />
                    </InputArea>
                    <Typography variant="subtitle2" fontSize={13}>
                        {t(isUpdateAnswerLoading ? 'speichern' : 'gespeichert')}
                    </Typography>
                    <Stack direction="row" sx={{ justifyContent: 'flex-end', mt: 2, height: 43 }}>
                        {generatedAnswerMail?.bodyText !== currentAnswerMail.mailContentText && (
                            <Button variant="text" color="secondary" onClick={handleResetClick}>
                                {t('resetText')}
                            </Button>
                        )}
                        <Button variant="contained" color="warning" sx={{ ml: 2 }} disabled={isSendAnswerLoading || isUpdateAnswerLoading} onClick={handleSendAnswerClick}>
                            {isSendAnswerLoading ? <CircularProgress size={20} color="inherit" /> : t('sendReply')}
                        </Button>
                    </Stack>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default AiEmailMissingParameterDialog;
