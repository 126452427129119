import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import store from 'src/redux/store';
import { MessageResource, VariableResource } from 'src/backend/coreCalc';
import CategoryMessageForm from './CategoryMessageForm';

interface Props {
    messages: Array<MessageResource>;
    variables: Array<VariableResource>;
    isOpen: boolean;
    setOpen?: (isOpen: boolean) => void;
    createMessage: (message: MessageResource) => void;
}

const CreateCategoryMessageDialog: React.FC<Props> = ({ messages, variables, isOpen, setOpen, createMessage }) => {
    const ref = useRef<any>(null);
    const s = store.getState();
    const theme = useTheme();
    const [message, setMessage] = useState<MessageResource>();
    const [isValid, setValid] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);

    const handleCreateClick = () => {
        setLoading(true);
        createMessage({ id: -1, ...message });
    };

    useEffect(() => {
        if (!isLoading) return;
        const foundTempId = messages.some((message) => message.id === -1);

        if (!foundTempId) {
            setLoading(false); // Stop loading when temp ID is gone
            setOpen(false);
        }
    }, [messages, message, isLoading]);

    return (
        <Dialog open={isOpen} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ pt: 3.1, pb: 2.7 }}>
                <Typography variant="h4">Hinweis erstellen</Typography>
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 16, top: 16, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack gap={2} pt={1}>
                    <CategoryMessageForm message={message} variables={variables} setMessage={setMessage} setValid={setValid} />
                    <Stack mt="auto">
                        <Button variant="contained" startIcon={<AutoAwesomeTwoToneIcon />} sx={{ ml: 'auto', mt: 3 }} onClick={handleCreateClick} disabled={!isValid || isLoading}>
                            {isLoading ? <CircularProgress color="inherit" size={20} /> : 'Erstellen'}
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default CreateCategoryMessageDialog;
