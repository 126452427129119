import { Box, List, ListItem, ListItemText, Stack, styled, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SummaryResource } from 'src/backend/market';
import { wT } from 'src/utils/wizardTranslations';
import store from 'src/redux/store';
import { getAllMessages } from 'src/utils/CalcHelpers';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import GppBadTwoToneIcon from '@mui/icons-material/GppBadTwoTone';

const IconWrapper = styled(Box)(
    ({ theme }) => `
        display: inline-flex;
        justify-content: center;
        margin-left: ${theme.spacing(1)};
        margin-right: ${theme.spacing(0.4)};
        .MuiSvgIcon-root {
            font-size: 1.1em;
            margin: auto;
        }
    `
);
const StyledListItemText = styled(ListItemText)(
    ({ theme }) => `
        .MuiListItemText-primary {
            display: flex;
            font-size: 1em;
        }
        .MuiListItemText-secondary {
            font-size: 1em;
        }
    `
);

interface Props {
    summary: SummaryResource;
}

export const MessagesSummary: React.FC<Props> = ({ summary }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const s = store.getState();

    const allMessages = useMemo(() => getAllMessages(summary.calculationResult), [summary.calculationResult]);

    const hasMessages = allMessages.length > 0;

    const hints = useMemo(() => allMessages.filter((message) => message.messageType === 'HINT').length, [allMessages]);
    const warnings = useMemo(() => allMessages.filter((message) => message.messageType === 'WARNING').length, [allMessages]);
    const errors = useMemo(() => allMessages.filter((message) => message.messageType.endsWith('ERROR')).length, [allMessages]);

    return (
        hasMessages && (
            <Stack>
                <Typography variant="h4" mt={4} mb={1} display="flex" alignItems="center" textTransform="uppercase">
                    {t('generalMessages')}
                    {hints > 0 && (
                        <>
                            <IconWrapper>
                                <InfoTwoToneIcon />
                            </IconWrapper>{' '}
                            {hints}
                        </>
                    )}
                    {warnings > 0 && (
                        <>
                            <IconWrapper>
                                <WarningTwoToneIcon />
                            </IconWrapper>{' '}
                            {warnings}
                        </>
                    )}
                    {errors > 0 && (
                        <>
                            <IconWrapper>
                                <GppBadTwoToneIcon />
                            </IconWrapper>{' '}
                            {errors}
                        </>
                    )}
                </Typography>

                <List sx={{ p: 0 }}>
                    {allMessages.map((message, index) => (
                        <ListItem key={index} sx={{ px: 0 }}>
                            <StyledListItemText
                                primary={
                                    <>
                                        {wT(message.messageId + '.short', s)}
                                        <Tooltip title={t(message.messageType)}>
                                            <IconWrapper>
                                                {message.messageType === 'HINT' ? <InfoTwoToneIcon /> : message.messageType === 'WARNING' ? <WarningTwoToneIcon /> : <GppBadTwoToneIcon />}
                                            </IconWrapper>
                                        </Tooltip>
                                    </>
                                }
                                secondary={wT(message.messageId + '.long', s)}
                            />
                        </ListItem>
                    ))}
                </List>
            </Stack>
        )
    );
};
