import { Card, Stack, TextField, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import CategoryArea from './CategoryArea';
import { t } from 'i18next';
import { ProCalcUpdateTypes, updateProCalc } from 'src/redux/shared/proCalc.common';
import { ShortCategoryResource } from 'src/backend/coreCalc';
import UploadArea from './UploadArea';
import SubCategoryArea from './SubCategoryArea';

interface Props {
    categories: Array<ShortCategoryResource>;
    maxWidth?: number | string;
    activate3dUpload?: boolean;
}

const ItemSelector: React.FC<Props> = ({ categories, maxWidth, activate3dUpload = false }) => {
    const dispatch = useDispatch();
    const [selectedCategory, setSelectedCategory] = useState<ShortCategoryResource>();
    const [searchText, setSearchText] = useState<string>('');
    const theme = useTheme();

    const handleSubCategorySelection = (subCategory: ShortCategoryResource) => {
        dispatch(updateProCalc(ProCalcUpdateTypes.CREATE_ITEM, { categoryId: subCategory.id }));
        // if (partId) dispatch(updateProCalc(ProCalcUpdateTypes.CREATE_FRAGMENT, { partId, itemId, geometryPackage: subGeometryPackage.pkg, geometryPackageName: subGeometryPackage.name }));
        // else dispatch(updateProCalc(ProCalcUpdateTypes.CREATE_PART, { itemId }));
        // if (closeOnLoad) closeOnLoad();
    };

    return (
        <Card sx={{ maxWidth }}>
            <Stack direction="row" position="relative">
                {activate3dUpload && <UploadArea categories={categories} />}
                {categories && (
                    <Stack flexGrow={1}>
                        <CategoryArea categories={categories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
                        <TextField
                            id={'searchPartsTextField'}
                            sx={{ px: 2 }}
                            variant="outlined"
                            placeholder={t('searchItems')}
                            value={searchText}
                            size="small"
                            onChange={(event) => setSearchText(event.target.value)}
                            fullWidth
                        />
                        <SubCategoryArea categories={categories} searchText={searchText} selectedCategory={selectedCategory} handleSubCategorySelection={handleSubCategorySelection} />
                    </Stack>
                )}
            </Stack>
        </Card>
    );
};

export default ItemSelector;
