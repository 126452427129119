/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangedByRegularCustomerFlagDto } from '../models/ChangedByRegularCustomerFlagDto';
import type { CompanyNameDto } from '../models/CompanyNameDto';
import type { CompanyNameTakenResource } from '../models/CompanyNameTakenResource';
import type { CustomerTypeDto } from '../models/CustomerTypeDto';
import type { CustomerTypeResource } from '../models/CustomerTypeResource';
import type { MailAlreadyUsedDto } from '../models/MailAlreadyUsedDto';
import type { MailAlreadyUsedResource } from '../models/MailAlreadyUsedResource';
import type { NewGeneralFileDto } from '../models/NewGeneralFileDto';
import type { PresignedUrlResource } from '../models/PresignedUrlResource';
import type { PriceManipulationParameterDto } from '../models/PriceManipulationParameterDto';
import type { PriceManipulationParameterResource } from '../models/PriceManipulationParameterResource';
import type { RegularCustomerContactPersonDto } from '../models/RegularCustomerContactPersonDto';
import type { RegularCustomerDto } from '../models/RegularCustomerDto';
import type { RegularCustomerFilterBoundariesResource } from '../models/RegularCustomerFilterBoundariesResource';
import type { RegularCustomerFilterDto } from '../models/RegularCustomerFilterDto';
import type { RegularCustomerPageResultResource } from '../models/RegularCustomerPageResultResource';
import type { RegularCustomerResource } from '../models/RegularCustomerResource';
import type { RegularCustomerStateDto } from '../models/RegularCustomerStateDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RegularCustomerRestControllerService {

    /**
     * @param userId
     * @param regularCustomerId
     * @returns RegularCustomerResource OK
     * @throws ApiError
     */
    public static getRegularCustomer(
        userId: number,
        regularCustomerId: number,
    ): CancelablePromise<RegularCustomerResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/regularCustomers/${userId}/${regularCustomerId}`,
        });
    }

    /**
     * @param userId
     * @param regularCustomerId
     * @param requestBody
     * @returns RegularCustomerResource OK
     * @throws ApiError
     */
    public static updateRegularCustomer(
        userId: number,
        regularCustomerId: number,
        requestBody: RegularCustomerDto,
    ): CancelablePromise<RegularCustomerResource> {
        return __request({
            method: 'PUT',
            path: `/marketplace/regularCustomers/${userId}/${regularCustomerId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param regularCustomerId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteRegularCustomer(
        userId: number,
        regularCustomerId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/marketplace/regularCustomers/${userId}/${regularCustomerId}`,
        });
    }

    /**
     * @param userId
     * @param regularCustomerId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static setRegularCustomerState(
        userId: number,
        regularCustomerId: number,
        requestBody: RegularCustomerStateDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/regularCustomers/${userId}/${regularCustomerId}/state`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param regularCustomerId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static setLogo(
        userId: number,
        regularCustomerId: number,
        requestBody: NewGeneralFileDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/regularCustomers/${userId}/${regularCustomerId}/logo`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param regularCustomerId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateDataChangedByRegularCustomerFlag(
        userId: number,
        regularCustomerId: number,
        requestBody: ChangedByRegularCustomerFlagDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/regularCustomers/${userId}/${regularCustomerId}/dataChangedByRegularCustomerFlag`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns CompanyNameTakenResource OK
     * @throws ApiError
     */
    public static isRegularCustomerNameTaken(
        userId: number,
        requestBody: CompanyNameDto,
    ): CancelablePromise<CompanyNameTakenResource> {
        return __request({
            method: 'PUT',
            path: `/marketplace/regularCustomers/${userId}/regularCustomerCompanyNames/taken`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param priceManipulationParameterId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updatePriceManipulationParameter(
        userId: number,
        priceManipulationParameterId: number,
        requestBody: PriceManipulationParameterDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/regularCustomers/${userId}/priceManipulationParameters/${priceManipulationParameterId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param priceManipulationParameterId
     * @returns any OK
     * @throws ApiError
     */
    public static deletePriceManipulationParameter(
        userId: number,
        priceManipulationParameterId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/marketplace/regularCustomers/${userId}/priceManipulationParameters/${priceManipulationParameterId}`,
        });
    }

    /**
     * @param userId
     * @param customerTypeId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateCustomerType(
        userId: number,
        customerTypeId: number,
        requestBody: CustomerTypeDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/regularCustomers/${userId}/customerTypes/${customerTypeId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param customerTypeId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteCustomerType(
        userId: number,
        customerTypeId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/marketplace/regularCustomers/${userId}/customerTypes/${customerTypeId}`,
        });
    }

    /**
     * @param userId
     * @returns any OK
     * @throws ApiError
     */
    public static updateAllRegularCustomerStatistics(
        userId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/regularCustomers/${userId}/allRegularCustomerStatistics`,
        });
    }

    /**
     * @param regularCustomerKey
     * @returns any OK
     * @throws ApiError
     */
    public static updateRegularCustomerStatistic(
        regularCustomerKey: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/regularCustomers/${regularCustomerKey}/statistics`,
        });
    }

    /**
     * @param regularCustomerKey
     * @returns RegularCustomerResource OK
     * @throws ApiError
     */
    public static getRegularCustomerByKey(
        regularCustomerKey: string,
    ): CancelablePromise<RegularCustomerResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/regularCustomers/regularCustomerKeys/${regularCustomerKey}`,
        });
    }

    /**
     * @param regularCustomerKey
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateRegularCustomerContactPerson(
        regularCustomerKey: string,
        requestBody: RegularCustomerContactPersonDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/regularCustomers/regularCustomerKeys/${regularCustomerKey}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param page
     * @param size
     * @param sortBy
     * @returns RegularCustomerPageResultResource OK
     * @throws ApiError
     */
    public static getAllRegularCustomers(
        userId: number,
        page: number,
        size: number,
        sortBy: string = 'companyNameAsc',
    ): CancelablePromise<RegularCustomerPageResultResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/regularCustomers/${userId}`,
            query: {
                'page': page,
                'size': size,
                'sortBy': sortBy,
            },
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns RegularCustomerResource OK
     * @throws ApiError
     */
    public static createRegularCustomer(
        userId: number,
        requestBody: RegularCustomerDto,
    ): CancelablePromise<RegularCustomerResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/regularCustomers/${userId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @returns PriceManipulationParameterResource OK
     * @throws ApiError
     */
    public static getAllPriceManipulationParameters(
        userId: number,
    ): CancelablePromise<Array<PriceManipulationParameterResource>> {
        return __request({
            method: 'GET',
            path: `/marketplace/regularCustomers/${userId}/priceManipulationParameters`,
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns PriceManipulationParameterResource OK
     * @throws ApiError
     */
    public static createPriceManipulationParameter(
        userId: number,
        requestBody: PriceManipulationParameterDto,
    ): CancelablePromise<PriceManipulationParameterResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/regularCustomers/${userId}/priceManipulationParameters`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns MailAlreadyUsedResource OK
     * @throws ApiError
     */
    public static checkIfMailAlreadyUsed1(
        userId: number,
        requestBody: MailAlreadyUsedDto,
    ): CancelablePromise<MailAlreadyUsedResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/regularCustomers/${userId}/mailAlreadyUsed`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param page
     * @param size
     * @param requestBody
     * @param sortBy
     * @returns RegularCustomerPageResultResource OK
     * @throws ApiError
     */
    public static getAllRegularCustomersWithFilter(
        userId: number,
        page: number,
        size: number,
        requestBody: RegularCustomerFilterDto,
        sortBy: string = 'companyNameAsc',
    ): CancelablePromise<RegularCustomerPageResultResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/regularCustomers/${userId}/filter`,
            query: {
                'page': page,
                'size': size,
                'sortBy': sortBy,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @returns CustomerTypeResource OK
     * @throws ApiError
     */
    public static getAllCustomerTypes(
        userId: number,
    ): CancelablePromise<Array<CustomerTypeResource>> {
        return __request({
            method: 'GET',
            path: `/marketplace/regularCustomers/${userId}/customerTypes`,
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns CustomerTypeResource OK
     * @throws ApiError
     */
    public static createCustomerType(
        userId: number,
        requestBody: CustomerTypeDto,
    ): CancelablePromise<CustomerTypeResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/regularCustomers/${userId}/customerTypes`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns PresignedUrlResource OK
     * @throws ApiError
     */
    public static getRegularCustomerImageUploadUrl(
        requestBody: NewGeneralFileDto,
    ): CancelablePromise<PresignedUrlResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/regularCustomers/imageUploadUrl`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @returns RegularCustomerFilterBoundariesResource OK
     * @throws ApiError
     */
    public static getRegularCustomerFilterBoundaries(
        userId: number,
    ): CancelablePromise<RegularCustomerFilterBoundariesResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/regularCustomers/${userId}/filter/boundaries`,
        });
    }

    /**
     * @param priceManipulationParameterId
     * @returns PriceManipulationParameterResource OK
     * @throws ApiError
     */
    public static getPriceManipulationParameter(
        priceManipulationParameterId: number,
    ): CancelablePromise<PriceManipulationParameterResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/regularCustomers/priceManipulationParameters/${priceManipulationParameterId}`,
        });
    }

}