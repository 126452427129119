import { Autocomplete, AutocompleteChangeReason, AutocompleteInputChangeReason, Box, Button, Grid, IconButton, Popover, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CallTwoToneIcon from '@mui/icons-material/CallTwoTone';
import AlternateEmailTwoToneIcon from '@mui/icons-material/AlternateEmailTwoTone';
import RecordVoiceOverTwoToneIcon from '@mui/icons-material/RecordVoiceOverTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import { cloneDeep, get, set } from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { RootState } from 'src/redux/store';
import { loadCustomers } from 'src/redux/thunks/customers.thunk';
import LinkTwoToneIcon from '@mui/icons-material/LinkTwoTone';
import CustomerMap from './CustomerMap';
import { LoadScriptNext } from '@react-google-maps/api';
import { stopWalkthrough } from '../../../../redux/thunks/guidethrough.thunk';
import { RegularCustomerResource, RegularCustomerRestControllerService, UserResource } from 'src/backend/market';
import i18n from 'src/i18n/i18n';
import CountrySelect from 'src/components/input/CountrySelect';
import { API_CONFIG } from 'src/config';
import ButtonSelector from 'src/components/input/ButtonSelector/ButtonSelector';
import { ProCalcUpdateTypes } from 'src/redux/shared/proCalc.common';
import { ProCalcUpdateLoading } from 'src/redux/slices/proCalc.reducer';
import { CustomerResource } from 'src/backend/coreCalc';

const libraries: Array<any> = ['places'];

interface Props {
    customer?: CustomerResource;
    buttonRef: any;
    customers: { [p: string]: RegularCustomerResource };
    user: UserResource;
    isUpdateLoading: ProCalcUpdateLoading;
    isOpen: boolean;
    setOpen: (open: boolean) => void;
    linkedRegularCustomer?: RegularCustomerResource;
    setLinkedRegularCustomer?: (regularCustomer: RegularCustomerResource) => void;
    saveCustomer: (customer: CustomerResource) => void;
}

const CustomerPopover: React.FC<Props> = ({ customer = {}, buttonRef, customers, user, isUpdateLoading, isOpen, setOpen, linkedRegularCustomer, setLinkedRegularCustomer, saveCustomer }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();
    const [predictions, setPredictions] = useState<Array<any>>([]);
    const [userPos, setUserPos] = useState<google.maps.LatLngLiteral>(null);
    const [customerPos, setCustomerPos] = useState<google.maps.LatLngLiteral>(null);
    const [sessionToken, setSessionToken] = useState<any>(null);
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const autocomplete = useRef<any>(null);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (autocomplete.current || !isLoaded) return;

        autocomplete.current = new google.maps.places.AutocompleteService();
        setSessionToken(new google.maps.places.AutocompleteSessionToken());

        if (user.company.latitude != null && user.company.longitude != null) {
            setUserPos({
                lat: user.company.latitude,
                lng: user.company.longitude
            });
        }
    }, [isLoaded]);

    useEffect(() => {
        if (!customer?.address) return;
        if (customer.address.latitude && customer.address.longitude) setCustomerPos({ lat: customer.address.latitude, lng: customer.address.longitude });
        else setCustomerPos(null);
    }, [customer?.address?.latitude, customer?.address?.longitude]);

    const updatePlacePredictions = (companyName = '') => {
        if (!autocomplete.current) return;
        if (companyName.length < 3) return setPredictions([]);

        autocomplete.current.getQueryPredictions(
            {
                input: companyName,
                types: ['establishment'],
                sessionToken
            },
            (predictions) => {
                setPredictions(predictions);
            }
        );
    };

    const autocompleteOptions = [
        ...Object.values(customers || {}).map((customer) => ({ category: 'regularCustomers', value: customer.companyName, customer })),
        ...(predictions || []).map((prediction) => ({ category: 'googleMapsPredictions', value: prediction.description, prediction }))
    ];

    useEffect(() => {
        if (Object.values(customers).length === 0) dispatch(loadCustomers(4));

        if (customer.regularCustomerId) {
            RegularCustomerRestControllerService.getRegularCustomer(user.id, customer.regularCustomerId).then((regularCustomer) => setLinkedRegularCustomer(regularCustomer));
        }
    }, []);

    useEffect(() => {
        dispatch(loadCustomers(4, { searchString: customer.companyName }));
        updatePlacePredictions(customer.companyName);
    }, [customer.companyName]);

    const handleContactedViaChange = (contactedVia: CustomerResource.contactedVia) => {
        saveCustomer({ ...customer, contactedVia });
    };
    const handleCountrySelected = (event, selectedItem, reason: AutocompleteChangeReason) => {
        if (reason !== 'selectOption' && reason !== 'clear') return;
        saveCustomer({
            ...customer,
            placeId: null,
            address: { ...customer.address, country: selectedItem }
        });
    };
    const handleCompanyChange = (event: SyntheticEvent, newValue: string, reason: AutocompleteInputChangeReason) => {
        if (reason !== 'input') return;
        saveCustomer({ ...customer, companyName: newValue });
        unlinkRegularCustomer();
    };
    const handleCustomerSelected = (event, selectedItem, reason: AutocompleteChangeReason) => {
        if (reason !== 'selectOption') return;

        if (selectedItem.category === 'regularCustomers') {
            if (linkedRegularCustomer?.companyName === selectedItem.value) return setRegularCustomerValues(linkedRegularCustomer);
            return linkRegularCustomer(selectedItem.customer);
        }
        unlinkRegularCustomer();
        if (selectedItem.category === 'googleMapsPredictions') {
            setGoogleMapsPrediction(selectedItem.prediction);
        }
    };
    const setRegularCustomerValues = (regularCustomer: RegularCustomerResource) => {
        saveCustomer({
            ...customer,
            companyName: regularCustomer.companyName,
            firstName: regularCustomer.contactPersonFirstName,
            lastName: regularCustomer.contactPersonLastName,
            email: regularCustomer.email,
            phone: regularCustomer.phone,
            address: {
                street: regularCustomer.street,
                houseNumber: regularCustomer.houseNumber,
                zipcode: regularCustomer.zipcode,
                city: regularCustomer.city,
                country: ''
            },
            placeId: null,
            notes: regularCustomer.notes
        });
    };
    const linkRegularCustomer = (regularCustomer: RegularCustomerResource) => {
        saveCustomer({ ...customer, regularCustomerId: regularCustomer.id });
        setLinkedRegularCustomer(regularCustomer);
        setRegularCustomerValues(regularCustomer);
    };
    const unlinkRegularCustomer = () => {
        if (!linkedRegularCustomer) return;

        saveCustomer({ ...customer, regularCustomerId: null });
        setLinkedRegularCustomer(null);
    };
    const setGoogleMapsPrediction = (prediction) => {
        saveCustomer({
            ...customer,
            companyName: prediction.structured_formatting.main_text,
            address: { street: '', houseNumber: '', zipcode: '', city: '', country: '' },
            placeId: prediction.place_id
        });
    };

    const renderTextField = (label, key, addressInput = false) => {
        return (
            <TextField
                fullWidth
                label={t(label)}
                value={get(customer, key) || ''}
                onChange={(event) => {
                    if (addressInput) {
                        saveCustomer({ ...customer, placeId: null });
                    }
                    const clonedCustomer = cloneDeep(customer);
                    set(clonedCustomer, key, event.target.value);
                    saveCustomer(clonedCustomer);
                }}
                name={key}
                inputProps={{ maxLength: 350 }}
                InputLabelProps={{ shrink: true }}
            />
        );
    };

    return (
        <Popover
            anchorEl={buttonRef.current}
            onClose={() => {
                setOpen(false);
                dispatch(stopWalkthrough());
            }}
            open={isOpen}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <IconButton
                aria-label="close"
                onClick={() => {
                    setOpen(false);
                    dispatch(stopWalkthrough());
                }}
                sx={{ position: 'absolute', p: 0.6, right: 0, top: 0, color: (theme) => theme.palette.grey[500], zIndex: 5000 }}
            >
                <CloseTwoToneIcon />
            </IconButton>
            <form>
                <Stack direction="row">
                    <Stack spacing={3} p={3} maxWidth={{ xs: '100%', md: '50rem' }}>
                        <Stack>
                            <Typography variant="subtitle2" gutterBottom display="flex" justifyContent="space-between">
                                {t('contactedVia')}
                            </Typography>

                            <ButtonSelector
                                items={[
                                    { value: CustomerResource.contactedVia.PHONE, label: 'phone', icon: <CallTwoToneIcon /> },
                                    { value: CustomerResource.contactedVia.EMAIL, label: 'email', icon: <AlternateEmailTwoToneIcon /> },
                                    { value: CustomerResource.contactedVia.IN_PERSON, label: 'inPerson', icon: <RecordVoiceOverTwoToneIcon /> },
                                    { value: CustomerResource.contactedVia.WEB, label: 'web', icon: <LanguageTwoToneIcon /> }
                                ]}
                                value={customer.contactedVia}
                                setValue={handleContactedViaChange}
                                sx={{ mb: 0 }}
                            />
                        </Stack>

                        <Stack>
                            <Autocomplete
                                id={'companyNameTextField'}
                                freeSolo
                                fullWidth
                                value={customer.companyName || ''}
                                options={autocompleteOptions}
                                onChange={handleCustomerSelected}
                                onInputChange={handleCompanyChange}
                                groupBy={(option) => option.category}
                                getOptionLabel={(option) => (typeof option === 'string' ? option : option.value)}
                                disableClearable
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('company')}
                                        name="companyName"
                                        inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                                renderGroup={(params) => (
                                    <li key={params.group}>
                                        <Typography variant="subtitle2" p={1} pt={1.5} pl={1.5}>
                                            {t(params.group)}
                                        </Typography>
                                        <ul style={{ padding: 0 }}>{params.children}</ul>
                                    </li>
                                )}
                            />
                            {linkedRegularCustomer && (
                                <Stack direction="row" alignItems="center" mb={-1}>
                                    <LinkTwoToneIcon sx={{ opacity: 0.7 }} />
                                    <Typography variant="subtitle2" ml={1}>
                                        {t('regularCustomerIsLinked', { companyName: linkedRegularCustomer.companyName })}
                                    </Typography>
                                    <Button onClick={unlinkRegularCustomer} sx={{ opacity: 0.7, p: 0.5, m: 0.5, ml: 'auto', mr: -1 }}>
                                        {t('unlink')}
                                    </Button>
                                </Stack>
                            )}
                        </Stack>

                        <Stack id={'dataBox'} spacing={3} maxWidth="50rem">
                            <Grid container gap={1} wrap={'nowrap'}>
                                <Grid item xs={8}>
                                    {renderTextField('street', 'address.street', true)}
                                </Grid>
                                <Grid item xs={4}>
                                    {renderTextField('houseNumber', 'address.houseNumber', true)}
                                </Grid>
                            </Grid>
                            <Grid container gap={1} wrap={'nowrap'}>
                                <Grid item xs={4}>
                                    {renderTextField('zipcode', 'address.zipcode', true)}
                                </Grid>
                                <Grid item xs={8}>
                                    {renderTextField('city', 'address.city', true)}
                                </Grid>
                            </Grid>

                            <CountrySelect
                                value={customer.address?.country || ''}
                                name="address.country"
                                helperText={customer.address?.country && customer.address?.country}
                                onChange={handleCountrySelected}
                            />

                            <Grid container gap={1} wrap={'nowrap'}>
                                <Grid item xs={6}>
                                    {renderTextField('firstName', 'contactPerson.firstName')}
                                </Grid>
                                <Grid item xs={6}>
                                    {renderTextField('lastName', 'contactPerson.lastName')}
                                </Grid>
                            </Grid>
                            {renderTextField('contactPhone', 'contactPerson.phone')}
                            {renderTextField('contactEmail', 'contactPerson.email')}
                            {renderTextField('customerNotes', 'note')}
                        </Stack>
                    </Stack>
                    <Box width="45rem">
                        <LoadScriptNext key={`map-${i18n.language}`} googleMapsApiKey={API_CONFIG.GOOGLE_MAPS_API_KEY} libraries={libraries} language={i18n.language} onLoad={() => setLoaded(true)}>
                            {isLoaded && userPos && !isSmallScreen && (
                                <CustomerMap isRequestLoading={isUpdateLoading[ProCalcUpdateTypes.CUSTOMER]} user={user} userPos={userPos} customerPos={customerPos} />
                            )}
                        </LoadScriptNext>
                    </Box>
                </Stack>
            </form>
        </Popover>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        customers: state.customers.customers,
        user: state.user.currentUser,
        isUpdateLoading: state.proCalc.isUpdateLoading
    };
};
export default connect(mapStateToProps)(CustomerPopover);
