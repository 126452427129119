import { IconButton, InputAdornment, Tooltip, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import AutoFixHighTwoToneIcon from '@mui/icons-material/AutoFixHighTwoTone';
import CachedTwoToneIcon from '@mui/icons-material/CachedTwoTone';
import { useTranslation } from 'react-i18next';
import { getVariableValue, searchForProvidedVariable } from 'src/utils/CalcHelpers';
import { ValueResource, VariableResource } from 'src/backend/coreCalc';

interface Props {
    variable: VariableResource;
    variables: Array<VariableResource>;
    setValue: (value: ValueResource) => void;
}

const ExtractedIndicator: React.FC<Props> = ({ variable, variables, setValue }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const extractedVariableIdentifier = 'extracted' + variable.internalIdentifier;

    const extractedVariable = useMemo(() => searchForProvidedVariable(variables, extractedVariableIdentifier), [extractedVariableIdentifier, variables]);

    const isExtracted = !!extractedVariable;
    const isUsingExtracted = useMemo(() => {
        if (!isExtracted) return false;
        return getVariableValue(variable) === getVariableValue(extractedVariable);
    }, [variable, extractedVariable]);

    const reset = () => {
        setValue(extractedVariable.value);
    };

    return (
        isExtracted && (
            <>
                <InputAdornment position="start" className={'extracted-indicator ' + (isUsingExtracted ? 'is-extracted' : '')}>
                    {isUsingExtracted ? (
                        <Tooltip title={t('valueWasExtracted')}>
                            <AutoFixHighTwoToneIcon sx={{ cursor: 'help', color: theme.colors.success.dark }} />
                        </Tooltip>
                    ) : (
                        <Tooltip title={t('resetToExtractedValue')}>
                            <IconButton onClick={reset} sx={{ m: '-8px' }}>
                                <CachedTwoToneIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </InputAdornment>
            </>
        )
    );
};
export default ExtractedIndicator;
