import { Typography, Stack, useTheme, Button } from '@mui/material';
import Accordion from 'src/components/display/Accordion/Accordion';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { useDispatch } from 'react-redux';
import { RuleResource, VariableResource, CriterionResource, HolderResource } from 'src/backend/coreCalc';
import RuleInputHeader from './RuleInputHeader';
import CriteriaInput from '../CriteriaInput/CriteriaInput';
import HolderInput from '../HolderInput/HolderInput';

interface Props {
    id: string;
    rule: RuleResource;
    variables: Array<VariableResource>;
    openAccordionId: string;
    setOpenAccordionId: (newId: string) => void;
    updateRule?: (updatedRule: RuleResource, oldRule: RuleResource) => void;
    removeRule?: (ruleToRemove: RuleResource) => void;
    isDisabled?: boolean;
    disableVariableValues?: boolean;
}

const RuleInput: React.FC<Props> = ({ id, rule, variables, openAccordionId, setOpenAccordionId, updateRule = () => {}, removeRule = () => {}, isDisabled, disableVariableValues }) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const changeRuleValue = (valueHolder: HolderResource) => {
        updateRule({ ...rule, valueHolder }, rule);
    };

    const changeRuleCriteria = (criteria: Array<CriterionResource>) => {
        updateRule({ ...rule, criteria }, rule);
    };

    return (
        <Accordion
            accordionHeader={<RuleInputHeader rule={rule} variables={variables} />}
            accordionId={id}
            openAccordionId={openAccordionId}
            setOpenAccordionId={setOpenAccordionId}
            hideFirstBorder={true}
        >
            <Stack px="24px" py="16px" maxWidth="100%" overflow="hidden">
                <HolderInput label="Wert/Variable" holder={rule.valueHolder} setHolder={changeRuleValue} variables={variables} disabled={isDisabled} />

                {rule.isDefault ? (
                    <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 3, mb: 1, fontWeight: 600, fontSize: 13, lineHeight: 1.2 }} variant="subtitle1">
                        <InfoTwoToneIcon sx={{ fontSize: '1.7rem' }} />
                        Der Standardwert kann nicht gelöscht werden. Er wird dann angewendet, wenn kein anderer Wert anwendbar ist.
                    </Typography>
                ) : (
                    <>
                        <Typography variant="h4" sx={{ fontSize: 17, mt: 4, mb: 0.5, opacity: 0.7 }} gutterBottom>
                            Wann soll der Wert angewandt werden?
                        </Typography>
                        <Typography sx={{ fontSize: 14, lineHeight: 1.3, mb: 2 }} variant="subtitle1">
                            Wähle aus, wie der Wert für diese Kategorie berechnet werden soll. Wenn keine der vordefinierten Kalkulationen für dich geeignet ist, kannst du deine eigene Kalkulation
                            erstellen.
                        </Typography>
                        <CriteriaInput criteria={rule.criteria} variables={variables} setCriteria={changeRuleCriteria} />

                        <Button startIcon={<DeleteForeverTwoToneIcon />} color="error" sx={{ ml: 'auto', mt: 4 }} onClick={() => removeRule(rule)} disabled={isDisabled}>
                            Wert löschen
                        </Button>
                    </>
                )}
            </Stack>
        </Accordion>
    );
};

export default RuleInput;
