import { InputAdornment, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { InputVariableResource, NumberInputVariableResource, NumberValueResource, ValueResource, VariableResource } from 'src/backend/coreCalc';
import store from 'src/redux/store';
import { createNumberValidator, getNumberValue, measureInputValueWidth } from 'src/utils/CalcHelpers';
import InfoLabel from '../InfoLabel';
import { StyledTextInputField } from '../InputSections.styles';
import ExtractedIndicator from '../ExtractedIndicator';
import { formatUnit } from 'src/utils/FormatHelpers';
import DefaultIndicator from '../DefaultIndicator';

interface Props {
    variable: NumberInputVariableResource;
    variables: Array<VariableResource>;
    setVariable: (variable: NumberInputVariableResource) => void;
}

const NumberInputField: React.FC<Props> = ({ variable, variables, setVariable }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const { t } = useTranslation();
    const [error, setError] = useState<string>('');
    const ref = useRef<any>(null);
    const [left, setLeft] = useState<number>(0);
    const [value, setValue] = useState<string>('');

    const savedValue = useMemo(() => getNumberValue(variable.value) || getNumberValue(variable.defaultValue) || variable.min, [variable]);

    const numberSchema = createNumberValidator(variable);

    const validateAndSaveValue = async (newValue: string) => {
        try {
            await numberSchema.validate(newValue);
            setVariableValue({ type: ValueResource.type.NUMBER_VALUE, numberValue: parseFloat(newValue) });
            setError('');
        } catch (error) {
            setError(error.message);
        }
    };

    const setVariableValue = (value: NumberValueResource) => {
        setVariable({ ...variable, value });
    };

    const handleChange = (event) => {
        const newValue = event.target.value;
        setValue(newValue);
        validateAndSaveValue(newValue);
    };

    const handleBlur = () => {
        if (!error) return;
        setValue(savedValue + '');
        validateAndSaveValue(savedValue + '');
    };

    useEffect(() => {
        if (ref.current) setLeft(measureInputValueWidth(ref.current));
    }, [ref.current, value]);

    useEffect(() => {
        setValue(savedValue + '');
    }, []);

    return (
        <StyledTextInputField
            type="number"
            label={<InfoLabel variable={variable} />}
            onChange={handleChange}
            onBlur={handleBlur}
            value={value}
            error={!!error}
            helperText={error}
            onFocus={(event) => event.target.select()}
            InputProps={{
                startAdornment: <ExtractedIndicator variable={variable} variables={variables} setValue={setVariableValue} />,
                endAdornment: (
                    <InputAdornment position="end">
                        <DefaultIndicator variable={variable} left={left} />
                        {formatUnit(variable.unit)}
                    </InputAdornment>
                )
            }}
            inputProps={{
                min: variable.min,
                max: variable.max
            }}
            inputRef={ref}
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled={variable.visibility === InputVariableResource.visibility.DISABLED}
            size="small"
        />
    );

    // todo
    // return dimensionsInfos ? (
    //     <Stack>
    //         {renderTextField()}
    //         <Tooltip title={formatDimensionsInfos(dimensionsInfos, param.name === THREADS)} placement="left">
    //             <Box className="dimensions-infos" sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
    //                 <InfoTwoToneIcon sx={{ fontSize: 18, mx: 0.5, opacity: 0.7 }} />
    //                 <Typography variant="subtitle2" sx={{ fontSize: 14 }} noWrap>
    //                     {formatDimensionsInfos(dimensionsInfos, param.name === THREADS)}
    //                 </Typography>
    //             </Box>
    //         </Tooltip>
    //     </Stack>
    // ) : (
    //     renderTextField()
    // );
};

export default NumberInputField;
