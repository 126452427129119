/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BooleanValueResource } from './BooleanValueResource';
import type { ListValueResource } from './ListValueResource';
import type { MetadataResource } from './MetadataResource';
import type { NumberValueResource } from './NumberValueResource';
import type { StringValueResource } from './StringValueResource';
import type { VariableResource } from './VariableResource';

export type NumberInputVariableResource = (VariableResource & {
    name?: string;
    description?: string;
    metadata?: Array<MetadataResource>;
    sequence?: number;
    section?: NumberInputVariableResource.section;
    hasUiContract?: boolean;
    alwaysActive?: boolean;
    visibility?: NumberInputVariableResource.visibility;
    defaultValue?: (BooleanValueResource | ListValueResource | NumberValueResource | StringValueResource);
    unit?: NumberInputVariableResource.unit;
    min?: number;
    max?: number;
    naturalNumber?: boolean;
});

export namespace NumberInputVariableResource {

    export enum section {
        GEOMETRY_SECTION = 'GEOMETRY_SECTION',
        COMPONENT_SECTION = 'COMPONENT_SECTION',
        SURFACE_SECTION = 'SURFACE_SECTION',
        CALCULATION_SECTION = 'CALCULATION_SECTION',
        ADDITIONAL_WORK_SECTION = 'ADDITIONAL_WORK_SECTION',
        QUALITY_SECTION = 'QUALITY_SECTION',
    }

    export enum visibility {
        HIDDEN = 'HIDDEN',
        DISABLED = 'DISABLED',
        VISIBLE = 'VISIBLE',
    }

    export enum unit {
        MILLIMETER = 'MILLIMETER',
        CENTIMETER = 'CENTIMETER',
        METER = 'METER',
        KILOMETER = 'KILOMETER',
        SQUARE_MILLIMETER = 'SQUARE_MILLIMETER',
        SQUARE_CENTIMETER = 'SQUARE_CENTIMETER',
        SQUARE_METER = 'SQUARE_METER',
        SQUARE_KILOMETER = 'SQUARE_KILOMETER',
        CUBIC_MILLIMETER = 'CUBIC_MILLIMETER',
        CUBIC_CENTIMETER = 'CUBIC_CENTIMETER',
        CUBIC_METER = 'CUBIC_METER',
        GRAM = 'GRAM',
        KILOGRAM = 'KILOGRAM',
        TONNE = 'TONNE',
        PIECE = 'PIECE',
    }


}
