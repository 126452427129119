import { Dialog, DialogTitle, Stack, Typography, CircularProgress, IconButton, DialogContent, useTheme } from '@mui/material';
import { SSA3DViewer } from '@surface-solutions/ssa-3d-viewer';
import { renderCalculatedValue } from 'src/components/display/CalculatedValues';
import SurfLoader from 'src/components/display/Loader/SurfLoader';
import i18n from 'src/i18n/i18n';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import LooksTwoTwoToneIcon from '@mui/icons-material/LooksTwoTwoTone';
import Looks3TwoToneIcon from '@mui/icons-material/Looks3TwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import { ModelStats, NumberWithUnit } from '@surface-solutions/ssa-3d-viewer/dist/context/ViewerContext';
import { useEffect, useState } from 'react';
import { FileResource } from 'src/backend/market';
import { loadFile } from 'src/redux/thunks/fileManager.thunk';
import { useDispatch } from 'react-redux';
import { formatValue } from 'src/utils/FormatHelpers';
import { useTranslation } from 'react-i18next';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';
import ThreeDViewerDialog from 'src/components/display/ThreeDViewerDialog/ThreeDViewerDialog';

interface Props {
    is3dViewer: boolean;
    threeDFileId: string;
    hasPrevious?: boolean;
    hasNext?: boolean;
    selectPrevious?: () => void;
    selectNext?: () => void;
    close3dViewer?: () => void;
}

const Item3dViewerDialog: React.FC<Props> = ({ is3dViewer, threeDFileId, hasPrevious, hasNext, selectPrevious, selectNext, close3dViewer }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();
    const [fileResource, setFileResource] = useState<FileResource>();

    useEffect(() => {
        if (!threeDFileId) return;
        loadFileResource();
    }, [threeDFileId]);

    const loadFileResource = async () => {
        const file: FileResource = await dispatch(loadFile(threeDFileId));
        setFileResource(file);
    };

    return (
        <ThreeDViewerDialog fileResource={fileResource} isOpen={is3dViewer} setOpen={() => close3dViewer()}>
            {hasPrevious && (
                <IconButton color="primary" onClick={selectPrevious} sx={{ position: 'absolute', left: 0, alignSelf: 'center' }}>
                    <ArrowBackIosNewTwoToneIcon sx={{ fontSize: 32 }} />
                </IconButton>
            )}
            {hasNext && (
                <IconButton color="primary" onClick={selectNext} sx={{ position: 'absolute', right: 0, alignSelf: 'center' }}>
                    <ArrowForwardIosTwoToneIcon sx={{ fontSize: 32 }} />
                </IconButton>
            )}
        </ThreeDViewerDialog>
    );
};

export default Item3dViewerDialog;
