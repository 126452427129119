/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MetadataDTO } from './MetadataDTO';
import type { SelectOptionDTO } from './SelectOptionDTO';
import type { StringValueDTO } from './StringValueDTO';
import type { VariableDTO } from './VariableDTO';

export type SingleSelectInputVariableDTO = (VariableDTO & {
    name?: string;
    description?: string;
    metadata?: Array<MetadataDTO>;
    sequence?: number;
    section?: SingleSelectInputVariableDTO.section;
    hasUiContract?: boolean;
    alwaysActive?: boolean;
    visibility?: SingleSelectInputVariableDTO.visibility;
    value?: StringValueDTO;
    defaultValue?: StringValueDTO;
    options?: Array<SelectOptionDTO>;
});

export namespace SingleSelectInputVariableDTO {

    export enum section {
        GEOMETRY_SECTION = 'GEOMETRY_SECTION',
        COMPONENT_SECTION = 'COMPONENT_SECTION',
        SURFACE_SECTION = 'SURFACE_SECTION',
        CALCULATION_SECTION = 'CALCULATION_SECTION',
        ADDITIONAL_WORK_SECTION = 'ADDITIONAL_WORK_SECTION',
        QUALITY_SECTION = 'QUALITY_SECTION',
    }

    export enum visibility {
        HIDDEN = 'HIDDEN',
        DISABLED = 'DISABLED',
        VISIBLE = 'VISIBLE',
    }


}
