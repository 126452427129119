/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CriterionDTO } from './CriterionDTO';
import type { RuleDTO } from './RuleDTO';
import type { VariableDTO } from './VariableDTO';

export type SurchargeVariableDTO = (VariableDTO & {
    description?: string;
    setValueRules?: Array<RuleDTO>;
    criteriaWhenSurchargeApplies?: Array<CriterionDTO>;
    isAbsoluteSurcharge?: boolean;
    mode?: SurchargeVariableDTO.mode;
});

export namespace SurchargeVariableDTO {

    export enum mode {
        USE_CRITERIA = 'USE_CRITERIA',
        DISABLED = 'DISABLED',
        ENABLED = 'ENABLED',
    }


}
