import { Typography, Stack, useTheme, TextField, Autocomplete } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RoundedVariableResource, VariableResource } from 'src/backend/coreCalc';
import { getVariableName, getVariable } from 'src/utils/CalcHelpers';
import { formatVariable } from 'src/utils/FormatHelpers';
import VariableSelectionInput from 'src/components/input/VariableSelectionInput/VariableSelectionInput';

interface Props {
    variable: RoundedVariableResource;
    variables: Array<VariableResource>;
    setVariable: (variable: VariableResource) => void;
    setValid: (isValid: boolean) => void;
    isSmall?: boolean;
    isDisabled: boolean;
}

const RoundedVariableForm: React.FC<Props> = ({ variable, variables, setVariable, setValid, isSmall, isDisabled }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();

    useEffect(() => {
        if (!variable) return setValid(false);
        const isInvalid = getVariableName(variable) == null || getVariableName(variable).trim() === '' || variable.referenceVariableInternalIdentifier == null || isNaN(variable.numberOfDigits);
        setValid(!isInvalid);
    }, [variable]);

    const handleNameChange = (event) => {
        setVariable({
            ...variable,
            displayName: event.target.value
        } as RoundedVariableResource);
    };
    const handleVariableSelection = (variable: VariableResource) => {
        setVariable({
            ...variable,
            referenceVariableInternalIdentifier: variable.internalIdentifier
        } as RoundedVariableResource);
    };
    const handleDigitsChange = (event) => {
        setVariable({
            ...variable,
            numberOfDigits: parseInt(event.target.value)
        } as RoundedVariableResource);
    };

    return (
        variable && (
            <Stack gap={3}>
                <TextField label="Name" value={getVariableName(variable)} onChange={handleNameChange} variant="outlined" fullWidth size={isSmall ? 'small' : 'medium'} disabled={isDisabled} />
                <VariableSelectionInput
                    selectedVariableInternalIdentifier={variable.referenceVariableInternalIdentifier}
                    variables={variables}
                    onVariableSelection={handleVariableSelection}
                    label="Zu rundende Variable"
                    isDisabled={isDisabled}
                    size={isSmall ? 'small' : 'medium'}
                />
                <TextField
                    type="number"
                    label="Auf wie viele Kommastellen runden?"
                    value={variable.numberOfDigits || ''}
                    onChange={handleDigitsChange}
                    variant="outlined"
                    fullWidth
                    size={isSmall ? 'small' : 'medium'}
                    disabled={isDisabled}
                />
            </Stack>
        )
    );
};

export default RoundedVariableForm;
