import { Typography, Stack, Button, useTheme, Collapse, IconButton } from '@mui/material';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import { Fragment, useMemo, useState } from 'react';
import { UsedInCalcTag, VariableItem } from '../CalcEditor.styles';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import HeadlineButton from 'src/components/display/HeadlineButton/HeadlineButton';
import { groupBy, orderBy } from 'lodash';
import { formatVariable } from 'src/utils/FormatHelpers';
import CreateVariableOverviewDialog from '../Variable/CreateVariableOverviewDialog';
import ChangeVariableDialog from '../Variable/ChangeVariableDialog';
import { FoundVariablesResult } from '../CalcEditor.types';
import { VariableResource } from 'src/backend/coreCalc';
import { getVariableName } from 'src/utils/CalcHelpers';
import { useTranslation } from 'react-i18next';

type GroupedVariableInfo = {
    variables: Array<VariableResource>;
    totalUsedInCalculation: number;
};
type VariableTypesMap = {
    [type in VariableResource.type]?: GroupedVariableInfo;
};
type GroupedVariables = {
    general: GroupedVariableInfo;
} & VariableTypesMap;

interface Props {
    variables?: Array<VariableResource>;
    calcRelevantVariableInternalIdentifiers?: Array<string>;
    foundVariablesResult: FoundVariablesResult;
    createVariable?: (variable: VariableResource) => void;
    saveVariable?: (variable: VariableResource) => void;
    removeVariable?: (variableId: number) => void;
}

const CalcBuilderVariables: React.FC<Props> = ({ variables, calcRelevantVariableInternalIdentifiers, foundVariablesResult, createVariable, saveVariable, removeVariable }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState<{ [type: string]: boolean }>({});
    const [isCreateVariableOpen, setCreateVariableOpen] = useState<boolean>(false);
    const [variableToEdit, setVariableToEdit] = useState<VariableResource>();

    const groupedVariables = useMemo(() => {
        const groupedByType = groupBy(variables, 'type');
        const result: GroupedVariables = { general: { variables: [], totalUsedInCalculation: 0 } };

        Object.entries(groupedByType).forEach(([type, vars]) => {
            if (type === VariableResource.type.ON_THE_FLY_VALUE_VARIABLE) return;
            const totalUsedInCalculation = vars.reduce((sum, v) => sum + (foundVariablesResult.get(v.internalIdentifier)?.count || 0), 0);
            const sortedVars = orderBy(vars, ['usedInCalculation', 'internalIdentifier'], ['desc', 'asc']);

            if (vars.length > 5) {
                result[type] = { variables: sortedVars, totalUsedInCalculation };
            } else {
                result.general.variables.push(...sortedVars);
                result.general.totalUsedInCalculation += totalUsedInCalculation;
            }
        });

        if (result.general.variables) result.general.variables = orderBy(result.general.variables, ['usedInCalculation', 'name'], ['desc', 'asc']);

        return result;
    }, [variables, foundVariablesResult]);

    const isCalcRelevant = useMemo(() => {
        if (!variableToEdit?.id) return false;
        if (foundVariablesResult.get(variableToEdit.internalIdentifier)) return true;
        return (calcRelevantVariableInternalIdentifiers || []).includes(variableToEdit.internalIdentifier);
    }, [variableToEdit, calcRelevantVariableInternalIdentifiers, foundVariablesResult]);

    return (
        <Stack>
            {Object.entries(groupedVariables).map(([type, group]) =>
                type === 'general' ? (
                    <Fragment key={type}>
                        <Typography variant="h3" mb={2}>
                            Variablen
                        </Typography>
                        <Stack gap={1.2} mb={3}>
                            {group.variables.map(
                                (variable) =>
                                    variable.type !== VariableResource.type.SURCHARGE_VARIABLE && (
                                        <VariableItem direction="row" key={variable.id}>
                                            <Stack mr="auto" overflow="hidden">
                                                <Typography fontWeight={600} noWrap>
                                                    {getVariableName(variable)}
                                                </Typography>
                                                <Typography fontSize={12} sx={{ opacity: 0.7 }} noWrap>
                                                    {formatVariable(variable, variables)}
                                                </Typography>
                                            </Stack>

                                            {foundVariablesResult.get(variable.internalIdentifier)?.count > 0 && (
                                                <UsedInCalcTag>{foundVariablesResult.get(variable.internalIdentifier).count} mal verwendet</UsedInCalcTag>
                                            )}
                                            <IconButton color="secondary" sx={{ mr: -0.8 }} onClick={() => setVariableToEdit(variable)}>
                                                <SettingsTwoToneIcon />
                                            </IconButton>
                                        </VariableItem>
                                    )
                            )}
                        </Stack>
                    </Fragment>
                ) : (
                    <Fragment key={type}>
                        <HeadlineButton isOpen={isOpen[type]} setOpen={(open) => setOpen({ ...isOpen, [type]: open })}>
                            {t(type)}
                            <span style={{ opacity: 0.6, fontWeight: 400, fontSize: 13, marginLeft: 'auto' }}>In Verwendung: {group.totalUsedInCalculation}</span>
                        </HeadlineButton>

                        <Collapse in={isOpen[type]} timeout="auto" unmountOnExit>
                            <Stack gap={1.2} mb={3}>
                                {group.variables.map((variable) => (
                                    <VariableItem direction="row" key={variable.id}>
                                        <Stack mr="auto" overflow="hidden">
                                            <Typography fontWeight={600} noWrap>
                                                {getVariableName(variable)}
                                            </Typography>
                                            <Typography fontSize={12} sx={{ opacity: 0.7 }} noWrap>
                                                {formatVariable(variable, variables)}
                                            </Typography>
                                        </Stack>

                                        {foundVariablesResult.get(variable.internalIdentifier)?.count > 0 && (
                                            <UsedInCalcTag>{foundVariablesResult.get(variable.internalIdentifier).count} mal verwendet</UsedInCalcTag>
                                        )}
                                        <IconButton color="secondary" sx={{ mr: -0.8 }} onClick={() => setVariableToEdit(variable)}>
                                            <SettingsTwoToneIcon />
                                        </IconButton>
                                    </VariableItem>
                                ))}
                            </Stack>
                        </Collapse>
                    </Fragment>
                )
            )}

            <Button startIcon={<AddBoxTwoToneIcon />} onClick={() => setCreateVariableOpen(true)}>
                Variable erstellen
            </Button>
            <CreateVariableOverviewDialog variables={variables} isOpen={isCreateVariableOpen} setOpen={setCreateVariableOpen} createVariable={createVariable} />
            {variableToEdit && (
                <ChangeVariableDialog
                    variable={variableToEdit}
                    variables={variables}
                    isOpen={!!variableToEdit}
                    setOpen={() => setVariableToEdit(null)}
                    createVariable={createVariable}
                    saveVariable={saveVariable}
                    removeVariable={removeVariable}
                    isCalcRelevant={isCalcRelevant}
                />
            )}
        </Stack>
    );
};

export default CalcBuilderVariables;
