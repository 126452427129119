import { Stack, useTheme, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RuleBasedVariableResource, RuleResource, VariableResource } from 'src/backend/coreCalc';
import { createValueHolder, getVariableName } from 'src/utils/CalcHelpers';
import RuleInputs from 'src/components/input/RuleInputs/RuleInputs';

interface Props {
    variable: RuleBasedVariableResource;
    variables: Array<VariableResource>;
    setVariable: (variable: VariableResource) => void;
    setValid: (isValid: boolean) => void;
    isSmall?: boolean;
    isDisabled: boolean;
}

const RulesBasedVariableForm: React.FC<Props> = ({ variable, variables, setVariable, setValid, isSmall, isDisabled }) => {
    const dispatch = useDispatch();
    const theme = useTheme();

    useEffect(() => {
        if (!variable?.rules || variable.rules.length < 1)
            setVariable({
                ...variable,
                rules: [{ isDefault: true, valueHolder: createValueHolder(0) }]
            } as RuleBasedVariableResource);
    }, []);

    useEffect(() => {
        if (!variable) return setValid(false);
        const isInvalid = getVariableName(variable) == null || getVariableName(variable).trim() === '' || variable.rules == null || variable.rules.length < 1;
        setValid(!isInvalid);
    }, [variable]);

    const handleNameChange = (event) => {
        setVariable({
            ...variable,
            displayName: event.target.value
        });
    };

    const setRules = (rules: Array<RuleResource>) => {
        setVariable({
            ...variable,
            rules
        } as RuleBasedVariableResource);
    };

    return (
        variable && (
            <Stack gap={3}>
                <TextField label="Name" value={getVariableName(variable)} onChange={handleNameChange} variant="outlined" fullWidth size={isSmall ? 'small' : 'medium'} disabled={isDisabled} />
                <RuleInputs rules={variable.rules} variables={variables} setRules={setRules} isDisabled={isDisabled} />
            </Stack>
        )
    );
};

export default RulesBasedVariableForm;
