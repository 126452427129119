import { Typography, Stack, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { RuleResource, VariableResource, HolderResource } from 'src/backend/coreCalc';
import { getHolderData } from 'src/utils/CalcHelpers';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    rule: RuleResource;
    variables: Array<VariableResource>;
}

const RuleInputHeader: React.FC<Props> = ({ rule, variables }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const criteriaDescription = useMemo(() => {
        const filteredCriteria = (rule.criteria || []).filter(
            (criterion) => getHolderData(criterion.left, variables) != null && criterion.operator != null && getHolderData(criterion.right, variables) != null
        );
        return filteredCriteria.map((criterion) => `"${getHolderData(criterion.left, variables)}" ${t(criterion.operator).toLowerCase()} "${getHolderData(criterion.right, variables)}"`).join(' & ');
    }, [rule.criteria]);

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" flexGrow={1} maxWidth="100%" overflow="hidden">
            <Stack alignItems="flex-start" overflow="hidden">
                <Typography variant="h4" lineHeight={1.2}>
                    {(rule.valueHolder.type === HolderResource.type.VALUE_HOLDER ? 'Wert ' : 'Wert von ') + getHolderData(rule.valueHolder, variables)}
                </Typography>
                <Typography variant="subtitle2" fontSize={13} lineHeight={1.3} noWrap>
                    {rule.isDefault ? (
                        'Standard'
                    ) : criteriaDescription?.length > 0 ? (
                        <>Wenn {criteriaDescription}</>
                    ) : (
                        <span style={{ color: theme.colors.error.main }}>Keine Kriterien. Wert wird ignoriert</span>
                    )}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default RuleInputHeader;
