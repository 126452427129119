/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TemplateCollectionResource } from '../models/TemplateCollectionResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class TemplateRestControllerService {

    /**
     * @returns TemplateCollectionResource OK
     * @throws ApiError
     */
    public static getTemplateCollection(): CancelablePromise<TemplateCollectionResource> {
        return __request({
            method: 'GET',
            path: `/coreCalc/templates`,
        });
    }

}