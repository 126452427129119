import { Button, Divider, IconButton, List, Popover, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import LocalOfferTwoToneIcon from '@mui/icons-material/LocalOfferTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { t } from 'i18next';
import { connect, useDispatch } from 'react-redux';
import { RootState } from 'src/redux/store';
import { SurchargeVariableResource, VariableResource } from 'src/backend/coreCalc';
import SurchargeListItem from '../../SurchargeListItem';
import { ProCalcUpdateTypes, updateProCalc } from 'src/redux/shared/proCalc.common';

interface Props {
    itemId: number;
    variables: Array<VariableResource>;
    isSmall?: boolean;
    disabled?: boolean;
}

const ItemSurcharges: React.FC<Props> = ({ itemId, variables, isSmall, disabled }) => {
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState<boolean>(false);
    const ref = useRef<any>(null);

    const surchargeVariables = useMemo(() => {
        return (variables || []).filter((variable) => variable.type === VariableResource.type.SURCHARGE_VARIABLE) as Array<SurchargeVariableResource>;
    }, [variables]);

    const appliedSurchargeVariables = useMemo(() => {
        return (surchargeVariables || []).filter((variable) => variable.isApplied) as Array<SurchargeVariableResource>;
    }, [variables]);

    const saveMode = (variableId: number, mode: SurchargeVariableResource.mode) => {
        dispatch(updateProCalc(ProCalcUpdateTypes.ITEM_SURCHARGE_VARIABLE_MODE, { itemId, variableId, mode }));
    };

    return (
        surchargeVariables?.length > 0 && (
            <>
                <Button
                    sx={{ px: 1.2, py: 0.5, flexShrink: 0 }}
                    startIcon={<LocalOfferTwoToneIcon />}
                    variant="text"
                    color="secondary"
                    onClick={() => setOpen(true)}
                    ref={ref}
                    disabled={disabled}
                    size={isSmall ? 'small' : 'medium'}
                >
                    {t('surcharges')}
                    {appliedSurchargeVariables.length > 0 && ` (${appliedSurchargeVariables.length})`}
                </Button>

                <Popover
                    anchorEl={ref.current}
                    onClose={() => setOpen(false)}
                    open={isOpen}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                >
                    <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', p: 0.6, right: 0, top: 0, color: (theme) => theme.palette.grey[500] }}>
                        <CloseTwoToneIcon />
                    </IconButton>
                    <Stack p={2} width="25rem" maxWidth="100%">
                        <Typography variant="h3" textAlign="center">
                            {t('itemSurcharges')}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom textAlign="center" mb={0}>
                            {t('itemSurchargesExplanation')}
                        </Typography>

                        <Divider sx={{ mt: 2, mb: 1.5 }} />

                        <List disablePadding component="div" sx={{ mx: -2 }}>
                            {surchargeVariables.map((variable, index) => (
                                <SurchargeListItem key={index} variable={variable} saveMode={saveMode} />
                            ))}
                        </List>
                    </Stack>
                </Popover>
            </>
        )
    );
};

export default ItemSurcharges;
