import { Button, Stack, styled, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AiEmailConversationViewer from '../AiEmail/AiEmailConversationViewer';
import { AiMailConversationResource } from 'src/backend/market';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import { RootState } from 'src/redux/store';
import { connect } from 'react-redux';
import { InternalCalculationResource } from 'src/backend/internalCalc';
import MasterSettings from './MasterSettings';
import MasterAttachments from './MasterAttachments';
import MasterSurcharges from './MasterSurcharges';
import { CalculationResource, VariableResource } from 'src/backend/coreCalc';

const StyledStack = styled(Stack)(
    ({ theme }) => `
        & > .MuiButtonBase-root {
            flex-direction: column;
        }
        & > .MuiButtonBase-root > .MuiButton-startIcon {
            margin: 4px 0;
            margin-bottom: 2px;
        }
        & > .MuiButtonBase-root > .MuiButton-startIcon > .MuiSvgIcon-root {
            font-size: 24px;
        }
        
    `
);

interface Props {
    calculation: CalculationResource;
    mailConversation: AiMailConversationResource;
}

const MasterButtons: React.FC<Props> = ({ calculation, mailConversation }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [isAiEmailViewerOpen, setAiEmailViewerOpen] = useState<boolean>(false);

    return (
        <StyledStack gap={2} direction="row" alignItems="center" mx={-1}>
            {/* <MasterNotes/> */}
            <MasterSurcharges variables={calculation?.masterVariables} />
            {mailConversation?.conversation?.length > 0 && (
                <>
                    <Button color="secondary" startIcon={<EmailTwoToneIcon />} onClick={() => setAiEmailViewerOpen(true)}>
                        {t('email')}
                    </Button>
                    <AiEmailConversationViewer mailConversation={mailConversation} isOpen={isAiEmailViewerOpen} setOpen={setAiEmailViewerOpen} />
                </>
            )}
            <MasterAttachments attachments={calculation?.masterAttachments} />
            <MasterSettings variables={calculation?.masterVariables} />
        </StyledStack>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        mailConversation: state.proCalc.mailConversation
    };
};
export default connect(mapStateToProps)(MasterButtons);
