/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AddressResource } from './AddressResource';

export type CustomerResource = {
    regularCustomerId?: number;
    companyName?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    contactedVia?: CustomerResource.contactedVia;
    placeId?: string;
    address?: AddressResource;
    notes?: string;
}

export namespace CustomerResource {

    export enum contactedVia {
        WEB = 'WEB',
        PHONE = 'PHONE',
        EMAIL = 'EMAIL',
        IN_PERSON = 'IN_PERSON',
    }


}
