import { Typography, Stack, useTheme, TextField, Autocomplete, SxProps, Theme } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RoundedVariableResource, ValueResource, VariableResource } from 'src/backend/coreCalc';
import { getVariableName, getVariable } from 'src/utils/CalcHelpers';
import { formatVariable } from 'src/utils/FormatHelpers';

interface Props {
    selectedVariableInternalIdentifier: string;
    variables: Array<VariableResource>;
    onVariableSelection: (variable: VariableResource) => void;
    label?: string;
    isDisabled?: boolean;
    size?: 'small' | 'medium';
    sx?: SxProps<Theme>;
}

const VariableSelectionInput: React.FC<Props> = ({ selectedVariableInternalIdentifier, variables, onVariableSelection, label, isDisabled, size, sx }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();

    const selectedVariable = useMemo(() => {
        console.log(selectedVariableInternalIdentifier, variables);

        if (!selectedVariableInternalIdentifier) return;
        return getVariable(selectedVariableInternalIdentifier, variables) || null;
    }, [selectedVariableInternalIdentifier]);

    const handleVariableChange = (event, option: VariableResource) => {
        onVariableSelection(option);
    };

    return (
        <Autocomplete
            disabled={isDisabled}
            renderInput={(params) => <TextField {...params} error={!selectedVariableInternalIdentifier} label={label} />}
            noOptionsText={t('noOptions')}
            disableClearable
            value={selectedVariable}
            onChange={handleVariableChange}
            options={variables}
            size={size}
            sx={sx}
            getOptionLabel={(option: VariableResource) => (option ? getVariableName(option) : '')}
            isOptionEqualToValue={(option: VariableResource, value: VariableResource) => option.internalIdentifier === value.internalIdentifier}
            renderOption={(props, option: VariableResource) => (
                <li {...props} key={option.id}>
                    <Stack alignItems="flex-start" overflow="hidden">
                        <Typography fontSize={14} fontWeight={700} lineHeight={1.3} sx={{ textOverflow: 'ellipsis', maxWidth: '100%', overflow: 'hidden' }}>
                            {getVariableName(option)}
                        </Typography>
                        <Typography variant="subtitle2" fontSize={12} lineHeight={1.47} color="inherit" sx={{ opacity: 0.9 }}>
                            {formatVariable(option, variables)}
                        </Typography>
                    </Stack>
                </li>
            )}
        />
    );
};

export default VariableSelectionInput;
