import i18n from 'src/i18n/i18n';
import { DateTime } from 'luxon';
import configureMeasurements, { area, AreaSystems, AreaUnits, length, LengthSystems, LengthUnits } from 'convert-units';
import { getTermAsString } from './TermHelper';
import {
    AddressResource,
    InputVariableResource,
    NumberInputVariableResource,
    ProvidedVariableResource,
    RoundedVariableResource,
    RuleBasedVariableResource,
    ShortItemResource,
    SurchargeVariableResource,
    TermVariableResource,
    VariableResource
} from 'src/backend/coreCalc';
import { getVariableName, getValue, getVariable, getNumberValue, getHolderData } from './CalcHelpers';

// Measures: The names of the measures being used
type Measures = 'length' | 'area';
// Systems: The systems being used across all measures
type Systems = LengthSystems | AreaSystems;
// Units: All the units across all measures and their systems
type Units = LengthUnits | AreaUnits;

const convert = configureMeasurements<Measures, Systems, Units>({
    length,
    area
});

export const defaultLocale = 'de-AT';
export const defaultCurrency = 'EUR';
const isoThreads = [
    {
        thread: 'M1',
        coreHoleDrillSize: 0.75
    },
    {
        thread: 'M1.1',
        coreHoleDrillSize: 0.85
    },
    {
        thread: 'M1.2',
        coreHoleDrillSize: 0.95
    },
    {
        thread: 'M1.4',
        coreHoleDrillSize: 1.1
    },
    {
        thread: 'M1.6',
        coreHoleDrillSize: 1.3
    },
    {
        thread: 'M1.8',
        coreHoleDrillSize: 1.5
    },
    {
        thread: 'M2',
        coreHoleDrillSize: 1.6
    },
    {
        thread: 'M2.2',
        coreHoleDrillSize: 1.8
    },
    {
        thread: 'M2.5',
        coreHoleDrillSize: 2.1
    },
    {
        thread: 'M3',
        coreHoleDrillSize: 2.5
    },
    {
        thread: 'M3.5',
        coreHoleDrillSize: 2.9
    },
    {
        thread: 'M4',
        coreHoleDrillSize: 3.3
    },
    {
        thread: 'M5',
        coreHoleDrillSize: 4.2
    },
    {
        thread: 'M6',
        coreHoleDrillSize: 5.0
    },
    {
        thread: 'M8',
        coreHoleDrillSize: 6.8
    },
    {
        thread: 'M10',
        coreHoleDrillSize: 8.5
    },
    {
        thread: 'M12',
        coreHoleDrillSize: 10.2
    },
    {
        thread: 'M14',
        coreHoleDrillSize: 12.0
    },
    {
        thread: 'M16',
        coreHoleDrillSize: 14.0
    },
    {
        thread: 'M18',
        coreHoleDrillSize: 15.5
    },
    {
        thread: 'M20',
        coreHoleDrillSize: 17.5
    },
    {
        thread: 'M22',
        coreHoleDrillSize: 19.5
    },
    {
        thread: 'M24',
        coreHoleDrillSize: 21.0
    },
    {
        thread: 'M27',
        coreHoleDrillSize: 24.0
    },
    {
        thread: 'M30',
        coreHoleDrillSize: 26.5
    },
    {
        thread: 'M36',
        coreHoleDrillSize: 32.0
    },
    {
        thread: 'M42',
        coreHoleDrillSize: 37.5
    },
    {
        thread: 'M48',
        coreHoleDrillSize: 43.0
    },
    {
        thread: 'M56',
        coreHoleDrillSize: 50.5
    },
    {
        thread: 'M64',
        coreHoleDrillSize: 58.0
    }
];

export const formatPrice = (priceValue: number | string, showPrefix: boolean = false, locale: string = defaultLocale, currency: string = defaultCurrency): string => {
    if (typeof priceValue === 'string') priceValue = parseFloat(priceValue);
    const formatter = Intl.NumberFormat(locale, { style: 'currency', currency });
    const prefix = showPrefix ? (priceValue < 0 ? '- ' : '+ ') : '';
    return prefix + formatter.format(priceValue);
};

export const formatValue = (value: number | string, locale: string = defaultLocale, maximumFractionDigits: number = 3): string => {
    if (typeof value === 'string') value = parseFloat(value);
    const formatter = Intl.NumberFormat(locale, { maximumFractionDigits });
    return formatter.format(value);
};

export const getMonthName = (monthNumber: number) => {
    const date = new Date();

    date.setMonth(monthNumber - 1);
    return date.toLocaleString(i18n.language, { month: 'long' });
};

export const getLuxonDate = (date: string | Date): DateTime => {
    if (!date) return;
    return typeof date === 'string' ? DateTime.fromISO(date, { zone: 'Europe/Vienna' }) : DateTime.fromJSDate(date);
};
export const compareDates = (dateA: string | Date, dateB: string | Date) => {
    return getLuxonDate(dateA)?.toMillis() - getLuxonDate(dateB)?.toMillis();
};

export const formatDate = (date: string | Date, toFormat: string = 'dd.MM.yyyy HH:mm') => {
    if (!date) return;
    const luxonDate = getLuxonDate(date);
    return luxonDate.setLocale(i18n.language).toLocal().toFormat(toFormat);
};

export const getRelativeTime = (date: string | Date) => {
    if (!date) return;
    const luxonDate = getLuxonDate(date);
    return luxonDate.setLocale(i18n.language).toRelative();
};

export const formatDistance = (distance: string | number, fromUnit: LengthUnits = 'm', locale: string = defaultLocale) => {
    if (!distance) return distance;
    if (typeof distance === 'string') distance = parseFloat(distance);
    const result = convert(distance).from(fromUnit).toBest();
    return `${formatValue(result.val, locale, 1)} ${result.unit}`;
};
export const formatArea = (area: string | number, fromUnit: AreaUnits = 'm2', locale: string = defaultLocale) => {
    if (!area) return;
    if (typeof area === 'string') area = parseFloat(area);
    const result = convert(area).from(fromUnit).toBest();
    let unit = '';
    if (result.unit == 'mm2') unit = 'mm²';
    if (result.unit == 'cm2') unit = 'cm²';
    if (result.unit == 'm2') unit = 'm²';
    if (result.unit == 'km2') unit = 'km²';
    return `${formatValue(result.val, locale, 3)} ${unit}`;
};

export const formatDimensionsInfos = (dimensionsInfos?: Array<{ radius: number }>, isoMatching?: boolean): string => {
    if (!dimensionsInfos || dimensionsInfos.length < 1) return '0';
    const dimensionsGrouped = dimensionsInfos.reduce((accumulator, info) => {
        if (accumulator[info.radius]) accumulator[info.radius]++;
        else accumulator[info.radius] = 1;
        return accumulator;
    }, {});

    const tolerance = 0.05;

    return Object.entries(dimensionsGrouped)
        .map(([radius, amount]) => {
            const diameter = parseFloat(radius) * 2;
            if (isoMatching) {
                const foundMatch = isoThreads.find((isoThread) => {
                    const diff = Math.abs(isoThread.coreHoleDrillSize - diameter);
                    return diff <= tolerance;
                });
                if (foundMatch) return `${amount} × ${foundMatch.thread}`;
            }
            return `${amount} × Ø ${formatValue(diameter)} mm`;
        })
        .join(', ');
};

export const getAddressLine1Set = (address: AddressResource) => {
    if (!address) return;
    const houseNumberPrefix = address.street && address.houseNumber ? ' ' : '';
    return (address.street || '') + houseNumberPrefix + (address.houseNumber || '');
};
export const getAddressLine2Set = (address: AddressResource) => {
    if (!address) return;
    const cityPrefix = address.zipcode && address.city ? ' ' : '';
    const countryPrefix = (address.zipcode || address.city) && address.country ? ', ' : '';
    const country = address.country ? i18n.t('country:' + address.country) : null;
    return (address.zipcode || '') + cityPrefix + (address.city || '') + countryPrefix + (country || '');
};

export const getColors = (items: Array<ShortItemResource>) => {
    const colors = new Set<string>();
    items.forEach((item) => item.color && colors.add(item.color));
    return [...colors];
};
export const getMaterials = (items: Array<ShortItemResource>) => {
    const materials = new Set<string>();
    items.forEach((item) => item.material && materials.add(item.color));
    return [...materials];
};

export const formatVariable = (variable: VariableResource, variables: Array<VariableResource>) => {
    if (variable.type === VariableResource.type.DECLARATION_VARIABLE) return 'Definierter Wert: ' + getValue(variable.value);
    if (variable.type === VariableResource.type.PROVIDED_VARIABLE) return 'Bereitgestellter Wert';
    if (variable.type === VariableResource.type.ROUND_VARIABLE) {
        const roundedVariable = variable as RoundedVariableResource;
        const referenceVariable = getVariable(roundedVariable.referenceVariableInternalIdentifier, variables);
        const variableInternalIdentifier = getVariableName(referenceVariable) || roundedVariable.referenceVariableInternalIdentifier;
        return 'Wert: ' + variableInternalIdentifier + ' gerundet';
    }
    if (variable.type === VariableResource.type.TERM_VARIABLE) return 'Berechneter Wert: ' + getTermAsString((variable as TermVariableResource).term, variables, false);
    if (variable.type === VariableResource.type.RULE_BASED_VARIABLE) {
        const ruleBasedVariable = variable as RuleBasedVariableResource;
        const values = ruleBasedVariable?.rules.map((rule) => getHolderData(rule.valueHolder, variables));
        return 'Wert an Kondition gebunden: ' + values.join(' | ');
    }
    if (variable.type === VariableResource.type.BOOLEAN_INPUT_VARIABLE) return 'Eingabewert: Ein / Aus';
    if (variable.type === VariableResource.type.NUMBER_INPUT_VARIABLE) return 'Eingabewert: Zahl';
    if (variable.type === VariableResource.type.TEXT_INPUT_VARIABLE) return 'Eingabewert: Text';
    if (variable.type === VariableResource.type.SINGLE_SELECT_INPUT_VARIABLE) return 'Eingabewert: Auswahl';
    if (variable.type === VariableResource.type.MULTI_SELECT_INPUT_VARIABLE) return 'Eingabewert: Mehrfache Auswahl';
    if (variable.type === VariableResource.type.SURCHARGE_VARIABLE) return 'Aufschlagswert';
};

export const formatSurchargeValue = (variable: SurchargeVariableResource, locale: string = defaultLocale, currency: string = defaultCurrency): string => {
    let valueString = '';
    const value = getNumberValue(variable.value) || 0;
    if (value > 0) valueString += '+';
    valueString += variable.isAbsoluteSurcharge ? formatPrice(value, false, locale, currency) : value * 100 + '%';
    return valueString;
};

export const formatUnit = (unit: ProvidedVariableResource.unit | NumberInputVariableResource.unit): string => {
    if (!unit) return;
    let unitString = '';
    if (unit === ProvidedVariableResource.unit.KILOGRAM) unitString = 'kg';
    if (unit === ProvidedVariableResource.unit.MILLIMETER) unitString = 'mm';
    if (unit === ProvidedVariableResource.unit.SQUARE_METER) unitString = 'm²';
    if (unit === ProvidedVariableResource.unit.CUBIC_METER) unitString = 'm³';
    return unitString || unit.toString();
};
