import { Typography, Stack, useTheme, Button } from '@mui/material';
import Accordion from 'src/components/display/Accordion/Accordion';
import { formatVariable } from 'src/utils/FormatHelpers';
import { useDispatch } from 'react-redux';
import VariableForm from '../Variable/VariableForm';
import { useState } from 'react';
import { SurchargeVariableResource, VariableResource } from 'src/backend/coreCalc';
import { getVariableName } from 'src/utils/CalcHelpers';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';

interface Props {
    variable: SurchargeVariableResource;
    variables?: Array<VariableResource>;
    openAccordionId: string;
    setOpenAccordionId: (newId: string) => void;
    updateVariable: (updatedVariable: VariableResource, oldVariable: VariableResource) => void;
    removeVariable: (variableToRemove: VariableResource) => void;
}

const SurchargesItem: React.FC<Props> = ({ variable, variables, openAccordionId, setOpenAccordionId, updateVariable, removeVariable }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [isValid, setValid] = useState<boolean>(false);

    const setVariable = (updatedVariable: VariableResource) => {
        updateVariable(updatedVariable, variable);
    };

    return (
        <Accordion
            accordionHeader={
                <Stack direction="row" alignItems="center" justifyContent="space-between" flexGrow={1}>
                    <Stack alignItems="flex-start">
                        <Typography variant="h4" lineHeight={1.1}>
                            {getVariableName(variable)}
                        </Typography>
                        <Typography variant="subtitle2" lineHeight={1.2}>
                            {formatVariable(variable, variables)}
                        </Typography>
                    </Stack>
                </Stack>
            }
            accordionId={variable.id + ''}
            openAccordionId={openAccordionId}
            setOpenAccordionId={setOpenAccordionId}
            hideFirstBorder={true}
        >
            <Stack px="24px" py="16px">
                <VariableForm variable={variable} variables={variables} setVariable={setVariable} setValid={setValid} isSmall={true} />
                <Button startIcon={<DeleteForeverTwoToneIcon />} color="error" sx={{ ml: 'auto', mt: 4 }} onClick={() => removeVariable(variable)}>
                    Aufschlag löschen
                </Button>
            </Stack>
        </Accordion>
    );
};

export default SurchargesItem;
