import { TextField, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { InputVariableResource, ValueResource, VariableResource } from 'src/backend/coreCalc';
import { debounce } from 'lodash';
import NumberInputField from './NumberInputField';
import TextInputField from './TextInputField';
import BooleanInputField from './BooleanInputField';
import SingleSelectInputField from './SingleSelectInputField';

interface Props {
    variable: InputVariableResource;
    variables?: Array<VariableResource>;
    saveVariableValue: (variableId: number, value: ValueResource) => void;
}

const InputField: React.FC<Props> = ({ variable, variables, saveVariableValue }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();
    const [localVariable, setLocalVariable] = useState<InputVariableResource>(variable);

    const debouncedSave = useCallback(debounce(saveVariableValue, 500), []);

    const setVariable = (newVariable: VariableResource) => {
        setLocalVariable(newVariable);
        debouncedSave(newVariable.id, newVariable.value);
    };

    useEffect(() => {
        setLocalVariable(variable);
    }, [variable]);

    if (!localVariable || localVariable.visibility === InputVariableResource.visibility.HIDDEN) {
        return <></>;
    }

    switch (localVariable.type) {
        case VariableResource.type.NUMBER_INPUT_VARIABLE:
            return <NumberInputField variable={localVariable} setVariable={setVariable} variables={variables} />;
        case VariableResource.type.TEXT_INPUT_VARIABLE:
            return <TextInputField variable={localVariable} setVariable={setVariable} variables={variables} />;
        case VariableResource.type.BOOLEAN_INPUT_VARIABLE:
            return <BooleanInputField variable={localVariable} setVariable={setVariable} variables={variables} />;
        case VariableResource.type.SINGLE_SELECT_INPUT_VARIABLE:
            return <SingleSelectInputField variable={localVariable} setVariable={setVariable} variables={variables} />;
    }
};

export default InputField;
