/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CriterionResource } from './CriterionResource';
import type { RuleResource } from './RuleResource';
import type { VariableResource } from './VariableResource';

export type SurchargeVariableResource = (VariableResource & {
    setValueRules?: Array<RuleResource>;
    criteriaWhenSurchargeApplies?: Array<CriterionResource>;
    isAbsoluteSurcharge?: boolean;
    mode?: SurchargeVariableResource.mode;
    isApplied?: boolean;
});

export namespace SurchargeVariableResource {

    export enum mode {
        USE_CRITERIA = 'USE_CRITERIA',
        DISABLED = 'DISABLED',
        ENABLED = 'ENABLED',
    }


}
