import { AppDispatch } from '../store';
import {
    addCategory,
    addCategoryComputation,
    addCategoryMessage,
    addCategoryVariable,
    addMasterVariable,
    addSubCategory,
    deleteCategory,
    deleteCategoryComputation,
    deleteCategoryMessage,
    deleteCategoryVariable,
    deleteMasterVariable,
    deleteSubCategory,
    setCategoryVariable,
    setMasterVariable
} from '../slices/calcEditor.reducer';
import {
    BlueprintResource,
    BlueprintRestControllerService,
    CategoryDTO,
    SubCategoryDTO,
    CategoryResource,
    CategoryTemplateResource,
    ComputationResource,
    MessageResource,
    NewCategoryDTO,
    ValueDTO,
    ValueResource,
    VariableDTO,
    VariableResource
} from 'src/backend/coreCalc';
import { createTermVariable } from 'src/utils/CalcHelpers';

export const updateBlueprintName = (blueprint: BlueprintResource, value: string) => async () => {
    await BlueprintRestControllerService.updateName(blueprint.id, { name: value });
};

export const updateMasterComputation = (blueprint: BlueprintResource, value: ComputationResource) => async () => {
    await BlueprintRestControllerService.updateMasterComputation(blueprint.id, {
        name: value.name
        // term: value.term as TermDTO todo
    });
};

export const createMasterVariable = (blueprint: BlueprintResource, value: VariableResource) => async (dispatch: AppDispatch) => {
    const variable = await BlueprintRestControllerService.createMasterVariable(blueprint.id, value as VariableDTO);
    dispatch(deleteMasterVariable(-1)); // delete the temp variable
    dispatch(addMasterVariable(variable));
};

export const updateMasterVariable = (blueprint: BlueprintResource, value: VariableResource) => async (dispatch: AppDispatch) => {
    const variable = await BlueprintRestControllerService.updateMasterVariable(blueprint.id, value.id, value as VariableDTO);
    dispatch(setMasterVariable(variable));
};

export const removeMasterVariable = (blueprint: BlueprintResource, value: number) => async () => {
    await BlueprintRestControllerService.deleteMasterVariable(blueprint.id, value);
};

export const updateMasterVariableValue = (blueprint: BlueprintResource, value: { variableId: number; value: ValueResource }) => async (dispatch: AppDispatch) => {
    await BlueprintRestControllerService.updateMasterVariableValue1(blueprint.id, value.variableId, value.value as ValueDTO);
};

export const updateCategory = (blueprint: BlueprintResource, value: CategoryResource) => async () => {
    await BlueprintRestControllerService.updateCategory(blueprint.id, value.id, value as CategoryDTO);
};

export const updateCategoryName = (blueprint: BlueprintResource, value: { categoryId: number; name: string }) => async () => {
    await BlueprintRestControllerService.updateCategory(blueprint.id, value.categoryId, { name: value.name });
};

export const createCategory = (blueprint: BlueprintResource, value: CategoryTemplateResource) => async (dispatch: AppDispatch) => {
    const categoryDto: NewCategoryDTO = {
        name: value.name,
        defaultComputationTemplateId: value.defaultComputationTemplateId,
        supportedComputationTemplateIds: value.supportedComputationTemplateIds,
        variables: [],
        icon: value.icon,
        subCategoriesTranslationKeys: value.subCategoriesTranslationKeys
    };

    value.variableTemplates.forEach((templateVariable) => {
        const variable = { ...templateVariable };
        delete variable.id;
        categoryDto.variables.push(variable as VariableDTO);
    });
    const category = await BlueprintRestControllerService.createCategory(blueprint.id, categoryDto);
    dispatch(deleteCategory(-1));
    dispatch(addCategory(category));
};

export const duplicateCategory = (blueprint: BlueprintResource, value: number) => async (dispatch: AppDispatch) => {
    const category = await BlueprintRestControllerService.duplicateCategory(blueprint.id, value);
    dispatch(deleteCategory(-1));
    dispatch(addCategory(category));
};

export const removeCategory = (blueprint: BlueprintResource, value: number) => async () => {
    await BlueprintRestControllerService.deleteCategory(blueprint.id, value);
};

export const createCategoryComputation =
    (blueprint: BlueprintResource, value: { categoryId: number; computation: ComputationResource; resultVariable: VariableResource }) => async (dispatch: AppDispatch) => {
        const variable = await dispatch(createCategoryVariable(blueprint, { categoryId: value.categoryId, variable: value.resultVariable }));
        const computation = await BlueprintRestControllerService.createCategoryComputation(blueprint.id, value.categoryId, {
            name: value.computation.name,
            resultVariableInternalIdentifier: variable.internalIdentifier
        });
        dispatch(addCategoryVariable({ categoryId: value.categoryId, variable }));
        dispatch(deleteCategoryComputation({ categoryId: value.categoryId, computationId: -1 }));
        dispatch(addCategoryComputation({ categoryId: value.categoryId, computation }));
    };

export const removeCategoryComputation = (blueprint: BlueprintResource, value: { categoryId: number; computationId: number }) => async (dispatch: AppDispatch) => {
    // todo
};

export const updateCategoryComputation = (blueprint: BlueprintResource, value: { categoryId: number; computation: ComputationResource }) => async () => {
    await BlueprintRestControllerService.updateCategoryComputation(blueprint.id, value.categoryId, value.computation.id, {
        name: value.computation.name
    });
};

export const updateCategoryComputationSelection = (blueprint: BlueprintResource, value: { categoryId: number; computationId: number }) => async () => {
    await BlueprintRestControllerService.updateCategory(blueprint.id, value.categoryId, { selectedComputationId: value.computationId });
};

export const createCategoryVariable = (blueprint: BlueprintResource, value: { categoryId: number; variable: VariableResource }) => async (dispatch: AppDispatch) => {
    const variable = await BlueprintRestControllerService.createCategoryVariable(blueprint.id, value.categoryId, value.variable as VariableDTO);
    dispatch(deleteCategoryVariable({ categoryId: value.categoryId, variableId: -1 }));
    dispatch(addCategoryVariable({ categoryId: value.categoryId, variable }));
    return variable;
};

export const updateCategoryVariable = (blueprint: BlueprintResource, value: { categoryId: number; variable: VariableResource }) => async (dispatch: AppDispatch) => {
    const variable = await BlueprintRestControllerService.updateCategoryVariable(blueprint.id, value.categoryId, value.variable.id, value.variable as VariableDTO);
    dispatch(setCategoryVariable({ categoryId: value.categoryId, variable }));
};

export const removeCategoryVariable = (blueprint: BlueprintResource, value: { categoryId: number; variableId: number }) => async () => {
    await BlueprintRestControllerService.deleteCategoryVariable(blueprint.id, value.categoryId, value.variableId);
};

export const updateCategoryVariableValue = (blueprint: BlueprintResource, value: { categoryId: number; variableId: number; value: ValueResource }) => async (dispatch: AppDispatch) => {
    await BlueprintRestControllerService.updateCategoryVariableValue(blueprint.id, value.categoryId, value.variableId, value.value as ValueDTO);
};
export const createCategoryMessage = (blueprint: BlueprintResource, value: { categoryId: number; message: MessageResource }) => async (dispatch: AppDispatch) => {
    // const variable = await BlueprintRestControllerService.createCategoryMessage(blueprint.id, value.categoryId, value.message as MessageDTO);
    dispatch(deleteCategoryMessage({ categoryId: value.categoryId, messageId: -1 }));
    dispatch(addCategoryMessage({ categoryId: value.categoryId, message: { ...value.message, id: Math.random() } })); //  todo remove random
};

export const updateCategoryMessage = (blueprint: BlueprintResource, value: { categoryId: number; message: MessageResource }) => async (dispatch: AppDispatch) => {
    // const variable = await BlueprintRestControllerService.updateCategoryMessage(blueprint.id, value.categoryId, value.variable.id, value.variable as VariableDTO);
    // dispatch(setCategoryVariable({ categoryId: value.categoryId, variable }));
};

export const removeCategoryMessage = (blueprint: BlueprintResource, value: { categoryId: number; messageId: number }) => async () => {
    // await BlueprintRestControllerService.deleteCategoryMessage(blueprint.id, value.categoryId, value.messageId);
};

export const createSubCategory = (blueprint: BlueprintResource, value: { categoryId: number; subCategory: CategoryResource }) => async (dispatch: AppDispatch) => {
    const subCategory = await BlueprintRestControllerService.createSubCategory(blueprint.id, value.categoryId, value.subCategory as SubCategoryDTO);
    dispatch(deleteSubCategory({ categoryId: value.categoryId, subCategoryId: -1 }));
    dispatch(addSubCategory({ categoryId: value.categoryId, subCategory }));
};

export const updateSubCategory = (blueprint: BlueprintResource, value: { categoryId: number; subCategory: CategoryResource }) => async () => {
    await BlueprintRestControllerService.updateCategory(blueprint.id, value.subCategory.id, value.subCategory as SubCategoryDTO);
};

export const removeSubCategory = (blueprint: BlueprintResource, value: { categoryId: number; subCategoryId: number }) => async () => {
    await BlueprintRestControllerService.deleteCategory(blueprint.id, value.subCategoryId);
};
