import { Typography, Stack, Button, Dialog, DialogTitle, IconButton, DialogContent, useTheme, TextField } from '@mui/material';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { nav } from 'src/statics/navigations';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createBlueprint } from 'src/redux/thunks/calcEditors.thunk';

interface Props {
    isOpen: boolean;
    setOpen?: (isOpen: boolean) => void;
}

const NewCalcEditorDialog: React.FC<Props> = ({ isOpen, setOpen }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [name, setName] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);

    const handleClick = async () => {
        setLoading(true);
        const blueprint = await dispatch(createBlueprint(name));
        if (!blueprint) return alert('error');
        navigate(nav.TOOLS.sub.CALC_EDITOR.sub.EDIT.path.replace(':id', blueprint.id));
        setLoading(false);
    };

    const isValid = useMemo(() => {
        return name != null && name.trim() !== '';
    }, [name]);

    return (
        <Dialog open={isOpen} fullWidth maxWidth="sm" PaperProps={{ sx: { width: '100%' } }} onClose={() => setOpen(false)}>
            <DialogTitle>
                <Typography sx={{ fontSize: '1.875rem', fontWeight: 700, mt: 0.5, mb: 1, pr: 7 }} gutterBottom>
                    Neue Kalkulationsgrundlage
                </Typography>
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 16, top: 16, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack gap={3} pt={1}>
                    <TextField label={t('name')} value={name} onChange={(event) => setName(event.target.value)} />
                    <Button variant="contained" startIcon={<AutoAwesomeTwoToneIcon />} sx={{ ml: 'auto' }} onClick={handleClick} disabled={!isValid}>
                        Erstellen
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default NewCalcEditorDialog;
