/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MetadataResource } from './MetadataResource';
import type { ValueResource } from './ValueResource';

export type InputVariableResource = {
    id?: number;
    internalIdentifier?: string;
    displayName?: string;
    translationKey?: string;
    type?: InputVariableResource.type;
    value?: ValueResource;
    name?: string;
    description?: string;
    metadata?: Array<MetadataResource>;
    sequence?: number;
    section?: InputVariableResource.section;
    hasUiContract?: boolean;
    alwaysActive?: boolean;
    visibility?: InputVariableResource.visibility;
    defaultValue?: ValueResource;
}

export namespace InputVariableResource {

    export enum type {
        TERM_VARIABLE = 'TERM_VARIABLE',
        PROVIDED_VARIABLE = 'PROVIDED_VARIABLE',
        DECLARATION_VARIABLE = 'DECLARATION_VARIABLE',
        SURCHARGE_VARIABLE = 'SURCHARGE_VARIABLE',
        ROUND_VARIABLE = 'ROUND_VARIABLE',
        RULE_BASED_VARIABLE = 'RULE_BASED_VARIABLE',
        ON_THE_FLY_VALUE_VARIABLE = 'ON_THE_FLY_VALUE_VARIABLE',
        BOOLEAN_INPUT_VARIABLE = 'BOOLEAN_INPUT_VARIABLE',
        NUMBER_INPUT_VARIABLE = 'NUMBER_INPUT_VARIABLE',
        SINGLE_SELECT_INPUT_VARIABLE = 'SINGLE_SELECT_INPUT_VARIABLE',
        MULTI_SELECT_INPUT_VARIABLE = 'MULTI_SELECT_INPUT_VARIABLE',
        TEXT_INPUT_VARIABLE = 'TEXT_INPUT_VARIABLE',
    }

    export enum section {
        GEOMETRY_SECTION = 'GEOMETRY_SECTION',
        COMPONENT_SECTION = 'COMPONENT_SECTION',
        SURFACE_SECTION = 'SURFACE_SECTION',
        CALCULATION_SECTION = 'CALCULATION_SECTION',
        ADDITIONAL_WORK_SECTION = 'ADDITIONAL_WORK_SECTION',
        QUALITY_SECTION = 'QUALITY_SECTION',
    }

    export enum visibility {
        HIDDEN = 'HIDDEN',
        DISABLED = 'DISABLED',
        VISIBLE = 'VISIBLE',
    }


}
