import { Typography, Card, Stack, Button } from '@mui/material';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import Category from './Category';
import { useState } from 'react';
import CreateCategoryDialog from './CreateCategoryDialog';
import { RootState } from 'src/redux/store';
import { connect } from 'react-redux';
import { BlueprintResource } from 'src/backend/coreCalc';
import { useTranslation } from 'react-i18next';

interface Props {
    blueprint: BlueprintResource;
}

const CategoryList: React.FC<Props> = ({ blueprint }) => {
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState<boolean>(false);

    return (
        <>
            <Typography mt={4} color="secondary" fontSize={20} fontWeight="bold">
                Kategorien
            </Typography>
            <Typography mb={2} color="secondary" fontSize={14}>
                Kategorien sind eine Sammlung von annähernd gleich kalkulierbaren Teilen. Es muss mindestens eine Standardkategorie definiert sein!
            </Typography>

            <Stack gap={4}>
                {(blueprint.categories || []).map((category, index) =>
                    category.id === -1 ? (
                        <Card key={index} sx={{ p: 2, minHeight: '25rem' }}>
                            {t('loadingItem')}
                        </Card>
                    ) : (
                        <Category key={category.id + category.name + index} category={category} settings={blueprint.settings} />
                    )
                )}
            </Stack>

            <Button variant="contained" sx={{ mr: 'auto', mt: blueprint?.categories?.length > 0 ? 4 : 0 }} startIcon={<AddBoxTwoToneIcon />} onClick={() => setOpen(true)}>
                Kategorie hinzufügen
            </Button>
            <CreateCategoryDialog isOpen={isOpen} setOpen={setOpen} />
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        blueprint: state.calcEditor.blueprint
    };
};
export default connect(mapStateToProps)(CategoryList);
