import { Typography, styled, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import store, { useDispatch } from 'src/redux/store';
import Accordion from 'src/components/display/Accordion/Accordion';
import MuiIcon from 'src/components/display/MuiIcon/MuiIcon';
import { InputVariableResource, ValueResource, VariableResource } from 'src/backend/coreCalc';
import InputField from './InputFields/InputField';

const InputFieldWrapper = styled('div')(
    ({ theme }) => `
        display: flex;
        flex-wrap: wrap;
        gap: ${theme.spacing(2.5)} ${theme.spacing(3)};
        padding: ${theme.spacing(2)};
        padding-bottom: ${theme.spacing(2.5)};
        
        & > *,
        .MuiFormControl-root,
        .MuiOutlinedInput-root,
        .dimensions-infos {
            width: 13rem;
            min-width: 0;
        }
    `
);

const iconMap = {
    [InputVariableResource.section.GEOMETRY_SECTION]: 'StraightenTwoTone',
    [InputVariableResource.section.COMPONENT_SECTION]: 'ViewInArTwoTone',
    [InputVariableResource.section.SURFACE_SECTION]: 'FormatPaintTwoTone',
    [InputVariableResource.section.CALCULATION_SECTION]: 'BallotTwoTone',
    [InputVariableResource.section.ADDITIONAL_WORK_SECTION]: 'PlaylistAddCircleTwoTone',
    [InputVariableResource.section.QUALITY_SECTION]: 'StarHalfTwoTone'
};

interface Props {
    section: string;
    inputVariables: Array<InputVariableResource>;
    variables?: Array<VariableResource>;
    calcRelevantVariableInternalIdentifiers?: Array<string>;
    saveVariableValue: (variableId: number, value: ValueResource) => void;
    openAccordionId: string;
    setOpenAccordionId: (newId: string) => void;
}

const InputSection: React.FC<Props> = ({ section, inputVariables, variables, calcRelevantVariableInternalIdentifiers, saveVariableValue, openAccordionId, setOpenAccordionId }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const { t } = useTranslation();

    const shownVariables = useMemo(() => {
        return (inputVariables || []).filter((variable) => {
            if (variable.alwaysActive) return true;
            return calcRelevantVariableInternalIdentifiers.includes(variable.internalIdentifier);
        });
    }, [inputVariables, calcRelevantVariableInternalIdentifiers]);

    return (
        shownVariables.length > 0 && (
            <Accordion
                key={section}
                accordionHeader={
                    <>
                        <MuiIcon icon={iconMap[section]} />
                        <Typography ml={1} variant="h4">
                            {section}
                        </Typography>
                    </>
                }
                accordionId={section}
                openAccordionId={openAccordionId}
                setOpenAccordionId={setOpenAccordionId}
            >
                <InputFieldWrapper>
                    {shownVariables.map((variable) => (
                        <InputField key={variable.id} variable={variable} variables={variables} saveVariableValue={saveVariableValue} />
                    ))}
                </InputFieldWrapper>
            </Accordion>
        )
    );
};

export default InputSection;
