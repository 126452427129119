import { Button, Divider, IconButton, Popover, Stack, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { RootState } from 'src/redux/store';
import { connect, useDispatch } from 'react-redux';
import { InputVariableResource, ValueResource, VariableResource } from 'src/backend/coreCalc';
import { ProCalcUpdateTypes, updateProCalc } from 'src/redux/shared/proCalc.common';
import InputField from 'src/components/input/InputSections/InputFields/InputField';
import { isInputVariable } from 'src/utils/CalcHelpers';

interface Props {
    variables?: Array<VariableResource>;
}

const MasterSettings: React.FC<Props> = ({ variables }) => {
    const { t } = useTranslation();
    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const dispatch = useDispatch();

    const inputVariables = (variables || []).filter((variable) => isInputVariable(variable) && !(variable as InputVariableResource).hasUiContract);

    const saveVariableValue = (variableId: number, value: ValueResource) => {
        dispatch(updateProCalc(ProCalcUpdateTypes.MASTER_VARIABLE_VALUE, { variableId, value }));
    };

    return (
        inputVariables.length > 0 && (
            <>
                <Button ref={ref} color="secondary" startIcon={<SettingsTwoToneIcon />} onClick={() => setOpen(true)}>
                    {t('orderSettings')}
                </Button>
                <Popover
                    anchorEl={ref.current}
                    onClose={() => setOpen(false)}
                    open={isOpen}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', p: 0.6, right: 0, top: 0, color: (theme) => theme.palette.grey[500] }}>
                        <CloseTwoToneIcon />
                    </IconButton>
                    <Stack sx={{ p: 3, width: '30rem', maxWidth: '100%' }} id={'masterCalcDetailsCard'}>
                        <Typography variant="h3" textAlign="center">
                            {t('details')}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom textAlign="center" mb={0}>
                            {t('generalCalcSettings')}
                        </Typography>

                        <Divider sx={{ my: 2 }} />

                        <Stack pt={1} gap={3}>
                            {inputVariables.map((variable) => (
                                <InputField key={variable.id} variable={variable} variables={variables} saveVariableValue={saveVariableValue} />
                            ))}
                        </Stack>
                    </Stack>
                </Popover>
            </>
        )
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        calculation: state.proCalc.calculation
    };
};
export default connect(mapStateToProps)(MasterSettings);
