import { Avatar, Badge, Box, Button, Stack, styled, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CallTwoToneIcon from '@mui/icons-material/CallTwoTone';
import AlternateEmailTwoToneIcon from '@mui/icons-material/AlternateEmailTwoTone';
import RecordVoiceOverTwoToneIcon from '@mui/icons-material/RecordVoiceOverTwoTone';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import store from 'src/redux/store';
import LinkTwoToneIcon from '@mui/icons-material/LinkTwoTone';
import { getAddressLine1Set, getAddressLine2Set } from 'src/utils/FormatHelpers';
import { CustomerResource } from 'src/backend/coreCalc';
import { RegularCustomerResource } from 'src/backend/market';
import { getCustomerInfo, getCustomerText, isCustomerUnset } from 'src/utils/CalcHelpers';

const AvatarWrapper = styled(Avatar)(
    ({ theme }) => `
        font-size: 24px;
        width: ${theme.spacing(6.4)};
        height: ${theme.spacing(6.4)};
    `
);
const StyledIconBox = styled(Box)(
    ({ theme }) => `
        margin-top: -3px;
        margin-left: -3px;
        & .MuiSvgIcon-root {
            padding: 2px;
            background: ${theme.colors.primary.main};
            color: ${theme.colors.alpha.white[100]};
            font-size: 18px;
            border-radius: 50%;
        }
    `
);

interface Props {
    customer: CustomerResource;
    linkedRegularCustomer?: RegularCustomerResource;
    buttonRef: any;
    setOpen: Function;
    disabled?: boolean;
}

const CustomerDetailsDisplay: React.FC<Props> = ({ customer = {}, linkedRegularCustomer, buttonRef, setOpen, disabled }) => {
    const { t } = useTranslation();
    const s = store.getState();

    return (
        <>
            <Stack direction={{ md: 'column', xl: 'row' }} textAlign={{ xs: 'center', xl: 'left' }} alignItems="center" overflow="hidden">
                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                        customer?.contactedVia && (
                            <StyledIconBox display="flex" justifySelf="flex-end" alignItems="center">
                                {customer.contactedVia === CustomerResource.contactedVia.PHONE && <CallTwoToneIcon />}
                                {customer.contactedVia === CustomerResource.contactedVia.EMAIL && <AlternateEmailTwoToneIcon />}
                                {customer.contactedVia === CustomerResource.contactedVia.IN_PERSON && <RecordVoiceOverTwoToneIcon />}
                                {customer.contactedVia === CustomerResource.contactedVia.WEB && <LanguageTwoToneIcon />}
                            </StyledIconBox>
                        )
                    }
                >
                    <AvatarWrapper alt={getCustomerText(customer) || '?'} src={linkedRegularCustomer?.logo || '/static/images/avatars/3.jpg'} />
                </Badge>
                <Stack mt={{ xs: 1.5, xl: 0 }} ml={{ xs: 0, xl: 1.8 }} alignItems={{ xs: 'center', xl: 'flex-start' }} alignSelf="center" overflow="hidden" maxWidth="100%">
                    <Stack direction="row" overflow="hidden" alignItems="center" maxWidth="100%">
                        <Typography variant="h4" fontWeight="bold" noWrap lineHeight={1.5} maxWidth="100%">
                            {getCustomerInfo(customer)}
                        </Typography>
                        {linkedRegularCustomer && (
                            <Tooltip title={t('regularCustomerIsLinked', { companyName: linkedRegularCustomer.companyName })}>
                                <LinkTwoToneIcon sx={{ ml: 0.5 }} fontSize="small" />
                            </Tooltip>
                        )}
                    </Stack>
                    {getAddressLine1Set(customer.address) !== '' && (
                        <Typography variant="subtitle2" lineHeight={1.2} fontSize={14} noWrap>
                            {getAddressLine1Set(customer.address)}
                        </Typography>
                    )}
                    {getAddressLine2Set(customer.address) !== '' && (
                        <Typography variant="subtitle2" lineHeight={1.2} fontSize={14} noWrap>
                            {getAddressLine2Set(customer.address)}
                        </Typography>
                    )}
                </Stack>
                <Box display="flex" justifySelf="flex-end" alignItems="center" maxWidth="100%" pl={{ xs: 0, xl: 1.5 }} pt={{ xs: 1, xl: 0 }} ml={{ md: 0, xl: 'auto' }}>
                    <Button variant="outlined" onClick={() => setOpen(true)} ref={buttonRef} sx={{ whiteSpace: 'nowrap' }} disabled={disabled}>
                        {isCustomerUnset(customer) ? t('addCustomer') : t('editCustomer')}
                    </Button>
                </Box>
            </Stack>
            {/* {(values?.contactPerson?.firstName || values?.contactPerson?.lastName ||
          values?.contactPerson?.phone || values?.note || !_.isEmpty(priceParameter)) && <Divider sx={{ my: 2 }}/>}
        {(values?.contactPerson?.firstName || values?.contactPerson?.lastName) && <Stack direction="row">
            <Typography variant="subtitle2" fontWeight="bold">{t('contactPerson')}:&nbsp;</Typography>
            <Typography variant="subtitle2">{values.contactPerson.firstName} {values.contactPerson.lastName}</Typography>
        </Stack>}
        {values?.contactPerson?.phone && <Stack direction="row">
            <Typography variant="subtitle2" fontWeight="bold">{t('phone')}:&nbsp;</Typography>
            <Typography variant="subtitle2">{values.contactPerson.phone}</Typography>
        </Stack>}
        {values?.note && <Stack direction="row">
            <Typography variant="subtitle2" fontWeight="bold">{t('note')}:&nbsp;</Typography>
            <Typography variant="subtitle2">{values.note}</Typography>
        </Stack>}
        {!_.isEmpty(priceParameter) && Object.values(priceParameter).map((param: PriceManipulationParameterValueResource, index) => 
            <Stack direction="row" key={index}>
                <Typography variant="subtitle2" fontWeight="bold">{wT(param.parameter.label, s)}:&nbsp;</Typography>
                <Typography variant="subtitle2">{param.value}{param.parameter.unit}</Typography>
            </Stack>
        )} */}
        </>
    );
};

export default CustomerDetailsDisplay;
