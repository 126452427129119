import { Typography, Stack, Divider, Button, Dialog, DialogTitle, IconButton, DialogContent, styled, useTheme } from '@mui/material';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useEffect, useMemo, useState } from 'react';
import SurchargesItem from './SurchargesItem';
import { cloneDeep, isEqual } from 'lodash';
import { SurchargeVariableResource, VariableResource } from 'src/backend/coreCalc';
import { createValueHolder } from 'src/utils/CalcHelpers';

const StyledStack = styled(Stack)(
    ({ theme }) => `
        height: 100%;
        .accordion-button {
            padding-left: 24px;
            padding-right: 24px;
        }
    `
);

interface Props {
    variables: Array<VariableResource>;
    isOpen: boolean;
    setOpen?: (isOpen: boolean) => void;
    createVariable: (variable: VariableResource) => void;
    saveVariable: (variable: VariableResource) => void;
    removeVariable: (variableId: number) => void;
}

const SurchargesDialog: React.FC<Props> = ({ variables, isOpen, setOpen, saveVariable, createVariable, removeVariable }) => {
    const theme = useTheme();
    const [openAccordionId, setOpenAccordionId] = useState<string>();
    const [localVariables, setLocalVariables] = useState<Array<VariableResource>>(variables);

    useEffect(() => {
        setLocalVariables(variables);
    }, [variables]);

    const surchargeVariables = useMemo(() => {
        return (localVariables || []).filter((variable) => variable.type === VariableResource.type.SURCHARGE_VARIABLE) as Array<SurchargeVariableResource>;
    }, [localVariables]);

    const addVariable = () => {
        const baseName = 'Neuer Aufschlag';
        let newName = baseName;
        let counter = 1;

        const isDuplicateName = (displayName) => {
            return surchargeVariables.some((variable) => variable.displayName === displayName);
        };
        while (isDuplicateName(newName)) {
            newName = `${baseName} (${counter})`;
            counter++;
        }

        createVariable({
            id: -1,
            type: VariableResource.type.SURCHARGE_VARIABLE,
            displayName: newName,
            ruleBasedValue: [{ isDefault: true, valueHolder: createValueHolder(0) }],
            criteria: []
        } as SurchargeVariableResource);
    };

    const updateVariable = (updatedVariable: VariableResource, oldVariable: VariableResource) => {
        const variableIndex = localVariables.findIndex((variable) => variable === oldVariable);
        const clonedVariables = cloneDeep(localVariables);
        clonedVariables[variableIndex] = updatedVariable;
        setLocalVariables(clonedVariables);
    };

    const removeVariableWrapper = (variableToRemove: VariableResource) => {
        if (variableToRemove.id) {
            removeVariable(variableToRemove.id);
        } else {
            setLocalVariables(localVariables.filter((variable) => variable !== variableToRemove));
        }
    };

    const closeDialog = () => {
        localVariables.forEach((variable) => {
            const found = (variables || []).find((v) => v.id === variable.id);
            if (isEqual(found, variable) || variable.id === -1) return;
            saveVariable(variable);
        });
        setOpen(false);
    };

    return (
        <Dialog open={isOpen} fullWidth maxWidth="md" PaperProps={{ sx: { width: '100%' } }} onClose={closeDialog}>
            <DialogTitle>
                <Typography sx={{ fontSize: '1.875rem', fontWeight: 700, mt: 0.5, mb: 1, pr: 7 }} gutterBottom>
                    Aufschläge
                </Typography>
                <Typography sx={{ fontSize: 14, lineHeight: 1.3 }} variant="subtitle1">
                    Wähle aus, wie der Preis für diese Kategorie berechnet werden soll. Wenn keine der vordefinierten Kalkulationen für dich geeignet ist, kannst du deine eigene Kalkulation erstellen.
                </Typography>
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 16, top: 16, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ px: 0, pb: 0 }}>
                <StyledStack>
                    <Divider />
                    {surchargeVariables.map((variable, index) => (
                        <SurchargesItem
                            key={variable.id + variable.internalIdentifier + index}
                            variable={variable}
                            variables={variables}
                            openAccordionId={openAccordionId}
                            setOpenAccordionId={setOpenAccordionId}
                            updateVariable={updateVariable}
                            removeVariable={removeVariableWrapper}
                        />
                    ))}
                    <Divider sx={{ mt: 'auto' }} />
                    <Button startIcon={<AddBoxTwoToneIcon />} onClick={addVariable} sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                        Eigenen Aufschlag erstellen
                    </Button>
                </StyledStack>
            </DialogContent>
        </Dialog>
    );
};

export default SurchargesDialog;
