import { Fragment, useEffect, useMemo, useState } from 'react';

import { Box, ListItemAvatar, ListItemText, Divider, List, ListItem, Card, Typography, Button, Avatar, styled, useTheme, Grid, Stack, Tooltip, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';
import Add from '@mui/icons-material/Add';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import InternalCalcsAmount from './InternalCalcsAmount';
import InternalCalcsTotalValue from './InternalCalcsTotalValue';
import InternalCalcsRegularCustomers from './InternalCalcsRegularCustomers';
import { RootState } from 'src/redux/store';
import { connect, useDispatch } from 'react-redux';
import { InternalCalculationDashboardStatisticResource, InternalCalculationStatisticRestControllerService, UserResource } from 'src/backend/market';
import { formatDate, formatPrice, getMonthName } from 'src/utils/FormatHelpers';
import { CURRENCY_SETTING_NAME, INVALID, NUMBER_FORMAT_SETTING_NAME, VALID } from 'src/statics/statics';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import { nav } from 'src/statics/navigations';
import { sortBy } from 'lodash';
import { ShortInternalCalculationResource } from 'src/backend/internalCalc';
import { DateTime } from 'luxon';
import { findGeneralSetting } from 'src/utils/CalcHelpers';
import { getRecentProCalcs } from 'src/redux/thunks/proCalcs.thunk';
import { ShortCalculationResource } from 'src/backend/coreCalc';

const ListGridWrapper = styled(Grid)(
    ({ theme }) => `
        padding-left: 0 !important;
        border-left: 1px solid ${theme.colors.alpha.black[7]};
    `
);
const ListWrapper = styled(List)(
    ({ theme }) => `
        .MuiDivider-root:first-of-type {
            display: none;
        }
    `
);
const ChartWrapper = styled(Chart)(
    ({ theme }) => `
        svg {
            overflow: visible;
        }
    `
);
const DividerWrapper = styled(Divider)(
    ({ theme }) => `
        background-color: ${theme.colors.alpha.black[7]};
    `
);

interface Props {
    user: UserResource;
    isLoading: boolean;
}

const InternalCalcs: React.FC<Props> = ({ user, isLoading }) => {
    const dispatch = useDispatch();
    const { t }: { t: any } = useTranslation();
    const [statistic, setStatistic] = useState<InternalCalculationDashboardStatisticResource>({});
    const [proCalcs, setProCalcs] = useState<Array<ShortCalculationResource>>();
    const theme = useTheme();

    useEffect(() => {
        dispatch(getRecentProCalcs()).then((proCalcs) => {
            setProCalcs(proCalcs);
        });

        // InternalCalculationStatisticRestControllerService.getInternalCalculationStatistic(user.id).then((response) => {
        //     setStatistic(response);
        // }); todo
    }, []);

    const monthlyData = useMemo(() => {
        const data = sortBy(statistic?.monthlyCalculations, ['year', 'month']);
        let found = false;

        const filteredData = [];
        for (const d of data) {
            if (found || d.count > 0) {
                found = true;
                filteredData.push(d);
            }
        }

        if (filteredData.length === 1) {
            const monthData = filteredData[0];
            const previousMonth = DateTime.fromObject({ year: monthData.year, month: monthData.month }).minus({ months: 1 });
            const previousMonthData = { count: 0, month: previousMonth.month, year: previousMonth.year };
            return [previousMonthData, monthData];
        }
        return filteredData;
    }, [statistic]);

    const filteredCalcs = useMemo(() => {
        if (!proCalcs) return [];
        return proCalcs.slice(0, 5);
    }, [proCalcs]);

    const isNoData = filteredCalcs.length === 0;
    const getNumberFormat = (calc) => findGeneralSetting(NUMBER_FORMAT_SETTING_NAME, calc.generalSettings);
    const getCurrency = (calc) => findGeneralSetting(CURRENCY_SETTING_NAME, calc.generalSettings);

    const Box1Options: ApexOptions = {
        chart: {
            background: 'transparent',
            toolbar: {
                show: false
            },
            sparkline: {
                enabled: true
            },
            zoom: {
                enabled: false
            }
        },
        colors: [theme.colors.info.main],
        dataLabels: {
            enabled: false
        },
        theme: {
            mode: theme.palette.mode
        },
        stroke: {
            show: true,
            colors: [theme.colors.info.main],
            width: 3
        },
        legend: {
            show: false
        },
        fill: {
            gradient: {
                shade: 'light',
                type: 'vertical',
                shadeIntensity: 0.4,
                inverseColors: false,
                opacityFrom: 0.9,
                opacityTo: 0,
                stops: [0, 100]
            }
        },
        labels: monthlyData.map((data) => getMonthName(data.month)),
        xaxis: {
            labels: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            crosshairs: {
                width: 1
            }
        },
        yaxis: {
            show: false,
            min: 0
        },
        tooltip: {
            enabled: !isNoData
        }
    };
    const Box1Data = [
        {
            name: t('monthlyCalculations'),
            data: isNoData ? [1, 1] : monthlyData.map((data) => data.count)
        }
    ];

    return (
        <Card id={'internalCalcDashboard'} sx={{ display: 'flex', position: 'relative', minHeight: 388 }}>
            {isLoading ? (
                <Stack justifyContent="center" alignItems="center" width="100%">
                    <CircularProgress />
                </Stack>
            ) : (
                <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item xs={12} lg={3.3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Box pt={3} px={3}>
                            <Box sx={{ display: 'flex' }}>
                                <Typography variant="h3" noWrap>
                                    {t('internalCalcCardTitle')}
                                </Typography>
                                <Button
                                    component={Link}
                                    to={nav.CALCULATIONS.sub.EDIT.path.replace(':id', 'new')}
                                    variant="contained"
                                    startIcon={<Add />}
                                    size="small"
                                    sx={{
                                        height: 'auto',
                                        m: 'auto',
                                        mr: 0,
                                        p: '5px 10px',
                                        whiteSpace: 'noWrap',
                                        '.MuiButton-startIcon': {
                                            mr: '4px'
                                        }
                                    }}
                                >
                                    {t('addCalc')}
                                </Button>
                            </Box>
                            <Typography variant="subtitle2">{t('internalCalcCardExplanation')}</Typography>
                        </Box>
                        <ChartWrapper options={Box1Options} series={Box1Data} type="area" height={270} sx={{ marginTop: 'auto' }} />
                    </Grid>
                    <ListGridWrapper item xs={12} lg={3.3}>
                        <Typography variant="subtitle2" sx={{ m: '10px 18px', fontWeight: 'bold' }} pl={{ xs: 2, lg: 0 }}>
                            {t('latestCalcs')}
                        </Typography>
                        <DividerWrapper />
                        <ListWrapper disablePadding>
                            {filteredCalcs.map((calc) => (
                                <Fragment key={calc.id}>
                                    <DividerWrapper />
                                    <ListItem sx={{ py: 1.2, px: 2.5, pl: { xs: 4, lg: 2.5 } }}>
                                        <ListItemAvatar sx={{ mr: 0, minWidth: '46px' }}>
                                            <Avatar alt={calc.name} src="/broken-image.jpg" sx={{ minWidth: '32px', width: '32px', height: '32px' }} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={calc.name}
                                            primaryTypographyProps={{ variant: 'subtitle2', noWrap: true, fontSize: '13px', maxWidth: '300px' }}
                                            secondary={formatDate(calc.createdAt)}
                                            secondaryTypographyProps={{ variant: 'subtitle2', noWrap: true, fontSize: '11px' }}
                                        />
                                        <Typography sx={{ ml: 1, pr: 2, opacity: 0.5 }} variant="h4">
                                            {formatPrice(calc.value, false, getNumberFormat(calc), getCurrency(calc))}
                                        </Typography>
                                        <Button
                                            component={Link}
                                            to={nav.CALCULATIONS.sub.VIEW.path.replace(':id', calc.id)}
                                            variant="text"
                                            color="secondary"
                                            sx={{
                                                padding: '5px',
                                                backgroundColor: `${theme.colors.secondary.lighter}`,
                                                '&:hover': {
                                                    backgroundColor: `${theme.colors.secondary.main}`,
                                                    color: `${theme.palette.getContrastText(theme.colors.secondary.main)}`
                                                }
                                            }}
                                        >
                                            {t('view')}
                                        </Button>
                                    </ListItem>
                                </Fragment>
                            ))}
                            {isNoData && (
                                <Typography variant="subtitle2" sx={{ py: 1.2 }} textAlign="center">
                                    {t('noDataYet')}
                                </Typography>
                            )}
                        </ListWrapper>
                        {!isNoData && (
                            <>
                                <DividerWrapper />
                                <Box p={0.5} sx={{ textAlign: 'center' }}>
                                    <Button component={Link} to={nav.CALCULATIONS.path} variant="text" endIcon={<ArrowForwardTwoToneIcon />} size="small">
                                        {t('viewAll')}
                                    </Button>
                                </Box>
                            </>
                        )}
                    </ListGridWrapper>
                    <ListGridWrapper item xs={12} lg={5.4} justifyContent="center" alignItems="center">
                        <Grid container spacing={3} p={3} justifyContent="center">
                            <Grid item xs={12} lg={6}>
                                <Stack spacing={3} justifyContent="center">
                                    <InternalCalcsAmount value={statistic.totalCalculationsOfCompany} monthlyIncrease={isNoData ? 0 : statistic.totalCalculationsOfCompanyMonthlyRelativeIncrease} />
                                    <InternalCalcsTotalValue value={statistic.totalPriceOfCompany} monthlyIncrease={isNoData ? [] : statistic.totalPriceOfCompanyMonthlyRelativeIncrease} />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <InternalCalcsRegularCustomers isNoData={isNoData} regularCustomersPercent={Math.round((statistic?.calculationsWithRegularCustomersPercent || 0) * 100)} />
                            </Grid>
                        </Grid>
                    </ListGridWrapper>
                </Grid>
            )}
        </Card>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        user: state.user.currentUser,
        isLoading: state.proCalc.isLoading
    };
};
export default connect(mapStateToProps)(InternalCalcs);
