import { CalendarTodayTwoTone, ContentCopyTwoTone } from '@mui/icons-material';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import { alpha, Box, Button, Card, Divider, IconButton, LinearProgress, Stack, styled, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { formatDate, formatDistance, formatPrice, getColors, getMaterials } from 'src/utils/FormatHelpers';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AiMailConversationResource } from 'src/backend/market';
import { DateTime } from 'luxon';
import { wT } from 'src/utils/wizardTranslations';
import { updatePaging } from 'src/redux/thunks/proCalcs.thunk';
import CalcOverviewGridEmail from './CalcOverviewGridEmail';
import CalculationStatus from '../display/CalculationStatus/CalculationStatus';
import { LinearProgressWithLabel } from '../display/LinearProgressWithLabel';
import SurfLoader from '../display/Loader/SurfLoader';
import ColorDrops from '../display/ColorDrops';
import store from 'src/redux/store';
import { ShortCalculationResource, ShortItemResource } from 'src/backend/coreCalc';
import { getCustomerInfo, getText } from 'src/utils/CalcHelpers';

const GridBox = styled(Box)(
    ({ theme }) => `
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
        grid-gap: ${theme.spacing(4)};
        padding: ${theme.spacing(2)};

        .MuiCard-root.disabled {
            cursor: not-allowed;
            opacity: 0.7;
        }
        .MuiCard-root:not(.disabled):hover {
            box-shadow: 0 0 0 2px ${theme.colors.info.main}, 0 0 12px 1px ${theme.colors.info.main};
        }
        .MuiCard-root:not(.disabled)active {
            box-shadow: 0 0 0 2px ${theme.colors.info.main}, 0 0 18px 3px ${theme.colors.info.main};
        }

        .calculation-status {
            font-size: 14px;
            line-height: 1.47;
            gap: 4px;
            padding: 3px 5px;
            border-radius: 5px;
        }
        .calculation-status.unopened {
            padding-left: 10px;
            padding-right: 10px;
        }
        .calculation-status .MuiSvgIcon-root {
            width: 0.8em;
            height: 0.8em;
        }

        ${theme.breakpoints.down('sm')} {
            padding: ${theme.spacing(1)};
            display: flex;
            flex-direction: column;
            gap: ${theme.spacing(4)};
            overflow: hidden;
        }
    `
);

type ItemOrGroup = ShortItemResource | { value: number; items: ShortItemResource[] };

interface Props {
    calculations: Array<ShortCalculationResource>;
    mailConversations: { [uniqueId: string]: AiMailConversationResource };
    isLoading: boolean;
    paging: { page: number; pageSize: number; total: number; rowCount: number };
    handleEditClicked: Function;
    handleViewClicked: Function;
    handleDuplicateClicked: Function;
    handleDeleteClicked: Function;
}

const CalcOverviewGrid: React.FC<Props> = ({ calculations, mailConversations, paging, isLoading, handleEditClicked, handleViewClicked, handleDuplicateClicked, handleDeleteClicked }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();
    const s = store.getState();

    const handleLoadNext = () => {
        dispatch(updatePaging({ pageSize: paging.pageSize + 10 }));
    };

    useEffect(() => {
        if (paging.pageSize !== 15) {
            dispatch(updatePaging({ pageSize: 15 }));
        }
    }, []);

    const getItems = (items: Array<ShortItemResource>): Array<ItemOrGroup> => {
        if (items.length <= 4) return items;

        const firstFourItems = items.slice(0, 4);
        const remainingItems = items.slice(4);

        const value = remainingItems.reduce((sum, item) => sum + item.value, 0);
        const groupedItem: { value: number; items: ShortItemResource[] } = { value, items: remainingItems };

        return [...firstFourItems, groupedItem];
    };

    const getFileExtensions = (row: ShortCalculationResource) => {
        const fileExtensions = [];
        row.items.forEach((item) => {
            const fileName = item.dataSourceOriginalFileName || '';
            if (fileName.split('.').length > 1) {
                fileExtensions.push(fileName.split('.').pop().toUpperCase());
            }
        });
        return [...new Set(fileExtensions)];
    };

    const getDateFromDays = (days: number) => {
        return DateTime.now().plus({ days });
    };

    const getThroughput = (row: ShortCalculationResource) => {
        if (!row.wishDate || !row.bringDate) return;
        const throughputDays = parseInt(row.wishDate) - parseInt(row.bringDate);
        return `${throughputDays || ''} ${t(throughputDays === 0 ? 'sameDay' : throughputDays > 1 ? 'days' : 'day')}`;
    };
    const getDateFromNumber = (dateNumber: string) => {
        // todo could be a number
        if (dateNumber == null) return;
        return getDateFromDays(parseInt(dateNumber)).toLocaleString(DateTime.DATE_SHORT);
    };
    const getDeliveryPercentage = (row: ShortCalculationResource) => {
        const hoursSinceBringDate = row.bringDate ? getDateFromDays(parseInt(row.bringDate)).diffNow().as('hours') : 0;
        const hoursBeforeWishDate = row.wishDate ? getDateFromDays(parseInt(row.wishDate)).diffNow().as('hours') : 0;
        const sumHours = Math.abs(hoursSinceBringDate) + Math.abs(hoursBeforeWishDate);

        return hoursSinceBringDate > 0 ? 0 : hoursBeforeWishDate <= 0 ? 100 : (100 * Math.abs(hoursSinceBringDate)) / sumHours;
    };

    const rows = Object.values(calculations);
    return (
        <Box pb={8} m={{ md: -2 }} maxWidth={{ xs: '100%', md: 'unset' }} height="100%">
            {rows.length === 0 && isLoading ? (
                <Stack height="100%" justifyContent="center" alignItems="center" pt={8}>
                    <SurfLoader />
                </Stack>
            ) : rows.length === 0 ? (
                <Stack alignItems="center" justifyContent="center" height="30rem" bgcolor={theme.colors.alpha.black[7]} borderRadius={5}>
                    <Typography>{t('noCalculations')}</Typography>
                </Stack>
            ) : (
                <InfiniteScroll
                    next={handleLoadNext}
                    hasMore={rows.length !== paging.total || isLoading}
                    endMessage={
                        rows.length > 20 && (
                            <Typography my={2} textAlign="center">
                                {t('noMoreCalculations')}
                            </Typography>
                        )
                    }
                    loader={<LinearProgress variant="indeterminate" />}
                    dataLength={rows.length}
                >
                    <GridBox maxWidth="100%">
                        {rows.map((row: ShortCalculationResource) => (
                            <Card
                                key={row.id}
                                sx={{
                                    p: 2,
                                    cursor: 'pointer',
                                    display: 'flex',
                                    position: 'relative'
                                }}
                                onClick={() => handleEditClicked(row)}
                            >
                                <Stack flexGrow={1} maxWidth="100%" overflow="hidden">
                                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                                        <Stack direction="row" alignItems="center">
                                            <Typography variant="h4" noWrap>
                                                {getCustomerInfo(row.customer)}
                                            </Typography>
                                            {row?.customer?.regularCustomerId && (
                                                <Typography
                                                    noWrap
                                                    ml={0.6}
                                                    fontSize="0.85em"
                                                    fontWeight="bold"
                                                    lineHeight={1}
                                                    bgcolor={theme.colors.warning.lighter}
                                                    color={theme.colors.warning.main}
                                                    p={0.4}
                                                    borderRadius={0.6}
                                                    className="regular-customer"
                                                >
                                                    {t('regularCustomer')}
                                                </Typography>
                                            )}
                                        </Stack>
                                        {/* {!!row.distance && (
                                            <Typography variant="subtitle2" color="primary" whiteSpace="nowrap">
                                                {formatDistance(row.distance)}
                                            </Typography>
                                        )} */}
                                    </Stack>
                                    <Stack direction="row" alignItems="center" mt={0.5}>
                                        <CalendarTodayTwoTone sx={{ fontSize: '1rem', mr: 0.6 }} />
                                        <Typography variant="subtitle2" noWrap>
                                            {formatDate(row.createdAt)}
                                        </Typography>
                                    </Stack>
                                    <Divider sx={{ my: 2 }} />
                                    <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
                                        <Typography variant="subtitle2" noWrap color="text.secondary">
                                            ID
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={12}>
                                            #{row.id}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                                        <Typography variant="subtitle2" noWrap color="text.secondary">
                                            {t('status')}
                                        </Typography>
                                        <CalculationStatus status={row.status} mailConversation={mailConversations?.[row.id]} />
                                    </Stack>
                                    <CalcOverviewGridEmail mailConversation={mailConversations?.[row.id]} calcId={row.id} />
                                    <Stack direction="row" gap={4} justifyContent="space-between" mt={2}>
                                        <Stack maxWidth="100%" overflow="hidden">
                                            <Typography variant="subtitle2" lineHeight={1.2} noWrap color="text.secondary">
                                                {t('anlieferung')}
                                            </Typography>
                                            <Typography fontSize={15} lineHeight={1.4} noWrap fontWeight="bold">
                                                {getDateFromNumber(row.bringDate) || '-'}
                                            </Typography>
                                        </Stack>

                                        {row.bringDate && row.wishDate && (
                                            <Stack justifyContent="flex-end" maxWidth="100%" overflow="hidden">
                                                <Typography fontSize={15} lineHeight={1.4} noWrap color="text.secondary">
                                                    ⇠ {getThroughput(row)} ⇢
                                                </Typography>
                                            </Stack>
                                        )}

                                        <Stack textAlign="right" maxWidth="100%" overflow="hidden">
                                            <Typography variant="subtitle2" lineHeight={1.2} noWrap color="text.secondary">
                                                {t('abholung')}
                                            </Typography>
                                            <Typography fontSize={15} lineHeight={1.4} noWrap fontWeight="bold">
                                                {getDateFromNumber(row.wishDate) || '-'}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <LinearProgressWithLabel value={getDeliveryPercentage(row)} />
                                    <Divider sx={{ my: 2 }} />

                                    <Stack direction="row" maxWidth="100%" overflow="hidden">
                                        <Stack mr={3} overflow="hidden">
                                            <Typography variant="subtitle2">{t('articles')}</Typography>
                                            {getItems(row.items).map((itemOrGroup, index) =>
                                                'items' in itemOrGroup ? (
                                                    <Typography key={index} variant="subtitle2" fontWeight="bold" fontSize={13} noWrap>
                                                        {t('xMoreParts', { x: itemOrGroup.items.length })}
                                                    </Typography>
                                                ) : (
                                                    <Typography key={index} variant="subtitle2" fontWeight="bold" fontSize={13} noWrap>
                                                        {itemOrGroup.quantity} × {getText(itemOrGroup)}
                                                    </Typography>
                                                )
                                            )}
                                        </Stack>
                                        <Stack ml="auto" textAlign="right" maxWidth="100%">
                                            <Typography variant="subtitle2">{t('price')}</Typography>
                                            {getItems(row.items).map((item, index) => (
                                                <Typography key={index} variant="subtitle2" fontWeight="bold" fontSize={13} color="primary" noWrap>
                                                    {formatPrice(item.value)}
                                                </Typography>
                                            ))}
                                        </Stack>
                                    </Stack>
                                    <Typography textAlign="right" variant="h3" fontWeight="bold" color="primary" mt={1} noWrap>
                                        {formatPrice(row.value)}
                                    </Typography>
                                    <Divider sx={{ my: 2 }} />
                                    <Stack mb={2}>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography variant="subtitle2" noWrap color="text.secondary">
                                                {t('colors')}
                                            </Typography>
                                            <Typography variant="subtitle2" fontSize={15} fontWeight="bold">
                                                {getColors(row.items).length > 0 ? <ColorDrops colors={getColors(row.items)} /> : '-'}
                                            </Typography>
                                        </Stack>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography variant="subtitle2" noWrap color="text.secondary">
                                                {t('materials')}
                                            </Typography>
                                            <Tooltip
                                                title={
                                                    getMaterials(row.items)
                                                        .map((material) => wT(material, s))
                                                        .join(', ') || '-'
                                                }
                                            >
                                                <Typography variant="subtitle2" fontSize={15} fontWeight="bold" noWrap>
                                                    {getMaterials(row.items)
                                                        .map((material) => wT(material, s))
                                                        .join(', ') || '-'}
                                                </Typography>
                                            </Tooltip>
                                        </Stack>
                                        {getFileExtensions(row).length > 0 && (
                                            <Stack direction="row" justifyContent="space-between">
                                                <Typography variant="subtitle2" noWrap color="text.secondary">
                                                    {t('fileExtensions')}
                                                </Typography>
                                                <Tooltip title={getFileExtensions(row).join(', ')}>
                                                    <Typography variant="subtitle2" fontSize={15} fontWeight="bold" noWrap>
                                                        {getFileExtensions(row).join(', ')}
                                                    </Typography>
                                                </Tooltip>
                                            </Stack>
                                        )}
                                    </Stack>

                                    <Stack direction="row" spacing={1} mt="auto">
                                        <Button
                                            variant="contained"
                                            sx={{ flexGrow: 1 }}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleEditClicked(row);
                                            }}
                                        >
                                            {t('edit')}
                                        </Button>
                                        <Tooltip title={t('viewSummary')}>
                                            <div>
                                                <IconButton
                                                    color="primary"
                                                    sx={{
                                                        bgcolor: theme.colors.primary.lighter,
                                                        '&:hover': {
                                                            bgcolor: alpha(theme.colors.primary.main, 0.25)
                                                        }
                                                    }}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleViewClicked(row);
                                                    }}
                                                >
                                                    <ArticleTwoToneIcon />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title={t('kalulationDuplizieren')}>
                                            <div>
                                                <IconButton
                                                    color="inherit"
                                                    sx={{
                                                        bgcolor: theme.colors.alpha.black[10],
                                                        '&:hover': {
                                                            bgcolor: theme.colors.alpha.black[20]
                                                        }
                                                    }}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleDuplicateClicked(row);
                                                    }}
                                                >
                                                    <ContentCopyTwoTone />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title={t('kalulationLschen')}>
                                            <div>
                                                <IconButton
                                                    color="error"
                                                    sx={{
                                                        bgcolor: theme.colors.error.lighter,
                                                        '&:hover': {
                                                            bgcolor: alpha(theme.colors.error.main, 0.2)
                                                        }
                                                    }}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleDeleteClicked(row.id);
                                                    }}
                                                >
                                                    <DeleteTwoToneIcon />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </Stack>
                                </Stack>
                            </Card>
                        ))}
                    </GridBox>
                </InfiniteScroll>
            )}
        </Box>
    );
};

export default CalcOverviewGrid;
