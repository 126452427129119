import { Stack, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProvidedVariableResource } from 'src/backend/coreCalc';
import Statistic from './Statistic';

const StyledStack = styled(Stack)(
    ({ theme }) => `
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: ${theme.spacing(2)};
        gap: ${theme.spacing(2, 0.5)};
        white-space: nowrap;
        padding: ${theme.spacing(2)};

        ${theme.breakpoints.down('md')} {
            & > .MuiBox-root {
                margin: ${theme.spacing(0, 1)};
            }
            .MuiAvatar-root {
                width: ${theme.spacing(3)};
                height: ${theme.spacing(3)};
                margin-right: ${theme.spacing(0.5)};
            }
            .MuiSvgIcon-root {
                font-size: 1.2rem;
            }
            .MuiTypography-subtitle2 {
                font-size: 11px;
                line-height: 1;
            }
            .MuiTypography-h5,
            .MuiTypography-subtitle1 {
                font-size: 13px;
            }
        }
    `
);

interface Props {
    statisticVariables?: Array<ProvidedVariableResource>;
}

const Stats: React.FC<Props> = ({ statisticVariables }) => {
    const { t } = useTranslation();

    return (
        <StyledStack>
            {statisticVariables.map((statisticVariable) => (
                <Statistic key={statisticVariable.id} statisticVariable={statisticVariable} />
            ))}
        </StyledStack>
    );
};

export default Stats;
