/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BooleanValueResource } from './BooleanValueResource';
import type { ListValueResource } from './ListValueResource';
import type { NumberValueResource } from './NumberValueResource';
import type { StringValueResource } from './StringValueResource';

export type VariableResource = {
    id?: number;
    internalIdentifier?: string;
    displayName?: string;
    translationKey?: string;
    type?: VariableResource.type;
    value?: (BooleanValueResource | ListValueResource | NumberValueResource | StringValueResource);
}

export namespace VariableResource {

    export enum type {
        TERM_VARIABLE = 'TERM_VARIABLE',
        PROVIDED_VARIABLE = 'PROVIDED_VARIABLE',
        DECLARATION_VARIABLE = 'DECLARATION_VARIABLE',
        SURCHARGE_VARIABLE = 'SURCHARGE_VARIABLE',
        ROUND_VARIABLE = 'ROUND_VARIABLE',
        RULE_BASED_VARIABLE = 'RULE_BASED_VARIABLE',
        ON_THE_FLY_VALUE_VARIABLE = 'ON_THE_FLY_VALUE_VARIABLE',
        BOOLEAN_INPUT_VARIABLE = 'BOOLEAN_INPUT_VARIABLE',
        NUMBER_INPUT_VARIABLE = 'NUMBER_INPUT_VARIABLE',
        SINGLE_SELECT_INPUT_VARIABLE = 'SINGLE_SELECT_INPUT_VARIABLE',
        MULTI_SELECT_INPUT_VARIABLE = 'MULTI_SELECT_INPUT_VARIABLE',
        TEXT_INPUT_VARIABLE = 'TEXT_INPUT_VARIABLE',
    }


}
