import { Stack, useTheme, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DeclaredVariableResource, NumberValueResource, StringValueResource, ValueResource, VariableResource } from 'src/backend/coreCalc';
import { getVariableName, getVariableValue } from 'src/utils/CalcHelpers';

interface Props {
    variable: DeclaredVariableResource;
    setVariable: (variable: VariableResource) => void;
    setValid: (isValid: boolean) => void;
    isSmall?: boolean;
    isDisabled: boolean;
}

const DeclarationVariableForm: React.FC<Props> = ({ variable, setVariable, setValid, isSmall, isDisabled }) => {
    const dispatch = useDispatch();
    const theme = useTheme();

    useEffect(() => {
        if (!variable) return setValid(false);
        const isInvalid = getVariableName(variable) == null || getVariableName(variable).trim() === '' || variable.value == null || (variable.value as NumberValueResource)?.numberValue == null;
        setValid(!isInvalid);
    }, [variable]);

    const handleNameChange = (event) => {
        setVariable({
            ...variable,
            displayName: event.target.value
        });
    };

    const handleValueChange = (event) => {
        setVariable({
            ...variable,
            value: { type: ValueResource.type.NUMBER_VALUE, numberValue: parseInt(event.target.value) } as NumberValueResource
        });
    };

    return (
        variable && (
            <Stack gap={3}>
                <TextField label="Name" value={getVariableName(variable)} onChange={handleNameChange} variant="outlined" fullWidth size={isSmall ? 'small' : 'medium'} disabled={isDisabled} />
                <TextField label="Wert" value={getVariableValue(variable) || ''} onChange={handleValueChange} variant="outlined" fullWidth size={isSmall ? 'small' : 'medium'} disabled={isDisabled} />
            </Stack>
        )
    );
};

export default DeclarationVariableForm;
