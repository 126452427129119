import { Box, Button, Dialog, IconButton, Popover, styled, useTheme } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import Item from './Item';
import { ItemResource, SettingsResource } from 'src/backend/coreCalc';
import { ViewerDetails, ViewerTypes } from '../../ProCalc.types';

const StyledBox = styled(Box)(
    ({ theme }) => `
        .item-header {
            padding-right: 45px;
        }
    `
);

interface Props {
    item: ItemResource;
    settings: SettingsResource;
    viewerDetails: ViewerDetails;
    setCurrentViewer: (viewer: { item: ItemResource; dialogType: ViewerTypes }) => void;
}

const ItemShowButton: React.FC<Props> = ({ item, settings, viewerDetails, setCurrentViewer }) => {
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState<boolean>(false);
    const ref = useRef<any>(null);

    return (
        <>
            <Button size="small" variant="outlined" startIcon={<VisibilityTwoToneIcon />} onClick={() => setOpen(true)} ref={ref}>
                Ansehen
            </Button>
            <Dialog fullWidth maxWidth="xl" PaperProps={{ sx: { width: '100%' } }} onClose={() => setOpen(false)} open={isOpen}>
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', p: 0.6, right: 10, top: 10, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
                {isOpen && (
                    <StyledBox>
                        <Item item={item} settings={settings} viewerDetails={viewerDetails} setCurrentViewer={setCurrentViewer} />
                    </StyledBox>
                )}
            </Dialog>
        </>
    );
};

export default ItemShowButton;
