import { Button, Card, CircularProgress, Divider, LinearProgress, MenuItem, Select, Stack, Tooltip, Typography, styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RootState, snackContext, useDispatch } from 'src/redux/store';
import PageHeader from 'src/components/display/PageHeader';
import { useCallback, useEffect, useRef, useState } from 'react';
import DragAndDropFileInput from 'src/components/input/DragAndDropFileInput';
import { SSA3DViewer } from '@surface-solutions/ssa-3d-viewer';
import { connect } from 'react-redux';
import { FileResource } from 'src/backend/market';
import { ModelStats } from '@surface-solutions/ssa-3d-viewer/dist/context/ViewerContext';
import { addFileWithThumbnail, loadFiles, updateFileNameFilter, updatePaging, updateSorting } from 'src/redux/thunks/fileManager.thunk';
import { formatDate } from 'src/utils/FormatHelpers';
import { Link } from 'react-router-dom';
import { nav } from 'src/statics/navigations';
import SurfLoader from 'src/components/display/Loader/SurfLoader';
import useDialog from 'src/hooks/useDialog';
import { TextField } from '@mui/material';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { debounce } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ALLOWED_3D_FILE_TYPES } from 'src/statics/statics';
import { getFileExtension } from 'src/utils/CalcHelpers';

const Grid = styled('div')(
    ({ theme }) => `
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
            gap: ${theme.spacing(4)};
            & > * {
                min-height: 20rem;
            }
        `
);

const CardContent = styled(Stack)(
    ({ theme }) => `
            width: 20rem;
            height: 20rem;
            margin: auto;
            position: relative;
        `
);

const CardHeader = styled(Stack)(
    ({ theme }) => `
            padding: ${theme.spacing(1)};
            height: 2.5rem;
            margin-bottom: -2.5rem;
            z-index: 10;
            background: ${theme.colors.alpha.white[70]}
        `
);

const StyledImage = styled('img')(
    ({ theme }) => `
            height: 100%;
            width: 100%;
            object-fit: contain;
            margin: auto;
            pointer-events: none;
        `
);

interface Props {
    files?: FileResource[];
    fileNameFilter?: string;
    filesPaging: { page: number; pageSize: number; total: number; rowCount: number };
    filesSortBy: string;
}

const Viewer3dOverview: React.FC<Props> = ({ files, fileNameFilter, filesPaging, filesSortBy }) => {
    const [tmp3dFiles, setTmp3dFiles] = useState<File[]>([]);
    const [amountOfFiles, setAmountOfFiles] = useState<number>(0);
    const [is3dFileLoading, set3dFileLoading] = useState<boolean>(false);
    const [areFilesLoading, setFilesLoading] = useState<boolean>(false);
    const [isSkeletonLoader, setSkeletonLoading] = useState<boolean>(false);
    const [isSearchLoading, setSearchLoading] = useState<boolean>(false);
    const [searchString, setSearchString] = useState<string>(fileNameFilter || '');
    const dispatch = useDispatch();
    const dialog = useDialog();
    const { t } = useTranslation();
    const theme = useTheme();

    const setFileNameFilter = (fileName: string) => dispatch(updateFileNameFilter(fileName));
    const debouncedSetFileNameFilter = useCallback(debounce(setFileNameFilter, 500), []);

    useEffect(() => {
        dispatch(
            loadFiles(() => {
                setFilesLoading(false);
            })
        );
    }, []);

    useEffect(() => {
        debouncedSetFileNameFilter(searchString);
    }, [searchString]);

    // const handleFileUpload = (fileList: FileList) => {
    //     const newFiles = Array.from(fileList);
    //     if (!newFiles) return;

    //     const newFile = newFiles[0];
    //     const isDuplicate = files.some((file) => file.name === newFile.name);
    //     if (!isDuplicate) {
    //         setTmp3dFile(newFile);
    //         setSkeletonLoading(true);
    //     } else {
    //         dialog.openDialog({
    //             severity: 'warning',
    //             disableClickAway: true,
    //             component: <Typography>{t('fileWithSameNameWasUpload')}</Typography>,
    //             title: t('duplicateDetected'),
    //             okText: t('continue'),
    //             okCallback: () => {
    //                 dialog.closeDialog();
    //                 setTmp3dFile(newFile);
    //                 setSkeletonLoading(true);
    //             }
    //         });
    //     }
    // };

    const handleFileUpload = async (fileList: FileList) => {
        const files = Array.from(fileList);
        if (!files) return;

        const threeDFiles = [];

        setFilesLoading(true);

        for (const file of files) {
            const fileExtension = getFileExtension(file.name);

            if (ALLOWED_3D_FILE_TYPES.includes(fileExtension)) {
                threeDFiles.push(file);
            } else {
                snackContext.enqueueSnackbar(t('fileHasInvalidFileExtension'), { variant: 'error' });
            }
        }
        setFilesLoading(false);

        if (threeDFiles.length > 0) {
            set3dFileLoading(true);
            setTmp3dFiles(threeDFiles);
            setAmountOfFiles(threeDFiles.length);
            setSkeletonLoading(true);
        }
    };

    const handleOnViewerLoader = async (stats: ModelStats) => {
        const file = tmp3dFiles[0];
        if (!file) return;

        await dispatch(addFileWithThumbnail(file, file.name, stats.screenshot));

        const files = [...tmp3dFiles];
        files.shift();
        setTmp3dFiles(files);
        if (files.length === 0) {
            set3dFileLoading(false);
            setSkeletonLoading(false);
        }
    };

    const handleFileNameFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value);
    };

    const handleLoadNext = () => {
        dispatch(updatePaging({ pageSize: filesPaging.pageSize + 15 }));
    };

    const hasNoFiles = files.length === 0 && !isSkeletonLoader;

    return (
        <>
            <PageHeader secondaryTitle={t('3dViewerExplanation')} secondaryTitlePosition="below" sx={{ flexShrink: 0, '.title-stack': { mr: 0, flexShrink: 0 }, overflowX: 'auto' }}>
                <Divider orientation="vertical" flexItem sx={{ my: -4, mx: 4 }} />
                <Stack direction="row" sx={{ mx: 2, position: 'relative', alignItems: 'center', gap: 2 }}>
                    <TextField
                        value={searchString}
                        inputProps={{ sx: { py: 1.54 } }}
                        InputProps={{
                            startAdornment: <SearchTwoToneIcon sx={{ opacity: 0.6, mr: 1 }} />,
                            endAdornment: isSearchLoading && <CircularProgress size={25} sx={{ display: 'flex', ml: 1 }} />
                        }}
                        placeholder={t('searchFiles')}
                        sx={{ width: '20rem', mr: 'auto' }}
                        onChange={handleFileNameFilterChange}
                    />
                    <Select
                        value={filesSortBy}
                        fullWidth
                        onChange={(event) => dispatch(updateSorting(event.target.value))}
                        renderValue={(selected) => t('sortedBy') + ': ' + t(selected)}
                        sx={{ '.MuiSelect-select': { py: 1.54 }, width: 'auto' }}
                    >
                        {['idAsc', 'idDesc', 'createdAtAsc', 'createdAtDesc'].map((item) => (
                            <MenuItem value={item} key={item}>
                                {t(item)}
                            </MenuItem>
                        ))}
                    </Select>
                </Stack>
            </PageHeader>

            {areFilesLoading ? (
                <Stack alignItems="center" justifyContent="center" height="100%">
                    <SurfLoader />
                </Stack>
            ) : (
                <Stack mt={2} mx={{ md: -2 }} flexGrow={1}>
                    {hasNoFiles && !fileNameFilter ? (
                        <DragAndDropFileInput style={{ flexGrow: 1 }} accept=".step, .stp,3 .stl, .3mf" primaryText={t('dragAndDropYour3dFilesHere')} fileSelected={handleFileUpload} />
                    ) : hasNoFiles ? (
                        <Typography my={2} textAlign="center">
                            {t('noFilesFound')}
                        </Typography>
                    ) : (
                        <InfiniteScroll
                            next={handleLoadNext}
                            hasMore={files.length < filesPaging.total}
                            endMessage={
                                files.length > 20 && (
                                    <Typography my={2} textAlign="center">
                                        {t('noMoreFiles')}
                                    </Typography>
                                )
                            }
                            loader={<LinearProgress variant="indeterminate" sx={{ mt: 3 }} />}
                            dataLength={files.length}
                            style={{ padding: theme.spacing(2) }}
                        >
                            <Grid>
                                <DragAndDropFileInput
                                    style={{ flexGrow: 1 }}
                                    accept=".step, .stp,3 .stl, .3mf"
                                    primaryText={t('dragAndDropYour3dFilesHere')}
                                    fileSelected={handleFileUpload}
                                    disabled={isSkeletonLoader}
                                />
                                {isSkeletonLoader && (
                                    <Card sx={{ textDecoration: 'none' }}>
                                        <CardContent>
                                            <CardHeader direction="row" justifyContent="space-between">
                                                <Typography variant="h4" lineHeight="auto">
                                                    {tmp3dFiles[0]?.name}
                                                </Typography>
                                            </CardHeader>
                                            <CircularProgress size={45} sx={{ margin: 'auto' }} />
                                        </CardContent>
                                    </Card>
                                )}
                                {files.map((file) => {
                                    const fileName = file.name.split('.').slice(0, -1).join('.');
                                    const fileExtension = file.name.split('.').pop();

                                    return (
                                        <Tooltip title={file.name} key={file.nodeId} placement="top">
                                            <Card
                                                component={Link}
                                                to={nav.VIEWER_3D.sub.VIEW.path.replace(':id', file.nodeId)}
                                                sx={{
                                                    textDecoration: 'none',
                                                    userSelect: 'none',
                                                    '&:hover': { boxShadow: `0 0 0 2px ${theme.colors.info.main}, 0 0 12px 1px ${theme.colors.info.main}` },
                                                    '&:active': { boxShadow: `0 0 0 2px ${theme.colors.info.main}, 0 0 18px 3px ${theme.colors.info.main}` }
                                                }}
                                            >
                                                <CardContent>
                                                    <CardHeader direction="row" justifyContent="space-between">
                                                        <Typography variant="h4" lineHeight="1" noWrap>
                                                            {fileName}
                                                        </Typography>
                                                        <Typography variant="subtitle2" lineHeight="1" whiteSpace="nowrap">
                                                            {formatDate(file.createdAt.slice(0, -5))}
                                                        </Typography>
                                                    </CardHeader>
                                                    <StyledImage src={file.thumbnail} alt={t('imageOfItem')} />
                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            position: 'absolute',
                                                            right: theme.spacing(1),
                                                            bottom: theme.spacing(1),
                                                            bgcolor: theme.colors.info.dark,
                                                            color: theme.colors.alpha.white[100],
                                                            py: 0.1,
                                                            px: 0.4,
                                                            borderRadius: 1
                                                        }}
                                                    >
                                                        .{fileExtension}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Tooltip>
                                    );
                                })}
                            </Grid>
                        </InfiniteScroll>
                    )}
                </Stack>
            )}

            {tmp3dFiles[0] && (
                <div style={{ position: 'absolute', opacity: 0 }}>
                    <SSA3DViewer file={tmp3dFiles[0]} onViewerLoaded={handleOnViewerLoader} />
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        files: state.fileManager.files,
        fileNameFilter: state.fileManager.fileNameFilter,
        filesPaging: state.fileManager.filesPaging,
        filesSortBy: state.fileManager.filesSortBy
    };
};
export default connect(mapStateToProps)(Viewer3dOverview);
