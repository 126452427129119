import { Dialog, DialogTitle, Stack, Typography, CircularProgress, IconButton, DialogContent, useTheme, Box } from '@mui/material';
import { SSA3DViewer } from '@surface-solutions/ssa-3d-viewer';
import { renderCalculatedValue } from 'src/components/display/CalculatedValues';
import SurfLoader from 'src/components/display/Loader/SurfLoader';
import i18n from 'src/i18n/i18n';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import LooksTwoTwoToneIcon from '@mui/icons-material/LooksTwoTwoTone';
import Looks3TwoToneIcon from '@mui/icons-material/Looks3TwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import { ModelStats, NumberWithUnit } from '@surface-solutions/ssa-3d-viewer/dist/context/ViewerContext';
import { useState } from 'react';
import { FileResource } from 'src/backend/market';
import { useDispatch } from 'react-redux';
import { formatValue } from 'src/utils/FormatHelpers';
import { useTranslation } from 'react-i18next';
import Statistic, { AvatarLabelInfo } from '../Stats/Statistic';
import { NumberValueResource, ProvidedVariableResource, ValueResource } from 'src/backend/coreCalc';

interface Props {
    fileResource: FileResource;
    isOpen: boolean;
    setOpen: (open: boolean) => void;
    children?: any;
}

const ThreeDViewerDialog: React.FC<Props> = ({ fileResource, isOpen, setOpen, children }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();
    const [modelStats, setModelStats] = useState<ModelStats>();

    const getValue = (numberWithUnit: NumberWithUnit): NumberValueResource => {
        return { type: ValueResource.type.NUMBER_VALUE, numberValue: numberWithUnit?.value };
    };
    const getUnit = (numberWithUnit: NumberWithUnit) => {
        switch (numberWithUnit.unit) {
            case 'm²':
                return ProvidedVariableResource.unit.SQUARE_METER;
            case 'm³':
                return ProvidedVariableResource.unit.CUBIC_METER;
        }
    };

    const convertToStatisticVariable = (displayName: string, numberWithUnit: NumberWithUnit) => {
        return {
            displayName,
            unit: getUnit(numberWithUnit),
            value: getValue(numberWithUnit)
        };
    };

    const getDimensionValue = () => {
        if (!modelStats?.boundingBox) return;
        return (
            `${formatValue(modelStats.boundingBox.length.value)} × ` +
            `${formatValue(modelStats.boundingBox.width.value)} × ` +
            `${formatValue(modelStats.boundingBox.height.value)} ${modelStats.boundingBox.length.unit}`
        );
    };

    const closeDialog = () => {
        setOpen(false);
        setModelStats(null);
    };

    return (
        <Dialog onClose={closeDialog} open={isOpen} maxWidth="xl" PaperProps={{ sx: { width: '100%', height: '100%' } }}>
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', pr: 1.2, height: 56 }}>
                <Stack direction="row" alignItems="center" flexGrow={1}>
                    <Typography variant="h4">{t('3dViewer')}</Typography>
                    <Stack direction="row" justifyContent="center" spacing={2} flexGrow={1}>
                        <Stack direction="row" spacing={2} p={0.6} alignItems="center" borderRadius={1} border="1px solid" borderColor={theme.colors.alpha.black[10]}>
                            <Typography variant="caption">{t('object')}: </Typography>
                            {modelStats ? (
                                <Stack direction="row" spacing={2}>
                                    <Statistic statisticVariable={convertToStatisticVariable(t('surfaceArea'), modelStats.surfaceArea)} />
                                    <Statistic statisticVariable={convertToStatisticVariable(t('volume'), modelStats.volume)} />
                                </Stack>
                            ) : (
                                <CircularProgress size={20} />
                            )}
                        </Stack>
                        <Stack direction="row" spacing={2} p={0.6} alignItems="center" borderRadius={1} border="1px solid" borderColor={theme.colors.alpha.black[10]}>
                            <Typography variant="caption">{t('boundingBox')}: </Typography>
                            {modelStats ? (
                                <Stack direction="row" spacing={2}>
                                    <Statistic statisticVariable={convertToStatisticVariable(t('surfaceArea'), modelStats.boundingBox.surfaceArea)} />

                                    <Box display="flex" alignItems="center" maxWidth="100%" overflow="hidden" className="calculated-value">
                                        <AvatarLabelInfo sx={{ mr: 1 }} variant="rounded">
                                            <AssessmentTwoToneIcon />
                                        </AvatarLabelInfo>
                                        <Box maxWidth="100%" overflow="hidden">
                                            <Typography variant="subtitle2" noWrap fontSize="0.929em" lineHeight={1.2}>
                                                {t('lengthWidthHeight')}
                                            </Typography>
                                            <Typography variant="h5" lineHeight={1.23} title={getDimensionValue()} noWrap>
                                                {getDimensionValue()}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Stack>
                            ) : (
                                <CircularProgress size={20} />
                            )}
                        </Stack>
                        <Stack direction="row" spacing={2} p={0.6} alignItems="center" borderRadius={1} border="1px solid" borderColor={theme.colors.alpha.black[10]}>
                            <Typography variant="caption">{t('convexHull')}: </Typography>
                            {modelStats ? (
                                <Stack direction="row" spacing={2}>
                                    <Statistic statisticVariable={convertToStatisticVariable(t('surfaceArea'), modelStats.convexHull.surfaceArea)} />
                                </Stack>
                            ) : (
                                <CircularProgress size={20} />
                            )}
                        </Stack>
                    </Stack>
                    <IconButton aria-label="close" onClick={closeDialog} sx={{ mt: -0.28, color: (theme) => theme.palette.grey[500] }}>
                        <CloseTwoToneIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ p: 0, position: 'relative', display: 'flex' }}>
                {fileResource?.url ? (
                    <SSA3DViewer url={fileResource.url} fileName={fileResource.name} language={i18n.language} onViewerLoaded={(stats) => setModelStats(stats)} />
                ) : (
                    <Stack alignItems="center" justifyContent="center" height="100%" flexGrow={1}>
                        <SurfLoader />
                    </Stack>
                )}
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default ThreeDViewerDialog;
