import { Button, InputAdornment, InputBase, Popover, Stack, styled, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import { isNaN } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LinearProgressWithLabel } from 'src/components/display/LinearProgressWithLabel';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { useFormikContext } from 'formik';
import store from 'src/redux/store';
import { wT } from 'src/utils/wizardTranslations';
import { DateTime } from 'luxon';
import { MasterVariableIdentifier, NumberValueResource, StringValueResource, ValueResource, VariableResource } from 'src/backend/coreCalc';
import { getInputValue, getValue, searchForSpecialInputVariable } from 'src/utils/CalcHelpers';

interface Props {
    variables?: Array<VariableResource>;
    saveVariableValue?: (variableId: number, value: ValueResource) => void;
}

const MasterDeliveryAndPickup: React.FC<Props> = ({ variables, saveVariableValue }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const bringRef = useRef<any>(null);
    const wishRef = useRef<any>(null);
    const [isBringOpen, setBringOpen] = useState<boolean>(false);
    const [isWishOpen, setWishOpen] = useState<boolean>(false);

    const bringDate = useMemo(() => searchForSpecialInputVariable(variables, MasterVariableIdentifier.INCOMING_DATE), [variables]);
    const wishDate = useMemo(() => searchForSpecialInputVariable(variables, MasterVariableIdentifier.OUTGOING_DATE), [variables]);

    const bringDateValue = getInputValue(bringDate);
    const wishDateValue = getInputValue(wishDate);
    const bringDateDays = bringDateValue ? parseInt(bringDateValue as string) : null;
    const wishDateDays = wishDateValue ? parseInt(wishDateValue as string) : null;

    const onChangeDate = (newDate: DateTime, variableId: number) => {
        const daysFromNow = Math.ceil(newDate.diffNow('days').days);
        const value = isNaN(daysFromNow) ? 0 : daysFromNow;
        saveVariableValue(variableId, { type: ValueResource.type.NUMBER_VALUE, numberValue: value } as NumberValueResource);
        if (variableId === bringDate.id) setBringOpen(false);
        else if (variableId === wishDate.id) setWishOpen(false);
    };

    const getDateFromDays = (days: number) => {
        return DateTime.now().plus({ days });
    };

    const throughputDays = wishDateDays - bringDateDays;

    const hoursSinceBringDate = useMemo(() => {
        if (!bringDateDays) return 0;
        return getDateFromDays(bringDateDays).diffNow().as('hours');
    }, [bringDate]);

    const hoursBeforePickupDate = useMemo(() => {
        if (!wishDateDays) return 0;
        return getDateFromDays(wishDateDays).diffNow().as('hours');
    }, [wishDate]);

    const sumHours = Math.abs(hoursSinceBringDate) + Math.abs(hoursBeforePickupDate);
    const deliveryPercentage = hoursSinceBringDate > 0 ? 0 : hoursBeforePickupDate <= 0 ? 100 : (100 * Math.abs(hoursSinceBringDate)) / sumHours;

    return (
        bringDate &&
        wishDate && (
            <Stack flexGrow={1}>
                <Stack direction="row" gap={2} justifyContent="space-between" mb={0.2}>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <Button ref={bringRef} color="inherit" sx={{ flexDirection: 'column', p: 0.4, m: -0.4, textAlign: 'left', alignItems: 'flex-start' }} onClick={() => setBringOpen(true)}>
                            <Typography fontSize="0.929em" lineHeight={1.2} noWrap color="text.secondary">
                                {t('bereitstellung')}
                            </Typography>
                            <Typography fontSize="1em" lineHeight={1.4} noWrap fontWeight="bold" display="flex" alignItems="center">
                                {getDateFromDays(bringDateDays).toLocaleString(DateTime.DATE_SHORT)}
                                <EditTwoToneIcon sx={{ color: theme.colors.primary.main, fontSize: 15, ml: 0.4 }} />
                            </Typography>
                        </Button>
                        <Popover
                            anchorEl={bringRef.current}
                            onClose={() => setBringOpen(false)}
                            open={isBringOpen}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        >
                            <StaticDatePicker
                                onChange={(newDate) => onChangeDate(newDate, bringDate.id)}
                                maxDate={getDateFromDays(wishDateDays)}
                                slots={{ actionBar: () => <></>, toolbar: () => <></> }}
                            />
                        </Popover>

                        <Stack justifyContent="flex-end" alignItems="center">
                            <Typography fontSize="1em" lineHeight={1.4} noWrap color="text.secondary">
                                ⇠ {throughputDays || ''} {t(throughputDays === 0 ? 'sameDay' : throughputDays > 1 ? 'days' : 'day')} ⇢
                            </Typography>
                        </Stack>

                        <Button ref={wishRef} color="inherit" sx={{ flexDirection: 'column', p: 0.4, m: -0.4, textAlign: 'right', alignItems: 'flex-end' }} onClick={() => setWishOpen(true)}>
                            <Typography fontSize="0.929em" lineHeight={1.2} noWrap color="text.secondary">
                                {t('abholung')}
                            </Typography>
                            <Typography fontSize="1em" lineHeight={1.4} noWrap fontWeight="bold" display="flex" alignItems="center">
                                {getDateFromDays(wishDateDays).toLocaleString(DateTime.DATE_SHORT)}
                                <EditTwoToneIcon sx={{ color: theme.colors.primary.main, fontSize: 15, ml: 0.4 }} />
                            </Typography>
                        </Button>
                        <Popover
                            anchorEl={wishRef.current}
                            onClose={() => setWishOpen(false)}
                            open={isWishOpen}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        >
                            <StaticDatePicker
                                onChange={(newDate) => onChangeDate(newDate, wishDate.id)}
                                minDate={getDateFromDays(bringDateDays)}
                                slots={{ actionBar: () => <></>, toolbar: () => <></> }}
                            />
                        </Popover>
                    </LocalizationProvider>
                </Stack>
                <LinearProgressWithLabel value={deliveryPercentage} className="delivery-percentage" />
            </Stack>
        )
    );
};

export default MasterDeliveryAndPickup;
