import {
    CalculationFragmentResource,
    CalculationParameterDto,
    CalculationResultResource,
    InternalCalcPriceManipulationParameterValueResource,
    SurchargeOverrideStorageResource
} from 'src/backend/internalCalc';
import { cloneDeep, debounce, DebouncedFunc } from 'lodash';
import {
    createItem,
    createItemAttachment,
    createItemWithFile,
    createMasterAttachment,
    duplicateItem,
    removeItem,
    removeItemAttachment,
    removeMasterAttachment,
    updateCustomer,
    updateItem,
    updateItemSurchargeVariableMode,
    updateItemVariableValue,
    updateMasterSurchargeVariableMode,
    updateMasterVariableValue,
    updateName
} from '../thunks/proCalc.thunk';
import { AppDispatch, AppThunk, RootState } from '../store';
import {
    ProCalcSavingStatus,
    ProCalcUpdateLoading,
    addItemAttachment,
    addMasterAttachment,
    addTmpItem,
    deleteItem,
    deleteItemAttachment,
    deleteMasterAttachment,
    handleUpdateFailure,
    handleUpdateSuccess,
    saveCalculationRecord,
    setItem,
    setItemSurchargeVariableMode,
    setItemVariableValue,
    setMasterSurchargeVariableMode,
    setMasterVariableValue,
    setProCalcValue,
    setSavingStatus,
    setUpdateLoading
} from '../slices/proCalc.reducer';
import { Selector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAllMessages, getGeneralSetting } from 'src/utils/CalcHelpers';
import { formatPrice } from 'src/utils/FormatHelpers';
import { CURRENCY_SETTING_NAME, NUMBER_FORMAT_SETTING_NAME } from 'src/statics/statics';
import { AttachmentResource, CalculationResource, CustomerResource, ItemResource, NewItemDTO, SurchargeVariableResource, ValueResource } from 'src/backend/coreCalc';
import { NewItemWithFile } from 'src/components/pro-calc/ProCalc.types';

export enum ProCalcUpdateTypes {
    NAME = 'name',
    CUSTOMER = 'customer',
    MASTER_VARIABLE_VALUE = 'masterVariableValue',
    MASTER_SURCHARGE_VARIABLE_MODE = 'masterSurchargeVariableMode',
    CREATE_MASTER_ATTACHMENT = 'createMasterAttachment',
    DELETE_MASTER_ATTACHMENT = 'deleteMasterAttachment',
    ITEM = 'item',
    CREATE_ITEM = 'createItem',
    CREATE_ITEM_WITH_FILE = 'createItemWithFile',
    DUPLICATE_ITEM = 'duplicateItem',
    DELETE_ITEM = 'deleteItem',
    ITEM_VARIABLE_VALUE = 'itemVariableValue',
    ITEM_SURCHARGE_VARIABLE_MODE = 'itemSurchargeVariableMode',
    CREATE_ITEM_ATTACHMENT = 'createItemAttachment',
    DELETE_ITEM_ATTACHMENT = 'deleteItemAttachment',

    NOTE = 'note',
    PRICE_MANIPULATION_PARAMETER = 'priceManipulationParameter',
    SURCHARGE_OVERRIDES = 'surchargeOverrides',
    // CREATE_PART = 'createPart',
    // CREATE_3D_PART = 'create3dPart',
    PART_NAME = 'partName',
    FORCE_PART_PRICE = 'forcePartPrice'
}

type ProCalcUpdateValueMap = {
    [ProCalcUpdateTypes.NAME]: string;
    [ProCalcUpdateTypes.CUSTOMER]: CustomerResource;
    [ProCalcUpdateTypes.MASTER_VARIABLE_VALUE]: { variableId: number; value: ValueResource };
    [ProCalcUpdateTypes.MASTER_SURCHARGE_VARIABLE_MODE]: { variableId: number; mode: SurchargeVariableResource.mode };
    [ProCalcUpdateTypes.CREATE_MASTER_ATTACHMENT]: AttachmentResource;
    [ProCalcUpdateTypes.DELETE_MASTER_ATTACHMENT]: number;
    [ProCalcUpdateTypes.ITEM]: ItemResource;
    [ProCalcUpdateTypes.CREATE_ITEM]: NewItemDTO;
    [ProCalcUpdateTypes.CREATE_ITEM_WITH_FILE]: NewItemWithFile;
    [ProCalcUpdateTypes.DUPLICATE_ITEM]: number;
    [ProCalcUpdateTypes.DELETE_ITEM]: number;
    [ProCalcUpdateTypes.ITEM_VARIABLE_VALUE]: { itemId: number; variableId: number; value: ValueResource };
    [ProCalcUpdateTypes.ITEM_SURCHARGE_VARIABLE_MODE]: { itemId: number; variableId: number; mode: SurchargeVariableResource.mode };
    [ProCalcUpdateTypes.CREATE_ITEM_ATTACHMENT]: { itemId: number; attachment: AttachmentResource };
    [ProCalcUpdateTypes.DELETE_ITEM_ATTACHMENT]: { itemId: number; attachmentId: number };

    [ProCalcUpdateTypes.NOTE]: string | null;
    [ProCalcUpdateTypes.PRICE_MANIPULATION_PARAMETER]: { index: number; paramValue: InternalCalcPriceManipulationParameterValueResource };
    [ProCalcUpdateTypes.SURCHARGE_OVERRIDES]: Array<SurchargeOverrideStorageResource>;
    // [ProCalcUpdateTypes.CREATE_PART]: { itemId: number; fileResource?: FileResource; additionalStorage?: Array<AdditionalStorageDto>; userDefinedPartName?: string };
    // [ProCalcUpdateTypes.CREATE_3D_PART]: { itemId: number; file: File | FileResource; stats: ModelStats };
    [ProCalcUpdateTypes.PART_NAME]: { partId: number; name: string };
    [ProCalcUpdateTypes.FORCE_PART_PRICE]: { partId: number; force: boolean; price: number };
};

interface ProCalcUpdateInfo<T extends ProCalcUpdateTypes> {
    optimisticUpdateFunction: (value: ProCalcUpdateValueMap[T]) => void;
    updateFunction: (calc: CalculationResource, value: ProCalcUpdateValueMap[T]) => void;
    debounce?: boolean;
}
export const ProCalcUpdateInfos: Record<ProCalcUpdateTypes, ProCalcUpdateInfo<ProCalcUpdateTypes>> = {
    [ProCalcUpdateTypes.NAME]: {
        optimisticUpdateFunction: (value) => setProCalcValue({ path: 'name', value }),
        updateFunction: updateName
    },
    [ProCalcUpdateTypes.CUSTOMER]: {
        optimisticUpdateFunction: (value) => setProCalcValue({ path: 'customer', value }),
        updateFunction: updateCustomer,
        debounce: true
    },
    [ProCalcUpdateTypes.MASTER_VARIABLE_VALUE]: {
        optimisticUpdateFunction: setMasterVariableValue,
        updateFunction: updateMasterVariableValue
    },
    [ProCalcUpdateTypes.MASTER_SURCHARGE_VARIABLE_MODE]: {
        optimisticUpdateFunction: setMasterSurchargeVariableMode,
        updateFunction: updateMasterSurchargeVariableMode
    },
    [ProCalcUpdateTypes.CREATE_MASTER_ATTACHMENT]: {
        optimisticUpdateFunction: addMasterAttachment,
        updateFunction: createMasterAttachment
    },
    [ProCalcUpdateTypes.DELETE_MASTER_ATTACHMENT]: {
        optimisticUpdateFunction: deleteMasterAttachment,
        updateFunction: removeMasterAttachment
    },
    [ProCalcUpdateTypes.ITEM]: {
        optimisticUpdateFunction: setItem,
        updateFunction: updateItem
    },
    [ProCalcUpdateTypes.CREATE_ITEM]: {
        optimisticUpdateFunction: addTmpItem,
        updateFunction: createItem
    },
    [ProCalcUpdateTypes.CREATE_ITEM_WITH_FILE]: {
        optimisticUpdateFunction: addTmpItem,
        updateFunction: createItemWithFile
    },
    [ProCalcUpdateTypes.DUPLICATE_ITEM]: {
        optimisticUpdateFunction: addTmpItem,
        updateFunction: duplicateItem
    },
    [ProCalcUpdateTypes.DELETE_ITEM]: {
        optimisticUpdateFunction: deleteItem,
        updateFunction: removeItem
    },
    [ProCalcUpdateTypes.ITEM_VARIABLE_VALUE]: {
        optimisticUpdateFunction: setItemVariableValue,
        updateFunction: updateItemVariableValue
    },
    [ProCalcUpdateTypes.ITEM_SURCHARGE_VARIABLE_MODE]: {
        optimisticUpdateFunction: setItemSurchargeVariableMode,
        updateFunction: updateItemSurchargeVariableMode
    },
    [ProCalcUpdateTypes.CREATE_ITEM_ATTACHMENT]: {
        optimisticUpdateFunction: addItemAttachment,
        updateFunction: createItemAttachment
    },
    [ProCalcUpdateTypes.DELETE_ITEM_ATTACHMENT]: {
        optimisticUpdateFunction: deleteItemAttachment,
        updateFunction: removeItemAttachment
    },

    [ProCalcUpdateTypes.NOTE]: {
        optimisticUpdateFunction: (value) => setProCalcValue({ path: 'note', value }),
        updateFunction: () => {}
    },
    [ProCalcUpdateTypes.PRICE_MANIPULATION_PARAMETER]: {
        optimisticUpdateFunction: (value: any) => setProCalcValue({ path: `priceManipulationParameterValues[${value.index}]`, value: value.paramValue }),
        updateFunction: () => {}
    },
    [ProCalcUpdateTypes.SURCHARGE_OVERRIDES]: {
        optimisticUpdateFunction: () => {},
        updateFunction: () => {}
    },
    [ProCalcUpdateTypes.PART_NAME]: {
        optimisticUpdateFunction: () => {},
        updateFunction: () => {}
    },
    [ProCalcUpdateTypes.FORCE_PART_PRICE]: {
        optimisticUpdateFunction: () => {},
        updateFunction: () => {}
    }
};

/*
####################
##  UPDATE LOGIC  ##
####################
*/
type QueueElement = {
    updateType: ProCalcUpdateTypes;
    value: any;
};
type DebouncedUpdate = {
    [updateType in ProCalcUpdateTypes]?: { debounceFunction: DebouncedFunc<() => void>; value: any };
};

let updatesQueue: Array<QueueElement> = [];
let debouncedUpdates: DebouncedUpdate = {};
let isProcessingQueue = false;

const debounceUpdate = (updateType: ProCalcUpdateTypes, value: any, dispatch: AppDispatch, getState: () => RootState, delay: number = 500) => {
    // If there's no debounced function for this updateType, create one
    if (!debouncedUpdates[updateType]) {
        debouncedUpdates[updateType] = createDebounceUpdate(updateType, value, dispatch, getState, delay);
    }
    // Update the value for this updateType and invoke the debounced function
    debouncedUpdates[updateType].value = value;
    debouncedUpdates[updateType].debounceFunction();
};

const createDebounceUpdate = (updateType: ProCalcUpdateTypes, value: any, dispatch: AppDispatch, getState: () => RootState, delay: number) => {
    const debounceFunction = debounce(async () => {
        if (!debouncedUpdates[updateType]) return;
        // Value can not be used, debouncedUpdates[updateType].value assures that we always get the most recent value
        await enqueueAndProcessUpdate(updateType, debouncedUpdates[updateType].value, dispatch, getState);
        delete debouncedUpdates[updateType];
    }, delay);
    return { debounceFunction, value };
};

const enqueueAndProcessUpdate = async (updateType: ProCalcUpdateTypes, value: any, dispatch: AppDispatch, getState: () => RootState) => {
    // Add the update to the queue
    updatesQueue.push({ updateType, value });
    if (!isProcessingQueue) {
        isProcessingQueue = true;
        await processQueue(dispatch, getState);
        isProcessingQueue = false;
    }
};

const processQueue = async (dispatch: AppDispatch, getState: () => RootState) => {
    // Process each item in the queue sequentially
    while (updatesQueue.length > 0) {
        const { updateType, value } = updatesQueue.shift()!;
        await processUpdate(dispatch, getState, updateType, value);
    }
};

const processUpdate = async (dispatch: AppDispatch, getState: () => RootState, updateType: ProCalcUpdateTypes, value: any) => {
    try {
        const calc = cloneDeep(getState().proCalc.calculation);
        console.log(updateType, calc);

        const updateFunction = ProCalcUpdateInfos[updateType].updateFunction;
        await dispatch(updateFunction(calc, value));
        dispatch(handleUpdateSuccess(updateType));
    } catch (error) {
        dispatch(handleUpdateFailure({ updateType, error }));
    }
};

const optimisticUpdate = <T extends ProCalcUpdateTypes>(dispatch: AppDispatch, updateType: T, value: ProCalcUpdateValueMap[T]) => {
    dispatch(saveCalculationRecord());
    const optimisticUpdateFunction = ProCalcUpdateInfos[updateType].optimisticUpdateFunction;
    dispatch(optimisticUpdateFunction(value));
};

export const updateProCalc = <T extends ProCalcUpdateTypes>(updateType: T, value: ProCalcUpdateValueMap[T]): AppThunk => {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(setSavingStatus(ProCalcSavingStatus.LOADING));
        dispatch(setUpdateLoading({ updateType, isLoading: true }));

        // Perform the optimistic update for immediate UI feedback
        optimisticUpdate(dispatch, updateType, value);

        if (ProCalcUpdateInfos[updateType]?.debounce === true) {
            // Debounce the update to prevent rapid successive updates of the same type
            debounceUpdate(updateType, value, dispatch, getState);
        } else {
            enqueueAndProcessUpdate(updateType, value, dispatch, getState); // don't drop an update
        }
    };
};

export const cancelProCalcUpdates = (): AppThunk => {
    return () => {
        // Clear all debounced updates
        Object.keys(debouncedUpdates).forEach((updateType: ProCalcUpdateTypes) => {
            if (debouncedUpdates[updateType] && debouncedUpdates[updateType].debounceFunction.cancel) {
                debouncedUpdates[updateType].debounceFunction.cancel();
            }
        });
        debouncedUpdates = {}; // Reset the debouncedUpdates object

        // Clear the updates queue
        updatesQueue = [];

        // Reset the queue processing flag
        isProcessingQueue = false;
    };
};

/*
#################
##  SELECTORS  ##
#################
*/

const selectCostResult: Selector<RootState, CalculationResultResource> = (state) => state.proCalc.calculation?.costResult || {};

export const selectProCalcSettings = createSelector([selectCostResult], (costResult) => ({
    numberFormat: getGeneralSetting(NUMBER_FORMAT_SETTING_NAME, costResult),
    currency: getGeneralSetting(CURRENCY_SETTING_NAME, costResult)
}));

export const selectProCalcPriceDetails = createSelector([selectCostResult, selectProCalcSettings], (costResult, settings) => ({
    price: costResult.price,
    priceMin: costResult.priceMin,
    priceMax: costResult.priceMax,
    priceNonRounded: costResult.priceNonRounded,
    priceMinNonRounded: costResult.priceMinNonRounded,
    priceMaxNonRounded: costResult.priceMaxNonRounded,
    unspoiledPrice: costResult.unspoiledPrice,
    unspoiledPriceMin: costResult.unspoiledPriceMin,
    unspoiledPriceMax: costResult.unspoiledPriceMax,
    formattedPriceNonRounded: formatPrice(costResult.priceNonRounded, false, settings.numberFormat, settings.currency)
}));

export const selectProCalcMessages = createSelector([selectCostResult], (costResult) => {
    const allMessages = getAllMessages(costResult);
    return {
        all: allMessages,
        hints: allMessages.filter((message) => message.messageType === 'HINT'),
        warnings: allMessages.filter((message) => message.messageType === 'WARNING'),
        errors: allMessages.filter((message) => message.messageType === 'ERROR' || (message.messageType.startsWith('INVALID') && message.messageType !== 'INVALID_PRICE_ERROR'))
    };
});

const selectIsUpdateLoading: Selector<RootState, ProCalcUpdateLoading> = (state) => state.proCalc.isUpdateLoading || {};
export const selectProCalcPriceLoading = createSelector([selectIsUpdateLoading], (isUpdateLoading) => {
    if (
        isUpdateLoading[ProCalcUpdateTypes.CUSTOMER] ||
        isUpdateLoading[ProCalcUpdateTypes.PRICE_MANIPULATION_PARAMETER] ||
        isUpdateLoading[ProCalcUpdateTypes.MASTER_VARIABLE_VALUE] ||
        isUpdateLoading[ProCalcUpdateTypes.MASTER_SURCHARGE_VARIABLE_MODE] ||
        isUpdateLoading[ProCalcUpdateTypes.ITEM] ||
        isUpdateLoading[ProCalcUpdateTypes.CREATE_ITEM] ||
        isUpdateLoading[ProCalcUpdateTypes.DELETE_ITEM] ||
        isUpdateLoading[ProCalcUpdateTypes.DUPLICATE_ITEM] ||
        isUpdateLoading[ProCalcUpdateTypes.ITEM_VARIABLE_VALUE] ||
        isUpdateLoading[ProCalcUpdateTypes.FORCE_PART_PRICE] // todo
    )
        return true;
    return false;
});
