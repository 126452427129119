import { FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import store from 'src/redux/store';
import { CriterionResource, MessageResource, VariableResource } from 'src/backend/coreCalc';
import CriteriaInput from 'src/components/input/CriteriaInput/CriteriaInput';
import { isCriteriaValid } from 'src/utils/CalcHelpers';
import { useTranslation } from 'react-i18next';

interface Props {
    message: MessageResource;
    variables: Array<VariableResource>;
    setMessage: (message: MessageResource) => void;
    setValid: (isValid: boolean) => void;
}

const CategoryMessageForm: React.FC<Props> = ({ message, variables, setValid, setMessage }) => {
    const s = store.getState();
    const theme = useTheme();
    const { t } = useTranslation();

    useEffect(() => {
        if (!message) return setValid(false);
        const isInvalid =
            message?.title == null || message.title.trim() === '' || message?.message == null || message.message.trim() === '' || message?.type == null || !isCriteriaValid(message?.criteria);
        setValid(!isInvalid);
    }, [message]);

    const handleTitleChange = (event) => {
        setMessage({
            ...message,
            title: event.target.value
        });
    };
    const handleMessageChange = (event) => {
        setMessage({
            ...message,
            message: event.target.value
        });
    };
    const handleMessageTypeChange = (event) => {
        setMessage({
            ...message,
            type: event.target.value
        });
    };
    const handleCriteriaChange = (criteria: Array<CriterionResource>) => {
        setMessage({
            ...message,
            criteria
        });
    };

    return (
        <>
            <TextField label="Titel" value={message?.title || ''} variant="outlined" onChange={handleTitleChange} />
            <TextField label="Beschreibung" value={message?.message || ''} variant="outlined" onChange={handleMessageChange} />
            <FormControl fullWidth>
                <InputLabel id="message-type">Benachrichtigsart</InputLabel>
                <Select labelId="message-type" label="Benachrichtigsart" value={message?.type || ''} onChange={handleMessageTypeChange}>
                    {Object.values(MessageResource.type).map((type) => (
                        <MenuItem key={type} value={type}>
                            {t(type)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Stack>
                <Typography variant="h4" sx={{ fontSize: 17, mt: 3, mb: 0.5, opacity: 0.7 }} gutterBottom>
                    Wann soll die Nachricht angezeigt werden?
                </Typography>
                <Typography sx={{ fontSize: 14, lineHeight: 1.3, mb: 2 }} variant="subtitle1">
                    Wenn die Nachricht immer zu sehen sein soll, brauchst du kein Kriterium hinzufügen. Beim Hinzufügen von mehr als einem Kriterium müssen alle Kriterien erfüllt sein, damit die
                    Meldung angezeigt wird.
                </Typography>
                <CriteriaInput criteria={message?.criteria || []} variables={variables} setCriteria={handleCriteriaChange} />
            </Stack>
        </>
    );
};

export default CategoryMessageForm;
