import { Button, Divider, Stack, styled, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CriterionResource, RuleResource, ValueResource, VariableResource } from 'src/backend/coreCalc';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import RuleInput from './RuleInput';
import { cloneDeep } from 'lodash';
import { createValueHolder } from 'src/utils/CalcHelpers';

const StyledStack = styled(Stack)(
    ({ theme }) => `
        height: 100%;
        margin-left: -24px;
        margin-right: -24px;
        .accordion-button {
            padding-left: 24px;
            padding-right: 24px;
        }
    `
);

interface Props {
    rules: Array<RuleResource>;
    variables?: Array<VariableResource>;
    isDisabled?: boolean;
    disableVariableValues?: boolean;
    setRules: (rules: Array<RuleResource>) => void;
}

const RuleInputs: React.FC<Props> = ({ rules, variables, isDisabled, disableVariableValues, setRules }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();

    const [openAccordionId, setOpenAccordionId] = useState<string>();

    const addRule = () => {
        setRules([
            ...rules,
            {
                valueHolder: createValueHolder(0),
                criteria: []
            }
        ]);
    };

    const updateRule = (updatedRule: RuleResource, oldRule: RuleResource) => {
        const ruleIndex = (rules || []).findIndex((rule) => rule === oldRule);
        if (ruleIndex === -1) return;
        const clonedRules = cloneDeep(rules);
        clonedRules[ruleIndex] = updatedRule;
        setRules(clonedRules);
    };

    const removeRule = (ruleToRemove: RuleResource) => {
        setRules(rules.filter((rule) => rule !== ruleToRemove));
    };

    return (
        <StyledStack>
            <Divider />
            {(rules || []).map((rule, index) => (
                <RuleInput
                    key={index}
                    id={'' + index}
                    rule={rule}
                    variables={variables}
                    openAccordionId={openAccordionId}
                    setOpenAccordionId={setOpenAccordionId}
                    updateRule={updateRule}
                    removeRule={removeRule}
                    isDisabled={isDisabled}
                    disableVariableValues={disableVariableValues}
                />
            ))}
            <Divider sx={{ mt: 'auto' }} />
            <Button startIcon={<AddBoxTwoToneIcon />} onClick={addRule} sx={{ borderRadius: 0 }} disabled={isDisabled}>
                Weiteren Wert hinzufügen
            </Button>
        </StyledStack>
    );
};

export default RuleInputs;
