import { Card, CardActionArea, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageHeader from 'src/components/display/PageHeader';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import TuneTwoToneIcon from '@mui/icons-material/TuneTwoTone';
import PeopleTwoToneIcon from '@mui/icons-material/PeopleTwoTone';
import AdminPanelSettingsTwoToneIcon from '@mui/icons-material/AdminPanelSettingsTwoTone';
import DisplaySettingsTwoToneIcon from '@mui/icons-material/DisplaySettingsTwoTone';
import { nav } from 'src/statics/navigations';
import { NavLink } from 'react-router-dom';
import { canUserAccessRoute } from 'src/utils/RouteHelper';

function ToolsOverview() {
    const { t } = useTranslation();

    const isAllowedToAccessAdminPage = canUserAccessRoute(nav.TOOLS.sub.ADMIN.path);
    const isDevelopment = process.env.REACT_APP_STAGE == null || process.env.REACT_APP_STAGE === 'development';

    return (
        <>
            <PageHeader />
            <Grid container mt={1} spacing={2}>
                <Grid item xs={12} sm={isAllowedToAccessAdminPage ? 6 : 12}>
                    <Card>
                        <CardActionArea sx={{ p: 2 }} component={NavLink} to={nav.TOOLS.sub.DEVELOPER_SETTINGS.path}>
                            <SettingsTwoToneIcon sx={{ mb: 1 }} />
                            <Typography variant="h4" noWrap>
                                {t(nav.TOOLS.sub.DEVELOPER_SETTINGS.caption)}
                            </Typography>
                        </CardActionArea>
                    </Card>
                </Grid>
                {isAllowedToAccessAdminPage && (
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardActionArea sx={{ p: 2 }} component={NavLink} to={nav.TOOLS.sub.ADMIN.path}>
                                <AdminPanelSettingsTwoToneIcon sx={{ mb: 1 }} />
                                <Typography variant="h4" noWrap>
                                    {t(nav.TOOLS.sub.ADMIN.caption)}
                                </Typography>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )}
                <Grid item xs={12} sm={12}>
                    <Card>
                        <CardActionArea sx={{ p: 2 }} component={NavLink} to={nav.TOOLS.sub.CUSTOMERS.path}>
                            <PeopleTwoToneIcon sx={{ mb: 1 }} />
                            <Typography variant="h4" noWrap>
                                {t(nav.TOOLS.sub.CUSTOMERS.caption)}
                            </Typography>
                        </CardActionArea>
                    </Card>
                </Grid>
                {isDevelopment && (
                    <Grid item xs={12} sm={12}>
                        <Card>
                            <CardActionArea sx={{ p: 2 }} component={NavLink} to={nav.TOOLS.sub.CALC_EDITOR.path}>
                                <TuneTwoToneIcon sx={{ mb: 1 }} />
                                <Typography variant="h4" noWrap>
                                    {t(nav.TOOLS.sub.CALC_EDITOR.caption)}
                                </Typography>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </>
    );
}

export default ToolsOverview;
