import { FormControlLabel, FormGroup, Switch, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { BooleanInputVariableResource, BooleanValueResource, InputVariableResource, ValueResource, VariableResource } from 'src/backend/coreCalc';
import store from 'src/redux/store';
import { getBooleanValue, getVariableName } from 'src/utils/CalcHelpers';

interface Props {
    variable: BooleanInputVariableResource;
    variables?: Array<VariableResource>;
    setVariable: (variable: BooleanInputVariableResource) => void;
}

const BooleanInputField: React.FC<Props> = ({ variable, variables, setVariable }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const { t } = useTranslation();

    const value = getBooleanValue(variable.value != null ? variable.value : variable.defaultValue) || false;

    const setVariableValue = (value: BooleanValueResource) => {
        setVariable({ ...variable, value });
    };

    const handleChange = (event) => {
        setVariableValue({ type: ValueResource.type.BOOLEAN_VALUE, booleanValue: event.target.checked });
    };

    return (
        <FormGroup>
            <FormControlLabel
                label={getVariableName(variable)}
                control={<Switch checked={value} onChange={handleChange} />}
                disabled={variable.visibility === InputVariableResource.visibility.DISABLED}
            />
        </FormGroup>
    );
};

export default BooleanInputField;
