//INTERFACE AND INITIAL STATE
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AiMailConversationResource } from 'src/backend/market';
import { ProCalcUpdateTypes } from '../shared/proCalc.common';
import { set, get } from 'lodash';
import { AttachmentResource, CalculationResource, ItemResource, SurchargeVariableResource, ValueResource } from 'src/backend/coreCalc';

export type ProCalcHoleRecognitionResult = {
    holes?: number;
    threads?: number;
    holeDimensions?: Array<{ radius: number }>;
    threadDimensions?: Array<{ radius: number }>;
};
export enum ProCalcSavingStatus {
    LOADING = 'internalCalcSaving',
    LOADED = 'internalCalcSaved',
    ERROR = 'internalCalcSaveError'
}
export type ProCalcError = {
    type: 'badRequest' | 'notAllowed' | 'locked' | 'unknown';
    data?: string;
};
export type ProCalcUpdateLoading = { [updateType in ProCalcUpdateTypes]?: boolean };
export type ProCalcUpdateError = { [updateType in ProCalcUpdateTypes]?: any };

interface IProCalcState {
    calculation: CalculationResource;
    previousCalculations: Array<CalculationResource>;
    mailConversation: AiMailConversationResource;
    savingStatus: ProCalcSavingStatus;
    isUpdateLoading: ProCalcUpdateLoading;
    error: ProCalcError;
    updateErrors: ProCalcUpdateError;
    disablePartValidation: boolean;
}

const initialState: IProCalcState = {
    calculation: {},
    previousCalculations: [],
    // surchargeOverrides: [],
    mailConversation: null,
    savingStatus: ProCalcSavingStatus.LOADED,
    isUpdateLoading: {},
    error: null,
    updateErrors: {},
    disablePartValidation: false
};

export const slice = createSlice({
    name: 'proCalc',
    initialState,
    reducers: {
        setProCalc: (state, action: PayloadAction<CalculationResource>) => {
            state.calculation = action.payload;
        },
        resetProCalc: (state) => {
            state.error = null;
        },
        setProCalcValue: (state, action: PayloadAction<{ path: string; value: any; calcId?: number }>) => {
            if (!state.calculation || isWrongCalcId(state, action)) return;
            set(state.calculation, action.payload.path, action.payload.value);
        },
        setProCalcSelectively: (state, action: PayloadAction<{ updatedCalculation: CalculationResource; paths: Array<string>; calcId?: number }>) => {
            if (!state.calculation || isWrongCalcId(state, action)) return;
            action.payload.paths.forEach((path) => {
                const updatedSection = get(action.payload.updatedCalculation, path);
                set(state.calculation, path, updatedSection);
            });
        },
        setSavingStatus: (state, action: PayloadAction<ProCalcSavingStatus>) => {
            state.savingStatus = action.payload;
        },
        setUpdateLoading: (state, action: PayloadAction<{ updateType: ProCalcUpdateTypes; isLoading: boolean }>) => {
            state.isUpdateLoading[action.payload.updateType] = action.payload.isLoading;
        },
        clearUpdateLoading: (state) => {
            Object.keys(state.isUpdateLoading).forEach((updateType) => {
                state.isUpdateLoading[updateType] = false;
            });
        },
        setDisablePartValidation: (state, action: PayloadAction<boolean>) => {
            state.disablePartValidation = action.payload;
        },
        setMailConversation: (state, action: PayloadAction<AiMailConversationResource>) => {
            state.mailConversation = action.payload;
        },
        setMasterVariableValue: (state, action: PayloadAction<{ variableId: number; value: ValueResource }>) => {
            if (!state.calculation?.masterVariables) return;
            const variableIndex = state.calculation.masterVariables.findIndex((variable) => variable.id === action.payload.variableId);
            if (variableIndex === -1) return;
            state.calculation.masterVariables[variableIndex].value = action.payload.value;
        },
        setMasterSurchargeVariableMode: (state, action: PayloadAction<{ variableId: number; mode: SurchargeVariableResource.mode }>) => {
            if (!state.calculation?.masterVariables) return;
            const variableIndex = state.calculation.masterVariables.findIndex((variable) => variable.id === action.payload.variableId);
            if (variableIndex === -1) return;
            (state.calculation.masterVariables[variableIndex] as SurchargeVariableResource).mode = action.payload.mode;
        },
        addMasterAttachment: (state, action: PayloadAction<AttachmentResource>) => {
            if (!state.calculation?.masterAttachments) return;
            state.calculation.masterAttachments.push(action.payload);
        },
        deleteMasterAttachment: (state, action: PayloadAction<number>) => {
            if (!state.calculation?.masterAttachments) return;
            state.calculation.masterAttachments = state.calculation.masterAttachments.filter((attachment) => attachment.id !== action.payload);
        },
        addTmpItem: (state) => {
            if (!state.calculation?.items) return;
            state.calculation.items.push({ id: -1 });
        },
        addItem: (state, action: PayloadAction<ItemResource>) => {
            if (!state.calculation?.items) return;
            state.calculation.items.push(action.payload);
        },
        deleteItem: (state, action: PayloadAction<number>) => {
            if (!state.calculation?.items) return;
            state.calculation.items = state.calculation.items.filter((part) => part.id !== action.payload);
        },
        setItem: (state, action: PayloadAction<ItemResource>) => {
            if (!state.calculation?.items) return;
            const itemIndex = state.calculation.items.findIndex((category) => category.id === action.payload.id);
            if (itemIndex === -1) return;
            state.calculation.items[itemIndex] = action.payload;
        },
        setItemVariableValue: (state, action: PayloadAction<{ itemId: number; variableId: number; value: ValueResource }>) => {
            if (!state.calculation?.items) return;
            const itemIndex = state.calculation.items.findIndex((category) => category.id === action.payload.itemId);
            if (itemIndex === -1) return;
            const variableIndex = state.calculation.items[itemIndex].variables.findIndex((variable) => variable.id === action.payload.variableId);
            if (variableIndex === -1) return;
            state.calculation.items[itemIndex].variables[variableIndex].value = action.payload.value;
        },
        addItemAttachment: (state, action: PayloadAction<{ itemId: number; attachment: AttachmentResource }>) => {
            if (!state.calculation?.items) return;
            const itemIndex = state.calculation.items.findIndex((category) => category.id === action.payload.itemId);
            if (itemIndex === -1) return;
            // state.calculation.items[itemIndex].attachments.push(action.payload.attachment);
        },
        deleteItemAttachment: (state, action: PayloadAction<{ itemId: number; attachmentId: number }>) => {
            if (!state.calculation?.items) return;
            const itemIndex = state.calculation.items.findIndex((category) => category.id === action.payload.itemId);
            if (itemIndex === -1) return;
            // state.calculation.items[itemIndex].attachments = state.calculation.items[itemIndex].attachments.filter(a => a.id !== action.payload.attachmentId);
        },
        setItemSurchargeVariableMode: (state, action: PayloadAction<{ itemId: number; variableId: number; mode: SurchargeVariableResource.mode }>) => {
            if (!state.calculation?.items) return;
            const itemIndex = state.calculation.items.findIndex((category) => category.id === action.payload.itemId);
            if (itemIndex === -1) return;
            const variableIndex = state.calculation.items[itemIndex].variables.findIndex((variable) => variable.id === action.payload.variableId);
            if (variableIndex === -1) return;
            (state.calculation.items[itemIndex].variables[variableIndex] as SurchargeVariableResource).mode = action.payload.mode;
        },

        // setPartParameter: (state, action: PayloadAction<{ partId: number; itemId: number; parameter: CalculationParameterDto; calcId?: number }>) => {
        //     if (!state.calculation?.parts || isWrongCalcId(state, action)) return;
        //     const partIndex = state.calculation.parts.findIndex((part) => part.id === action.payload.partId);
        //     const parameterIndex = state.calculation.parts[partIndex].setParameters.findIndex((setParameter) => setParameter.name === action.payload.parameter.name);
        //     state.calculation.parts[partIndex].setParameters[parameterIndex].value = action.payload.parameter.value;
        // },
        // setPartParameters: (state, action: PayloadAction<{ partId: number; itemId: number; parameters: Array<CalculationParameterDto> }>) => {
        //     if (!state.calculation?.parts) return;
        //     const partIndex = state.calculation.parts.findIndex((part) => part.id === action.payload.partId);
        //     state.calculation.parts[partIndex].setParameters = action.payload.parameters;
        // },
        // setPartName: (state, action: PayloadAction<{ partId: number; name: string }>) => {
        //     if (!state.calculation?.parts) return;
        //     const partIndex = state.calculation.parts.findIndex((part) => part.id === action.payload.partId);
        //     state.calculation.parts[partIndex].userDefinedPartName = action.payload.name;
        // },
        // setPartForcePrice: (state, action: PayloadAction<{ partId: number; force: boolean; price: number }>) => {
        //     if (!state.calculation?.parts) return;
        //     const { partId, force, price } = action.payload;
        //     const partIndex = state.calculation.parts.findIndex((part) => part.id === partId);
        //     const subCalculationIdentificationKey = state.calculation.parts[partIndex].subCalculationIdentificationKey;
        //     const partCostResult = (state.calculation?.costResult?.subCalculationResults || []).find((x) => x.additionalInformation === subCalculationIdentificationKey);
        //     if (partCostResult) partCostResult.priceNonRounded = price;
        //     state.calculation.parts[partIndex].forcePrice = { force, price };
        // },
        handleError: (state, action: PayloadAction<any>) => {
            const error = action.payload;
            console.error('handleError', error);

            if (error.status === 423) {
                const userId = (error.body?.message || '').split(':')[1];
                state.error = { type: 'locked', data: userId };
            } else if (error.status === 400) {
                state.error = { type: 'badRequest' };
            } else if (error.status === 403) {
                state.error = { type: 'notAllowed' };
            } else {
                state.error = { type: 'unknown', data: error?.status };
            }
        },
        resetError: (state) => {
            state.error = null;
        },
        saveCalculationRecord: (state) => {
            // Record the previous state to provide rollback on failure
            state.previousCalculations.push(state.calculation);
        },
        handleUpdateSuccess: (state, action: PayloadAction<ProCalcUpdateTypes>) => {
            state.savingStatus = ProCalcSavingStatus.LOADED;
            state.isUpdateLoading[action.payload] = false;
            state.error = null;
        },
        handleUpdateFailure: (state, action: PayloadAction<{ updateType: ProCalcUpdateTypes; error: any }>) => {
            state.savingStatus = ProCalcSavingStatus.ERROR;
            state.isUpdateLoading[action.payload.updateType] = false;
            // Restore previous state and set error
            state.calculation = state.previousCalculations.pop() ?? state.calculation;
            state.isUpdateLoading[action.payload.updateType] = action.payload.error;
            slice.caseReducers.handleError(state, { payload: action.payload.error, type: 'handleError' });
        }
    }
});
export const reducer = slice.reducer;

const isWrongCalcId = (state, action: PayloadAction<any>) => {
    if (!action.payload.calcId) return false;
    return state.calculation?.id !== action.payload.calcId;
};

export const {
    addItem,
    addItemAttachment,
    addMasterAttachment,
    addTmpItem,
    clearUpdateLoading,
    deleteItem,
    deleteItemAttachment,
    deleteMasterAttachment,
    handleError,
    handleUpdateFailure,
    handleUpdateSuccess,
    resetError,
    resetProCalc,
    saveCalculationRecord,
    setItem,
    setItemVariableValue,
    setItemSurchargeVariableMode,
    setMailConversation,
    setMasterSurchargeVariableMode,
    setMasterVariableValue,
    setProCalc,
    setProCalcSelectively,
    setProCalcValue,
    setSavingStatus,
    setUpdateLoading,

    setDisablePartValidation
} = slice.actions;
