/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CriterionResource } from './CriterionResource';

export type MessageResource = {
    id?: number;
    type?: MessageResource.type;
    title?: string;
    message?: string;
    titleTranslationKey?: string;
    messageTranslationKey?: string;
    criteria?: Array<CriterionResource>;
}

export namespace MessageResource {

    export enum type {
        HINT = 'HINT',
        WARNING = 'WARNING',
        ERROR = 'ERROR',
    }


}
