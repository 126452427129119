import { FormControl, InputLabel, MenuItem, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { InputVariableResource, SingleSelectInputVariableResource, StringValueResource, ValueResource, VariableResource } from 'src/backend/coreCalc';
import store from 'src/redux/store';
import { getStringValue, getText } from 'src/utils/CalcHelpers';
import ExtractedIndicator from '../ExtractedIndicator';
import InfoLabel from '../InfoLabel';
import { StyledSelectInputField } from '../InputSections.styles';

interface Props {
    variable: SingleSelectInputVariableResource;
    variables?: Array<VariableResource>;
    setVariable: (variable: SingleSelectInputVariableResource) => void;
}

const SingleSelectInputField: React.FC<Props> = ({ variable, variables, setVariable }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const { t } = useTranslation();

    const defaultValue = getStringValue(variable.defaultValue) || '';
    const value = getStringValue(variable.value) || defaultValue || '';

    const setVariableValue = (value: StringValueResource) => {
        setVariable({ ...variable, value });
    };

    const handleChange = (event) => {
        setVariableValue({ type: ValueResource.type.STRING_VALUE, stringValue: event.target.value });
    };

    return (
        <FormControl fullWidth disabled={variable.visibility === InputVariableResource.visibility.DISABLED}>
            <InputLabel id={variable.id + '-label'} sx={{ marginLeft: '-0.35em' }} shrink>
                <InfoLabel variable={variable} />
            </InputLabel>
            <StyledSelectInputField
                labelId={variable.id + '-label'}
                label={<InfoLabel variable={variable} />}
                value={value}
                startAdornment={<ExtractedIndicator variable={variable} variables={variables} setValue={setVariableValue} />}
                onChange={handleChange}
                size="small"
            >
                {(variable.options || []).map((option, index) => {
                    return (
                        <MenuItem key={index} value={option.internalIdentifier}>
                            {getText(option)}
                            {defaultValue === option.internalIdentifier && (
                                <span className="default-indicator" style={{ marginLeft: 4, opacity: 0.5, fontSize: 12 }}>
                                    (Default)
                                </span>
                            )}
                        </MenuItem>
                    );
                })}
            </StyledSelectInputField>
        </FormControl>
    );
};

export default SingleSelectInputField;
