/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ValueDTO = {
    type: ValueDTO.type;
}

export namespace ValueDTO {

    export enum type {
        BOOLEAN_VALUE = 'BOOLEAN_VALUE',
        STRING_VALUE = 'STRING_VALUE',
        NUMBER_VALUE = 'NUMBER_VALUE',
        LIST_VALUE = 'LIST_VALUE',
    }


}
