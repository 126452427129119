/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { HolderDTO } from './HolderDTO';

export type CriterionDTO = {
    left?: HolderDTO;
    operator?: CriterionDTO.operator;
    right?: HolderDTO;
    position?: number;
    connector?: CriterionDTO.connector;
}

export namespace CriterionDTO {

    export enum operator {
        EQUALS = 'EQUALS',
        NOT_EQUALS = 'NOT_EQUALS',
        LESS_THAN = 'LESS_THAN',
        GREATER_THAN = 'GREATER_THAN',
        LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
        GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
    }

    export enum connector {
        AND = 'AND',
        OR = 'OR',
    }


}
