/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CalculationFilterDTO = {
    searchString?: string;
    status?: CalculationFilterDTO.status;
    from?: string;
    to?: string;
}

export namespace CalculationFilterDTO {

    export enum status {
        UNOPENED = 'UNOPENED',
        PENDING_MAIL_ANSWER = 'PENDING_MAIL_ANSWER',
        NEEDS_MAIL_CLARIFICATION = 'NEEDS_MAIL_CLARIFICATION',
        CALCULATING = 'CALCULATING',
        SHIPPING = 'SHIPPING',
        COMPLETED = 'COMPLETED',
    }


}
