import { Typography, Stack, useTheme, TextField, Button, IconButton, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { v4 as generateUuid } from 'uuid';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SingleSelectInputVariableResource, SelectOptionResource, ValueResource, VariableResource } from 'src/backend/coreCalc';
import { getStringValue, getText } from 'src/utils/CalcHelpers';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { cloneDeep } from 'lodash';

interface Props {
    variable: SingleSelectInputVariableResource;
    variables: Array<VariableResource>;
    setVariable: (variable: SingleSelectInputVariableResource) => void;
    setValid: (isValid: boolean) => void;
    isSmall?: boolean;
    isDisabled: boolean;
}

const MultiSelectInputVariableForm: React.FC<Props> = ({ variable, variables, setVariable, setValid, isSmall, isDisabled }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();

    const defaultOption = useMemo(() => (variable.options || []).find((option) => option.internalIdentifier === getStringValue(variable.defaultValue)), [variable]);

    useEffect(() => {
        if (!variable) return setValid(false);
        const isInvalid = variable.options == null || variable.options.length < 1 || !defaultOption;
        setValid(!isInvalid);
    }, [variable, defaultOption]);

    const handleOptionChange = (internalIdentifier: string, event) => {
        const clonedOptions = cloneDeep(variable.options);
        const index = clonedOptions.findIndex((option) => option.internalIdentifier === internalIdentifier);
        clonedOptions[index].name = event.target.value;
        setVariable({
            ...variable,
            options: [...clonedOptions]
        });
    };

    const addOption = () => {
        setVariable({
            ...variable,
            options: [...(variable.options || []), { internalIdentifier: generateUuid() }]
        });
    };
    const removeOption = (optionToRemove: SelectOptionResource) => {
        setVariable({
            ...variable,
            options: [...(variable.options || []).filter((option) => option !== optionToRemove)]
        });
    };
    const handleDefaultOptionChange = (event) => {
        setVariable({
            ...variable,
            defaultValue: { type: ValueResource.type.STRING_VALUE, stringValue: event.target.value }
        });
    };

    return (
        variable && (
            <>
                <Typography variant="subtitle2" gutterBottom sx={{ mt: 4 }}>
                    Auswahloptionen anpassen
                </Typography>
                <Stack>
                    {(variable.options || []).map((option, index) => (
                        <Stack key={index} direction="row" sx={{ my: 1 }}>
                            <TextField
                                label={'Option ' + (index + 1)}
                                value={option.name}
                                size="small"
                                disabled={isDisabled}
                                onChange={(event) => handleOptionChange(option.internalIdentifier, event)}
                                fullWidth
                            />
                            <IconButton color="secondary" sx={{ mt: -0.2, mr: -0.5 }} onClick={() => removeOption(option)}>
                                <CloseTwoToneIcon />
                            </IconButton>
                        </Stack>
                    ))}

                    <Button startIcon={<AddBoxTwoToneIcon />} onClick={addOption} sx={{ ml: -1.3, mr: 'auto' }} size="small" disabled={isDisabled}>
                        Option hinzufügen
                    </Button>
                </Stack>
                {variable.options?.length > 0 && (
                    <FormControl fullWidth sx={{ mt: 4 }}>
                        <InputLabel id="select-default-value" size="small">
                            Welche Optionen sollen standardmäßig verwendet werden?
                        </InputLabel>
                        <Select
                            labelId="select-default-value"
                            label="Welche Option soll standardmäßig verwendet werden?"
                            value={defaultOption?.internalIdentifier || ''}
                            onChange={handleDefaultOptionChange}
                            size="small"
                            disabled={isDisabled}
                        >
                            {variable.options.map((option, index) => (
                                <MenuItem key={index} value={option.internalIdentifier}>
                                    {getText(option)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </>
        )
    );
};

export default MultiSelectInputVariableForm;
