import { Button, IconButton, Stack, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import store from 'src/redux/store';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import { CategoryResource } from 'src/backend/coreCalc';
import { getText } from 'src/utils/CalcHelpers';
import { SubCategoryItem } from '../../CalcEditor.styles';
import ChangeSubCategoryDialog from './ChangeSubCategoryDialog';
import CreateSubCategoryDialog from './CreateSubCategoryDialog';

interface Props {
    category?: CategoryResource;
}

const SubCategories: React.FC<Props> = ({ category }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const { t } = useTranslation();
    const [selectedSubCategory, setSelectedSubCategory] = useState<CategoryResource>();
    const [isCreateSubCategoryDialogOpen, setCreateSubCategoryDialogOpen] = useState<boolean>(false);

    return (
        <Stack p={3} justifyContent="center" width="23rem">
            <Typography variant="h4" gutterBottom>
                Teile
            </Typography>
            <Typography variant="subtitle2" fontSize={14} lineHeight={1.2} mb={0.5}>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            </Typography>

            <Stack>
                {(category.subCategories || []).map((subCategory, index) => (
                    <SubCategoryItem key={subCategory.id + '' + index} direction="row">
                        <Typography variant="subtitle2">{getText(subCategory)}</Typography>
                        <IconButton size="small" color="secondary" sx={{ mr: -0.8 }} onClick={() => setSelectedSubCategory(subCategory)}>
                            <SettingsTwoToneIcon />
                        </IconButton>
                    </SubCategoryItem>
                ))}
            </Stack>

            {selectedSubCategory && <ChangeSubCategoryDialog category={category} subCategory={selectedSubCategory} isOpen={!!selectedSubCategory} setOpen={() => setSelectedSubCategory(null)} />}

            <Button size="small" sx={{ alignSelf: 'flex-start', ml: '-12px' }} startIcon={<AddBoxTwoToneIcon />} onClick={() => setCreateSubCategoryDialogOpen(true)}>
                Teil hinzufügen
            </Button>
            {isCreateSubCategoryDialogOpen && <CreateSubCategoryDialog category={category} isOpen={isCreateSubCategoryDialogOpen} setOpen={setCreateSubCategoryDialogOpen} />}
        </Stack>
    );
};

export default SubCategories;
