import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BlueprintResource } from 'src/backend/coreCalc';

interface ICalcEditorState {
    blueprints: Array<BlueprintResource>;
}

const initialState: ICalcEditorState = {
    blueprints: []
};

export const slice = createSlice({
    name: 'calcEditors',
    initialState,
    reducers: {
        setBlueprints: (state, action: PayloadAction<Array<BlueprintResource>>) => {
            state.blueprints = action.payload;
        },
        addBlueprint: (state, action: PayloadAction<BlueprintResource>) => {
            state.blueprints.push(action.payload);
        },
        removeBlueprint: (state, action: PayloadAction<string>) => {
            state.blueprints = state.blueprints.filter((blueprint) => blueprint.id !== action.payload);
        }
    }
});
export const reducer = slice.reducer;

export const { setBlueprints, addBlueprint, removeBlueprint } = slice.actions;
