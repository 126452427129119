/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type VariableDTO = {
    type: VariableDTO.type;
    internalIdentifier?: string;
    displayName?: string;
    translationKey?: string;
}

export namespace VariableDTO {

    export enum type {
        TERM_VARIABLE = 'TERM_VARIABLE',
        PROVIDED_VARIABLE = 'PROVIDED_VARIABLE',
        DECLARATION_VARIABLE = 'DECLARATION_VARIABLE',
        SURCHARGE_VARIABLE = 'SURCHARGE_VARIABLE',
        ROUND_VARIABLE = 'ROUND_VARIABLE',
        RULE_BASED_VARIABLE = 'RULE_BASED_VARIABLE',
        ON_THE_FLY_VALUE_VARIABLE = 'ON_THE_FLY_VALUE_VARIABLE',
        BOOLEAN_INPUT_VARIABLE = 'BOOLEAN_INPUT_VARIABLE',
        NUMBER_INPUT_VARIABLE = 'NUMBER_INPUT_VARIABLE',
        SINGLE_SELECT_INPUT_VARIABLE = 'SINGLE_SELECT_INPUT_VARIABLE',
        MULTI_SELECT_INPUT_VARIABLE = 'MULTI_SELECT_INPUT_VARIABLE',
        TEXT_INPUT_VARIABLE = 'TEXT_INPUT_VARIABLE',
    }


}
