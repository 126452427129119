import { Box, Button, Divider, IconButton, List, ListItem, ListItemText, Popover, Stack, styled, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import { t } from 'i18next';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import GppBadTwoToneIcon from '@mui/icons-material/GppBadTwoTone';
import store, { useDispatch } from 'src/redux/store';
import { MessageResource, VariableResource } from 'src/backend/coreCalc';
import CreateCategoryMessageDialog from './CreateCategoryMessageDialog';
import ChangeCategoryMessageDialog from './ChangeCategoryMessageDialog';
import { BlueprintUpdateTypes, updateBlueprint } from 'src/redux/shared/calcEditor.common';

const ButtonIconWrapper = styled(Box)(
    ({ theme }) => `
        display: inline-flex;
        justify-content: center;
        margin-left: ${theme.spacing(1)};
        margin-right: ${theme.spacing(0.4)};
        .MuiSvgIcon-root {
            font-size: 15px;
            margin: auto;
        }
    `
);
const IconWrapper = styled(Box)(
    ({ theme }) => `
        display: inline-flex;
        justify-content: center;
        margin-left: ${theme.spacing(-0.5)};
        margin-right: ${theme.spacing(1.5)};
        .MuiSvgIcon-root {
            font-size: 24px;
            margin: auto;
        }
    `
);
const StyledListItemText = styled(ListItemText)(
    ({ theme }) => `
        .MuiListItemText-primary {
            display: flex;
            font-weight: 600
        }
    `
);

const iconMap = {
    WARNING: <WarningTwoToneIcon color="warning" />,
    ERROR: <GppBadTwoToneIcon color="error" />,
    HINT: <InfoTwoToneIcon />
};

export const getMessageIcon = (type: MessageResource.type) => {
    return iconMap[type as keyof typeof iconMap];
};
export const getMessageButtonIndicator = (messagesOfType: number, type: MessageResource.type, theme: Theme) => {
    if (messagesOfType < 1) return;
    const color = type === MessageResource.type.ERROR ? theme.colors.error.main : type === MessageResource.type.WARNING ? theme.colors.warning.main : 'unset';
    return (
        <>
            <ButtonIconWrapper>{getMessageIcon(type)}</ButtonIconWrapper>
            <span style={{ color, marginLeft: 5 }}>{messagesOfType}</span>
        </>
    );
};
export const getMessageBackgroundColor = (type: MessageResource.type, theme: Theme) => {
    return type === MessageResource.type.ERROR ? theme.colors.error.lighter : type === MessageResource.type.WARNING ? theme.colors.warning.lighter : theme.colors.alpha.black[7];
};

interface Props {
    categoryId: number;
    messages: Array<MessageResource>;
    variables: Array<VariableResource>;
    isSmall?: boolean;
}

const CategoryMessages: React.FC<Props> = ({ categoryId, messages, variables, isSmall }) => {
    const dispatch = useDispatch();
    const ref = useRef<any>(null);
    const s = store.getState();
    const theme = useTheme();
    const [isOpen, setOpen] = useState<boolean>(false);
    const [selectedMessage, setSelectedMessage] = useState<MessageResource>();
    const [isCreateMessageOpen, setCreateMessageOpen] = useState<boolean>(false);

    const hints = useMemo(() => (messages || []).filter((message) => message.type === MessageResource.type.HINT).length, [messages]);
    const warnings = useMemo(() => (messages || []).filter((message) => message.type === MessageResource.type.WARNING).length, [messages]);
    const errors = useMemo(() => (messages || []).filter((message) => message.type === MessageResource.type.ERROR).length, [messages]);

    const createMessage = (message: MessageResource) => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.CREATE_CATEGORY_MESSAGE, { categoryId, message }));
    };
    const saveMessage = (message: MessageResource) => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.CATEGORY_MESSAGE, { categoryId, message }));
    };
    const removeMessage = (messageId: number) => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.DELETE_CATEGORY_MESSAGE, { categoryId, messageId }));
    };

    return (
        <>
            <Button
                sx={{ px: 1.2, py: 0.5, flexShrink: 0 }}
                startIcon={<EmailTwoToneIcon />}
                variant="text"
                color="secondary"
                onClick={() => setOpen(true)}
                ref={ref}
                size={isSmall ? 'small' : 'medium'}
            >
                {t('generalMessages')}
                {getMessageButtonIndicator(hints, MessageResource.type.HINT, theme)}
                {getMessageButtonIndicator(warnings, MessageResource.type.WARNING, theme)}
                {getMessageButtonIndicator(errors, MessageResource.type.ERROR, theme)}
            </Button>

            <Popover
                anchorEl={ref.current}
                onClose={() => setOpen(false)}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Stack p={2} maxWidth="35rem" width="25rem" maxHeight="50rem">
                    <Typography variant="h3" textAlign="center">
                        {t('categoryMessages')}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom textAlign="center" mb={2}>
                        {t('categoryMessagesExplanation')}
                    </Typography>

                    <List disablePadding sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                        {messages.map((message, index) => (
                            <ListItem key={index} sx={{ bgcolor: getMessageBackgroundColor(message.type, theme), borderRadius: 1 }}>
                                <Tooltip title={t(message.type)}>
                                    <IconWrapper>{getMessageIcon(message.type)}</IconWrapper>
                                </Tooltip>
                                <StyledListItemText primary={message.title || t(message.titleTranslationKey)} secondary={message.message || t(message.messageTranslationKey)} />
                                <IconButton color="secondary" sx={{ mr: -1.5 }} onClick={() => setSelectedMessage(message)}>
                                    <SettingsTwoToneIcon />
                                </IconButton>
                            </ListItem>
                        ))}
                        {selectedMessage && (
                            <ChangeCategoryMessageDialog
                                message={selectedMessage}
                                variables={variables}
                                isOpen={!!selectedMessage}
                                setOpen={() => setSelectedMessage(null)}
                                saveMessage={saveMessage}
                                removeMessage={removeMessage}
                            />
                        )}

                        <Button startIcon={<AddBoxTwoToneIcon />} sx={{ width: '100%' }} onClick={() => setCreateMessageOpen(true)}>
                            Eigenen Hinweis erstellen
                        </Button>
                        {isCreateMessageOpen && (
                            <CreateCategoryMessageDialog messages={messages} variables={variables} isOpen={isCreateMessageOpen} setOpen={setCreateMessageOpen} createMessage={createMessage} />
                        )}
                    </List>
                </Stack>
            </Popover>
        </>
    );
};

export default CategoryMessages;
