import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography, useTheme } from '@mui/material';
import React, { useRef, useState } from 'react';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import store from 'src/redux/store';
import { MessageResource, VariableResource } from 'src/backend/coreCalc';
import CategoryMessageForm from './CategoryMessageForm';

interface Props {
    message: MessageResource;
    variables: Array<VariableResource>;
    isOpen: boolean;
    setOpen?: (isOpen: boolean) => void;
    saveMessage: (message: MessageResource) => void;
    removeMessage: (messageId: number) => void;
}

const ChangeCategoryMessageDialog: React.FC<Props> = ({ message, variables, isOpen, setOpen, saveMessage, removeMessage }) => {
    const ref = useRef<any>(null);
    const s = store.getState();
    const theme = useTheme();
    const [localMessage, setLocalMessage] = useState<MessageResource>(message);
    const [isValid, setValid] = useState<boolean>(false);

    const handleSave = () => {
        saveMessage(localMessage);
        setOpen(false);
    };
    const handleRemove = () => {
        removeMessage(localMessage.id);
    };

    return (
        <Dialog open={isOpen} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ pt: 3.1, pb: 2.7 }}>
                <Typography variant="h4">Hinweis ändern</Typography>
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 16, top: 16, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack gap={2} pt={1}>
                    <CategoryMessageForm message={localMessage} variables={variables} setMessage={setLocalMessage} setValid={setValid} />

                    <Stack direction="row" justifyContent="space-between">
                        <Button variant="text" sx={{ mx: -1.5 }} color="error" startIcon={<DeleteTwoToneIcon />} onClick={handleRemove}>
                            Löschen
                        </Button>
                        <Button variant="contained" startIcon={<AutoAwesomeTwoToneIcon />} disabled={!isValid} onClick={handleSave}>
                            Änderungen speichern
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ChangeCategoryMessageDialog;
