import { EditTwoTone } from '@mui/icons-material';
import { Avatar, Badge, Box, Button, Chip, Collapse, IconButton, Stack, TableCell, TableRow, Tooltip, Typography, styled, useTheme } from '@mui/material';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate, formatDistance, formatPrice, formatValue, getAddressLine1Set, getAddressLine2Set, getColors, getMaterials } from 'src/utils/FormatHelpers';
import CallTwoToneIcon from '@mui/icons-material/CallTwoTone';
import AlternateEmailTwoToneIcon from '@mui/icons-material/AlternateEmailTwoTone';
import RecordVoiceOverTwoToneIcon from '@mui/icons-material/RecordVoiceOverTwoTone';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import VisibilityOffTwoToneIcon from '@mui/icons-material/VisibilityOffTwoTone';
import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import { AiMailConversationResource, RegularCustomerResource, RegularCustomerRestControllerService, UserResource } from 'src/backend/market';
import { wT } from 'src/utils/wizardTranslations';
import store, { RootState } from 'src/redux/store';
import { createPortal } from 'react-dom';
import AiEmailConversationViewer from 'src/components/pro-calc/AiEmail/AiEmailConversationViewer';
import ColorDrops from '../display/ColorDrops';
import CalculationStatus from '../display/CalculationStatus/CalculationStatus';
import { CustomerResource, ShortCalculationResource } from 'src/backend/coreCalc';
import { getCustomerInfo, getCustomerText, getFullName, getText } from 'src/utils/CalcHelpers';
import { connect } from 'react-redux';

const AvatarWrapper = styled(Avatar)(
    ({ theme }) => `
        width: 43px;
        height: 43px;
        font-size: 20px;
    `
);
const StyledIconBox = styled(Box)(
    ({ theme }) => `
        margin-top: -3px;
        margin-left: -3px;
        background: 'Red';
        & .MuiSvgIcon-root {
            padding: 2px;
            background: ${theme.colors.primary.main};
            color: ${theme.colors.alpha.white[100]};
            font-size: 16px;
            border-radius: 50%;
        }
    `
);

const TopIconButton = styled(Button)(
    ({ theme }) => `
        flex-direction: column;
        font-size: 16px;
        .MuiButton-startIcon {
            margin-right: 0;
            margin-left: 0;
            margin-bottom: 8px;
        }
        .MuiButton-startIcon .MuiSvgIcon-root {
            font-size: 27px;
        }
    `
);

interface Props {
    row: ShortCalculationResource;
    mailConversation: AiMailConversationResource;
    openRow: ShortCalculationResource;
    setOpenRow: (row: ShortCalculationResource) => void;
    handleEditClicked: Function;
    handleViewClicked: Function;
    handleDuplicateClicked: Function;
    handleDeleteClicked: Function;
    user: UserResource;
}

const CalcOverviewTableRow: React.FC<Props> = ({ row, mailConversation, openRow, setOpenRow, handleEditClicked, handleViewClicked, handleDuplicateClicked, handleDeleteClicked, user }) => {
    const isOpen = row === openRow;
    const theme = useTheme();
    const { t } = useTranslation();
    const s = store.getState();
    const chipWrapper = useRef(null);
    const chipRefs = useRef([]);
    const [overflowCount, setOverflowCount] = useState<number>(0);
    const [isAiEmailViewerOpen, setAiEmailViewerOpen] = useState<boolean>(false);
    const boxShadow = 'inset 5px 0 0 0 ' + (isOpen ? theme.colors.primary.main : 'transparent');
    const [regularCustomer, setRegularCustomer] = useState<RegularCustomerResource>();

    const checkChipOverflow = (chip, isPreviousOverflowing, offsetForLabel = 0) => {
        if (!chip) return false;
        chip.style.display = 'inline-flex';
        const isOverflowing = isPreviousOverflowing || chip.offsetLeft + chip.clientWidth + offsetForLabel > chip.parentElement.clientWidth;

        if (isOverflowing) {
            chip.style.display = 'none';
            return true;
        }
        return false;
    };

    const checkChipsOverflow = () => {
        const chips = chipRefs.current || [];
        const offsetForLabel = 30;
        let overflowCount = 0;
        setOverflowCount(0);

        chips.forEach((chip, index) => {
            const isPreviousOverflowing = overflowCount > 0;
            const isOverflowing = checkChipOverflow(chip, isPreviousOverflowing);
            if (isOverflowing) {
                overflowCount++;
                const previousChip = chips[index - 1];
                if (overflowCount === 1 && previousChip) {
                    const isPreviousOverflowing = checkChipOverflow(previousChip, false, offsetForLabel);
                    if (isPreviousOverflowing) overflowCount++;
                }
            }
        });

        setOverflowCount(overflowCount);
    };

    const itemNames = row.items.map((item) => getText(item));

    const getThroughput = () => {
        if (!row.wishDate || !row.bringDate) return;
        const throughputDays = parseInt(row.wishDate) - parseInt(row.bringDate);
        return `${throughputDays || ''} ${t(throughputDays === 0 ? 'sameDay' : throughputDays > 1 ? 'days' : 'day')}`;
    };
    const throughput = getThroughput();

    const getTotalPieces = (row: ShortCalculationResource) => {
        let itemCount = 0;
        row.items.forEach((item) => {
            itemCount += item.quantity;
        });
        return itemCount;
    };

    const getArea = (row: ShortCalculationResource) => {
        // todo
        // let surfaceUnit = row.surfaceUnit;
        // if (!surfaceUnit || surfaceUnit === 'NOT_SET') return '-';
        // if (surfaceUnit === 'm2') surfaceUnit = 'm²';
        // return formatValue(row.surfaceValue) + ' ' + surfaceUnit;
        return 0;
    };

    const isSet = (value: string) => {
        return !!value && !!value.trim();
    };

    useEffect(() => {
        if (row.customer?.regularCustomerId) {
            RegularCustomerRestControllerService.getRegularCustomer(user.id, row.customer.regularCustomerId).then((regularCustomer) => setRegularCustomer(regularCustomer));
        }
    }, []);

    useEffect(() => {
        checkChipsOverflow();
    }, [isOpen]);

    const debounceCheckChipsOverflow = debounce(checkChipsOverflow, 150);
    useEffect(() => {
        if (!chipWrapper.current) return;
        const resizeObserver = new ResizeObserver(debounceCheckChipsOverflow);
        resizeObserver.observe(chipWrapper.current);
        return () => resizeObserver.disconnect();
    }, [chipWrapper.current]);

    return (
        <React.Fragment>
            <TableRow
                hover
                sx={{
                    '& > .MuiTableCell-body': { borderBottom: 'unset', cursor: 'pointer' },
                    '&.MuiTableRow-hover:hover': { background: isOpen ? 'rgba(26, 117, 255, 0.16)' : theme.colors.primary.lighter },
                    background: isOpen ? 'rgba(26, 117, 255, 0.07)' : '',
                    boxShadow,
                    transition: '0.4s'
                }}
                onClick={() => setOpenRow(isOpen ? null : row)}
            >
                <TableCell sx={{ width: 50, maxWidth: 50 }}>
                    <IconButton aria-label="expand row" size="small">
                        {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell sx={{ maxWidth: 50 }}>
                    <Typography sx={{ fontSize: 12 }} noWrap>
                        {row.id}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        {/* todo <Tooltip title={t('xAttachments', { x: row.attachmentCount })}>
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '0.9rem',
                                            height: '0.9rem',
                                            textAlign: 'center',
                                            borderRadius: '50%',
                                            fontSize: 11,
                                            color: theme.colors.alpha.white[100],
                                            bgcolor: theme.colors.primary.main
                                        }}
                                    >
                                        {row.attachmentCount}
                                    </Box>
                                }
                            >
                                <AttachFileTwoToneIcon />
                            </Badge>
                        </Tooltip> */}
                        {mailConversation?.conversation?.length > 0 && (
                            <Tooltip title={t('emailConversation')}>
                                <IconButton
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setAiEmailViewerOpen(true);
                                    }}
                                >
                                    <EmailTwoToneIcon />
                                    {createPortal(
                                        <AiEmailConversationViewer mailConversation={mailConversation} suffix={'#' + row.id} isOpen={isAiEmailViewerOpen} setOpen={setAiEmailViewerOpen} />,
                                        document.body
                                    )}
                                </IconButton>
                            </Tooltip>
                        )}
                    </Stack>
                </TableCell>
                <TableCell sx={{ width: 350, p: 1 }}>
                    <Stack direction="row" textAlign="left" alignItems="center" overflow="hidden">
                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                                row.customer?.contactedVia && (
                                    <StyledIconBox display="flex" justifySelf="flex-end" alignItems="center">
                                        {row.customer.contactedVia === CustomerResource.contactedVia.PHONE && <CallTwoToneIcon />}
                                        {row.customer.contactedVia === CustomerResource.contactedVia.EMAIL && <AlternateEmailTwoToneIcon />}
                                        {row.customer.contactedVia === CustomerResource.contactedVia.IN_PERSON && <RecordVoiceOverTwoToneIcon />}
                                        {row.customer.contactedVia === CustomerResource.contactedVia.WEB && <LanguageTwoToneIcon />}
                                    </StyledIconBox>
                                )
                            }
                        >
                            <AvatarWrapper alt={getCustomerText(row.customer) || '?'} src={regularCustomer?.logo || '/static/images/avatars/3.jpg'} />
                        </Badge>
                        <Stack ml={1.4} alignItems="flex-start" alignSelf="center" overflow="hidden" maxWidth="100%">
                            <Stack direction="row" overflow="hidden" alignItems="center" maxWidth="100%">
                                <Typography variant="subtitle2" fontWeight="bold" noWrap lineHeight={1.3} fontSize={13} maxWidth="100%">
                                    {getCustomerInfo(row.customer)}
                                </Typography>
                                {regularCustomer && (
                                    <Typography
                                        noWrap
                                        ml={0.6}
                                        fontSize="0.85em"
                                        fontWeight="bold"
                                        lineHeight={1}
                                        bgcolor={theme.colors.warning.lighter}
                                        color={theme.colors.warning.main}
                                        p={0.4}
                                        borderRadius={0.6}
                                        className="regular-customer"
                                    >
                                        {t('regularCustomer')}
                                    </Typography>
                                )}
                            </Stack>
                            {getAddressLine1Set(row.customer?.address) !== '' && (
                                <Typography variant="subtitle2" lineHeight={1.2} fontSize={13} noWrap>
                                    {getAddressLine1Set(row.customer?.address)}
                                </Typography>
                            )}
                            {getAddressLine2Set(row.customer?.address) !== '' && (
                                <Typography variant="subtitle2" lineHeight={1.2} fontSize={13} noWrap>
                                    {getAddressLine2Set(row.customer?.address)}
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                </TableCell>
                <TableCell sx={{ minWidth: '10rem', width: '12vw', maxWidth: '12vw' }}>
                    {isOpen ? (
                        <Typography variant="subtitle2" fontWeight="bold" fontSize={13} noWrap>
                            {t('totalQuantity')}: {getTotalPieces(row)}
                        </Typography>
                    ) : row.items.length > 0 ? (
                        <Stack ref={chipWrapper} direction="row" overflow="hidden" gap={1} pr={4}>
                            {itemNames.map((itemName, index) => (
                                <Chip
                                    key={index}
                                    label={itemName}
                                    sx={{ height: 25, overflow: index === 0 && overflowCount > 0 ? 'unset' : 'unset', '.MuiChip-label': { p: 1, fontSize: 12 } }}
                                    ref={(element) => {
                                        if (index === 0) return;
                                        chipRefs.current[index - 1] = element;
                                    }}
                                />
                            ))}

                            {overflowCount > 0 && (
                                <Typography alignSelf="center" color="secondary" ml={0.5} fontWeight="bold" fontSize={13}>
                                    +{overflowCount}
                                </Typography>
                            )}
                        </Stack>
                    ) : (
                        '-'
                    )}
                </TableCell>
                <TableCell>
                    <Typography fontWeight="bold" color="primary">
                        {formatPrice(row.value)}
                    </Typography>
                </TableCell>
                <TableCell sx={{ width: 50, maxWidth: 50 }}>{formatDate(row.createdAt)}</TableCell>
                <TableCell sx={{ width: 150, maxWidth: 150 }}>{getColors(row.items).length > 0 ? <ColorDrops colors={getColors(row.items)} /> : '-'}</TableCell>
                <TableCell>
                    {getMaterials(row.items)
                        .map((material) => wT(material, s))
                        .join(', ') || '-'}
                </TableCell>
                <TableCell>{getArea(row) || '-'}</TableCell>
                <TableCell sx={{ maxWidth: 120 }}>
                    <CalculationStatus status={row.status} mailConversation={mailConversation} />
                </TableCell>
                <TableCell sx={{ width: 155 }}>
                    <Button
                        startIcon={<EditTwoTone />}
                        variant="contained"
                        onClick={(event) => {
                            event.stopPropagation();
                            handleEditClicked(row);
                        }}
                    >
                        {t('edit')}
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow
                sx={{
                    boxShadow,
                    background: isOpen ? 'rgba(26, 117, 255, 0.07)' : '',
                    transition: 'box-shadow 0.4s'
                }}
            >
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, verticalAlign: 'top' }}></TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, verticalAlign: 'top', maxWidth: 80 }} colSpan={2}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <Stack py={1}>
                            <Stack direction="row" alignItems="center">
                                <Typography variant="subtitle2" fontWeight="bold" fontSize={13}>
                                    {t('id')}:&nbsp;
                                </Typography>
                                <Tooltip title={row.id}>
                                    <Typography variant="subtitle2" fontSize={13} noWrap>
                                        {row.id || '-'}
                                    </Typography>
                                </Tooltip>
                            </Stack>
                            <Stack direction="row">
                                <Typography variant="subtitle2" fontWeight="bold" fontSize={13}>
                                    {t('name')}:&nbsp;
                                </Typography>
                                <Typography variant="subtitle2" fontSize={13} noWrap>
                                    {row.name || '-'}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Collapse>
                </TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, verticalAlign: 'top' }}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <Stack py={1}>
                            {(isSet(row.customer?.firstName) || isSet(row.customer?.lastName)) && (
                                <Stack direction="row">
                                    <Typography variant="subtitle2" fontWeight="bold" fontSize={13}>
                                        {t('contactPerson')}:&nbsp;
                                    </Typography>
                                    <Typography variant="subtitle2" fontSize={13}>
                                        {getFullName(row.customer)}
                                    </Typography>
                                </Stack>
                            )}
                            {isSet(row.customer?.phone) && (
                                <Stack direction="row">
                                    <Typography variant="subtitle2" fontWeight="bold" fontSize={13}>
                                        {t('phone')}:&nbsp;
                                    </Typography>
                                    <Typography variant="subtitle2" fontSize={13}>
                                        {row.customer.phone}
                                    </Typography>
                                </Stack>
                            )}
                            {isSet(row.customer?.notes) && (
                                <Stack direction="row">
                                    <Typography variant="subtitle2" fontWeight="bold" fontSize={13}>
                                        {t('note')}:&nbsp;
                                    </Typography>
                                    <Typography variant="subtitle2" fontSize={13}>
                                        {row.customer.notes}
                                    </Typography>
                                </Stack>
                            )}
                            {/* {!!row.customer?.distance && (
                                <Stack direction="row">
                                    <Typography variant="subtitle2" fontWeight="bold" fontSize={13}>
                                        {t('distance')}:&nbsp;
                                    </Typography>
                                    <Typography variant="subtitle2" fontSize={13}>
                                        {formatDistance(row.customer.distance)}
                                    </Typography>
                                </Stack>
                            )} */}
                        </Stack>
                    </Collapse>
                </TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, verticalAlign: 'top', minWidth: '10rem', width: '12vw', maxWidth: '12vw' }}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <Stack py={1}>
                            {row.items.map((item, index) => (
                                <Typography key={index} variant="subtitle2" fontWeight="bold" fontSize={13} noWrap>
                                    {item.quantity} × {getText(item)}
                                </Typography>
                            ))}
                        </Stack>
                    </Collapse>
                </TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, verticalAlign: 'top' }}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <Stack py={1}>
                            {row.items.map((item, index) => (
                                <Typography key={index} variant="subtitle2" fontWeight="bold" fontSize={13} color="primary">
                                    {formatPrice(item.value)}
                                </Typography>
                            ))}
                        </Stack>
                    </Collapse>
                </TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, verticalAlign: 'top', width: 50, maxWidth: 50 }}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <Stack py={1}>
                            <Stack direction="row">
                                <Typography variant="subtitle2" fontWeight="bold" fontSize={13} noWrap overflow="unset">
                                    {t('anlieferung')}:&nbsp;
                                </Typography>
                                <Typography variant="subtitle2" fontSize={13} noWrap>
                                    {formatDate(row.bringDate, 'dd.MM.yyyy') || '-'}
                                </Typography>
                            </Stack>
                            <Stack direction="row">
                                <Typography variant="subtitle2" fontWeight="bold" fontSize={13} noWrap overflow="unset">
                                    {t('abholung')}:&nbsp;
                                </Typography>
                                <Typography variant="subtitle2" fontSize={13} noWrap>
                                    {formatDate(row.wishDate, 'dd.MM.yyyy') || '-'}
                                </Typography>
                            </Stack>
                            <Stack direction="row">
                                <Typography variant="subtitle2" fontWeight="bold" fontSize={13} noWrap overflow="unset">
                                    {t('duration')}:&nbsp;
                                </Typography>
                                <Typography variant="subtitle2" fontSize={13} noWrap>
                                    {throughput}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Collapse>
                </TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, verticalAlign: 'top' }}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <Stack py={1}>
                            {getColors(row.items).map((color, index) => (
                                <Typography key={index} variant="subtitle2" fontWeight="bold" fontSize={13}>
                                    {color}
                                </Typography>
                            ))}
                        </Stack>
                    </Collapse>
                </TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <Stack direction="row" justifyContent="flex-end">
                            <Button
                                startIcon={<ArticleTwoToneIcon />}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleViewClicked(row);
                                }}
                            >
                                {t('viewSummary')}
                            </Button>
                            <Button
                                color="secondary"
                                startIcon={<ContentCopyTwoToneIcon />}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleDuplicateClicked(row);
                                }}
                            >
                                {t('duplicate')}
                            </Button>
                            <Button
                                color="error"
                                startIcon={<DeleteTwoToneIcon />}
                                sx={{ mr: -1 }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleDeleteClicked(row.id);
                                }}
                            >
                                {t('delete')}
                            </Button>
                        </Stack>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        user: state.user.currentUser
    };
};
export default connect(mapStateToProps)(CalcOverviewTableRow);
