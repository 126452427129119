import { Typography, Stack, Button, useTheme, Collapse, styled } from '@mui/material';
import { useState } from 'react';
import HeadlineButton from 'src/components/display/HeadlineButton/HeadlineButton';
import VariableForm from '../Variable/VariableForm';
import { VariableResource } from 'src/backend/coreCalc';
import { getVariableName } from 'src/utils/CalcHelpers';

const StyledStack = styled(Stack)(
    ({ theme }) => `
        .MuiButtonBase-root {
            font-size: 14px;
        }
    `
);

interface Props {
    count: number;
    variableInternalIdentifier: string;
    variable: VariableResource;
    variables: Array<VariableResource>;
    saveVariable?: (variable: VariableResource) => void;
}

const CalcBuilderUsedVariablesItem: React.FC<Props> = ({ variableInternalIdentifier, count, variable, variables, saveVariable }) => {
    const theme = useTheme();
    const [isOpen, setOpen] = useState<boolean>(false);
    const [isValid, setValid] = useState<boolean>(false);
    const [localVariable, setLocalVariable] = useState<VariableResource>(variable);

    const handleClick = () => {
        saveVariable(localVariable);
    };

    return (
        <StyledStack>
            <HeadlineButton isOpen={isOpen} setOpen={(open) => setOpen(open)} disabled={!variable?.internalIdentifier}>
                {getVariableName(variable) || (
                    <>
                        {variableInternalIdentifier} - <span style={{ color: theme.colors.error.main, marginLeft: 4 }}>Variable nicht gefunden (eventuell wurde die Variable gelöscht)</span>
                    </>
                )}
                <span style={{ opacity: 0.6, fontWeight: 400, fontSize: 13, marginLeft: 'auto' }}>{count} mal verwendet</span>
            </HeadlineButton>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Stack mb={3} bgcolor={theme.colors.alpha.black[7]} p={3} sx={{ borderRadius: 2 }}>
                    <Typography variant="h3" mb={3}>
                        Variable ändern
                    </Typography>
                    {variable && <VariableForm variable={localVariable} variables={variables} setVariable={setLocalVariable} setValid={setValid} isSmall />}
                    <Button size="small" variant="outlined" sx={{ mt: 3, mr: 'auto' }} disabled={!isValid} onClick={handleClick}>
                        Änderungen speichern
                    </Button>
                </Stack>
            </Collapse>
        </StyledStack>
    );
};

export default CalcBuilderUsedVariablesItem;
