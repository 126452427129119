import { combineReducers } from '@reduxjs/toolkit';
import { reducer as adminReducer } from 'src/redux/slices/admin.reducer';
import { reducer as constantsReducer } from 'src/redux/slices/constants.reducer';
import { reducer as customersReducer } from 'src/redux/slices/customer.reducer';
import { reducer as calcEditorReducer } from 'src/redux/slices/calcEditor.reducer';
import { reducer as calcEditorsReducer } from 'src/redux/slices/calcEditors.reducer';
import { reducer as proCalcReducer } from 'src/redux/slices/proCalc.reducer';
import { reducer as proCalcsReducer } from 'src/redux/slices/proCalcs.reducer';
import { reducer as templatesReducer } from 'src/redux/slices/templates.reducer';
import { reducer as regularUsersReducer } from 'src/redux/slices/user.reducer';
import { reducer as languageReducer } from 'src/redux/slices/language.reducer';
import { reducer as guidethroughReducer } from 'src/redux/slices/Guidethrough.reducer';
import { reducer as fileManagerReducer } from 'src/redux/slices/fileManager.reducer';

const appReducer = combineReducers({
    user: regularUsersReducer,
    constants: constantsReducer,
    customers: customersReducer,
    calcEditor: calcEditorReducer,
    calcEditors: calcEditorsReducer,
    proCalc: proCalcReducer,
    proCalcs: proCalcsReducer,
    templates: templatesReducer,
    admin: adminReducer,
    language: languageReducer,
    guidethrough: guidethroughReducer,
    fileManager: fileManagerReducer
});

const rootReducer = (state, action) => {
    // Clear all data in redux store to initial.
    if (action.type === 'DESTROY_SESSION') state = undefined;

    return appReducer(state, action);
};

export default rootReducer;
