import { useTheme } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import { BlueprintUpdateTypes } from 'src/redux/shared/calcEditor.common';
import DeclarationVariableForm from './Forms/DeclarationVariableForm';
import TermVariableForm from './Forms/TermVariableForm';
import RulesBasedVariableForm from './Forms/RulesBasedVariableForm';
import RoundedVariableForm from './Forms/RoundedVariableForm';
import ProvidedVariableForm from './Forms/ProvidedVariableForm';
import SurchargeVariableForm from './Forms/SurchargeVariableForm';
import {
    DeclaredVariableResource,
    InputVariableResource,
    ProvidedVariableResource,
    RoundedVariableResource,
    RuleBasedVariableResource,
    SurchargeVariableResource,
    TermVariableResource,
    VariableResource
} from 'src/backend/coreCalc';
import { RootState } from 'src/redux/store';
import { CalcEditorUpdateLoading } from 'src/redux/slices/calcEditor.reducer';
import { isInputVariable } from 'src/utils/CalcHelpers';
import InputVariableForm from './Forms/InputVariableForm/InputVariableForm';

interface Props {
    variable: VariableResource;
    variables: Array<VariableResource>;
    setVariable: (variable: VariableResource) => void;
    setValid: (isValid: boolean) => void;
    isSmall?: boolean;
    isUpdateLoading: CalcEditorUpdateLoading;
}

const VariableForm: React.FC<Props> = ({ variable, variables, setVariable, setValid, isSmall, isUpdateLoading }) => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const isDisabled = variable?.id === -1 || isUpdateLoading[BlueprintUpdateTypes.MASTER_VARIABLE] || isUpdateLoading[BlueprintUpdateTypes.CATEGORY_VARIABLE];

    return (
        <>
            {variable.type === VariableResource.type.DECLARATION_VARIABLE && (
                <DeclarationVariableForm variable={variable as DeclaredVariableResource} setVariable={setVariable} setValid={setValid} isSmall={isSmall} isDisabled={isDisabled} />
            )}
            {variable.type === VariableResource.type.PROVIDED_VARIABLE && (
                <ProvidedVariableForm variable={variable as ProvidedVariableResource} setVariable={setVariable} setValid={setValid} isSmall={isSmall} isDisabled={isDisabled} />
            )}
            {variable.type === VariableResource.type.TERM_VARIABLE && (
                <TermVariableForm variable={variable as TermVariableResource} variables={variables} setVariable={setVariable} setValid={setValid} isSmall={isSmall} isDisabled={isDisabled} />
            )}
            {variable.type === VariableResource.type.RULE_BASED_VARIABLE && (
                <RulesBasedVariableForm
                    variable={variable as RuleBasedVariableResource}
                    variables={variables}
                    setVariable={setVariable}
                    setValid={setValid}
                    isSmall={isSmall}
                    isDisabled={isDisabled}
                />
            )}
            {variable.type === VariableResource.type.ROUND_VARIABLE && (
                <RoundedVariableForm variable={variable as RoundedVariableResource} variables={variables} setVariable={setVariable} setValid={setValid} isSmall={isSmall} isDisabled={isDisabled} />
            )}
            {isInputVariable(variable) && (
                <InputVariableForm variable={variable as InputVariableResource} variables={variables} setVariable={setVariable} setValid={setValid} isSmall={isSmall} isDisabled={isDisabled} />
            )}
            {variable.type === VariableResource.type.SURCHARGE_VARIABLE && (
                <SurchargeVariableForm variable={variable as SurchargeVariableResource} variables={variables} setVariable={setVariable} setValid={setValid} isSmall={isSmall} isDisabled={isDisabled} />
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        isUpdateLoading: state.calcEditor.isUpdateLoading
    };
};
export default connect(mapStateToProps)(VariableForm);
