import { useEffect, useState } from 'react';
import { Autocomplete, AutocompleteChangeReason, Box, TextField } from '@mui/material';
import { loadAllIso3166LanguageCodes } from 'src/redux/thunks/language.thunk';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

type Props = {
    value?: string;
    name?: string;
    disabled?: boolean;
    helperText?: string;
    onChange?: (event, selectedItem, reason: AutocompleteChangeReason) => void;
};

const CountrySelect = ({ value, name, disabled, helperText, onChange }: Props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [iso3166Codes, setIso3166Codes] = useState<Array<string>>([]);

    useEffect(() => {
        dispatch(
            loadAllIso3166LanguageCodes((codes) => {
                setIso3166Codes(codes);
            })
        );
    }, []);

    return (
        <Autocomplete
            fullWidth
            value={value || null}
            options={iso3166Codes}
            onChange={onChange}
            disabled={disabled}
            noOptionsText={t('noOptions')}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t('country')}
                    name={name}
                    inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(helperText)}
                    helperText={helperText}
                />
            )}
            getOptionLabel={(option) => t('country:' + option)}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 1.5, flexShrink: 0 } }} {...props}>
                    <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.toLowerCase()}.png 2x`} alt="" />
                    {t('country:' + option)} ({option})
                </Box>
            )}
        />
    );
};

export default CountrySelect;
