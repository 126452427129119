import {
    BinaryTermResource,
    CriterionResource,
    ElasticTermResource,
    HolderResource,
    RuleBasedVariableResource,
    RuleResource,
    SurchargeVariableResource,
    TermResource,
    TermVariableResource,
    VariableResource
} from 'src/backend/coreCalc';
import { TermOperators, FoundVariablesResult } from 'src/components/calc-editor/CalcEditor.types';
import { getHolderData, getVariable, getVariableFromHolder, getVariableName, getVariableValue } from './CalcHelpers';

export const getTermAsString = (term: TermResource, variables: Array<VariableResource>, showBrackets = true) => {
    if (!term) return '';

    if (term.type === TermResource.type.BINARY) {
        const binaryTerm = term as BinaryTermResource;
        const operator = TermOperators.find((o) => o.type === binaryTerm.operator);
        const operation = `${getTermAsString(binaryTerm.a, variables)} ${operator.symbol} ${getTermAsString(binaryTerm.b, variables)}`;
        return showBrackets ? `(${operation})` : operation;
    } else if (term.type === TermResource.type.ELASTIC) {
        const elasticTerm = term as ElasticTermResource;
        return getHolderData(elasticTerm.flexibleData, variables);
    }
    return '';
};

export const recursivelyRemoveTerm = (term: TermResource, termToDelete: TermResource) => {
    if (term.type !== TermResource.type.BINARY) return term;

    const isATermToDelete = [TermResource.type.ELASTIC, TermResource.type.BINARY].includes(term['a'].type) && term['a'] === termToDelete;
    const isBTermToDelete = [TermResource.type.ELASTIC, TermResource.type.BINARY].includes(term['b'].type) && term['b'] === termToDelete;

    if (isATermToDelete) {
        return { ...term['b'] };
    } else if (isBTermToDelete) {
        return { ...term['a'] };
    } else {
        // Recursively update 'a' and 'b' terms
        return {
            ...term,
            a: recursivelyRemoveTerm(term['a'], termToDelete),
            b: recursivelyRemoveTerm(term['b'], termToDelete)
        };
    }
};

export const listVariablesInCriterion = (criterion: CriterionResource, variables: Array<VariableResource>, depth = 0, result: FoundVariablesResult = new Map()) => {
    const leftVariable = getVariableFromHolder(criterion.left, variables);
    if (leftVariable) listVariablesInVariable(leftVariable, variables, depth + 1, result);
    const rightVariable = getVariableFromHolder(criterion.right, variables);
    if (rightVariable) listVariablesInVariable(rightVariable, variables, depth + 1, result);
};

export const listVariablesInRule = (rule: RuleResource, variables: Array<VariableResource>, depth = 0, result: FoundVariablesResult = new Map()) => {
    const valueHolderVariable = getVariableFromHolder(rule.valueHolder, variables);
    if (valueHolderVariable) listVariablesInVariable(valueHolderVariable, variables, depth + 1, result);
    for (const criterion of rule.criteria) {
        listVariablesInCriterion(criterion, variables, depth + 1, result);
    }
};

export const listVariablesInVariable = (variable: VariableResource, variables: Array<VariableResource>, depth = 0, result: FoundVariablesResult = new Map()) => {
    if (Object.keys(VariableResource.type).includes(variable.type)) {
        const existingEntry = result.get(variable.internalIdentifier);

        if (existingEntry) {
            result.set(variable.internalIdentifier, { count: existingEntry.count + 1, minDepth: Math.min(existingEntry.minDepth, depth), variable });
        } else {
            result.set(variable.internalIdentifier, { count: 1, minDepth: depth, variable });
        }

        if (variable?.type === VariableResource.type.RULE_BASED_VARIABLE) {
            const rules = (variable as RuleBasedVariableResource).rules || [];
            for (const rule of rules) {
                listVariablesInRule(rule, variables, depth + 1, result);
            }
        }

        if (variable?.type === VariableResource.type.SURCHARGE_VARIABLE) {
            const rules = (variable as SurchargeVariableResource).setValueRules || [];
            for (const rule of rules) {
                listVariablesInRule(rule, variables, depth + 1, result);
            }
            const criteria = (variable as SurchargeVariableResource).criteriaWhenSurchargeApplies || [];
            for (const criterion of criteria) {
                listVariablesInCriterion(criterion, variables, depth + 1, result);
            }
        }

        if (variable?.type === VariableResource.type.TERM_VARIABLE) {
            const term = (variable as TermVariableResource).term || {};
            listVariablesInTerm(term, variables, depth + 1, result);
        }
    }
    return result;
};

export const listVariablesInTerm = (term: TermResource, variables: Array<VariableResource>, depth = 0, result: FoundVariablesResult = new Map()) => {
    if (!term) return result;

    if (term.type === TermResource.type.ELASTIC) {
        const elasticTerm = term as ElasticTermResource;
        const variable = getVariableFromHolder(elasticTerm.flexibleData, variables);
        if (variable) listVariablesInVariable(variable, variables, depth, result);
    } else if (term.type === TermResource.type.BINARY) {
        const binaryTerm = term as BinaryTermResource;
        listVariablesInTerm(binaryTerm.a, variables, depth, result);
        listVariablesInTerm(binaryTerm.b, variables, depth, result);
    }

    return result;
};
