/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MasterVariableIdentifier {
    MASTER_RELATIVE_SURCHARGES = 'masterRelativeSurcharges',
    MASTER_ABSOLUTE_SURCHARGES = 'masterAbsoluteSurcharges',
    CATEGORIES_SUM = 'categoriesSum',
    INCOMING_DATE = 'incomingDate',
    OUTGOING_DATE = 'outgoingDate',
    MASTER_PRICE = 'masterPrice',
}